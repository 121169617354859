import { useApolloClient } from '@apollo/react-hooks'
import { css } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Tooltip, VFlow } from 'bold-ui'
import {
  CidadaoAtendimentoSelectField,
  EquipeSelectField,
  Form,
  FormRenderProps,
  SubmitButton,
  TextAreaField,
} from 'components/form'
import { GARANTIA_ACESSO_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { useAdicionarGarantiaAcessoMutation } from 'graphql/hooks.generated'
import React, { useMemo } from 'react'
import { metaPath } from 'util/metaPath'
import { resolveEquipeTooltip } from 'view/atendimentos/tooltipResolver'

import { createEquipeCalculations } from '../calculator-garantiaAcesso'
import { TipoAtendimentoSelectField } from '../components/TipoAtendimentoSelectField'
import { convertGarantiaAcessoFormModelToInput } from '../convert-garantiaAcesso'
import { GarantiaAcessoFormModel } from '../model-garantiaAcesso'
import { garantiaAcessoValidator } from '../validator-garantiaAcesso'

const path = metaPath<GarantiaAcessoFormModel>()

interface GarantiaAcessoFormViewProps {
  unidadeSaudeId: ID
  initialValues: GarantiaAcessoFormModel
  onRegistrosConflitantes(values: GarantiaAcessoFormModel): Promise<boolean>
  onResetFormFields: (formApi: FormApi) => void
  onSaveSuccess: () => void
}

export default function GarantiaAcessoFormView(props: GarantiaAcessoFormViewProps) {
  const { unidadeSaudeId, initialValues, onRegistrosConflitantes, onResetFormFields, onSaveSuccess } = props
  const apollo = useApolloClient()
  const [salvar] = useAdicionarGarantiaAcessoMutation()

  const save = (values: GarantiaAcessoFormModel, formApi: FormApi) =>
    salvar({ variables: { input: convertGarantiaAcessoFormModelToInput(values) } }).then(() => {
      onSaveSuccess()
      onResetFormFields(formApi)
    })

  const handleSubmit = (values: GarantiaAcessoFormModel, formApi: FormApi) => {
    return onRegistrosConflitantes(values).then((confirmed) => confirmed && save(values, formApi))
  }

  const renderForm = (formProps: FormRenderProps<GarantiaAcessoFormModel>) => {
    const { values } = formProps
    return (
      <Grid>
        <Cell size={5}>
          <VFlow>
            <CidadaoAtendimentoSelectField
              name={path.cidadao}
              label='Cidadão'
              title='Cidadão'
              ativo
              required
              placeholder='Buscar por Nome completo, CPF ou CNS'
              obito={false}
              addCidadaoCallbackUrl={GARANTIA_ACESSO_PATH}
              verificarPresenteNaListaAtendimento={false}
            />

            <TipoAtendimentoSelectField
              name={path.tipoAtendimento}
              isOnlyGarantiaAcesso
              label='Tipo de atendimento'
              title='Tipo de atendimento'
              required
            />

            <TextAreaField
              name={path.motivo}
              label='Motivo'
              title='Motivo'
              maxLength={200}
              required
              style={css`
                height: 5rem;
                resize: none;
              `}
            />
          </VFlow>
        </Cell>

        <Cell size={5}>
          <VFlow>
            <Tooltip text={resolveEquipeTooltip(values)}>
              <EquipeSelectField name={path.equipe} label='Equipe' unidadeSaudeId={unidadeSaudeId} />
            </Tooltip>
          </VFlow>
        </Cell>

        <Cell alignSelf='flex-end' size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='medium' onClick={() => onResetFormFields(formProps.form)}>
              Limpar campos
            </Button>
            <SubmitButton size='medium' handleSubmit={formProps.handleSubmit} data-testid='adicionarGarantiaAcesso'>
              Adicionar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  const decorator = useMemo(() => createDecorator(...createEquipeCalculations(path, unidadeSaudeId, apollo)), [
    unidadeSaudeId,
    apollo,
  ])

  return (
    <Form<GarantiaAcessoFormModel>
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={garantiaAcessoValidator()}
      decorators={[decorator]}
    />
  )
}
