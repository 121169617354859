/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, ButtonProps } from 'bold-ui'
import { useWidth } from 'hooks/useMeasure'
import React, { useState } from 'react'

export interface StickyButtonProps extends ButtonProps {
  showsAt: number | 'bottom'
  containerRef?: HTMLDivElement
}

export function StickyButton(props: StickyButtonProps) {
  const { showsAt, containerRef, ...rest } = props

  const [visible, isVisible] = useState(false)
  const [buttonRef, buttonWidth] = useWidth()

  const styles = createStyles(visible, !!containerRef, buttonWidth)

  React.useEffect(() => {
    const documentRef = containerRef ? containerRef : document.documentElement
    const eventListener = containerRef || window

    const listener = () => {
      const limit = showsAt === 'bottom' ? documentRef.scrollHeight - window.innerHeight : showsAt
      const isAtOrPastLimit = documentRef.scrollTop + 1 >= limit
      if (isAtOrPastLimit && !visible) {
        isVisible(true)
      } else if (!isAtOrPastLimit && visible) {
        isVisible(false)
      }
    }

    eventListener.addEventListener('scroll', listener)

    return () => eventListener.removeEventListener('scroll', listener)
  }, [containerRef, showsAt, visible])

  return (
    <div css={styles.wrapper}>
      <Button {...rest} innerRef={buttonRef} style={styles.button} />
    </div>
  )
}

const createStyles = (visible: boolean, usingRef: boolean, buttonWidth: number) => ({
  button: css`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `,
  wrapper: css`
    display: ${visible ? 'block' : 'none'};
    position: ${usingRef ? 'sticky' : 'fixed'};
    bottom: 0;
    ${!usingRef ? 'left: 50%;' : `margin-left: calc(50% - ${buttonWidth / 2}px);`}
  `,
})
