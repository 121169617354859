import { Cell, Grid } from 'bold-ui'
import { DateField, NumberField, TextField } from 'components/form'
import moment from 'moment'
import React from 'react'
import { MetaPath } from 'util/metaPath'

export interface ResponsavelFieldGroupModel {
  nomeResponsavel?: string
  dataNascimento?: string
  cpfOuCns?: string
}

export interface ResponsavelDadosFieldGroupProps {
  name: MetaPath<ResponsavelFieldGroupModel>
}

export default function ResponsavelDadosFieldGroup(props: ResponsavelDadosFieldGroupProps) {
  const { name } = props

  return (
    <Grid wrap>
      <Cell size={6}>
        <TextField name={name.nomeResponsavel} label='Nome' maxLength={70} uppercase />
      </Cell>
      <Cell size={6} />
      <Cell size={2}>
        <DateField name={name.dataNascimento} label='Data de nascimento' maxDate={moment().toDate()} />
      </Cell>
      <Cell size={2}>
        <NumberField name={name.cpfOuCns} label='CPF/CNS' maxLength={15} />
      </Cell>
    </Grid>
  )
}
