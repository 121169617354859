import { VFlow } from 'bold-ui'
import { HistoricoPlanoPecGuiaEncaminhamentoFragment } from 'graphql/types.generated'
import React from 'react'
import HistoricoDetailTable from 'view/atendimentos/detail/historico/components/table/HistoricoDetailTable'

import { HistoricoPlanoPecGuiaEncaminhamentoPanel } from './HistoricoPlanoPecGuiaEncaminhamentoPanel'

export type HistoricoPlanoPecGuiaEncaminhamento = HistoricoPlanoPecGuiaEncaminhamentoFragment

interface HistoricoPlanoPecGuiaEncaminhamentoListProps {
  guiasEncaminhamento: HistoricoPlanoPecGuiaEncaminhamentoFragment[]
}

export default function HistoricoPlanoPecGuiaEncaminhamentoList({
  guiasEncaminhamento,
}: HistoricoPlanoPecGuiaEncaminhamentoListProps) {
  return (
    <HistoricoDetailTable title='Guia de encaminhamento' hasLines>
      <VFlow>
        {guiasEncaminhamento.map((guiaEncaminhamento) => (
          <HistoricoPlanoPecGuiaEncaminhamentoPanel
            guiaEncaminhamento={guiaEncaminhamento}
            key={`box-cc-historico-${guiaEncaminhamento.id}`}
          />
        ))}
      </VFlow>
    </HistoricoDetailTable>
  )
}
