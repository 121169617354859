import { Button, Cell, DropdownItem, HFlow, Icon, Tooltip, useTheme } from 'bold-ui'
import { TableAccordionColumnConfig } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { AccordionDataTableRowDefaultProps } from 'components/accordion/accordion-data-table/AccordionDataTableRowDefault'
import CheckPermission from 'components/auth/CheckPermission'
import { DropdownButton } from 'components/dropdown'
import { MouseEvent, useState } from 'react'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import { LembreteModel } from 'view/atendimentos/detail/soap/aside/types/LembreteModel'
import { grupoCboLembretes } from 'view/atendimentos/detail/soap/plano/acessos'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { useAccordionControl } from '../../../../../../components/accordion/useAccordionControl'
import { useEditableListField } from '../../EditableList'
import { LembreteFormModel } from './components/LembreteForm'
import { convertLembreteModel } from './convert'
import { LembretesTable } from './LembretesTable'
import { LembreteTableRowEditable } from './LembreteTableRowEditable'

interface EditableLembretesTableProps {
  prontuarioId: ID
  meta: MetaPath<LembreteFormModel>
  lotacao: LotacaoAtendimento
  isAtendimentoObservacao: boolean
  lembretesCache?: LembreteFormModel[]
}

const isItemFromCache = (itemId: ID) => itemId !== undefined

export function EditableLembretesTable(props: EditableLembretesTableProps) {
  const { prontuarioId, meta, lotacao, lembretesCache } = props

  const { handleSubmit, handleRowChanged, removeItem, handleSubmitWithoutReset } = useEditableListField({ name: meta })

  const [lembreteEmEdicaoId, setLembreteEmEdicaoId] = useState<ID>(null)

  const { resetExpandedItems } = useAccordionControl({})

  const isEditing = (lembrete?: LembreteModel): boolean =>
    lembreteEmEdicaoId !== null &&
    lembrete !== null &&
    (lembreteEmEdicaoId === lembrete.id || lembreteEmEdicaoId === lembrete._id)

  const handleEditClick = (event: MouseEvent<HTMLElement>, lembrete: LembreteModel) => {
    event.stopPropagation()
    if (!isEditing(lembrete)) setLembreteEmEdicaoId(lembrete.id || lembrete._id)
  }

  const removeLembreteEmEdicao = (lembrete: LembreteModel) => {
    if (isEditing(lembrete)) setLembreteEmEdicaoId(null)
  }

  const theme = useTheme()

  const handleDelete = (event: MouseEvent<HTMLElement>, lembreteId: ID) => {
    event.stopPropagation()
    const lembrete: LembreteFormModel = {
      _id: lembreteId,
      visibilidadeLembrete: null,
      descricao: null,
      ativo: null,
    }
    lembreteId && removeItem(lembrete)
    resetExpandedItems()
  }

  const handleAtivarOrInativar = (event: MouseEvent<HTMLElement>, lembrete: LembreteModel) => {
    event.stopPropagation()
    const lembreteChanged = convertLembreteModel(lembrete)
    lembreteChanged.ativo = !lembreteChanged.ativo
    const isLembreteFromCache = lembrete._id !== undefined
    isLembreteFromCache ? handleRowChanged(lembreteChanged) : handleSubmitWithoutReset(lembreteChanged)
    resetExpandedItems()
  }

  const renderButtons = (row: LembreteModel) => {
    const isAtivo = row.historicoLembrete[0]?.ativo
    return (
      <CheckPermission permission={grupoCboLembretes.adicionar}>
        <Cell size={4}>
          <HFlow hSpacing={0} alignItems='center'>
            <Tooltip text='Editar'>
              <Button size='small' kind='normal' skin='ghost' onClick={(e) => handleEditClick(e, row)}>
                <Icon name='accordionEditIcon' icon='penOutline' />
              </Button>
            </Tooltip>
            <Tooltip text='Mais opções'>
              <DropdownButton stopPropagationOnClick isInsideModal disabled={isEditing(row)}>
                <DropdownItem onClick={(e) => handleAtivarOrInativar(e, row)}>
                  {isAtivo ? 'Inativar' : 'Ativar'}
                </DropdownItem>
                <DropdownItem
                  type='danger'
                  onClick={(e) => handleDelete(e, row._id)}
                  disabled={!isItemFromCache(row._id)}
                >
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Icon icon='trashOutline' />
                    Excluir
                  </HFlow>
                </DropdownItem>
              </DropdownButton>
            </Tooltip>
          </HFlow>
        </Cell>
      </CheckPermission>
    )
  }

  const recenteColumn: TableAccordionColumnConfig<LembreteModel> = {
    name: 'recente',
    header: '',
    render: (item) => {
      return (
        isItemFromCache(item._id) && (
          <Tooltip text='Registrado agora'>
            <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
          </Tooltip>
        )
      )
    },
    size: 1,
  }

  const buttonsColumn: TableAccordionColumnConfig<LembreteModel> = {
    name: 'buttons',
    header: '',
    render: renderButtons,
    size: 3,
  }

  const customRow = (props: AccordionDataTableRowDefaultProps<LembreteModel>) => (
    <LembreteTableRowEditable
      isEditing={isEditing(props.row)}
      onRowChanged={handleRowChanged}
      onAddRow={handleSubmit}
      removeLembreteEmEdicao={removeLembreteEmEdicao}
      {...props}
    />
  )

  return (
    <LembretesTable
      prontuarioId={prontuarioId}
      lotacao={lotacao}
      lembretesCache={lembretesCache}
      extraColumns={[recenteColumn, buttonsColumn]}
      disableRow={isEditing}
      customRow={customRow}
    />
  )
}
