/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Icon, Theme, Tooltip, useTheme } from 'bold-ui'
import { OrigemAtendimento } from 'graphql/types.generated'

interface OrigemAtendimentoObservacaoIconProps {
  origemAtendimento: OrigemAtendimento
}

export const OrigemAtendimentoObservacaoIcon = (props: OrigemAtendimentoObservacaoIconProps) => {
  const { origemAtendimento } = props

  const theme = useTheme()
  const classes = styles(theme)

  const tooltipText =
    origemAtendimento === OrigemAtendimento.CDS
      ? 'Registros de origem CDS não possuem data nem horário de inicio e fim da observação'
      : origemAtendimento === OrigemAtendimento.RAS_PEC
      ? 'Registros de outras instalações não possuem data nem horário de inicio e fim da observação'
      : origemAtendimento === OrigemAtendimento.SISTEMA_TERCEIRO
      ? 'Registros de sistemas terceiros não possuem data nem horário de inicio e fim da observação'
      : ''

  return (
    <Tooltip text={tooltipText} placement='bottom-end'>
      <Icon icon='infoCircleFilled' fill='normal' size={1} style={classes.infoIcon} />
    </Tooltip>
  )
}

const styles = (theme: Theme) => ({
  infoIcon: css`
    &:hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
})
