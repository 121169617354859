import { HFlow, PagedTable, Tag, Text } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useUnidadeSaudeDetailQuery, useUnidadeSaudeTipoServicoQuery } from 'graphql/hooks.generated'
import { UnidadeSaudeTipoServicoQuery, UnidadeSaudeTipoServicoQueryInput } from 'graphql/types.generated'
import React, { useState } from 'react'
import Permissions from 'types/Permissions'
import TipoServicoTableFilter from 'view/unidade-saude/tipo-servico/list/TipoServicoTableFilter'

import TipoServicoTableDropdown from './TipoServicoTableDropdown'

export type UnidadeSaudeTipoServicoItem = UnidadeSaudeTipoServicoQuery['unidadeSaudeTiposServico']['content'][0]

export interface TipoServicoTableProps {
  unidadeSaudeId: ID
}

export default function TipoServicoTable(props: TipoServicoTableProps) {
  const [filter, setFilter] = useState<UnidadeSaudeTipoServicoQueryInput>({
    unidadeSaudeId: props.unidadeSaudeId,
    mostrarInativos: false,
    pageParams: {
      sort: ['nome'],
    },
  })

  const {
    data: { unidadeSaude },
  } = useUnidadeSaudeDetailQuery({
    variables: { id: props.unidadeSaudeId },
  })

  const {
    data: { unidadeSaudeTiposServico },
    loading,
    refetch,
  } = useUnidadeSaudeTipoServicoQuery({
    variables: { input: filter },
  })

  const renderButtons = (row: UnidadeSaudeTipoServicoItem) => {
    return (
      <CheckPermission permission={Permissions.visualizarUnidadesDeSaude.ativarOuInativarTipoDeServico}>
        <TipoServicoTableDropdown
          unidadeSaudeId={props.unidadeSaudeId}
          row={row}
          onUpdated={refetch}
          permissionToEditTipoServico={unidadeSaude.actions.permissaoAtivarInativarTipoServico}
        />
      </CheckPermission>
    )
  }

  const tableProps = usePagedTableProps({
    result: unidadeSaudeTiposServico,
    loading,
    onChange: setFilter,
  })

  return (
    <TableBox header={<TipoServicoTableFilter onChange={setFilter} initialValues={filter} />}>
      <PagedTable<UnidadeSaudeTipoServicoItem>
        {...tableProps}
        columns={[
          { name: 'nome', header: 'Tipo de serviço', render: renderNome, sortable: true },
          { name: 'buttons', render: renderButtons, style: { textAlign: 'right' } },
        ]}
      />
    </TableBox>
  )
}

const renderNome = (row: UnidadeSaudeTipoServicoItem) => (
  <HFlow hSpacing={0.5} alignItems='center'>
    <Text>{row.tipoServico.nome.toUpperCase()}</Text>
    {!row.ativo && <Tag>Inativo</Tag>}
  </HFlow>
)
