import { PivotTableProps } from 'components/pivot-table/PivotTable'
import { TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldDocument } from 'graphql/hooks.generated'

import { KeyMapping, keyMappings } from '../common/keyMapping-relatorioGerencial'
import { VisibilidadeModeloPersonalizadoEnum } from '../components/modelo-personalizado/form/model-modeloPersonalizadoRelatorioGerencialForm'
import {
  AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldDocument,
  TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldDocument,
} from './../../../../graphql/hooks.generated'
import { RelatorioGerencialAtendimentoProfissionalModel } from './model-relatorioGerencialAtendimentoProfissional'

const tipoAtendimento: KeyMapping = {
  keyName: 'Tipo de atendimento',
  formatter: (s: string) => s?.titleCase(),
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
  queryDocument: TipoDeAtendimentoRelatorioGerencialAtendimentoSelectFieldDocument,
}

const tipoConsulta: KeyMapping = {
  keyName: 'Tipo de consulta',
  formatter: (s: string) => s?.titleCase(),
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
  queryDocument: TipoDeConsultaRelatorioGerencialAtendimentoSelectFieldDocument,
}

const atendimentoCompartilhadoLotacao: KeyMapping = {
  keyName: 'Atendimento compartilhado',
  visibility: VisibilidadeModeloPersonalizadoEnum.PUBLICO,
  queryDocument: AtendimentoCompartilhadoRelatorioGerencialAtendimentoSelectFieldDocument,
}

export const atendimentoProfissionalKeyMapping: PivotTableProps<
  RelatorioGerencialAtendimentoProfissionalModel
>['keyMapping'] = new Map<keyof RelatorioGerencialAtendimentoProfissionalModel, KeyMapping>([
  ['uf', keyMappings.uf],
  ['municipio', keyMappings.municipio],
  ['unidadeSaude', keyMappings.unidadeSaude],
  ['equipe', keyMappings.equipe],
  ['tipoAtendimento', tipoAtendimento],
  ['nomeProfissional', keyMappings.nomeProfissional],
  ['turno', keyMappings.turno],
  ['mes', keyMappings.mes],
  ['sexo', keyMappings.sexo],
  ['dia', keyMappings.dia],
  ['diaDaSemana', keyMappings.diaDaSemana],
  ['hora', keyMappings.hora],
  ['tipoConsulta', tipoConsulta],
  ['atendimentoCompartilhadoLotacao', atendimentoCompartilhadoLotacao],
  ['categoriaProfissional', keyMappings.categoriaProfissional],
  ['semana', keyMappings.semana],
  ['faixaEtaria', keyMappings.faixaEtaria],
  ['cbo', keyMappings.cbo],
  ['producoes', keyMappings.producoes],
])
