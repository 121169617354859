import { DateRange } from 'bold-ui'
import { has } from 'lodash'
import { useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { InstantRange, LocalDateRange } from 'util/date/dateRange'

type CountConditionModel<T> = {
  [P in keyof T]?: boolean
}

export function useFilterCounter<T extends object>(filter: T, countConditions?: CountConditionModel<T>): number {
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    let internalCounter = 0
    Object.entries(filter).forEach(([key, value]) => {
      const countCondition = has(countConditions, key) ? countConditions[key] : true
      if (countCondition) {
        if (Array.isArray(value)) {
          internalCounter += value.length
        } else if (isAnyDateRange(value)) {
          if (!isUndefinedOrNull(value.startDate) || !isUndefinedOrNull(value.endDate)) {
            internalCounter++
          }
        } else if (!!value || value === 0) {
          internalCounter++
        }
      }
    })
    setCounter(internalCounter)
  }, [countConditions, filter])

  return counter
}

function hasDateProperties(range: any): boolean {
  return has(range, 'startDate') && has(range, 'endDate')
}

function isValidDateValue(value: any): boolean {
  return isUndefinedOrNull(value) || typeof value === 'object' || typeof value === 'number' || typeof value === 'string'
}

function isAnyDateRange(range: any): range is DateRange | InstantRange | LocalDateRange {
  if (hasDateProperties(range)) {
    return isValidDateValue(range.startDate) && isValidDateValue(range.endDate)
  }
  return false
}
