import { Portal } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { usePopperButton } from 'hooks/usePopperButton'
import { useServerTime } from 'hooks/useServerTime'
import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router'
import { AgendamentoEdicaoView } from 'view/agenda/cadastro-agendamento/AgendamentoEdicaoView'

import { AcessarVideochamadaAgendamentoButton } from './buttons/AcessarVideochamadaAgendamentoButton'
import { CancelarAgendamentoButton } from './buttons/CancelarAgendamentoButton'
import { EditarAgendamentoButton } from './buttons/EditarAgendamentoButton'
import { ImprimirAgendamentoButton } from './buttons/ImprimirAgendamentoButton'
import { InformarFaltaCidadaoButton } from './buttons/InformarFaltaCidadaoButton'
import { InserirListaAtendimentoButton } from './buttons/InserirListaAtendimentoButton'
import { RemoverFaltaCidadaoButton } from './buttons/RemoverFaltaCidadaoButton'
import { RemoverListaAtendimentoButton } from './buttons/RemoverListaAtendimentoButton'
import { VisualizarInformacoesCidadaoButton } from './buttons/VisualizarInformacoesCidadaoButton'
import { VisualizarProntuarioButton } from './buttons/VisualizarProntuarioButton'
import { getStatusAcoesConsulta } from './getStatusAcoesConsulta'
import { getStatusAcoesConsultaAD } from './getStatusAcoesConsultaAD'
import JustificativaCancelarAgendamentoModal from './JustificativaCancelarAgendamentoModal'
import MenuAcoesAgendamento from './MenuAcoesAgendamento'
import { AcaoAgendamentoButtonProps, AcaoAgendamentoEnum, AcoesAgendamentoProps } from './model-acoesAgendamento'

export interface AcoesAgendamentoConsultaProps extends AcoesAgendamentoProps {
  isDropdown: boolean
}

export function AcoesAgendamentoConsulta(props: AcoesAgendamentoConsultaProps) {
  const { event, isDropdown } = props
  const { getServerTimeNow } = useServerTime()

  const history = useHistory()
  const { data: session } = useSession()

  const hasAcessoVideochamada = event.videochamadaUuid && session.acesso?.id === event.lotacao?.id

  const [isModalJustificativaOpen, setModalJustificativaOpen] = useState(false)

  const statusAcoes = event.isAtencaoDomiciliar
    ? getStatusAcoesConsultaAD({ dataConsulta: event.start, dataAtual: getServerTimeNow() })
    : getStatusAcoesConsulta({
        situacao: event.situacao,
        situacaoAtendimento: event.atendimento?.situacao,
        possuiAtendimentoPrevio: event.atendimento?.possuiAtendimentosProfissionais,
        isForaUbs: event.isForaUbs,
        dataAtual: getServerTimeNow(),
        dataConsulta: new Date(event.start),
      })
  const statusVisualizarProntuario = statusAcoes[AcaoAgendamentoEnum.VISUALIZAR_PRONTUARIO]
  const statusEditarAgendamento = statusAcoes[AcaoAgendamentoEnum.EDITAR_AGENDAMENTO]
  const statusCancelarAgendamento = statusAcoes[AcaoAgendamentoEnum.CANCELAR_AGENDAMENTO]
  const statusImprimirAgendamento = statusAcoes[AcaoAgendamentoEnum.IMPRIMIR_AGENDAMENTO]
  const isConsultaCompartilhada = !!event.nomeProfissionalParticipante

  const handleClickVisualizarProntuario = () => {
    history.push(`/cidadao/${event.cidadaoId}/folha-rosto`)
  }

  const { rootRef, popperContainerRef, buttonRef, toggle, close, isOpen, popperContainerStyle } = usePopperButton({
    referenceElement: props.eventoElement,
    placement: 'bottom-start',
    strategy: 'absolute',
    closeOnBackdropClick: true,
  })

  return (
    <Fragment>
      <Portal>
        <div ref={rootRef}>
          {isOpen && (
            <div
              ref={popperContainerRef}
              className={popperContainerStyle}
              data-placement='bottom-start'
              data-visible={isOpen}
            >
              <AgendamentoEdicaoView {...props} onClose={close} isAtencaoDomiciliar={event.isAtencaoDomiciliar} />
            </div>
          )}
        </div>
      </Portal>
      <MenuAcoesAgendamento>
        {hasAcessoVideochamada && (
          <AcessarVideochamadaAgendamentoButton videochamadaUuid={event.videochamadaUuid} isDropdown={isDropdown} />
        )}
        {statusVisualizarProntuario.visible && (
          <VisualizarProntuarioButton
            {...props}
            disabled={statusVisualizarProntuario.disabled}
            tooltip={statusVisualizarProntuario.tooltip}
            isDropdown={!isConsultaCompartilhada || hasAcessoVideochamada}
            onClick={handleClickVisualizarProntuario}
          />
        )}
        <VisualizarInformacoesCidadaoButton
          cidadaoId={event.cidadaoId}
          isDropdown={isDropdown}
          disabled={statusVisualizarProntuario.disabled}
        />
        {Object.keys(AcaoAgendamentoEnum).map(
          (acao) =>
            statusAcoes[acao].visible &&
            recordBotoesAcoes[acao] &&
            React.createElement(recordBotoesAcoes[acao], {
              ...props,
              key: acao,
              disabled: statusAcoes[acao].disabled,
              tooltip: statusAcoes[acao].tooltip,
              isDropdown,
            })
        )}
        <EditarAgendamentoButton
          {...props}
          buttonRef={buttonRef}
          disabled={statusEditarAgendamento.disabled}
          tooltip={statusEditarAgendamento.tooltip}
          onClick={toggle}
          isDropdown
        />
        <ImprimirAgendamentoButton
          {...props}
          disabled={statusImprimirAgendamento.disabled}
          tooltip={statusImprimirAgendamento.tooltip}
          isDropdown
        />
        <CancelarAgendamentoButton
          {...props}
          openModalCancelarAgendamento={() => setModalJustificativaOpen(true)}
          disabled={statusCancelarAgendamento.disabled}
          tooltip={statusCancelarAgendamento.tooltip}
          isDropdown
        />
      </MenuAcoesAgendamento>
      <JustificativaCancelarAgendamentoModal
        event={event}
        open={isModalJustificativaOpen}
        setOpen={setModalJustificativaOpen}
      />
    </Fragment>
  )
}

const recordBotoesAcoes: Partial<Record<AcaoAgendamentoEnum, React.ComponentType<AcaoAgendamentoButtonProps>>> = {
  INSERIR_LISTA_ATENDIMENTO: InserirListaAtendimentoButton,
  REMOVER_LISTA_ATENDIMENTO: RemoverListaAtendimentoButton,
  INFORMAR_FALTA_CIDADAO: InformarFaltaCidadaoButton,
  REMOVER_FALTA_CIDADAO: RemoverFaltaCidadaoButton,
}
