/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, FormControl, Grid, HFlow, Icon, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import { LotacaoAndEstagioSelectField } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { RichTextField } from 'components/form/final-form/RichTextField/RichTextField'
import { Cbo } from 'graphql/types.generated'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import {
  CondutaGroupEnum,
  CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER,
  CuidadoCompartilhadoLotacaoSolicitanteModel,
  DiscussaoCasoExecutanteFieldsModel,
  DiscussaoCasoSolicitanteFieldsModel,
  TrocaExecutanteCuidadoCompartilhadoFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CboTrocaExecutanteSelectField } from '../CboTrocaExecutanteSelectField/CboTrocaExecutanteSelectField'
import { CondutaGroupCuidadoCompartilhadoRadioFieldContainer } from './CondutaGroupCuidadoCompartilhadoRadioFieldContainer'

interface TrocaExecutanteCuidadoCompartilhadoFieldProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel | DiscussaoCasoSolicitanteFieldsModel>
  lotacaoSolicitante: CuidadoCompartilhadoLotacaoSolicitanteModel
  profissionalExecutanteId: ID
  unidadeSaudeExecutanteId: ID
  isEspecializada: boolean
}

export const TrocaExecutanteCuidadoCompartilhadoField = (props: TrocaExecutanteCuidadoCompartilhadoFieldProps) => {
  const { path, lotacaoSolicitante, profissionalExecutanteId, unidadeSaudeExecutanteId, isEspecializada } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const name = path.trocaExecutante
  const excludeProfissionaisIds = [lotacaoSolicitante.profissional.id, profissionalExecutanteId]
  const equipeSolicitanteId = !isEspecializada ? lotacaoSolicitante.equipe?.id : null
  const condutaText = isEspecializada
    ? 'A troca de profissional de referência pode ser feita para qualquer profissional deste estabelecimento.'
    : 'A troca de profissional de referência pode ser feita para qualquer profissional vinculado com a equipe do profissional solicitante.'
  const tooltipText = isEspecializada
    ? 'Este campo exibe apenas os profissionais deste estabelecimento.'
    : 'O campo só traz profissionais de equipes NASF e eMulti vinculados a essa unidade.'

  const {
    input: { value: cbo },
  } = useField<Cbo>(name.cbo.absolutePath(), { subscription: { value: true } })

  return (
    <CondutaGroupCuidadoCompartilhadoRadioFieldContainer<TrocaExecutanteCuidadoCompartilhadoFieldsModel>
      name={name}
      pathCondutaGroup={path.condutaGroup}
      value={CondutaGroupEnum.TROCA_PROFISSIONAL_REFERENCIA}
    >
      <Grid>
        <Cell size={12}>
          <Text>{condutaText}</Text>
        </Cell>
        <Cell size={6}>
          <CboTrocaExecutanteSelectField
            name={name.cbo}
            label='CBO'
            estabelecimentoReferenciaId={unidadeSaudeExecutanteId}
            equipeVinculadaId={equipeSolicitanteId}
            excludeProfissionaisIds={excludeProfissionaisIds}
            onlyVinculacaoEquipe={!isEspecializada}
          />
        </Cell>
        <Cell size={6}>
          <FormControl label='Profissional' required>
            <HFlow hSpacing={0.5} alignItems='baseline' style={styles.hFlow}>
              <LotacaoAndEstagioSelectField
                name={name.lotacao}
                unidadeSaudeId={unidadeSaudeExecutanteId}
                cboId={cbo?.id}
                canWriteAtendimento={false}
                nivelSuperior
                excludeProfissionaisIds={excludeProfissionaisIds}
                apenasEquipeCuidadoCompartilhado={!isEspecializada}
                equipeVinculadaId={equipeSolicitanteId}
                required
              />
              <Tooltip text={tooltipText} placement='top'>
                <Icon icon='infoCircleFilled' fill='normal' size={1} style={styles.iconTooltip} />
              </Tooltip>
            </HFlow>
          </FormControl>
        </Cell>
        <Cell size={12}>
          <RichTextField
            name={name.motivo}
            label='Motivo'
            placeholder={CUIDADO_COMPARTILHADO_CONDUTAS_PLACEHOLDER}
            maxLength={1000}
            required
          />
        </Cell>
      </Grid>
    </CondutaGroupCuidadoCompartilhadoRadioFieldContainer>
  )
}

const createStyles = (theme: Theme) => ({
  hFlow: css`
    grid-auto-columns: 1fr auto;
  `,
  iconTooltip: css`
    &:hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
})
