/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Portal } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { isBefore } from 'date-fns'
import { usePopperButton } from 'hooks/usePopperButton'
import { useServerTime } from 'hooks/useServerTime'
import { Fragment, useState } from 'react'
import { AgendamentoEdicaoView } from 'view/agenda/cadastro-agendamento/AgendamentoEdicaoView'

import { AcessarVideochamadaAgendamentoButton } from './buttons/AcessarVideochamadaAgendamentoButton'
import { CancelarAgendamentoButton } from './buttons/CancelarAgendamentoButton'
import { EditarAgendamentoButton } from './buttons/EditarAgendamentoButton'
import JustificativaCancelarAgendamentoModal from './JustificativaCancelarAgendamentoModal'
import { AcoesAgendamentoProps } from './model-acoesAgendamento'
import { editarAgendamentoTooltip, excluirAgendamentoTooltip } from './utils'

export function AcoesAgendamentoReserva(props: AcoesAgendamentoProps) {
  const { event } = props

  const [isModalJustificativaOpen, setModalJustificativaOpen] = useState(false)

  const { data: session } = useSession()
  const { getServerTimeNow } = useServerTime()
  const isPassado = isBefore(event.start, getServerTimeNow())
  const hasAcessoVideochamada = event.videochamadaUuid && session.acesso?.id === event.lotacao?.id
  const isConsultaCompartilhada = !!event.nomeProfissionalParticipante
  const isParticipanteConsultaCompartilhada =
    isConsultaCompartilhada && event.lotacaoProfissionalAnfitriaoId !== event.lotacao.id

  const { rootRef, popperContainerRef, buttonRef, toggle, close, isOpen, popperContainerStyle } = usePopperButton({
    referenceElement: props.eventoElement,
    placement: 'bottom-start',
    strategy: 'absolute',
    closeOnBackdropClick: true,
  })

  return (
    <Fragment>
      <Portal>
        <div ref={rootRef}>
          {isOpen && (
            <div
              ref={popperContainerRef}
              className={popperContainerStyle}
              data-placement='bottom-start'
              data-visible={isOpen}
            >
              <AgendamentoEdicaoView {...props} onClose={close} isAtencaoDomiciliar={event.isAtencaoDomiciliar} />
            </div>
          )}
        </div>
      </Portal>
      <JustificativaCancelarAgendamentoModal
        event={event}
        open={isModalJustificativaOpen}
        setOpen={setModalJustificativaOpen}
      />
      <HFlow
        hSpacing={0.5}
        justifyContent='flex-end'
        alignItems='center'
        style={css`
          height: 100%;
          padding-right: 1rem;
        `}
      >
        {hasAcessoVideochamada && <AcessarVideochamadaAgendamentoButton videochamadaUuid={event.videochamadaUuid} />}
        <EditarAgendamentoButton
          {...props}
          buttonRef={buttonRef}
          disabled={isPassado || (isConsultaCompartilhada && isParticipanteConsultaCompartilhada)}
          tooltip={editarAgendamentoReservaTooltip(isPassado, isParticipanteConsultaCompartilhada)}
          onClick={toggle}
        />
        <CancelarAgendamentoButton
          {...props}
          openModalCancelarAgendamento={() => setModalJustificativaOpen(true)}
          disabled={isPassado}
          tooltip={excluirAgendamentoTooltip(isPassado)}
        />
      </HFlow>
    </Fragment>
  )
}

const editarAgendamentoReservaTooltip = (isPassado: boolean, isParticipanteConsultaCompartilhada: boolean) =>
  isParticipanteConsultaCompartilhada
    ? 'A edição do agendamento entre profissionais deve ser feita na agenda do profissional responsável'
    : editarAgendamentoTooltip(isPassado)
