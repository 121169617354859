/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Cell, Grid, HFlow, Icon, Tag, Tooltip } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import useSession from 'components/auth/useSession'
import { SelectFieldWithAddButton } from 'components/form/final-form/SelectField/SelectFieldWithAddButton'
import { useModelosByTipoQuery } from 'graphql/hooks.generated'
import { TipoModuloEnum } from 'graphql/types.generated'
import { useEffect } from 'react'
import { useField } from 'react-final-form'
import { useHistory, useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import { MetaPath } from 'util/metaPath'

import { MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL } from './modal/ModeloPersonalizadorRelatorioGerencialModal'
import {
  ModeloPersonalizadoModel,
  ModeloPersonalizadoSelectFieldModel,
  modeloPersonalizadoToString,
} from './model-modeloPersonalizado'

interface ModeloPersonalizadoSelectFieldProps {
  name: MetaPath<ModeloPersonalizadoModel>
  tipoModulo: TipoModuloEnum
  disabled: boolean
  fetchModeloPersonalizadoById: (id: string) => void
  resetPivotTable: () => void
}

export function ModeloPersonalizadoSelectField(props: ModeloPersonalizadoSelectFieldProps) {
  const { name, tipoModulo, disabled, fetchModeloPersonalizadoById, resetPivotTable } = props
  const styles = createStyles()

  const { url } = useRouteMatch()
  const history = useHistory()
  const { hasAuthorization } = useSession()

  const {
    input: { value: modeloSelecionado, onChange: setModeloSelecionado },
  } = useField<ModeloPersonalizadoModel>(name.absolutePath(), { subscription: { value: true } })

  const {
    data: { modelosPersonalizadosByTipoModulo: modelos },
    loading,
  } = useModelosByTipoQuery({
    variables: { tipoModulo },
  })

  useEffect(() => {
    const hasExcludedModeloSelecionado =
      modelos && modeloSelecionado && !modelos.some((modelo) => modelo.id === modeloSelecionado.id)

    if (hasExcludedModeloSelecionado) {
      setModeloSelecionado(undefined)
      resetPivotTable()
    }
  }, [resetPivotTable, modeloSelecionado, modelos, fetchModeloPersonalizadoById, setModeloSelecionado])

  const handleChange = (modelo: ModeloPersonalizadoSelectFieldModel) => {
    if (modelo) fetchModeloPersonalizadoById(modelo.id)
    resetPivotTable()
  }

  const handleClickCadastrarModelo = () => {
    history.push(`${url}${MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL}`)
  }

  const handleClickEditarModelo = () => {
    history.push(`${url}${MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL}/${modeloSelecionado?.id}`)
  }

  const canWriteModeloPublico = hasAuthorization(Permissions.relatorios.gerenciais.cadastrarModelosPublicos)

  const hasSelectedReadOnlyModelo = modeloSelecionado.isPublico && !canWriteModeloPublico
  const editarModeloButtonTooltip = hasSelectedReadOnlyModelo
    ? 'Você não tem permissão para editar modelos públicos'
    : null

  const renderItem = (item: ModeloPersonalizadoModel) => (
    <HFlow alignItems='center'>
      {item.nome}
      {item.isPublico && <Tag style={styles.tag}>Público</Tag>}
    </HFlow>
  )

  return (
    <Grid gap={1} gapVertical={0.25}>
      <Cell size={4}>
        <SelectFieldWithAddButton<ModeloPersonalizadoModel>
          label='Modelo de relatório com filtros personalizados'
          itemToString={modeloPersonalizadoToString}
          addText={undefined}
          addButtonText='Cadastrar modelo'
          onClickAddButton={handleClickCadastrarModelo}
          name={name}
          onChange={handleChange}
          items={modelos}
          loading={loading}
          disabled={disabled}
          renderItem={renderItem}
        />
      </Cell>
      <Cell alignSelf='flex-end'>
        {modeloSelecionado?.id && (
          <Tooltip text={editarModeloButtonTooltip}>
            <Button
              onClick={handleClickEditarModelo}
              kind='primary'
              skin='outline'
              size='small'
              disabled={disabled || hasSelectedReadOnlyModelo}
            >
              <Icon name='accordionEditIcon' icon='penOutline' style={styles.icon} />
              Editar modelo
            </Button>
          </Tooltip>
        )}
      </Cell>
      {modeloSelecionado?.hasCampoInconsistente && (
        <Cell size={12}>
          <Alert inline type='warning' styles={{ wrapper: styles.alertWrapper }}>
            Existem campos inconsistentes no relatório selecionado. Edite o modelo para resolver as inconsistências.
          </Alert>
        </Cell>
      )}
    </Grid>
  )
}

const createStyles = () => ({
  icon: css`
    margin-right: 0.5rem;
  `,
  alertWrapper: css`
    background: none;
    border: none;
    padding: 0;
  `,
  tag: css`
    background-color: transparent;
    color: ${blue.c40};
    border-color: ${blue.c40};
    border: 1px solid;
  `,
})
