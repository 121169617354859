import { OrigemAtendimento } from 'graphql/types.generated'
import React, { Fragment } from 'react'

import { CondicoesFormModel } from '../model'
import { SubModalVacinacaoOptions } from '../VacinacaoModal'
import { AprazamentoDose } from './AprazamentoDose'
import { RegistroAnteriorDoseAccordionItem } from './RegistroAnteriorDoseAccordionItem'
import { RegistroDoseAccordionItem } from './RegistroDoseAccordionItem'

// dates as strings just for now
export interface RegistroDoseWrapperProps {
  index?: number
  idUnicoRegistro?: ID
  imunobiologicoId: string
  doseId: string
  doseNome: string
  doseSigla: string
  dataRegistro: string
  dataAplicacao?: string
  dataValidadeLote: string
  estrategia: string
  nomeProfissional: string
  cbo: string
  dataAprazamento: string
  lote: string
  nomeFabricante: string
  viaDeAdministracao: string
  localDaAplicacao: string
  observacoes: string
  isGestante: boolean
  isPuerpera: boolean
  isViajante: boolean
  isComunicanteHanseniase: boolean
  grupoAtendimento?: string
  unidadeSaude: string
  origemDados: OrigemAtendimento
  openedAccordions?: (string | number)[]
  registradoAtendimento: boolean
  condicoesVacinacao: CondicoesFormModel
  idUnicoRegistroAprazamentoProxDose?: ID
  idUnicoRegistroAplicacaoProxDose?: ID
  isAplicadoExterior?: boolean
  isAplicacaoEquivalente?: boolean
  handleOnDelete?(
    idUnicoRegistro: ID,
    tipoModal: SubModalVacinacaoOptions,
    idUnicoRegistroAprazamentoProxDose?: ID
  ): void
  handleOnEdit?(idUnicoRegistro: ID, tipoModal: SubModalVacinacaoOptions): void
  readOnly?: boolean
  isRegistroAnterior: boolean
  isCovid: boolean
  hasOnlyEquivalentesAplicadas: boolean
}

export const RegistroDoseWrapper = (props: RegistroDoseWrapperProps) => {
  const { index, isRegistroAnterior, dataAplicacao } = props

  return (
    <Fragment>
      {isRegistroAnterior || dataAplicacao ? (
        isRegistroAnterior ? (
          <RegistroAnteriorDoseAccordionItem hasOnlyEquivalentesAplicadas {...props} />
        ) : (
          <RegistroDoseAccordionItem hasOnlyEquivalentesAplicadas {...props} />
        )
      ) : (
        <AprazamentoDose key={index} {...props} />
      )}
    </Fragment>
  )
}
