/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DateField, ErrorField, Form, FormRenderProps, HorarioAgendaSelectField, TextAreaField } from 'components/form'
import { addMonths, formatISO, parseISO } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import { useEditarAgendamentoReservaMutation, useSalvarAgendamentoReservaMutation } from 'graphql/hooks.generated'
import { MotivoAgendamentoReservaEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import { MotivoReservaRadioGroupField } from '../components/MotivoReservaRadioGroupField'
import { convertReservaModelToEditarInput, convertReservaModelToInput } from '../converter-agenda'
import { AgendamentoEdicaoModel, AgendamentoReservaModel, motivoReservaRecord } from '../model-agenda'
import { agendamentoReservaValidator } from './validator-agenda'

export const meta = metaPath<AgendamentoReservaModel>()

interface AgendamentoReservaFormBaseProps {
  onSubmit?(): void
  onClose?(): void

  isAtencaoDomiciliar?: boolean
}

interface NovoAgendamentoReservaFormProps extends AgendamentoReservaFormBaseProps {
  lotacaoId: ID

  start?: number
  end?: number

  isAtencaoDomiciliar?: boolean
}

interface EditarAgendamentoReservaFormProps extends AgendamentoReservaFormBaseProps {
  agendamento: AgendamentoEdicaoModel
}

type AgendamentoReservaFormProps = NovoAgendamentoReservaFormProps | EditarAgendamentoReservaFormProps

export const AgendamentoReservaForm = (props: AgendamentoReservaFormProps) => {
  const { onClose, onSubmit, isAtencaoDomiciliar: isAD = false } = props
  const alert = useAlert()
  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()
  const agendamentoEdicao = isEdicao(props) ? props.agendamento : null
  const lotacaoId = isEdicao(props) ? agendamentoEdicao.lotacao.id : props.lotacaoId

  const [salvar] = useSalvarAgendamentoReservaMutation()
  const [editar] = useEditarAgendamentoReservaMutation()

  const handleCancelar = () => onClose?.()

  const handleFormSubmit = (values: AgendamentoReservaModel) =>
    (agendamentoEdicao
      ? editar({ variables: { input: convertReservaModelToEditarInput(values, agendamentoEdicao.id) } }).then(() => {
          const nomeMotivoReserva = getNomeMotivoReserva(agendamentoEdicao.motivoReserva)
          alert(
            'success',
            `Reserva de horário${nomeMotivoReserva ? ' para ' + nomeMotivoReserva : ''} foi editada com sucesso.`
          )
        })
      : salvar({ variables: { input: convertReservaModelToInput(values, lotacaoId) } }).then(() => {
          alert('success', 'Agendamento de reserva cadastrado com sucesso.')
        })
    ).then(() => {
      onClose?.()
      onSubmit?.()
    })

  const { start, end } = !isEdicao(props) && props
  const initialValues = useMemo<Partial<AgendamentoReservaModel>>(
    () =>
      agendamentoEdicao
        ? {
            horario: agendamentoEdicao.horario,
            data: formatISO(agendamentoEdicao.horario.inicial),
            especificacaoMotivoReserva: agendamentoEdicao.especificacaoMotivoReserva,
            motivoReserva: agendamentoEdicao.motivoReserva,
            observacoes: agendamentoEdicao.observacoes,
          }
        : {
            horario: { inicial: new Date(start), final: new Date(end) },
            data: start ? formatISO(start) : undefined,
          },
    [agendamentoEdicao, end, start]
  )

  const renderForm = (formProps: FormRenderProps<Partial<AgendamentoReservaModel>>) => {
    const { handleSubmit, values } = formProps

    return (
      <React.Fragment>
        <div
          css={css`
            min-height: 23rem;
            margin-bottom: 5px;
          `}
        >
          <Grid>
            {/*TODO (RNG): Encontrar maneira de nao renderizar esta cell quando nao possui erro (#20422)*/}
            <Cell size={12}>
              <ErrorField name={FORM_ERROR} inline={false} type='alert' />
            </Cell>
            <Cell size={3}>
              <DateField
                name={meta.data}
                label='Data'
                minDate={serverTime}
                maxDate={addMonths(serverTime, 12)}
                clearable
                required
              />
            </Cell>
            <Cell size={9} />

            <Cell size={6}>
              <HorarioAgendaSelectField
                name={meta.horario}
                lotacaoId={lotacaoId}
                dia={values.data ? parseISO(values.data) : undefined}
                disabled={!values.data}
                verificarReservasAgendaOnline={false}
                atencaoDomiciliar={isAD}
                agendamentosIdsDesconsiderar={[agendamentoEdicao?.id]}
                showHorarioFinal
                clearable
                required
              />
              <ErrorField name='horarioInicial' />
              <ErrorField name='horarioFinal' />
            </Cell>
            <Cell size={6} />

            <Cell flexShrink={0}>
              <MotivoReservaRadioGroupField required name={meta.motivoReserva} />
            </Cell>
            <Cell flexGrow={1}>
              {values.motivoReserva === MotivoAgendamentoReservaEnum.OUTRO && (
                <TextAreaField
                  label='Especifique o motivo'
                  name={meta.especificacaoMotivoReserva}
                  maxLength={100}
                  style={css`
                    resize: none;
                  `}
                />
              )}
            </Cell>
            <Cell size={12}>
              <TextAreaField label='Observações' name={meta.observacoes} maxLength={200} />
            </Cell>
          </Grid>
        </div>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' onClick={handleCancelar} size='medium'>
            Cancelar
          </Button>
          <Button kind='primary' onClick={handleSubmit} size='medium'>
            Salvar
          </Button>
        </HFlow>
      </React.Fragment>
    )
  }

  return (
    <Form<Partial<AgendamentoReservaModel>>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleFormSubmit}
      suppressNotificationError //Previne mostrar alerta de validação fora do popup
      validate={agendamentoReservaValidator(getServerTimeNow())}
    />
  )
}

const getNomeMotivoReserva = (motivoReserva: MotivoAgendamentoReservaEnum) =>
  motivoReserva === MotivoAgendamentoReservaEnum.OUTRO ? null : motivoReservaRecord[motivoReserva].nome

const isEdicao = (props: AgendamentoReservaFormProps): props is EditarAgendamentoReservaFormProps =>
  !!(props as any)?.agendamento
