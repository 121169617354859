import { useErrorHandler } from 'components/error'
import { useSalvarJustificativaVisualizarProntuarioMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'

import { useJustificativaVerProntuarioId } from '../useJustificativaVerProntuarioId'
import { JustificativaFormModel, JustificativaModal } from './JustificativaModal'

interface JustificativaModalProps {
  cidadaoId: string
  prontuarioId: ID
  isModalOpen: boolean
  redirecionarParaFolhaRosto?: boolean
  onReject?(): void
  onConfirm?(justificativaId: ID): void
}

export const JustificativaVisualizarProntuarioModal = (props: JustificativaModalProps) => {
  const { cidadaoId, prontuarioId, isModalOpen, redirecionarParaFolhaRosto = true, onConfirm, onReject } = props

  const history = useHistory()
  const [salvarJustificativaVisualizarProntuario] = useSalvarJustificativaVisualizarProntuarioMutation()
  const handleRejection = useErrorHandler()
  const pathCidadao = `/cidadao/${cidadaoId}`
  const [, setJustificativaId, deleteJustificativaId] = useJustificativaVerProntuarioId(pathCidadao, cidadaoId)

  const handleOnSubmit = (values: JustificativaFormModel) => {
    return salvarJustificativaVisualizarProntuario({
      variables: { input: { prontuarioId: prontuarioId, justificativa: values.justificativa } },
    })
      .then(({ data: { salvarJustificativaVisualizarProntuario: justificativaId } }) => {
        setJustificativaId(justificativaId)
        onConfirm?.(justificativaId)
        redirecionarParaFolhaRosto && history.push(`${pathCidadao}/folha-rosto`)
      })
      .catch(handleRejection)
  }

  const handleCancel = () => {
    deleteJustificativaId()
    onReject?.()
  }

  return (
    <JustificativaModal
      heading='Justificativa para visualizar prontuário:'
      text='Informe uma justificativa para visualizar o prontuário:'
      isModalOpen={isModalOpen}
      onConfirm={handleOnSubmit}
      onCancel={handleCancel}
      onReject={onReject}
    />
  )
}
