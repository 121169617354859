/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useTheme } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { useErrorHandler } from 'components/error'
import { useInserirListaAtendimentoMutation } from 'graphql/hooks.generated'
import { TipoServicoPadraoEnum } from 'graphql/types.generated'
import { ReactComponent as DataChecked } from 'images/agenda/data-check-outlined.svg'
import { TipoServicoPadraoRecord } from 'types/enums'
import Permissions from 'types/Permissions'
import { isCboCirurgiaoDentista } from 'util/atendimento/cboHelper'

import MenuAcoesAgendamentoButton from '../MenuAcoesAgendamentoButton'
import { AcaoAgendamentoButtonProps } from '../model-acoesAgendamento'

export function InserirListaAtendimentoButton(props: AcaoAgendamentoButtonProps) {
  const { event, tooltip, disabled, isDropdown } = props
  const alert = useAlert()
  const theme = useTheme()
  const handleException = useErrorHandler()
  const [inserirListaAtendimento, { loading }] = useInserirListaAtendimentoMutation()

  const onClick = () => {
    const isCirurgiaoDentista = event.lotacao.cbo?.cbo2002 && isCboCirurgiaoDentista(event.lotacao.cbo.cbo2002)

    inserirListaAtendimento({
      variables: {
        input: {
          agendado: event.id,
          cidadao: event.cidadaoId,
          responsavel: event.lotacao.id,
          tiposServico: [
            TipoServicoPadraoRecord[TipoServicoPadraoEnum.AGENDADO],
            ...(isCirurgiaoDentista ? [TipoServicoPadraoRecord[TipoServicoPadraoEnum.ODONTOLOGIA]] : []),
          ],
        },
      },
    })
      .then(() => {
        alert('success', `O atendimento de ${event.nomeCidadao.titleCase()} foi incluído na lista.`)
      })
      .catch(handleException)
  }

  return (
    <CheckPermission permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}>
      <MenuAcoesAgendamentoButton
        text='Inserir na lista de atendimento'
        tooltip={tooltip}
        disabled={disabled}
        loading={loading}
        isDropdown={isDropdown}
        onClick={onClick}
        icon={
          <DataChecked
            css={css`
              fill: ${theme.pallete.text.main};
            `}
          />
        }
      />
    </CheckPermission>
  )
}
