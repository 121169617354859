import { AcessoCbo } from 'graphql/types.generated'
import { NIVEL_SUPERIOR, NIVEL_TECNICO } from 'types/NivelCbo'

export const grupoCboHistorico = [...NIVEL_SUPERIOR, AcessoCbo.TSB]

export const grupoCboAtendimento = [...NIVEL_SUPERIOR, AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM]

export const grupoCboVisualizarProntuario = [...NIVEL_SUPERIOR, ...NIVEL_TECNICO]

export const grupoCboVisualizarVacinacao = [...grupoCboVisualizarProntuario, AcessoCbo.ACS]

export const grupoCboVisualizarApenasEncaminhamentos = [AcessoCbo.ACS]

export const grupoCboVisualizarInformacoesCidadao = [AcessoCbo.ACS]

export const grupoCboVisualizarDetalhesEncaminhamentosEspecializados = [...grupoCboVisualizarProntuario]
