import { metaPath } from 'util/metaPath'
import { cpf, createValidator, required, senha } from 'util/validation'
import { igualA } from 'util/validation/rules/igualA'

import { AlterarSenhaModel, NovaSenhaModel } from './model'

const meta = metaPath<NovaSenhaModel>()

export const novaSenhaInputRules = {
  novaSenha: [required, senha],
  confirmacaoSenha: [required, senha, igualA(meta.novaSenha, 'nova senha', 'confirmação da senha')],
}

export const alterarSenhaValidator = (options: {
  senhaAtualRequired: boolean
  tokenRequired: boolean
  usuarioRequired: boolean
}) =>
  createValidator<AlterarSenhaModel>({
    ...novaSenhaInputRules,
    senhaAtual: options.senhaAtualRequired ? [required, senha] : [],
    token: options.tokenRequired ? [required] : [],
    usuario: options.usuarioRequired ? [required, cpf] : [],
  })
