import { Cell, Grid, isEmpty, Tooltip } from 'bold-ui'
import { DateField, FormRenderProps, TextField } from 'components/form'
import { DeclaracaoObitoField } from 'components/form/field/DeclaracaoObitoField'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import { resolveValue } from 'components/form/final-form/hooks/useField'
import { AtencaoDomiciliarCondutaDesfecho, AtencaoDomiciliarModalidade } from 'graphql/types.generated'
import moment from 'moment'
import React from 'react'
import { MetaPath, MetaRoot } from 'util/metaPath'

import { AtencaoDomiciliarCondutaDesfechoSelectField } from '../selectFields/AtencaoDomiciliarCondutaDesfechoSelectField'
import { AtencaoDomiciliarModalidadeSelectField } from '../selectFields/AtencaoDomiciliarModalidadeSelectField'
import { AtencaoDomiciliarOrigemSelectField } from '../selectFields/AtencaoDomiciliarOrigemSelectField'
import { CidadaoADSelectField } from '../selectFields/CidadaoADSelectField'
import { CidadaoListaADSelectField } from '../selectFields/CidadaoListaADSelectField'
import CidadaoADFormModel from './CidadaoADFormModel'

export interface CidadaoADDadosFieldGroupProps {
  name: MetaPath<CidadaoADFormModel> | MetaRoot<CidadaoADFormModel>
  edit?: boolean
  formProps: FormRenderProps<CidadaoADFormModel>
}

export default function CidadaoADDadosFieldGroup(props: CidadaoADDadosFieldGroupProps) {
  const { name, edit, formProps } = props
  const values = resolveValue(formProps.values, name) || ({} as CidadaoADFormModel)
  const modalidadePosObito = values.modalidade === AtencaoDomiciliarModalidade.POS_OBITO
  const hasCidadaoEmObito = !!formProps.initialValues.cidadaoAdEmObito
  const dataAtual = moment().toDate()

  return (
    <Grid wrap>
      <Cell size={6}>
        <Tooltip text={edit && 'Cidadão não pode ser editado'}>
          <CidadaoListaADSelectField
            name={name.cidadao}
            label='Cidadão'
            required
            disabled={edit}
            obito={false}
            resetFieldState={() =>
              formProps.form.getRegisteredFields().forEach((item) => formProps.form.resetFieldState(item))
            }
          />
        </Tooltip>
      </Cell>
      <Cell size={6} />
      <Cell md={3} lg={2}>
        <DateField name={name.dataAdmissao} label='Data de admissão' required maxDate={dataAtual} />
      </Cell>
      <Cell md={3} lg={2}>
        <Tooltip text={modalidadePosObito && (edit || hasCidadaoEmObito) && 'Modalidade não pode ser editada'}>
          <AtencaoDomiciliarModalidadeSelectField
            name={name.modalidade}
            label='Modalidade'
            required
            disabled={modalidadePosObito && (edit || hasCidadaoEmObito)}
            removePosObito={edit && !modalidadePosObito}
          />
        </Tooltip>
      </Cell>
      <Cell size={6} />
      {modalidadePosObito && (
        <>
          <Cell size={6}>
            <Tooltip text={(edit || hasCidadaoEmObito) && 'Cidadão em óbito não pode ser editado'}>
              <CidadaoADSelectField
                name={name.cidadaoAdEmObito}
                label='Cidadão em óbito'
                required
                disabled={edit || hasCidadaoEmObito}
                condutaDesfecho={AtencaoDomiciliarCondutaDesfecho.OBITO}
              />
            </Tooltip>
          </Cell>
          <Cell size={6} />
        </>
      )}
      <Cell size={3}>
        <Tooltip text={modalidadePosObito && 'Procedência não pode ser editada'}>
          <AtencaoDomiciliarOrigemSelectField
            name={name.procedencia}
            label='Procedência'
            disabled={modalidadePosObito}
            required={!modalidadePosObito}
          />
        </Tooltip>
      </Cell>
      <Cell size={3}>
        <TextField name={name.especifique} label='Especifique' maxLength={100} />
      </Cell>
      <Cell size={6} />
      <Cell size={6}>
        <CidSelectField
          name={name.cid10Principal}
          label='CID10 Principal'
          required
          sexo={isEmpty(values.cidadao?.identidadeGeneroDbEnum) ? values.cidadao?.sexo : undefined}
          renderGrupoCiapRelacionada={false}
        />
      </Cell>
      <Cell size={6} />
      <Cell size={6}>
        <CidSelectField
          name={name.cid10Secundario1}
          label='CID10 Secundário 1'
          sexo={isEmpty(values.cidadao?.identidadeGeneroDbEnum) ? values.cidadao?.sexo : undefined}
          renderGrupoCiapRelacionada={false}
        />
      </Cell>
      <Cell size={6} />
      <Cell size={6}>
        <CidSelectField
          name={name.cid10Secundario2}
          label='CID10 Secundário 2'
          sexo={isEmpty(values.cidadao?.identidadeGeneroDbEnum) ? values.cidadao?.sexo : undefined}
          renderGrupoCiapRelacionada={false}
        />
      </Cell>
      <Cell size={6} />

      {edit && (
        <>
          <Cell size={3}>
            <AtencaoDomiciliarCondutaDesfechoSelectField
              name={name.condutaDesfecho}
              label='Conduta/Desfecho'
              required
            />
          </Cell>
          {values?.condutaDesfecho && values?.condutaDesfecho === AtencaoDomiciliarCondutaDesfecho.OBITO && (
            <>
              <Cell size={2}>
                <DateField name={name.dataObito} label='Data de óbito' required maxDate={dataAtual} />
              </Cell>
              <Cell size={6} />
              <Cell size={3}>
                <DeclaracaoObitoField name={name.numeroObito} label='Número da declaração de óbito' />
              </Cell>
            </>
          )}
          {values?.condutaDesfecho && values?.condutaDesfecho !== AtencaoDomiciliarCondutaDesfecho.PERMANENCIA && (
            <Cell size={2}>
              <DateField name={name.dataSaida} label='Data de saída' required maxDate={dataAtual} />
            </Cell>
          )}
        </>
      )}
    </Grid>
  )
}
