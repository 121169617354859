import { HFlow, Tag } from 'bold-ui'
import React from 'react'

interface RiscoGravidezTagProps {
  isAltoRisco: boolean
}

export function RiscoGravidezTag(props: RiscoGravidezTagProps) {
  const { isAltoRisco, ...rest } = props

  return <HFlow {...rest}>{isAltoRisco ? <Tag type='danger'>Alto</Tag> : <Tag type='info'>Habitual</Tag>}</HFlow>
}
