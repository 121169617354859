import { blue, ColorScale, green, red, yellow } from 'bold-ui/lib/styles/colors'
import { UnidadeSaudeSelectModel } from 'components/form'
import { CboCuidadoCompartilhadoSelectModel } from 'components/form/field/select/CboCuidadoCompartilhadoSelectField/CboCuidadoCompartilhadoSelectField'
import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import { Color } from 'csstype'
import {
  AtendimentoProfissional,
  CbosCuidadoCompartilhadoSelectFieldQuery,
  ClassificacaoPrioridadeCuidadoEnum,
  CuidadosCompartilhadoPlanoQuery,
  EquipeCuidadoCompartilhadoSelectFieldQuery,
  Profissional,
  UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery,
} from 'graphql/types.generated'
import { LotacaoContextModel } from 'hooks/atendimento-context/model'
import { RowType } from 'view/atendimentos/detail/components/EditableList'

export const EDITAR_CUIDADO_COMPARTILHADO_URL = '/compartilhamento-de-cuidado/editar'
export enum TipoCompartilhamentoCuidadoPlanoEnum {
  CUIDADO_COMPARTILHADO = 'CUIDADO_COMPARTILHADO',
  GUIA_ENCAMINHAMENTO = 'GUIA_ENCAMINHAMENTO',
}
export interface CuidadoCompartilhadoPlanoModel extends RowType {
  cbo: CboCuidadoCompartilhadoSelectModel
  unidadeSaude?: UnidadeSaudeSelectModel
  lotacao?: LotacaoAndEstagioSelectFieldModel
  lotacaoSolicitante?: LotacaoContextModel
  prioridade: ClassificacaoPrioridadeCuidadoEnum
  discussao: string
  problemasECondicoes: {
    ciap?: CiapSelectFieldModel
    cid10?: CidSelectFieldModel
  }
  cidadaoAceitaAtendTic?: SimNaoEnum
  tipo: TipoCompartilhamentoCuidadoPlanoEnum
  lastUpdate: Instant
  atendimentoProfissional: AtendimentoProfissional
}

export type CuidadoCompartilhadoPlanoQueryModel = CuidadosCompartilhadoPlanoQuery['cuidadosCompartilhado']['content'][0]

export type ProfissionalCuidadoCompartilhadoSelectFieldModel = Pick<Profissional, 'id' | 'nome' | 'cpf'>

export type UnidadeSaudeCuidadoCompartilhadoSelectFieldQueryModel = UnidadeSaudeCuidadoCompartilhadoSelectFieldQuery['estabelecimentosListaCuidadoCompartilhado'][0]

export type EquipeCuidadoCompartilhadoSelectFieldQueryModel = EquipeCuidadoCompartilhadoSelectFieldQuery['equipesListaCuidadoCompartilhado'][0]

export type CboCuidadoCompartilhadoSelectFieldQueryModel = CbosCuidadoCompartilhadoSelectFieldQuery['cbosListaCuidadoCompartilhado'][0]

export interface CuidadoCompartilhadoPlanoTableItemModel {
  id: ID
  dataInicioAtendimento: Instant
  prioridadeAtual: ClassificacaoPrioridadeCuidadoEnum
  cidadaoAceitaAtendTic: boolean
  discussao: string
  lotacaoExecutanteAtual: CuidadoCompartilhadoPlanoQueryModel['lotacaoExecutanteAtual']
  cboExecutanteAtual: CuidadoCompartilhadoPlanoQueryModel['cboExecutanteAtual']
  lotacaoSolicitante: CuidadoCompartilhadoPlanoQueryModel['lotacaoSolicitante']
  isRegistradoAgora: boolean
  cid10?: CidSelectFieldModel
  ciap?: CiapSelectFieldModel
  cacheId?: string
}

export const cuidadoCompartilhadoClassificacaoPrioridadeRecord: Record<
  ClassificacaoPrioridadeCuidadoEnum,
  { label: string; mainColor: Color; colorScale: ColorScale }
> = {
  [ClassificacaoPrioridadeCuidadoEnum.BAIXA]: {
    label: 'Baixa',
    mainColor: blue.c40,
    colorScale: blue,
  },
  [ClassificacaoPrioridadeCuidadoEnum.MEDIA]: {
    label: 'Média',
    mainColor: green.c40,
    colorScale: green,
  },
  [ClassificacaoPrioridadeCuidadoEnum.ALTA]: {
    label: 'Alta',
    mainColor: yellow.c60,
    colorScale: yellow,
  },
  [ClassificacaoPrioridadeCuidadoEnum.MUITO_ALTA]: {
    label: 'Muito alta',
    mainColor: red.c40,
    colorScale: red,
  },
}
