import { AgeRangeType, AgeRangeValue } from 'components/date/AgeRange'
import { FormApi } from 'final-form'
import { GrupoAlvoBuscaAtivaVacinacaoEnum, StatusVacinacaoEnum } from 'graphql/types.generated'
import { isNumber } from 'lodash'

import { BuscaAtivaVacinacao, BuscaAtivaVacinacaoFormModel, ImunobiologicoBuscaAtiva } from './model-buscaAtiva'

export const vacinasMap = (item: BuscaAtivaVacinacao): Record<StatusVacinacaoEnum, ImunobiologicoBuscaAtiva[]> => ({
  [StatusVacinacaoEnum.ATRASADA]: item.vacinasAtrasadas,
  [StatusVacinacaoEnum.NO_PRAZO]: item.vacinasNoPrazo,
  [StatusVacinacaoEnum.APLICADA]: item.vacinasAplicadas,
})

const placeholderIdadeMaximaGrupoAlvoCriancaByAgeRangeType = {
  [AgeRangeType.ANOS]: 'Idade máxima (9)',
  [AgeRangeType.MESES]: 'Idade máxima (119)',
  [AgeRangeType.DIAS]: 'Idade máxima (3719)',
}

export const getFirstPlaceholderByGrupoAlvoToAgeRangeField: Partial<Record<
  GrupoAlvoBuscaAtivaVacinacaoEnum,
  string
>> = {
  [GrupoAlvoBuscaAtivaVacinacaoEnum.CRIANCAS]: 'Idade mínima (0)',
  [GrupoAlvoBuscaAtivaVacinacaoEnum.ADOLESCENTES]: 'Idade mínima (10)',
  [GrupoAlvoBuscaAtivaVacinacaoEnum.ADULTOS]: 'Idade mínima (20)',
  [GrupoAlvoBuscaAtivaVacinacaoEnum.IDOSOS]: 'Idade mínima (60)',
}

export const getSecondPlaceholderByGrupoAlvoToAgeRangeField = (
  periodo: AgeRangeType
): Partial<Record<GrupoAlvoBuscaAtivaVacinacaoEnum, string>> => ({
  [GrupoAlvoBuscaAtivaVacinacaoEnum.CRIANCAS]: placeholderIdadeMaximaGrupoAlvoCriancaByAgeRangeType[periodo],
  [GrupoAlvoBuscaAtivaVacinacaoEnum.ADOLESCENTES]: 'Idade máxima (19)',
  [GrupoAlvoBuscaAtivaVacinacaoEnum.ADULTOS]: 'Idade máxima (59)',
})

export const faixaEtariaFormatter = (faixaEtaria: AgeRangeValue): string => {
  const primeiraIdadeString = isNumber(faixaEtaria?.firstValue)
    ? getIdadeAndTipoFaixaEtaria(faixaEtaria.periodo, faixaEtaria.firstValue)
    : '-'

  const segundaIdadeString = isNumber(faixaEtaria?.secondValue)
    ? getIdadeAndTipoFaixaEtaria(faixaEtaria.periodo, faixaEtaria.secondValue)
    : '-'

  return `${primeiraIdadeString} até ${segundaIdadeString}`
}

const getIdadeAndTipoFaixaEtaria = (tipoFaixaEtaria: AgeRangeType, idade: number): string => {
  const ageRangeStringMap = {
    [AgeRangeType.ANOS]: 'ano'.pluralizeAndConcatValue(idade),
    [AgeRangeType.MESES]: `${idade} ${idade > 1 ? 'meses' : 'mês'}`,
    [AgeRangeType.DIAS]: 'dia'.pluralizeAndConcatValue(idade),
  }

  return ageRangeStringMap[tipoFaixaEtaria]
}

export const onClearField = (form: FormApi<BuscaAtivaVacinacaoFormModel>, field: string) => {
  form.change(field, undefined)
  form.blur(field)
}
