import { InfoLabel } from 'bold-ui'
import React from 'react'

import { ResultadoEspecificoPuericultura } from '../../model-resultadosExames'
import { getResultadoExameEspecificoPuericulturaLabel } from '../../util-resultadosExames'

interface ResultadoExamePuericulturaViewProps {
  value: ResultadoEspecificoPuericultura
}

export const ResultadoExamePuericulturaView = (props: ResultadoExamePuericulturaViewProps) => {
  const { value } = props

  return <InfoLabel title='Resultado'>{getResultadoExameEspecificoPuericulturaLabel(value)}</InfoLabel>
}
