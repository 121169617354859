/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, DateRange, HFlow, Icon, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { PivotTableProps } from 'components/pivot-table/PivotTable'
import { Dictionary, TreeRoot } from 'components/pivot-table/tree-builder/model-treeBuilder'
import { IGNORED_TREE_KEYS } from 'components/pivot-table/tree-builder/util-treeBuilder'
import { StickyButton } from 'components/sticky-button/StickyButton'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { titleCase } from 'util/strConversor'

import { GroupResult } from '../../tree-builder/GroupResult'
import { FieldFiltersByKey } from '../board/model-board'
import { CsvBuilder } from './CsvBuilder'
import { GridArea } from './GridArea'
import { InitialPosition, NestingResult, SpanValue, StackObj } from './model-pivotTableRender'
import { numberFormatter, PivotTableCell } from './PivotTableCell'

export type TableProps<T> = {
  keysMapping: PivotTableProps<T>['keyMapping']
  defaultTree: Dictionary<T> & TreeRoot
  complementaryTree?: Dictionary<T> & TreeRoot
  rowKeys?: Array<keyof T>
  columnKeys?: Array<keyof T>
  title: string
  filterDataKeyValues: FieldFiltersByKey<T>
  dateRangeFilter: DateRange
  aggregatorLabel: string
}

const RESULT_PATH_KEY = 'RESULT'
const PATH_SEPARATOR = '|'
const TABLE_MAX_SIZE = 5000
const TOTAL = 'Total'
const TOTAIS = 'Totais'

export function PivotTableRender<T>(props: TableProps<T>) {
  const { data } = useSession({ fetchPolicy: 'cache-only' })
  const {
    rowKeys,
    columnKeys,
    keysMapping,
    defaultTree,
    complementaryTree,
    title,
    filterDataKeyValues,
    dateRangeFilter,
    aggregatorLabel,
  } = props
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const [tableExceeds, setTableExceeds] = useState(false)
  const [displayRightShadow, setDisplayRightShadow] = useState(true)
  const [displayLeftShadow, setDisplayLeftShadow] = useState(false)
  const alert = useAlert()
  const { analytics } = useFirebase()

  let table: ReactElement[] = []
  const csvBuilder = new CsvBuilder()

  if (rowKeys?.length && complementaryTree?.nodeValue !== undefined && columnKeys?.length) {
    const rowResult = getResult(defaultTree, 'column', keysMapping, rowKeys)

    const { divs: horizontalDivs, rowTotalValues, totalRowNumber, cellPosition } = getHorizontal({
      csvBuilder,
      results: rowResult,
      keys: rowKeys,
      data: defaultTree,
      keysMapping,
      headerSpace: columnKeys.length + 1,
      mixedTable: {
        totalKey: columnKeys[0],
      },
    })

    table = [...horizontalDivs]

    // TODO (eldorado): remover quando corrigir cálculo repetido + loading sumindo antes de finalizar cálculo
    if (table.length < TABLE_MAX_SIZE) {
      const columnResult = getResult(complementaryTree, 'row', keysMapping, columnKeys)

      const verticalDivs = getVertical<T>({
        csvBuilder,
        results: columnResult,
        keys: columnKeys,
        data: complementaryTree,
        keysMapping,
        columnHeaderSpace: rowKeys.length + 1,
        mixedTable: {
          rowResult: rowResult,
          rowTotalValues: rowTotalValues,
          totalKey: rowKeys[0],
          totalRowNumber: totalRowNumber,
          cellPosition: cellPosition,
        },
      })

      table = [...table, ...verticalDivs]
    }
  } else if (rowKeys?.length) {
    const rowResult = getResult(defaultTree, 'column', keysMapping)
    const { divs: horizontalDivs } = getHorizontal<T>({
      csvBuilder,
      results: rowResult,
      keys: rowKeys,
      data: defaultTree,
      keysMapping,
      headerSpace: 2,
    })

    table = [...horizontalDivs]
  } else if (columnKeys?.length) {
    const columnResult = getResult(defaultTree, 'row', keysMapping)
    const verticalDivs = getVertical<T>({
      csvBuilder,
      results: columnResult,
      keys: columnKeys,
      data: defaultTree,
      keysMapping,
    })

    table = [...verticalDivs]
  }

  const handleCsvTableChange = () => {
    analytics.logEvent('relatorios_bi_exportar_csv', { relatorio: title })
    csvBuilder.build(title, filterDataKeyValues, keysMapping, data, dateRangeFilter, aggregatorLabel)
  }

  useEffect(() => {
    if (tableContainerRef.current) {
      setTableExceeds(tableContainerRef.current.scrollWidth > tableContainerRef.current.clientWidth)
    }
  }, [])

  const handleScroll = () => {
    if (tableContainerRef.current) {
      const displayRight =
        tableContainerRef.current.scrollLeft !==
        tableContainerRef.current.scrollWidth - tableContainerRef.current.clientWidth

      const displayLeft = tableContainerRef.current.scrollLeft > 10

      if (displayLeftShadow !== displayLeft) {
        setDisplayLeftShadow(displayLeft)
      }
      if (displayRight !== displayRightShadow) {
        setDisplayRightShadow(displayRight)
      }
    }
  }

  if (table.length > TABLE_MAX_SIZE) {
    alert(
      'warning',
      'A tabela ultrapassou o tamanho máximo. Diminua o número de campos selecionados nas linhas e colunas ou adicione mais filtros.'
    )
    return null
  }

  return (
    <VFlow>
      <div css={styles.tableContainer}>
        {tableExceeds && displayLeftShadow && <div css={styles.leftShadow}></div>}
        <div onScrollCapture={handleScroll} ref={tableContainerRef} key='table' css={styles.tableWrapper}>
          {table}
        </div>
        {tableExceeds && displayRightShadow && <div css={styles.rightShadow}></div>}
      </div>
      <HFlow justifyContent='flex-end'>
        <Button kind='primary' size='medium' onClick={handleCsvTableChange}>
          Exportar CSV
        </Button>
        <StickyButton showsAt='bottom' kind='normal' size='small' onClick={() => window.scrollTo({ top: 0 })}>
          <Icon icon='arrowUp' />
          Voltar ao topo
        </StickyButton>
      </HFlow>
    </VFlow>
  )
}

type GetHorinzontalParams<T> = {
  csvBuilder: CsvBuilder
  results: NestingResult<T>[]
  keys: Array<keyof T>
  data: Dictionary<T> & TreeRoot
  keysMapping: PivotTableProps<T>['keyMapping']
  headerSpace?: number
  mixedTable?: {
    totalKey?: keyof T
  }
}
type GetHorinzontalResults = {
  divs: ReactElement[]
  rowTotalValues: Map<string, number>
  totalRowNumber: number
  cellPosition: Set<string>
}

function getHorizontal<T>({
  csvBuilder,
  results,
  keys,
  data,
  keysMapping,
  headerSpace = 1,
  mixedTable,
}: GetHorinzontalParams<T>): GetHorinzontalResults {
  let maxRowEnd = 0
  let maxColumnEnd = 0
  const divs: ReactElement[] = []
  const rowTotalValues = new Map<string, number>()
  const cellPosition = new Set<string>()

  /**
   * Create headers
   */
  keys.forEach((k, idx) => {
    const gridArea = new GridArea(headerSpace, idx + 1, headerSpace + 1, idx + 2)
    divs.push(
      <PivotTableCell type={['header']} key={gridArea.toString()} gridArea={gridArea}>
        {keysMapping.get(k).keyName}
      </PivotTableCell>
    )
    csvBuilder.append(gridArea, titleCase(keysMapping.get(k).keyName))
  })

  /**
   * Populate values
   */
  for (let result of results) {
    if (mixedTable) {
      if (result.key === mixedTable.totalKey) {
        const rowTotalValuesKey: string = result.path.replace(
          getCurrentPath(result.key.toString(), result.value.toString()),
          ''
        )
        rowTotalValues.set(rowTotalValuesKey, result.total || 0)
      }
      if (!keys.includes(result.key)) {
        continue
      }
    }
    const lastKey = mixedTable && result.key === keys[keys.length - 1]
    const value = result.value
    const rowSpan = result.span.value
    const columnStart = result.column || 0
    const columnEnd = lastKey ? columnStart + 2 : columnStart + 1
    const rowStart = getIni(result.ini) + headerSpace
    const rowEnd = rowStart + rowSpan
    maxRowEnd = rowEnd > maxRowEnd ? rowEnd : maxRowEnd
    maxColumnEnd = columnEnd > maxColumnEnd ? columnStart : maxColumnEnd

    const gridArea = new GridArea(rowStart, columnStart, rowEnd, columnEnd)
    const formattedValue = typeof value === 'number' ? numberFormatter(value) : value

    divs.push(
      <PivotTableCell
        type={result.key !== RESULT_PATH_KEY ? ['header'] : ['value']}
        key={gridArea.toString()}
        gridArea={gridArea}
        endColumn={result.key === RESULT_PATH_KEY && !mixedTable}
      >
        {formattedValue}
      </PivotTableCell>
    )

    csvBuilder.append(gridArea, formattedValue, true)
    cellPosition.add(gridArea.toString())
  }

  /**
   * Populate totals
   */
  let totaisGridArea: GridArea
  if (mixedTable) {
    totaisGridArea = new GridArea(maxRowEnd, 1, maxRowEnd + 1, keys.length + 2)
  } else {
    totaisGridArea = new GridArea(headerSpace, maxColumnEnd, headerSpace + 1, maxColumnEnd + 1)
    const totalGridArea = new GridArea(maxRowEnd, 1, maxRowEnd + 1, maxColumnEnd)
    const dataValueGridArea = new GridArea(maxRowEnd, maxColumnEnd, maxRowEnd + 1, maxColumnEnd + 1)
    divs.push(
      <PivotTableCell type={['header']} key={totalGridArea.toString()} endRow={true} gridArea={totalGridArea}>
        Total
      </PivotTableCell>,
      <PivotTableCell
        type={['total', 'grandtotal', 'value']}
        key={dataValueGridArea.toString()}
        endColumn
        endRow
        gridArea={dataValueGridArea}
      >
        {data.nodeValue}
      </PivotTableCell>
    )
    csvBuilder.append(totalGridArea, TOTAL, true)
    csvBuilder.append(dataValueGridArea, numberFormatter(data.nodeValue))
  }

  divs.push(
    <PivotTableCell
      type={['header']}
      key={totaisGridArea.toString()}
      endColumn={mixedTable === undefined}
      endRow={mixedTable !== undefined}
      gridArea={totaisGridArea}
    >
      Totais
    </PivotTableCell>
  )
  csvBuilder.append(totaisGridArea, TOTAIS, true)

  cellPosition.add(totaisGridArea.toString())
  return { divs, rowTotalValues, totalRowNumber: maxRowEnd, cellPosition }
}
type GetVerticalProps<T> = {
  csvBuilder: CsvBuilder
  results: NestingResult<T>[]
  keys: Array<keyof T>
  data: Dictionary<T> & TreeRoot
  keysMapping: PivotTableProps<T>['keyMapping']
  columnHeaderSpace?: number
  mixedTable?: {
    rowResult: NestingResult<T>[]
    rowTotalValues: Map<string, number>
    totalKey: keyof T
    totalRowNumber: number
    cellPosition: Set<string>
  }
}

function getVertical<T>({
  csvBuilder,
  results,
  keys,
  data,
  keysMapping,
  columnHeaderSpace = 1,
  mixedTable,
}: GetVerticalProps<T>): ReactElement[] {
  let maxRowEnd = 0
  let maxColumnEnd = 0
  const divs: ReactElement[] = []
  const mixedTableStartRowCache = new Map<string, number>()
  const mixedTableColumnTotals = new Map<number, number>()
  const cellPositions = mixedTable?.cellPosition || new Set<string>()

  for (let result of results) {
    const value = result.value
    const columnSpan = result.span.value
    let rowStart = result.row || 0
    let columnStart = getIni(result.ini) + columnHeaderSpace
    if (mixedTable) {
      /**
       * Collect totals, collect total position
       */
      if (result.key === mixedTable.totalKey) {
        mixedTableColumnTotals.set(columnStart, result.total || 0)
      }
      if (!keys.includes(result.key) && result.key !== RESULT_PATH_KEY) {
        continue
      }
      if (mixedTable.rowResult && result.key === RESULT_PATH_KEY) {
        const rows = mixedTable.rowResult.filter((rx) => result.path.indexOf(rx.path) !== -1)
        rowStart = getIni(rows[rows.length - 1].ini) + keys.length + 1
        columnStart = getIni(result.ini.iniPai) + columnHeaderSpace
        mixedTableStartRowCache.set(rows[rows.length - 1].path, rowStart)
      }
    }
    /**
     * Populate values
     */
    const lastKey = mixedTable && result.key === keys[keys.length - 1]
    const rowEnd = lastKey ? rowStart + 2 : rowStart + 1
    const columnEnd = columnStart + columnSpan
    maxRowEnd = rowEnd > maxRowEnd ? rowEnd : maxRowEnd
    maxColumnEnd = columnEnd > maxColumnEnd ? columnStart : maxColumnEnd
    const gridArea = new GridArea(rowStart, columnStart, rowEnd, columnEnd)
    const formattedValue = typeof value === 'number' ? numberFormatter(value) : value

    if (result.key === RESULT_PATH_KEY) {
      divs.push(
        <PivotTableCell
          type={['value']}
          key={gridArea.toString()}
          endRow={mixedTable === undefined}
          gridArea={gridArea}
        >
          {value}
        </PivotTableCell>
      )
      csvBuilder.append(gridArea, formattedValue)
    } else {
      divs.push(
        <PivotTableCell type={['header']} key={gridArea.toString()} gridArea={gridArea}>
          {value}
        </PivotTableCell>
      )
      csvBuilder.append(gridArea, formattedValue)
    }
    cellPositions.add(gridArea.toString())
  }
  /**
   * Populate totals
   */
  divs.push(
    ...keys.map((k, i) => {
      const gridArea = new GridArea(i + 1, columnHeaderSpace, i + 2, columnHeaderSpace + 1)
      csvBuilder.append(gridArea, titleCase(keysMapping.get(k).keyName), false, columnHeaderSpace - 1)
      return (
        <PivotTableCell type={['header']} key={gridArea.toString()} gridArea={gridArea}>
          {keysMapping.get(k).keyName}
        </PivotTableCell>
      )
    })
  )

  let totaisGridArea: GridArea
  let dataValueGridArea: GridArea
  if (mixedTable) {
    const totalRowNumber = mixedTable.totalRowNumber
    totaisGridArea = new GridArea(1, maxColumnEnd + 1, keys.length + 2, maxColumnEnd + 2)
    dataValueGridArea = new GridArea(totalRowNumber, maxColumnEnd + 1, totalRowNumber + 1, maxColumnEnd + 2)

    mixedTableColumnTotals.forEach((value, key) => {
      const gridArea = new GridArea(totalRowNumber, key, totalRowNumber + 1, key + 1)
      divs.push(
        <PivotTableCell type={['total', 'value']} endRow key={gridArea.toString()} gridArea={gridArea}>
          {value}
        </PivotTableCell>
      )
      csvBuilder.append(gridArea, numberFormatter(value))
      cellPositions.add(gridArea.toString())
    })

    mixedTable.rowTotalValues.forEach((value, key) => {
      const rowNumber = mixedTableStartRowCache.get(key)
      if (rowNumber) {
        const gridArea = new GridArea(rowNumber, maxColumnEnd + 1, rowNumber + 1, maxColumnEnd + 2)
        divs.push(
          <PivotTableCell type={['total', 'value']} endColumn key={gridArea.toString()} gridArea={gridArea}>
            {value}
          </PivotTableCell>
        )
        csvBuilder.append(gridArea, numberFormatter(value))
        cellPositions.add(gridArea.toString())
      }
    })

    const gridArea = new GridArea(keys.length + 1, columnHeaderSpace, keys.length + 2, columnHeaderSpace + 1)
    divs.push(
      <PivotTableCell type={['header']} key={gridArea.toString()} gridArea={gridArea}></PivotTableCell>,
      <PivotTableCell
        type={['header']}
        key={totaisGridArea.toString()}
        endRow={false}
        endColumn={true}
        gridArea={totaisGridArea}
      >
        Totais
      </PivotTableCell>,
      <PivotTableCell
        type={['total', 'value', 'grandtotal']}
        key={dataValueGridArea.toString()}
        endColumn
        endRow
        gridArea={dataValueGridArea}
      >
        {data.nodeValue}
      </PivotTableCell>
    )
    csvBuilder.append(gridArea, '')
    csvBuilder.append(totaisGridArea, TOTAIS)
    csvBuilder.append(dataValueGridArea, numberFormatter(data.nodeValue))
    cellPositions.add(dataValueGridArea.toString())
    /**
     * Fill empty cells
     */

    for (let column = columnHeaderSpace + 1; column < maxColumnEnd + 2; column++) {
      for (let row = keys.length + 2; row < totalRowNumber + 1; row++) {
        const gridArea = new GridArea(row, column, row + 1, column + 1)
        if (!cellPositions.has(gridArea.toString())) {
          divs.push(
            <PivotTableCell
              type={['empty']}
              key={gridArea.toString()}
              endRow={row === totalRowNumber}
              endColumn={column === maxColumnEnd + 1}
              gridArea={gridArea}
            >
              -
            </PivotTableCell>
          )
          csvBuilder.append(gridArea, '')
        }
      }
    }
  } else {
    totaisGridArea = new GridArea(maxRowEnd - 1, 1, maxRowEnd, 2)
    dataValueGridArea = new GridArea(maxRowEnd - 1, maxColumnEnd + 1, maxRowEnd, maxColumnEnd + 2)
    const totalGridArea = new GridArea(1, maxColumnEnd + 1, maxRowEnd - 1, maxColumnEnd + 2)
    divs.push(
      <PivotTableCell type={['header']} key={totalGridArea.toString()} endColumn gridArea={totalGridArea}>
        Total
      </PivotTableCell>,
      <PivotTableCell
        type={['header']}
        key={totaisGridArea.toString()}
        endRow={true}
        endColumn={false}
        gridArea={totaisGridArea}
      >
        Totais
      </PivotTableCell>,
      <PivotTableCell
        type={['total', 'value', 'grandtotal']}
        key={dataValueGridArea.toString()}
        endColumn
        endRow
        gridArea={dataValueGridArea}
      >
        {data.nodeValue}
      </PivotTableCell>
    )
    csvBuilder.append(totalGridArea, TOTAL)
    csvBuilder.append(totaisGridArea, TOTAIS)
    csvBuilder.append(dataValueGridArea, numberFormatter(data.nodeValue))
  }
  return divs
}

function getResult<T>(
  data: Dictionary<T> & TreeRoot,
  increment: 'column' | 'row',
  keyMapping: PivotTableProps<T>['keyMapping'],
  onlyIncreaseSpanOnKeys?: Array<keyof T>
): NestingResult<T>[] {
  const stack: StackObj[] = []

  stack.push({ data: data, [increment]: 1 })

  const result: NestingResult<T>[] = []

  while (stack.length) {
    const obj = stack.shift()
    if (obj) {
      let spanAux: SpanValue
      let iniAux: InitialPosition
      const increaseSpan = !onlyIncreaseSpanOnKeys || onlyIncreaseSpanOnKeys.includes(obj.data.nodeKey)
      Object.keys(obj.data)
        .filter((k) => !IGNORED_TREE_KEYS.includes(k))
        .sort(keyMapping.get(obj.data.nodeKey).ordenator)
        .forEach((key) => {
          let span = { value: 1 }
          let spanTree = [span]
          if (obj.spanTree) {
            const myKeys = Object.keys(obj.data).filter((k) => !IGNORED_TREE_KEYS.includes(k))
            const lastSpan = obj.spanTree[0]
            if (myKeys.length > lastSpan.value && increaseSpan) {
              for (let i = 0; i < obj.spanTree.length; i++) {
                obj.spanTree[i].value++
              }
            }
            spanTree.push(...obj.spanTree)
          }

          const rowOrColumn = obj[increment] || 0
          const iniPai = obj.iniPai

          const value = keyMapping.get(obj.data.nodeKey).formatter?.(key) ?? key
          const currentPath: string = getCurrentPath(obj.data.nodeKey, value)
          const path = obj.path ? obj.path + currentPath : currentPath

          const ini: InitialPosition = { iniPai, spanAux: increaseSpan ? spanAux : { value: 0 }, iniAux }

          result.push({
            span: span,
            value: value,
            ini: ini,
            [increment]: rowOrColumn,
            path,
            key: obj.data.nodeKey,
            total: obj.data.nodeValue,
          })

          if (obj.data[key] instanceof GroupResult || obj.data[key].nodeKey === undefined) {
            result.push({
              span: span,
              [increment]: rowOrColumn + 1,
              ini: ini,
              path: path + PATH_SEPARATOR + RESULT_PATH_KEY,
              value: obj.data[key].nodeValue,
              key: RESULT_PATH_KEY as keyof T,
            })
          } else {
            stack.push({
              data: obj.data[key],
              spanTree: spanTree,
              [increment]: rowOrColumn + 1,
              iniPai: ini,
              path,
            })
          }
          iniAux = ini
          spanAux = span
        })
    }
  }
  return result
}

function getIni(ini: InitialPosition | undefined) {
  const stack: InitialPosition[] = []

  if (ini) {
    stack.push(ini)
  } else {
    return 0
  }

  let result = 1

  while (stack.length) {
    const i = stack.pop()
    if (i) {
      if (i.spanAux) {
        result += i.spanAux.value
      }
      if (i.iniAux) {
        stack.push(i.iniAux)
      } else if (i.iniPai) {
        stack.push(i.iniPai)
      }
    }
  }

  return result
}

function getCurrentPath(filterKey: string, value: string): string {
  return PATH_SEPARATOR + filterKey + '.' + value
}

const styles = {
  tableContainer: css`
    position: relative;
    height: 100%;
  `,
  tableWrapper: css`
    max-width: 100%;
    overflow: auto;
    display: grid;
    place-items: center center;
    place-content: start start;
  `,

  leftShadow: css`
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 16px);
    width: 0.5rem;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.12) 10%, rgba(255, 255, 255, 0) 100%);
  `,
  rightShadow: css`
    position: absolute;
    right: 0;
    top: 0;
    height: calc(100% - 16px);
    width: 0.5rem;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.12) 10%, rgba(255, 255, 255, 0) 100%);
  `,
}
