import api from 'api'
import { AcompanhamentoCondicaoSaudeQueryInput } from 'graphql/types.generated'

import { TipoRelatorioOperacionalEnum } from './model-acompanhamentoCondicaoSaude'

export const downloadRelatorio = (
  tipoRelatorio: TipoRelatorioOperacionalEnum,
  filter: AcompanhamentoCondicaoSaudeQueryInput
) => {
  switch (tipoRelatorio) {
    case TipoRelatorioOperacionalEnum.CRIANCA:
      return downloadRelatorioCrianca(filter)
    case TipoRelatorioOperacionalEnum.GESTANTE:
      return downloadRelatorioGestante(filter)
    default:
      return downloadRelatorioRiscoCardioVascular(filter)
  }
}

const downloadRelatorioCrianca = (filter: AcompanhamentoCondicaoSaudeQueryInput) => {
  return api.acompanhamentoSaude
    .downloadRelatorioOperacionalCrianca(filter)
    .then((response) => {
      generatePdf(
        new Blob([response.data], { type: 'application/pdf' }),
        'Relatório operacional de Crianças menores de 5 anos'
      )
    })
    .catch((error) => {
      return handleErrorDownloadRelatorio(error)
    })
}

const downloadRelatorioGestante = (filter: AcompanhamentoCondicaoSaudeQueryInput) => {
  return api.acompanhamentoSaude
    .downloadRelatorioOperacionalGestante(filter)
    .then((response) => {
      generatePdf(new Blob([response.data], { type: 'application/pdf' }), 'Relatório operacional de Gestante/Puérpera')
    })
    .catch((error) => {
      return handleErrorDownloadRelatorio(error)
    })
}

const downloadRelatorioRiscoCardioVascular = (filter: AcompanhamentoCondicaoSaudeQueryInput) => {
  return api.acompanhamentoSaude
    .downloadRelatorioOperacionalRiscoCardiovascular(filter)
    .then((response) => {
      generatePdf(
        new Blob([response.data], { type: 'application/pdf' }),
        'Relatório operacional de Risco cardiovascular'
      )
    })
    .catch((error) => {
      return handleErrorDownloadRelatorio(error)
    })
}

/**
 * @deprecated should be used `generatePdf` from util
 */
const generatePdf = (pdf: Blob, title: string) => {
  const pdfWindow: any = window.open(
    '',
    '',
    'width=' + window.screen.width * 0.8 + ', height=' + window.screen.height * 0.8
  )
  const url = pdfWindow.URL.createObjectURL(pdf, { type: 'application/pdf' })
  pdfWindow.document.write("<iframe width='100%' height='100%' frameborder='0' src='" + url + "'></iframe>")
  pdfWindow.document.title = title
}

const handleErrorDownloadRelatorio = (error) => {
  return new Promise<any>((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('loadend', (e) => {
      reject((e.srcElement as any).result)
    })
    reader.readAsText(error.response.data)
  })
}
