import { Alert, Heading, HFlow, Text, VFlow } from 'bold-ui'
import { useStatusConexaoRndsQuery } from 'graphql/hooks.generated'
import { StatusConexaoRndsEnum } from 'graphql/types.generated'
import React from 'react'

import StatusConexaoRndsTag from './StatusConexaoRndsTag'

export function StatusUltimoEnvioRnds() {
  const {
    data: { statusConexaoRnds },
  } = useStatusConexaoRndsQuery()

  return (
    <VFlow>
      {statusConexaoRnds === StatusConexaoRndsEnum.CERTIFICADO_NAO_ENCONTRADO && (
        <Alert type='danger' inline>
          <Text fontWeight='bold' color='danger'>
            {'Certificado habilitado não encontrado. '}
          </Text>
          O certificado pode ter sido movido, excluído ou renomeado. Entre em contato com o Administrador da Instalação.
        </Alert>
      )}
      <HFlow hSpacing={0.5}>
        <Heading level={4}>Status do último envio</Heading>
        {statusConexaoRnds && <StatusConexaoRndsTag statusConexaoRnds={statusConexaoRnds} />}
      </HFlow>
    </VFlow>
  )
}
