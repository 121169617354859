import { Button, Icon, useStyles } from 'bold-ui'
import { SubmitButton } from 'components/form'
import React from 'react'

export interface SearchFooterProps {
  isCoordenador: boolean
  equipeResponsavelId: ID
  onCleanFilters?(): void
  handleSubmit(event?: React.SyntheticEvent<HTMLFormElement>): void
}

export const SearchFooter = (props: SearchFooterProps) => {
  const { isCoordenador, equipeResponsavelId, onCleanFilters, handleSubmit } = props
  const { classes } = useStyles(createStyles)
  const isAbleToBuscar = isCoordenador || equipeResponsavelId

  return (
    <div className={classes.container}>
      <Button onClick={onCleanFilters} disabled={!isAbleToBuscar}>
        Limpar filtros
      </Button>
      <SubmitButton handleSubmit={handleSubmit} data-cy='BuscarCidadaosButton' disabled={!isAbleToBuscar}>
        <Icon icon='zoomOutline' style={{ marginRight: '0.5rem' }} />
        Buscar cidadãos
      </SubmitButton>
    </div>
  )
}

const createStyles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 0',
    '& > *:not(:last-child)': {
      marginRight: '0.75rem',
    },
  } as React.CSSProperties,
})
