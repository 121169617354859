/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Icon, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import { AccordionButton } from 'components/accordion/AccordionButton'
import CheckPermission from 'components/auth/CheckPermission'
import { CidadaoAtendimentoSelectField, CidadaoAtendimentoSelectFieldModel } from 'components/form'
import { AccordionField } from 'components/form/field/AccordionField'
import { useMemo } from 'react'
import { AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import { MetaArray, MetaPath } from 'util/metaPath'

import { CidadaoAgendamentoConsultaCompartilhada } from '../model-agenda'
import { ContatoCidadaoAgendamentoField } from './ContatoCidadaoAgendamentoField'

interface CidadaoAgendamentoCompartilhadoAccordionProps {
  title: string
  id: string
  accordionName: MetaArray<string | number>
  name: MetaPath<CidadaoAgendamentoConsultaCompartilhada>
  cidadao: CidadaoAtendimentoSelectFieldModel
  disabled?: boolean
  tooltip?: string
}

export const CidadaoAgendamentoCompartilhadoAccordion = (props: CidadaoAgendamentoCompartilhadoAccordionProps) => {
  const { title, accordionName, id, name, cidadao, tooltip, disabled = false } = props
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])
  const match = useRouteMatch()

  return (
    <div css={styles.wrapper}>
      <AccordionField name={accordionName} allowZeroExpanded>
        <AccordionItem uuid={id}>
          <AccordionButton wrapperStyle={styles.accordionButtonWrapper} style={styles.button}>
            <HFlow alignItems='center' hSpacing={0.5} style={styles.buttonTextWrapper}>
              <Icon icon='angleDown' size={1.5} fill='primary' />
              <Text component='span' fontSize={0.8125} fontWeight='bold' color='primary'>
                {title}
              </Text>
            </HFlow>
            <HFlow alignItems='center' style={styles.checkIconWrapper}>
              <Icon icon='checkCircleFilled' fill={!!cidadao ? 'primary' : 'disabled'} />
            </HFlow>
          </AccordionButton>
          <AccordionItemPanel css={styles.panel}>
            <Grid>
              <Cell size={6}>
                <Tooltip text={tooltip}>
                  <CidadaoAtendimentoSelectField
                    name={name.cidadao}
                    ativo
                    obito={false}
                    retrieveContato
                    label='Cidadão'
                    verificarPresenteNaListaAtendimento={false}
                    addCidadaoCallbackUrl={match.url}
                    required={!!cidadao}
                    disabled={disabled}
                  />
                </Tooltip>
              </Cell>
              <Cell size={6} />
              <Cell size={12}>
                <CheckPermission permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar}>
                  <ContatoCidadaoAgendamentoField
                    name={name.contato}
                    disabled={!cidadao}
                    telefoneCelularRequired={!!cidadao?.contato?.telefoneCelular}
                    emailRequired={!!cidadao?.contato?.email}
                  />
                </CheckPermission>
              </Cell>
            </Grid>
          </AccordionItemPanel>
        </AccordionItem>
      </AccordionField>
    </div>
  )
}
const createStyles = (theme: Theme) => ({
  panel: css`
    border: 1px ${theme.pallete.primary.main} solid;
    border-top-width: 0;
    padding: 1rem;
  `,
  button: css`
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.5rem;
    border: 1px solid ${theme.pallete.primary.main};
    background-color: ${theme.pallete.gray.c100};
    margin: 0;
    position: relative;
    height: 2.5rem;
  `,
  wrapper: css`
    margin: 1rem 0;
  `,
  accordionButtonWrapper: css`
    padding-bottom: 0;
  `,
  buttonTextWrapper: css`
    display: flex;
    width: 100%;
    height: 100%;
  `,
  checkIconWrapper: css`
    display: flex;
  `,
})
