import { Calculation } from 'final-form-calculate'
import { UnidadePeriodoRelatorioGerencialEnum } from 'graphql/types.generated'
import { isNull } from 'lodash'
import { MetaRoot } from 'util/metaPath'
import { KeyMapping } from 'view/relatorio/relatoriosgerenciais/common/keyMapping-relatorioGerencial'

import { isCampoPrivado } from '../utils-modeloPersonalizado'
import {
  ModeloPersonalizadoRelatorioGerencialCampoModel,
  ModeloPersonalizadoRelatorioGerencialFormModel,
  VisibilidadeModeloPersonalizadoEnum,
} from './model-modeloPersonalizadoRelatorioGerencialForm'

export const createModeloPersonalizadoRelatoriosGerenciaisPeriodoFormCalculations = (
  name: MetaRoot<ModeloPersonalizadoRelatorioGerencialFormModel>,
  keyMapping: Map<string, KeyMapping>,
  isCadastro: boolean
): Calculation[] => [
  {
    field: [name.unidadePeriodoModal.absolutePath()],
    updates: {
      [name.unidadePeriodoModal.absolutePath()]: (
        unidade: UnidadePeriodoRelatorioGerencialEnum,
        _,
        { unidadePeriodoModal: prevUnidade }: ModeloPersonalizadoRelatorioGerencialFormModel
      ) => {
        return isNull(unidade) ? prevUnidade : unidade
      },
    },
  },
  createCamposPrivadosCalculation(name, keyMapping, isCadastro),
]

export const createCamposPrivadosCalculation = (
  name: MetaRoot<ModeloPersonalizadoRelatorioGerencialFormModel>,
  keyMapping: Map<string, KeyMapping>,
  isCadastro: boolean
) => ({
  field: [name.visibilidade.absolutePath(), name.campos.absolutePath()],
  updates: {
    [name.campos.absolutePath()]: (
      _,
      { campos, visibilidade }: ModeloPersonalizadoRelatorioGerencialFormModel,
      { campos: prevCampos, visibilidade: prevVisibilidade }: ModeloPersonalizadoRelatorioGerencialFormModel
    ): ModeloPersonalizadoRelatorioGerencialCampoModel[] => {
      const hasCamposNovos = campos?.length > prevCampos?.length
      const hasChangedFromPrivadoToPublico =
        visibilidade === VisibilidadeModeloPersonalizadoEnum.PUBLICO &&
        prevVisibilidade === VisibilidadeModeloPersonalizadoEnum.PRIVADO
      const hasChangedFromPublicoToPrivado =
        visibilidade === VisibilidadeModeloPersonalizadoEnum.PRIVADO &&
        prevVisibilidade === VisibilidadeModeloPersonalizadoEnum.PUBLICO

      if (isCadastro) {
        if (
          (hasCamposNovos && visibilidade === VisibilidadeModeloPersonalizadoEnum.PUBLICO) ||
          hasChangedFromPrivadoToPublico
        ) {
          return campos.map((campo) => ({
            ...campo,
            isInvalido: isCampoPrivado(keyMapping.get(campo.key)),
          }))
        }

        if (hasChangedFromPublicoToPrivado) {
          return campos.map((campo) => ({ ...campo, isInvalido: false }))
        }
      }

      return campos
    },
  },
})
