import { CboCuidadoCompartilhadoSelectModel } from 'components/form/field/select/CboCuidadoCompartilhadoSelectField/CboCuidadoCompartilhadoSelectField'
import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { LotacaoAndEstagioSelectFieldModel } from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { Calculation } from 'final-form-calculate'
import { UnidadeSaude } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { MetaRoot } from 'util/metaPath'

import { CuidadoCompartilhadoPlanoModel, TipoCompartilhamentoCuidadoPlanoEnum } from '../model-cuidadocompartilhado'

export const cuidadoCompartilhadoFormCalculator = (
  meta: MetaRoot<CuidadoCompartilhadoPlanoModel>,
  flag: boolean,
  isSomenteCiap: boolean,
  isEdit: boolean
): Calculation[] => {
  return flag
    ? [
        {
          field: meta.cbo.absolutePath(),
          updates: {
            [meta.unidadeSaude.absolutePath()]: (
              value: CboCuidadoCompartilhadoSelectModel,
              allValues: CuidadoCompartilhadoPlanoModel,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              if (isUndefinedOrNull(value)) return null
              if (estahAdicionandoOuEditandoSegundaVezCbo(isEdit, prevValues) && isCboDifferent(value, prevValues))
                return null
              return allValues.unidadeSaude
            },
            [meta.lotacao.absolutePath()]: (
              value: CboCuidadoCompartilhadoSelectModel,
              allValues: CuidadoCompartilhadoPlanoModel,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              if (isUndefinedOrNull(value)) return null
              if (estahAdicionandoOuEditandoSegundaVezCbo(isEdit, prevValues) && isCboDifferent(value, prevValues))
                return null
              return allValues.lotacao
            },
            [meta.cidadaoAceitaAtendTic.absolutePath()]: (
              value: CboCuidadoCompartilhadoSelectModel,
              allValues: CuidadoCompartilhadoPlanoModel,
              _: CuidadoCompartilhadoPlanoModel
            ) => {
              if (isUndefinedOrNull(value)) return null
              if (!value.isVinculado) return null
              return allValues.cidadaoAceitaAtendTic
            },
            [meta.problemasECondicoes.absolutePath()]: (
              value: CboCuidadoCompartilhadoSelectModel,
              allValues: CuidadoCompartilhadoPlanoModel,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              if (isUndefinedOrNull(value)) return null
              if (estahAdicionandoOuEditandoSegundaVezCbo(isEdit, prevValues)) {
                const virouCuidadoCompartilhado = !prevValues.cbo?.isVinculado && value.isVinculado
                if (isSomenteCiap && virouCuidadoCompartilhado)
                  return { ciap: allValues.problemasECondicoes?.ciap, cid10: null }

                const virouGuiaEncaminhamento = !!prevValues.cbo?.isVinculado && !value.isVinculado
                if (!isSomenteCiap && virouGuiaEncaminhamento)
                  return { ciap: null, cid10: allValues.problemasECondicoes?.cid10 }
              }
              return allValues.problemasECondicoes
            },
            [meta.tipo.absolutePath()]: (value: CboCuidadoCompartilhadoSelectModel) => {
              if (isUndefinedOrNull(value)) return null
              return value.isVinculado
                ? TipoCompartilhamentoCuidadoPlanoEnum.CUIDADO_COMPARTILHADO
                : TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO
            },
          },
        },
        {
          field: meta.unidadeSaude.absolutePath(),
          updates: {
            [meta.lotacao.absolutePath()]: (
              value: UnidadeSaude,
              allValues: CuidadoCompartilhadoPlanoModel,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              return isUndefinedOrNull(value) ||
                (!isEmpty(prevValues) && value?.id !== prevValues.lotacao?.unidadeSaude?.id)
                ? null
                : allValues.lotacao
            },
          },
        },
        {
          field: meta.problemasECondicoes.ciap.absolutePath(),
          updates: {
            [meta.problemasECondicoes.cid10.absolutePath()]: (
              value: CiapSelectFieldModel,
              allValues: CuidadoCompartilhadoPlanoModel,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              const isGuiaEncaminhamento = allValues?.cbo && !allValues?.cbo?.isVinculado
              return isSomenteCiap &&
                isGuiaEncaminhamento &&
                (isUndefinedOrNull(value) ||
                  (!isEmpty(prevValues) && value?.id !== prevValues.problemasECondicoes?.ciap?.id))
                ? null
                : allValues.problemasECondicoes?.cid10
            },
          },
        },
      ]
    : [
        {
          field: meta.lotacao.absolutePath(),
          updates: {
            [meta.cbo.absolutePath()]: (
              value: LotacaoAndEstagioSelectFieldModel,
              _,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              if (!isUndefinedOrNull(value)) return { cbo: value.cbo, isVinculado: true }
              return prevValues.cbo
            },
          },
        },
        {
          field: meta.cbo.absolutePath(),
          updates: {
            [meta.lotacao.absolutePath()]: (
              value: CboCuidadoCompartilhadoSelectModel,
              allValues: CuidadoCompartilhadoPlanoModel,
              prevValues: CuidadoCompartilhadoPlanoModel
            ) => {
              return isUndefinedOrNull(value) ||
                (!isEmpty(prevValues) && value?.cbo?.id !== prevValues.lotacao?.cbo?.id)
                ? null
                : allValues.lotacao
            },
          },
        },
      ]
}

const estahAdicionandoOuEditandoSegundaVezCbo = (
  isEdit: boolean,
  prevValues: CuidadoCompartilhadoPlanoModel
): boolean => !isEdit || (!isEmpty(prevValues) && !isUndefinedOrNull(prevValues.cbo?.cbo))

const isCboDifferent = (
  value: CboCuidadoCompartilhadoSelectModel,
  prevValues: CuidadoCompartilhadoPlanoModel
): boolean => value.cbo.id !== prevValues?.cbo?.cbo?.id || value.isVinculado !== prevValues?.cbo?.isVinculado
