import { Ciap, Cid10 } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'

import { DadosUltimosContatosModel } from './folha-rosto/components/ultimos-contatos/converter-ultimosContatos'
import { HistoricoAtendimentoModel, HistoricoDetalhesModel } from './historico/model/model-historico'

export const isCodigoNaoInformado = (codigo: String): boolean =>
  isUndefinedOrNull(codigo) || codigo === '-' || codigo === 'Não informado'

export const getValorInformadoOrNull = (valor: string) => (!isCodigoNaoInformado(valor) ? valor : null)

export const convertCiap = (
  model: DadosUltimosContatosModel | HistoricoAtendimentoModel | HistoricoDetalhesModel
): Ciap =>
  model.cuidadoCompartilhado?.ciap
    ? model.cuidadoCompartilhado?.ciap
    : !isCodigoNaoInformado(model.ciapCodigo)
    ? ({
        codigo: model.ciapNome,
        descricao: model.ciapCodigo,
      } as Ciap)
    : null

export const convertCid10 = (
  model: DadosUltimosContatosModel | HistoricoAtendimentoModel | HistoricoDetalhesModel
): Cid10 =>
  model.cuidadoCompartilhado?.cid10
    ? model.cuidadoCompartilhado?.cid10
    : !isCodigoNaoInformado(model.cidCodigo)
    ? ({
        codigo: model.cidCodigo,
        nome: model.cidNome,
      } as Cid10)
    : null
