import { Grid } from 'bold-ui'
import { FilterItem } from 'components/filteritem/FilterItem'
import { FaixaEtariaEnum } from 'graphql/types.generated'
import React from 'react'
import { identidadeGenero as convertIdentidadeGenero, sexo as convertSexo } from 'types/enums'
import { faixaEtaria } from 'view/acompanhamento/models/model-acompanhamentoFilter'

import { AcompanhamentoCidadaosVinculadosFilterModel } from '../AcompanhamentoCidadaosVinculadosFilter'

export interface AcompanhamentoCidadaosVinculadosTableFilterViewProps {
  filtro: AcompanhamentoCidadaosVinculadosFilterModel
}

export function AcompanhamentoCidadaosVinculadosTableFilterView(
  props: AcompanhamentoCidadaosVinculadosTableFilterViewProps
) {
  const { microareas, sexo, identidadeGenero, tipoFaixaEtaria } = props.filtro

  const hasFaixaEtaria = tipoFaixaEtaria && tipoFaixaEtaria !== FaixaEtariaEnum.TODAS_FAIXAS

  return (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      <>
        {microareas && <FilterItem label='Microárea(s):' value={microareas?.join(', ')} />}
        {sexo && <FilterItem label='Sexo:' value={sexo.map((s) => convertSexo[s]).join(', ')} />}
        {identidadeGenero && (
          <FilterItem
            label='Identidade de Gênero:'
            value={identidadeGenero.map((genero) => convertIdentidadeGenero[genero]).join(', ')}
          />
        )}
        {hasFaixaEtaria && <FilterItem label='Faixa Etária:' value={faixaEtaria[tipoFaixaEtaria]} />}
      </>
    </Grid>
  )
}
