import { Cell, Grid, useTheme } from 'bold-ui'
import { dateFnsDefaultLocale } from 'components/agenda/agendaLocalizer'
import { SelectField, SelectFieldProps } from 'components/form'
import { ErrorField } from 'components/form/final-form'
import { addMinutes, format } from 'date-fns'
import { useHorarioAgendaSelectFieldQuery } from 'graphql/hooks.generated'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { getItemsHorarioFinal } from './getItemsHorarioFinal'
import { getProximoHorarioIndisponivel } from './getProximoHorarioIndisponivel'

export interface HorarioAgendaSelectFieldModel {
  inicial: Date
  final: Date
}

export interface HorarioAgendaSelectFieldProps
  extends Omit<SelectFieldProps<Date>, 'items' | 'itemToString' | 'name' | 'label' | 'value'> {
  name: MetaPath<HorarioAgendaSelectFieldModel>
  lotacaoId: ID
  outraLotacaoIdVerificarDisponibilidade?: ID
  showHorarioFinal?: boolean
  dia?: Date
  value?: HorarioAgendaSelectFieldModel
  labels?: { inicial: string; final?: string }
  verificarReservasAgendaOnline?: boolean
  atencaoDomiciliar?: boolean
  agendamentosIdsDesconsiderar?: Array<ID>
}

const itemToString = (item: Date) => item && format(item, 'HH:mm', { locale: dateFnsDefaultLocale })

export function HorarioAgendaSelectField(props: HorarioAgendaSelectFieldProps) {
  const {
    name,
    lotacaoId,
    outraLotacaoIdVerificarDisponibilidade,
    value,
    dia = new Date(),
    showHorarioFinal = false,
    labels = { inicial: 'Horário inicial', final: 'Horário final' },
    disabled,
    verificarReservasAgendaOnline = true,
    atencaoDomiciliar = false,
    agendamentosIdsDesconsiderar,
    ...rest
  } = props

  const theme = useTheme()

  const {
    data: { horariosAgenda },
    loading,
  } = useHorarioAgendaSelectFieldQuery({
    variables: {
      input: {
        lotacaoId,
        outraLotacaoIdVerificarDisponibilidade,
        dia: format(dia, 'yyyy-MM-dd'),
        isAtencaoDomiciliar: atencaoDomiciliar,
        agendamentosIdsDesconsiderar: agendamentosIdsDesconsiderar?.filterNotNull(),
      },
    },
    skip: disabled || !lotacaoId,
  })

  const {
    input: { value: horarioInicial, onChange: onChangeHorarioInicial, onBlur: onBlurHorarioInicial },
  } = useField<Date>(name.inicial.absolutePath())
  const {
    input: { onChange: onChangeHorarioFinal },
  } = useField<Date>(name.final.absolutePath())
  const setHorarioInicial = useCallback(onChangeHorarioInicial, [name.inicial])
  const setHorarioFinal = useCallback(onChangeHorarioFinal, [name.final])

  const horariosMap = useMemo(() => new Map(horariosAgenda?.map((h) => [new Date(h.horario), h])), [horariosAgenda])

  useEffect(() => {
    if (!loading) {
      const horarioDia = horarioInicial
        ? Array.from(horariosMap?.keys() ?? []).find(
            (h) => h.getHours() === horarioInicial.getHours() && h.getMinutes() === horarioInicial.getMinutes()
          )
        : undefined

      setHorarioInicial(horarioDia)
      setHorarioFinal(undefined)
      onBlurHorarioInicial() //Necessário para revalidar o campo
    }
  }, [horariosMap, horarioInicial, setHorarioFinal, setHorarioInicial, loading, onBlurHorarioInicial])

  useEffect(() => {
    !loading &&
      horarioInicial &&
      horariosMap.has(horarioInicial) &&
      setHorarioFinal(addMinutes(horarioInicial, horariosMap.get(horarioInicial).duracao))
  }, [horarioInicial, horariosMap, loading, setHorarioFinal])

  const isReservadoAgendaOnline = useCallback(
    (date: Date) => verificarReservasAgendaOnline && !!horariosMap.get(date)?.isReservadoAgendaOnline,
    [horariosMap, verificarReservasAgendaOnline]
  )

  const proximoHorarioOcupado = useMemo(
    () =>
      getProximoHorarioIndisponivel(
        horarioInicial,
        horariosAgenda?.map((h) => ({
          ...h,
          isOcupado: h.isOcupado || (verificarReservasAgendaOnline && h.isReservadoAgendaOnline),
        }))
      ),
    [horarioInicial, horariosAgenda, verificarReservasAgendaOnline]
  )

  const horariosFinais = useMemo(
    () => showHorarioFinal && getItemsHorarioFinal(horariosMap, horarioInicial, proximoHorarioOcupado),
    [horarioInicial, horariosMap, proximoHorarioOcupado, showHorarioFinal]
  )

  return (
    <Grid>
      <Cell size={showHorarioFinal ? 6 : 12}>
        <SelectField<Date>
          label={labels.inicial}
          value={value?.inicial}
          items={Array.from(horariosMap.keys()).sort()}
          itemToString={itemToString}
          itemIsEqual={(h) => horariosMap.get(h).isOcupado || isReservadoAgendaOnline(h)}
          customText={(h) => (horariosMap.get(h).isOcupado ? 'Ocupado' : 'Reservado')}
          selectedItemStyle={(h) =>
            horariosMap.get(h).isOcupado
              ? { icon: 'banOutline', color: theme.pallete.text.disabled }
              : { icon: 'checkCircleOutline', color: theme.pallete.status.info.main }
          }
          selectedItemTooltip={(h) =>
            !horariosMap.get(h).isOcupado && isReservadoAgendaOnline(h) && 'Reservado para agenda online'
          }
          name={name.inicial}
          disabled={disabled}
          validate={(h) =>
            horariosMap.get(h)?.isOcupado || isReservadoAgendaOnline(h) ? 'Horário ocupado' : undefined
          }
          {...rest}
        />
        <ErrorField name={name} ignoreObjectError />
      </Cell>
      {showHorarioFinal && (
        <Cell size={6}>
          <SelectField<Date>
            label={labels.final}
            value={value?.final}
            items={horariosFinais}
            itemToString={itemToString}
            name={name.final}
            disabled={disabled}
            {...rest}
          />
        </Cell>
      )}
    </Grid>
  )
}
