/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Theme, useTheme, VFlow } from 'bold-ui'
import { AccordionCompositeItem } from 'components/accordion/AccordionCompositeItem'
import { StatusInformation } from 'components/StatusInformation'
import { useMemo } from 'react'
import { Accordion } from 'react-accessible-accordion'

import {
  ResultadoExameComSolicitacaoModel,
  ResultadoExameEspecificoModel,
  ResultadoExameQueryModel,
  ResultadosExamesModel,
} from '../model-resultadosExames'
import { removePrefix, showCodigoProcedimentoRef } from '../util-resultadosExames'
import { ResultadoExameComSolicitacaoListItem } from './ResultadoExameComSolicitacaoListItem'
import { ResultadoExameSemSolicitacaoListItem } from './ResultadoExameSemSolicitacaoListItem'

interface ResultadosExamesListProps {
  resultadosSolicitados: Record<ID, ResultadoExameQueryModel>
  value: ResultadosExamesModel
}

export const ResultadosExamesList = (props: ResultadosExamesListProps) => {
  const { resultadosSolicitados, value } = props

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const resultadosComSolicitacaoSortedValue: [
    ID,
    ResultadoExameComSolicitacaoModel & ResultadoExameEspecificoModel
  ][] = useMemo(
    () =>
      value?.resultadosComSolicitacao
        ? Object.entries(value.resultadosComSolicitacao).sort(([a], [b]) =>
            resultadosSolicitados[removePrefix(a)]?.exame.descricao.localeCompare(
              resultadosSolicitados[removePrefix(b)]?.exame.descricao
            )
          )
        : [],
    [resultadosSolicitados, value]
  )

  const resultadosSemSolicitacaoValue = Object.values(value?.resultadosSemSolicitacao ?? {})
  const resultadosSemSoliciacaoSortedValue = resultadosSemSolicitacaoValue.sort((a, b) =>
    a.exame.descricao.localeCompare(b.exame.descricao)
  )

  const accordionTitle = useMemo(
    () =>
      [
        ...resultadosSemSoliciacaoSortedValue?.map(
          (item) => `${showCodigoProcedimentoRef(item?.exame, true).capitalize()}`
        ),
        ...resultadosComSolicitacaoSortedValue?.map(
          ([id]) => `${showCodigoProcedimentoRef(resultadosSolicitados[removePrefix(id)]?.exame, true).capitalize()}`
        ),
      ].join(' | '),
    [resultadosComSolicitacaoSortedValue, resultadosSemSoliciacaoSortedValue, resultadosSolicitados]
  )

  return (
    <Accordion allowZeroExpanded>
      <AccordionCompositeItem
        header={
          <HFlow alignItems='center' justifyContent='space-between' style={classes.header}>
            <Heading level={4} style={classes.heading}>
              {accordionTitle}
            </Heading>

            <StatusInformation text='Registrado agora' />
          </HFlow>
        }
        body={
          <VFlow vSpacing={0} style={classes.list}>
            {resultadosSemSoliciacaoSortedValue?.map((resultadoExame, index) => (
              <ResultadoExameSemSolicitacaoListItem key={index} item={resultadoExame} />
            ))}

            {resultadosComSolicitacaoSortedValue?.map(([id, resultadoExame]) => (
              <ResultadoExameComSolicitacaoListItem
                key={id}
                item={resultadoExame}
                resultadoSolicitado={resultadosSolicitados[removePrefix(id)]}
              />
            ))}
          </VFlow>
        }
        style={classes.accordionItem}
      />
    </Accordion>
  )
}

const styles = (theme: Theme) => ({
  accordionItem: css`
    & > [data-accordion-component='AccordionItemButton'] > div {
      padding: 1rem;
      padding-left: 0.5rem;
    }
  `,
  header: css`
    width: 100%;
    grid-auto-columns: auto;
    white-space: nowrap;
  `,
  heading: css`
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  list: css`
    & > div {
      border-bottom: 1px solid ${theme.pallete.gray.c80};

      &:last-of-type {
        border-bottom: 0;
      }
    }
  `,
})
