import { differenceInMonths } from 'date-fns'
import { isEmpty } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { toDate } from 'util/date/formatDate'
import { isObjectDeepEmpty } from 'util/object'
import {
  afterBirthdate,
  afterEqualTo,
  beforeEqualTo,
  createValidator,
  ErrorObject,
  maxLength,
  range,
  required,
  validate,
} from 'util/validation'
import { RESULTADO_EM_OBSERVACAO_REQUIRED_MESSAGE } from 'view/atendimentos/atendimento-individual/atendimento-observacao/model-atendObservacao'
import { isSoapEditableItem } from 'view/atendimentos/atendimento-individual/util'

import {
  AllResultadoEspecificoValueType,
  ExameQueryModel,
  FactoryResultadoExameEspecificoValidatorProps,
  ReplicateDatesModel,
  ResultadoExameComSolicitacaoModel,
  ResultadoExameEspecificoModel,
  ResultadoExameQueryModel,
  ResultadoExameSemSolicitacaoModel,
  ResultadosExamesModel,
  ResultadosExamesNaoSolicitadosModel,
  ResultadosExamesSolicitadosModel,
} from '../model-resultadosExames'
import { getResultadoExameEspecificoValidator, isExameEspecifico, removePrefix } from '../util-resultadosExames'

export const adicionarResultadosExamesValidator = (
  cidadaoDataNascimento: LocalDate,
  resultadosSolicitados: Record<ID, ResultadoExameQueryModel>,
  dataInicioAtendimento: LocalDate,
  isAtendimentoObservacao: boolean
) =>
  createValidator<ResultadosExamesModel>(
    {
      resultadosComSolicitacao: resultadosComSolicitacaoValidator(
        cidadaoDataNascimento,
        dataInicioAtendimento,
        resultadosSolicitados,
        isAtendimentoObservacao
      ),
      resultadosSemSolicitacao: resultadosSemSolicitacaoValidator(
        cidadaoDataNascimento,
        dataInicioAtendimento,
        isAtendimentoObservacao
      ),
    },
    (values: ResultadosExamesModel, errors: ErrorObject<ResultadosExamesModel>) => {
      errors['replicateSemSolicitacao'] = replicarValidator(values.replicateSemSolicitacao)
      if (!isEmpty(values.replicateComSolicitacao)) {
        errors['replicateComSolicitacao'] = {}
        Object.entries(values.replicateComSolicitacao).forEach(([key, entries]) => {
          errors['replicateComSolicitacao'][key] = replicarValidator(entries)
        })
      }
      return errors
    }
  )

const resultadosComSolicitacaoValidator = (
  dataNascimento: LocalDate,
  dataInicioAtendimento: LocalDate,
  resultadosSolicitados: Record<ID, ResultadoExameQueryModel>,
  isAtendimentoObservacao: boolean
) =>
  createValidator<ResultadosExamesSolicitadosModel>({}, (values, errors) => {
    if (!isEmpty(values) && resultadosSolicitados) {
      Object.entries(values).forEach(([key, entries]) => {
        const isEditableItem = !isUndefinedOrNull(entries.editingId)

        if (!isObjectDeepEmpty(entries.resultado) || (isAtendimentoObservacao && isEditableItem)) {
          const dataSolicitacao = resultadosSolicitados[removePrefix(key)].solicitacao.data
          const exame = resultadosSolicitados[removePrefix(key)].exame
          const isEspecifico = isExameEspecifico(exame)

          errors[key] = !isEspecifico
            ? exameComSolicitacaoValidator(
                entries,
                dataNascimento,
                dataSolicitacao,
                dataInicioAtendimento,
                isAtendimentoObservacao
              )
            : exameEspecificoValidator(
                entries,
                exame,
                dataSolicitacao,
                dataNascimento,
                dataInicioAtendimento,
                isAtendimentoObservacao
              )
        }
      })

      return errors
    }
  })

const replicarValidator = createValidator<ReplicateDatesModel>(
  {},
  (values: ReplicateDatesModel, errors: ErrorObject<ReplicateDatesModel>) => {
    if (!isUndefinedOrNull(values?.dataRealizado) && !isUndefinedOrNull(values?.dataResultado)) {
      errors.dataRealizado = beforeEqualTo(values.dataRealizado, values.dataResultado, 'data do resultado')
      errors.dataResultado = afterEqualTo(values.dataResultado, values.dataRealizado, 'data de realização')
    }
    return errors
  }
)

const resultadosSemSolicitacaoValidator = (
  dataNascimento: LocalDate,
  dataInicioAtendimento: LocalDate,
  isAtendimentoObservacao: boolean
) =>
  createValidator<ResultadosExamesNaoSolicitadosModel>({}, (values, errors) => {
    if (!isEmpty(values)) {
      Object.entries(values).forEach(([key, value]) => {
        const isEditableItem = !isUndefinedOrNull(value?.editingId)

        if (!isObjectDeepEmpty(value) || (isAtendimentoObservacao && isEditableItem)) {
          const { dataSolicitado, exame } = value
          const isEspecifico = isExameEspecifico(exame)

          errors[key] = !isEspecifico
            ? exameSemSolicitacaoValidator(value, dataNascimento, dataInicioAtendimento, isAtendimentoObservacao)
            : exameEspecificoValidator(
                value,
                exame,
                dataSolicitado,
                dataNascimento,
                dataInicioAtendimento,
                isAtendimentoObservacao
              )
        }
      })

      return errors
    }
  })

const exameComSolicitacaoValidator = (
  values: ResultadoExameComSolicitacaoModel,
  dataNascimento: LocalDate,
  dataSolicitacao: LocalDate,
  dataInicioAtendimento: LocalDate,
  isAtendimentoObservacao: boolean
): ErrorObject<ResultadoExameComSolicitacaoModel> => {
  const { resultado, dataRealizado, dataResultado, editingId } = values

  return {
    resultado:
      validateResultadoEmObservacao({ resultado, editingId }, isAtendimentoObservacao) || maxLength(2000)(resultado),
    dataRealizado: validateDataRealizado(
      dataRealizado,
      dataResultado,
      dataNascimento,
      dataSolicitacao,
      dataInicioAtendimento
    ),
    dataResultado: validateDataResultado(
      dataResultado,
      dataNascimento,
      dataSolicitacao,
      dataRealizado,
      dataInicioAtendimento
    ),
  }
}

const exameSemSolicitacaoValidator = (
  values: ResultadoExameSemSolicitacaoModel,
  dataNascimento: LocalDate,
  dataInicioAtendimento: LocalDate,
  isAtendimentoObservacao: boolean
): ErrorObject<ResultadoExameSemSolicitacaoModel> => {
  const { resultado, dataRealizado, dataResultado, dataSolicitado, editingId, exame } = values

  return {
    resultado:
      validateResultadoEmObservacao({ resultado, editingId }, isAtendimentoObservacao) ||
      required(resultado) ||
      maxLength(2000)(resultado),
    dataSolicitado: validateDataSolicitado(dataSolicitado, dataNascimento, dataInicioAtendimento),
    dataRealizado: validateDataRealizado(
      dataRealizado,
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataInicioAtendimento,
      exame.idadeMinima,
      exame.idadeMaxima
    ),
    dataResultado: validateDataResultado(
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataRealizado,
      dataInicioAtendimento
    ),
  }
}

const exameEspecificoValidator = (
  values: Omit<ResultadoExameEspecificoModel, 'dataSolicitado'>,
  exame: ExameQueryModel,
  dataSolicitado: LocalDate,
  dataNascimento: LocalDate,
  dataInicioAtendimento: LocalDate,
  isAtendimentoObservacao: boolean
): ErrorObject<ResultadoExameEspecificoModel> => {
  const { resultado, descricao, dataRealizado, dataResultado, editingId } = values

  const resultadoValidator = getResultadoExameEspecificoValidator(exame)

  const args = {
    dataRealizado,
    dataSolicitado,
    dataNascimento,
  }

  return {
    resultado:
      validateResultadoEmObservacao({ resultado, editingId }, isAtendimentoObservacao) ||
      resultadoValidator({ value: resultado, args }),
    descricao: maxLength(2000)(descricao),
    dataSolicitado: validateDataSolicitado(dataSolicitado, dataNascimento, dataInicioAtendimento),
    dataRealizado: validateDataRealizado(
      dataRealizado,
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataInicioAtendimento,
      exame.idadeMinima,
      exame.idadeMaxima
    ),
    dataResultado: validateDataResultado(
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataRealizado,
      dataInicioAtendimento
    ),
  }
}

const validateDataResultado = (
  dataResultado: LocalDate,
  dataNascimento: LocalDate,
  dataSolicitado: LocalDate,
  dataRealizado: LocalDate,
  dataInicioAtendimento: LocalDate
) =>
  dataResultado &&
  (afterBirthdate(dataResultado, dataNascimento) ||
    afterEqualTo(dataResultado, dataSolicitado, 'data de solicitação') ||
    afterEqualTo(dataResultado, dataRealizado, 'data de realização') ||
    beforeEqualTo(dataResultado, dataInicioAtendimento, 'data do atendimento'))

const validateDataRealizado = (
  dataRealizado: LocalDate,
  dataResultado: LocalDate,
  dataNascimento: LocalDate,
  dataSolicitado: LocalDate,
  dataInicioAtendimento: LocalDate,
  idadeMinima?: number,
  idadeMaxima?: number
) => {
  const idadeEmMeses = differenceInMonths(toDate(dataRealizado), toDate(dataNascimento))

  return (
    required(dataRealizado) ||
    afterBirthdate(dataRealizado, dataNascimento) ||
    afterEqualTo(dataRealizado, dataSolicitado, 'data de solicitação') ||
    beforeEqualTo(dataRealizado, dataInicioAtendimento, 'data do atendimento') ||
    (dataResultado && beforeEqualTo(dataRealizado, dataResultado, 'data do resultado')) ||
    (idadeEmMeses < idadeMinima || idadeEmMeses > idadeMaxima
      ? 'A data de realização do exame não está de acordo com a idade do cidadão exigida para este procedimento.'
      : null)
  )
}

const validateDataSolicitado = (
  dataSolicitado: LocalDate,
  dataNascimento: LocalDate,
  dataInicioAtendimento: LocalDate
) =>
  dataSolicitado &&
  (afterBirthdate(dataSolicitado, dataNascimento) ||
    beforeEqualTo(dataSolicitado, dataInicioAtendimento, 'data do atendimento'))

export const validateResultadoExamePrenatal = (
  props: FactoryResultadoExameEspecificoValidatorProps
): string | ErrorObject<AllResultadoEspecificoValueType> => {
  const { value, args } = props
  const { dataRealizado, dataSolicitado, dataNascimento } = args || {}

  const dpp = value?.dpp
  const idadeGestacional = value?.idadeGestacional

  const semanasValidation = validate(idadeGestacional?.semanas, [required, range(0, 42)])
  const diasValidation = validate(idadeGestacional?.dias, [range(0, 6)])

  const nenhumPreenchido = !dpp && isObjectDeepEmpty(idadeGestacional)

  return {
    error: nenhumPreenchido && 'É necessário o preenchimento de Idade gestacional eco ou DPP eco.',
    dpp:
      dpp &&
      (required(dpp) ||
        afterBirthdate(dpp, dataNascimento) ||
        afterEqualTo(dpp, dataSolicitado, 'data de solicitação') ||
        (dataRealizado && afterEqualTo(dpp, dataRealizado, 'data de realização'))),
    idadeGestacional:
      !isObjectDeepEmpty(idadeGestacional) &&
      ((semanasValidation && `Semanas ${semanasValidation.toLowerCase()}`) ||
        (diasValidation && `Dias ${diasValidation.toLowerCase()}`)),
  }
}

export const validateResultadoExamePuericultura = (
  props: FactoryResultadoExameEspecificoValidatorProps
): string | ErrorObject<AllResultadoEspecificoValueType> => {
  const { value } = props

  return validate(value, [required])
}

const validateResultadoEmObservacao = (
  value: ResultadoExameComSolicitacaoModel | ResultadoExameSemSolicitacaoModel | ResultadoExameEspecificoModel,
  isAtendimentoObservacao: boolean
) => {
  if (isAtendimentoObservacao && isSoapEditableItem(value)) {
    return required(value.resultado) ? RESULTADO_EM_OBSERVACAO_REQUIRED_MESSAGE : null
  }
}
