import { HFlow, InfoLabel } from 'bold-ui'
import { DateTime } from 'components/date'
import { useFlags } from 'config/useFlagsContext'
import { formatDistanceToNowStrict, formatDuration } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { CuidadoCompartilhado } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { differenceInBusinessHoursExcludingProlongedWeekend } from 'util/date/differenceInBusinessHoursExcludingProlongedWeekend'

interface DateTimeInfoCuidadoCompartilhadoProps {
  cuidadoCompartilhado: Pick<CuidadoCompartilhado, 'iniciadoEm' | 'dataUltimaEvolucao' | 'isEmulti'>
}

export function DateTimeInfoCuidadoCompartilhado(props: DateTimeInfoCuidadoCompartilhadoProps) {
  const {
    cuidadoCompartilhado: { iniciadoEm, dataUltimaEvolucao, isEmulti },
  } = props
  const { getServerTimeNow } = useServerTime()
  const { PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()

  const duracaoFormatado =
    PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED && isEmulti
      ? formatDuration(
          { hours: differenceInBusinessHoursExcludingProlongedWeekend(dataUltimaEvolucao, getServerTimeNow()) },
          { locale: ptBR, zero: true }
        )
      : formatDistanceToNowStrict(dataUltimaEvolucao, {
          locale: ptBR,
          unit: 'hour',
        })

  return (
    <HFlow hSpacing={2}>
      <InfoLabel title='Data de início'>
        <DateTime value={iniciadoEm} format='DD/MM/YYYY' />
      </InfoLabel>
      <InfoLabel title='Horário de início'>
        <DateTime value={iniciadoEm} format='HH:mm' />
      </InfoLabel>
      <InfoLabel title='Tempo de espera'>{duracaoFormatado}</InfoLabel>
    </HFlow>
  )
}
