import { VFlow } from 'bold-ui'
import { ErrorField } from 'components/form'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import {
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoSolicitanteFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { AgendarConsultaCuidadoCompartilhadoField } from '../components/discussao-caso-fields/AgendarConsultaCuidadoCompartilhadoField'
import { PerguntaCuidadoCompartilhadoField } from '../components/discussao-caso-fields/PerguntaCuidadoCompartilhadoField'
import { TrocaExecutanteCuidadoCompartilhadoField } from '../components/discussao-caso-fields/TrocaExecutanteCuidadoCompartilhadoField'

interface DiscussaoCasoSolicitanteFieldsProps {
  path: MetaPath<DiscussaoCasoSolicitanteFieldsModel>
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
  isEspecializada: boolean
  isExecutanteFromEstabelecimentoEspecializado: boolean
}

export const DiscussaoCasoSolicitanteFields = (props: DiscussaoCasoSolicitanteFieldsProps) => {
  const {
    path,
    cuidadoCompartilhado,
    videochamadaUuidState,
    isEspecializada,
    isExecutanteFromEstabelecimentoEspecializado,
  } = props

  return (
    <VFlow vSpacing={0.5}>
      <PerguntaCuidadoCompartilhadoField path={path} />

      <TrocaExecutanteCuidadoCompartilhadoField
        path={path}
        lotacaoSolicitante={cuidadoCompartilhado.lotacaoSolicitante}
        profissionalExecutanteId={cuidadoCompartilhado.lotacaoExecutanteAtual.profissional.id}
        unidadeSaudeExecutanteId={cuidadoCompartilhado.unidadeSaudeExecutanteAtual.id}
        isEspecializada={isEspecializada}
      />

      <AgendarConsultaCuidadoCompartilhadoField
        path={path}
        cuidadoCompartilhado={cuidadoCompartilhado}
        videochamadaUuidState={videochamadaUuidState}
        isExecutanteFromEstabelecimentoEspecializado={isExecutanteFromEstabelecimentoEspecializado}
      />

      <ErrorField name={path} ignoreObjectError />
    </VFlow>
  )
}
