import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { IdentidadeGeneroEnum } from 'graphql/types.generated'
import React from 'react'

type IdentidadeGeneroValidaEnum = Exclude<IdentidadeGeneroEnum, IdentidadeGeneroEnum.TRANSGENERO>

interface IdentidadeGeneroSelectFieldProps
  extends Omit<SelectFieldProps<IdentidadeGeneroEnum>, 'items' | 'itemToString'> {}

export function IdentidadeGeneroSelectField(props: IdentidadeGeneroSelectFieldProps) {
  return <SelectField<IdentidadeGeneroEnum> items={items} itemToString={renderItem} {...props} />
}

const renderItem = (item: IdentidadeGeneroEnum) => itemToString[item]

const itemToString: Record<IdentidadeGeneroValidaEnum, string> = {
  HOMEM_TRANS: 'Homem transgênero',
  MULHER_TRANS: 'Mulher transgênero',
  TRAVESTI: 'Travesti',
  HOMEM_CIS: 'Homem cisgênero',
  MULHER_CIS: 'Mulher cisgênero',
  NAO_BINARIO: 'Não-Binário',
  OUTRO_IDENTIDADE_GENERO: 'Outro',
}

export const items = Object.keys(itemToString) as IdentidadeGeneroEnum[]
