/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, HFlow, VFlow } from 'bold-ui'
import { CheckboxField } from 'components/form/final-form/CheckboxField'
import { ErrorField } from 'components/form/final-form/ErrorField'
import { TipoConsultaOdontoEnum, TipoEncaminhamentoOdontoEnum } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'

export interface CondutaOdontoPanelProps {
  name: MetaPath<TipoEncaminhamentoOdontoEnum[]>
  tipoConsulta?: TipoConsultaOdontoEnum
  hasEncaminhamentoExterno: boolean
}

export function CondutaOdontoPanel(props: CondutaOdontoPanelProps) {
  const { name, tipoConsulta, hasEncaminhamentoExterno } = props
  const tipoConsultaNullOrManutencao = !tipoConsulta || tipoConsulta === TipoConsultaOdontoEnum.CONSULTA_DE_MANUTENCAO

  return (
    <FormControl label='Conduta' required={!hasEncaminhamentoExterno}>
      <VFlow vSpacing={0.5}>
        <ErrorField name={name} />

        <HFlow
          hSpacing={4}
          style={css`
            margin-left: -0.25rem;
          `}
        >
          <VFlow vSpacing={0.5}>
            <CheckboxField
              name={name}
              label='Tratamento concluído'
              value={TipoEncaminhamentoOdontoEnum.TRATAMENTO_CONCLUIDO}
              disabled={tipoConsultaNullOrManutencao}
            />

            <CheckboxField
              name={name}
              label='Agendamento para grupos'
              value={TipoEncaminhamentoOdontoEnum.AGENDAMENTO_P_GRUPOS}
            />

            <CheckboxField
              name={name}
              label='Agendamento para eMulti'
              value={TipoEncaminhamentoOdontoEnum.AGENDAMENTO_P_EMULTI}
            />

            <CheckboxField
              name={name}
              label='Alta do episódio'
              value={TipoEncaminhamentoOdontoEnum.ALTA_DO_EPISODIO}
              disabled={!tipoConsultaNullOrManutencao}
            />
          </VFlow>

          <VFlow vSpacing={0.5}>
            <CheckboxField
              name={name}
              label='Retorno para consulta agendada'
              value={TipoEncaminhamentoOdontoEnum.RETORNO_PARA_CONSULTA_AGENDADA}
              data-testid='CondutaOdontoPanel.retornoParaConsultaAgendada'
            />

            <CheckboxField
              name={name}
              label='Agendamento para outros profissionais ab'
              value={TipoEncaminhamentoOdontoEnum.AGENDAMENTO_P_OUTROS_PROFISSIONAIS_AB}
            />
          </VFlow>
        </HFlow>
      </VFlow>
    </FormControl>
  )
}
