import { PrivateRoute } from 'components/route'
import React from 'react'
import { Switch, useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'

import GarantiaAcessoAgendarConsultaModalForm from './agendamento/GarantiaAcessoAgendarConsultaModalForm'
import GarantiaAcessoEditarCidadaoModalForm from './edicao/GarantiaAcessoEditarCidadaoModalForm'
import GarantiaAcessoView from './GarantiaAcessoView'
import GarantiaAcessoRemoverCidadaoModalForm from './remocao/GarantiaAcessoRemoverCidadaoModalForm'

export function GarantiaAcessoRootView() {
  const match = useRouteMatch()

  return (
    <>
      <GarantiaAcessoView />
      <Switch>
        <PrivateRoute
          path={`${match.url}/remover-cidadao/:garantiaAcessoId/`}
          component={GarantiaAcessoRemoverCidadaoModalForm}
          permission={Permissions.visualizarListaDeAtendimento}
        />
        <PrivateRoute
          path={`${match.url}/agendar-consulta/:garantiaAcessoId/`}
          component={GarantiaAcessoAgendarConsultaModalForm}
          permission={Permissions.visualizarAgenda.agendarEEditar}
        />
        <PrivateRoute
          path={`${match.url}/editar-cidadao/:garantiaAcessoId/`}
          component={GarantiaAcessoEditarCidadaoModalForm}
          permission={Permissions.visualizarListaDeAtendimento}
        />
      </Switch>
    </>
  )
}
