/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Cell, DataTable, FormLabel, Grid, Heading, HFlow, Text, Tooltip, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import {
  CheckboxField,
  DateField,
  DecimalField,
  ErrorField,
  FormPrompt,
  FractionalField,
  NumberField,
  parse,
  RadioField,
  RadioGroupField,
  TextAreaField,
  TextField,
} from 'components/form'
import { FormaFarmaceuticaSelectField } from 'components/form/field/select/FormaFarmaceuticaSelectField'
import { TipoReceitaSelectField } from 'components/form/field/select/TipoReceitaSelectField'
import { UnidadeMedidaSelectField } from 'components/form/field/select/UnidadeMedidaSelectField'
import { ViaAdministracaoSelectField } from 'components/form/field/select/ViaAdministracaoSelectField'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { confirm } from 'components/modals/confirm'
import theme from 'config/theme'
import { useFlags } from 'config/useFlagsContext'
import { useConexaoHorusLazyQuery, useConsultaEstoqueHorusLazyQuery } from 'graphql/hooks.generated'
import {
  ConexaoHorusQuery,
  DisponibilidadeHorusEnum,
  TipoFrequencia,
  TurnoEnum,
  UnidadeMedidaTempoEnum,
} from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import useAtmosphere from 'hooks/useAtmosphere'
import { useServerTime } from 'hooks/useServerTime'
import React, { useCallback, useEffect, useState } from 'react'
import { FormRenderProps, useField } from 'react-final-form'
import { tipoReceitaRecord } from 'types/enums'
import { metaPath } from 'util/metaPath'
import { v4 as uuidv4 } from 'uuid'

import { handleKeyEnter } from '../../../util'
import AlertPrescricaoModal, { AlertPrescricaoEnum } from '../components/AlertPrescricaoModal'
import DisponibilidadeHorus from '../components/DisponibilidadeHorus'
import FrequenciaTabs from '../components/FrequenciaTabs'
import MedicamentoNaoCadastradoSection from '../components/MedicamentoNaoCadastradoSection'
import MedicamentoSection from '../components/MedicamentoSection'
import { MedicamentoFormModel, TipoDoseEnum } from '../model-prescricao'
import {
  calculaDisponibilidadeHorus,
  calculateDataFimTratamento,
  createPosologiaLabel,
  enumToStringDisponibilidadeHorus,
} from '../utils/utils-prescricao'

const path = metaPath<MedicamentoFormModel>()

interface ResultadoConsultaHorusType {
  uuid: string
  validadeConexao: DisponibilidadeHorusEnum
  unidadesSaudeConsulta: ConsultaHorusItemType[]
}

export interface ConsultaHorusItemType {
  quantidadeDisponivel: number
  disponibilidade: DisponibilidadeHorusEnum
  unidadeSaude: {
    id: string
    nome?: string
  }
}

export interface AdicionarMedicamentoFormPanelProps extends FormRenderProps<MedicamentoFormModel> {
  isEdit: boolean
  isReplicar: boolean
  onClearEditedMedicamento(newEditedValue: MedicamentoFormModel): void
  medicamentoToEdit: MedicamentoFormModel
  onVerifyMedicamentoDuplicated(medicamento: MedicamentoFormModel): boolean
  showDoseAlert: boolean
}

function AdicionarMedicamentoFormPanel(props: AdicionarMedicamentoFormPanelProps) {
  const {
    values,
    form,
    handleSubmit,
    isEdit,
    isReplicar,
    onClearEditedMedicamento,
    medicamentoToEdit,
    submitFailed,
    errors,
    onVerifyMedicamentoDuplicated,
    showDoseAlert,
  } = props
  const [consultaEstoqueHorusLazyQuery] = useConsultaEstoqueHorusLazyQuery()
  const [hashConsultaHorus, setHashConsultaHorus] = useState<string>()
  const [horusData, setHorusData] = useState<ConsultaHorusItemType[]>()
  const [statusConsulta, setStatusConsulta] = useState<DisponibilidadeHorusEnum>()
  const [isHorusLoading, setIsHorusLoading] = useState(false)
  const [statusConexao, setStatusConexao] = useState<ConexaoHorusQuery>()
  const [showAlert, setShowAlert] = useState(false)
  const [executeConexaoQuery, { loading: loadingConexaoInternet }] = useConexaoHorusLazyQuery({
    onCompleted: (dataConexao) => setStatusConexao(dataConexao),
  })
  const { data: session } = useSession()
  const { getServerTimeNow } = useServerTime()
  const {
    atendimentoProfissional: { iniciadoEm: dataAtendimento },
    isRegistroTardio,
  } = useAtendimentoContext()
  const { meta: metaFrequenciaDuracaoError } = useField(path.frequenciaDuracao.absolutePath())
  const { meta: metaTotalDoseError } = useField(path.totalDoseError.absolutePath())
  const { meta: metaTurnosError } = useField(path.turnosError.absolutePath())
  const hasFrequenciaDuracaoError = !!metaFrequenciaDuracaoError.error
  const hasTotalDoseError = !!metaTotalDoseError.error && metaTotalDoseError.submitFailed
  const hasTurnosError = !!metaTurnosError.error && metaTurnosError.submitFailed

  const { PRESCRICAO_TURNO } = useFlags()
  const {
    input: { onChange: onChangeTab },
  } = usePecField({ name: path.tipoFrequencia })

  const setValuesOnChangeTab = useCallback(
    (tipoFrequencia: TipoFrequencia) => {
      const isFrequenciaOuTurnoSemDoseUnica =
        (tipoFrequencia === TipoFrequencia.FREQUENCIA ||
          tipoFrequencia === TipoFrequencia.TURNO ||
          tipoFrequencia === TipoFrequencia.FRACIONADO) &&
        ((!PRESCRICAO_TURNO && !values.doseUnica) || (PRESCRICAO_TURNO && values.tipoDose !== TipoDoseEnum.UNICA))

      form.mutators.setValue(path.intervaloDose.alias, null)
      form.mutators.setValue(path.frequenciaDose.alias, null)
      form.mutators.setValue(path.qtDoseManha.alias, null)
      form.mutators.setValue(path.qtDoseTarde.alias, null)
      form.mutators.setValue(path.qtDoseNoite.alias, null)
      form.mutators.setValue(path.turno.alias, tipoFrequencia === TipoFrequencia.TURNO ? TurnoEnum.MANHA : null)
      form.mutators.setValue(path.quantidadePeriodoFrequenciaTurno.alias, isFrequenciaOuTurnoSemDoseUnica ? 1 : null)
      form.mutators.setValue(
        path.unidadeMedidaTempoFrequenciaTurno.alias,
        isFrequenciaOuTurnoSemDoseUnica ? UnidadeMedidaTempoEnum.DIAS : null
      )
    },
    [PRESCRICAO_TURNO, form.mutators, values.doseUnica, values.tipoDose]
  )

  const handleOnChangeTab = useCallback(
    (tipoFrequencia: TipoFrequencia, tipoDose: TipoDoseEnum) => {
      if (
        (!PRESCRICAO_TURNO && !values.doseUnica) ||
        (PRESCRICAO_TURNO && TipoDoseEnum.COMUM === tipoDose && TipoFrequencia.FRACIONADO !== tipoFrequencia)
      ) {
        onChangeTab(tipoFrequencia)
        form.mutators.setValue && setValuesOnChangeTab(tipoFrequencia)
      }
    },
    [PRESCRICAO_TURNO, form.mutators.setValue, onChangeTab, setValuesOnChangeTab, values.doseUnica]
  )

  useEffect(() => {
    form.mutators.setValue(
      path.dataFimTratamento.alias,
      calculateDataFimTratamento(values.duracao, values.dataInicioTratamento, values.escalaDuracao)
    )
  }, [form.mutators, values.dataInicioTratamento, values.duracao, values.escalaDuracao])

  useEffect(() => {
    form.mutators.setValue(
      path.posologia.alias,
      createPosologiaLabel({
        tipoFrequencia: values.tipoFrequencia,
        intervaloDose: values.intervaloDose,
        frequenciaDose: values.frequenciaDose,
        quantidadePeriodoFrequencia: values.quantidadePeriodoFrequenciaTurno,
        unidadeMedidaTempoFrequencia: values.unidadeMedidaTempoFrequenciaTurno,
        turno: values.turno,
        doseUnica: values.doseUnica,
        qtDose: values.qtDose,
        unidadeMedida: values.unidadeMedidaDose,
        qtDoseManha: values.qtDoseManha,
        qtDoseTarde: values.qtDoseTarde,
        qtDoseNoite: values.qtDoseNoite,
        tipoDose: values.tipoDose,
        PRESCRICAO_TURNO: PRESCRICAO_TURNO,
      })
    )
  }, [
    form.mutators,
    values.qtDose,
    values.tipoDose,
    values.frequenciaDose,
    values.intervaloDose,
    values.quantidadePeriodoFrequenciaTurno,
    values.tipoFrequencia,
    values.turno,
    values.unidadeMedidaDose,
    values.unidadeMedidaTempoFrequenciaTurno,
    values.qtDoseManha,
    values.qtDoseTarde,
    values.qtDoseNoite,
    values.doseUnica,
    PRESCRICAO_TURNO,
  ])

  const submit = useCallback(() => {
    handleSubmit()
    setHorusData(null)
  }, [handleSubmit])

  const handleOnReset = useCallback(
    (registroManual: boolean) => {
      if (isEdit) {
        const id: ID = values.id
        onClearEditedMedicamento({
          id,
          registroManual,
          dataInicioTratamento: parse(dataAtendimento),
          escalaDuracao: UnidadeMedidaTempoEnum.DIAS,
          tipoFrequencia: TipoFrequencia.INTERVALO,
        })
        form.reset()
        form.getRegisteredFields().forEach((field) => form.resetFieldState(field))
      } else {
        form.reset()
        form.getRegisteredFields().forEach((field) => form.resetFieldState(field))
        form.mutators.setValue(path.registroManual.alias, registroManual)
      }
      setHorusData(null)
    },
    [dataAtendimento, form, isEdit, onClearEditedMedicamento, values.id]
  )

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false)
      }, 5000)
    }
  }, [showAlert])

  useEffect(() => {
    executeConexaoQuery()
    if (statusConexao) {
      if (statusConexao?.conexao?.internet.habilitado) {
        if (statusConexao?.conexao?.horus.habilitado) {
          if (values.principioAtivoCombo?.principioAtivo) {
            const hash = uuidv4()
            setHashConsultaHorus(hash)
            setIsHorusLoading(true)
            consultaEstoqueHorusLazyQuery({
              variables: {
                filter: {
                  principioAtivo: values.principioAtivoCombo.principioAtivo.nome,
                  codCatMat: values.principioAtivoCombo.codigoRename ?? values.principioAtivoCombo.codigoCatMat,
                  uuid: hash,
                },
              },
            })
          }
        } else {
          setStatusConsulta(DisponibilidadeHorusEnum.DESABILITADO)
        }
      } else {
        setStatusConsulta(DisponibilidadeHorusEnum.SEM_CONEXAO)
      }
    }
  }, [consultaEstoqueHorusLazyQuery, executeConexaoQuery, statusConexao, values.principioAtivoCombo])

  useEffect(() => {
    if (values.isVersaoAnterior) {
      form.getRegisteredFields().forEach((field) => form.blur(field))
    }
  }, [form, values.isVersaoAnterior])

  useAtmosphere<ResultadoConsultaHorusType>({
    topic: `consulta-horus/${session.profissional.usuario.id}`,
    onMessage: (responseBody) => {
      if (responseBody?.uuid === hashConsultaHorus) {
        setStatusConsulta(responseBody.validadeConexao)
        setIsHorusLoading(false)
        setHorusData(responseBody.unidadesSaudeConsulta)
        form.mutators.setValue(path.disponibilidade.alias, responseBody?.unidadesSaudeConsulta[0]?.disponibilidade)
      }
    },
  })

  const handleOnCheckMedicamentoNaoCadastrado = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked: boolean = e.target.checked
      handleOnReset(checked)
      setHorusData(null)
    },
    [handleOnReset]
  )

  const handleOnChangeEscalaDuracao = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.mutators.setValue(path.duracao.alias, null)
    },
    [form.mutators]
  )

  const handleOnChangeTipoDose = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === TipoDoseEnum.UNICA) {
        form.mutators.setValue(path.usoContinuo.alias, false)
        form.mutators.setValue(path.turno.alias, null)
        form.mutators.setValue(path.duracao.alias, 1)
        form.mutators.setValue(path.escalaDuracao.alias, UnidadeMedidaTempoEnum.DIAS)
        form.mutators.setValue(path.frequenciaDose.alias, null)
        form.mutators.setValue(path.quantidadePeriodoFrequenciaTurno.alias, null)
        form.mutators.setValue(path.unidadeMedidaTempoFrequenciaTurno.alias, null)
        form.mutators.setValue(path.intervaloDose.alias, null)
        form.mutators.setValue(path.qtDoseManha.alias, null)
        form.mutators.setValue(path.qtDoseTarde.alias, null)
        form.mutators.setValue(path.qtDoseNoite.alias, null)
      } else {
        if (values.tipoDose === TipoDoseEnum.UNICA) {
          form.mutators.setValue(path.duracao.alias, null)
        }
        if (values.tipoFrequencia !== TipoFrequencia.INTERVALO) {
          form.mutators.setValue(path.quantidadePeriodoFrequenciaTurno.alias, 1)
          form.mutators.setValue(path.unidadeMedidaTempoFrequenciaTurno.alias, UnidadeMedidaTempoEnum.DIAS)
        }
        if (values.tipoFrequencia === TipoFrequencia.TURNO) {
          form.mutators.setValue(path.turno.alias, TurnoEnum.MANHA)
        }
        if (e.target.value === TipoDoseEnum.COMUM) {
          onChangeTab(TipoFrequencia.INTERVALO)
          form.mutators.setValue && setValuesOnChangeTab(TipoFrequencia.INTERVALO)
        }
        if (PRESCRICAO_TURNO && e.target.value === TipoDoseEnum.FRACIONADA) {
          onChangeTab(TipoFrequencia.FRACIONADO)
          form.mutators.setValue(path.qtDose.alias, null)
          form.mutators.setValue && setValuesOnChangeTab(TipoFrequencia.FRACIONADO)
        }
      }
    },
    [form.mutators, values.tipoDose, values.tipoFrequencia, onChangeTab, setValuesOnChangeTab, PRESCRICAO_TURNO]
  )

  const onChangeDoseUnica = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        form.mutators.setValue(path.usoContinuo.alias, false)
        form.mutators.setValue(path.turno.alias, null)
        form.mutators.setValue(path.duracao.alias, 1)
        form.mutators.setValue(path.escalaDuracao.alias, UnidadeMedidaTempoEnum.DIAS)
        form.mutators.setValue(path.frequenciaDose.alias, null)
        form.mutators.setValue(path.quantidadePeriodoFrequenciaTurno.alias, null)
        form.mutators.setValue(path.unidadeMedidaTempoFrequenciaTurno.alias, null)
        form.mutators.setValue(path.intervaloDose.alias, null)
      } else {
        form.mutators.setValue(path.duracao.alias, null)
        if (values.tipoFrequencia !== TipoFrequencia.INTERVALO) {
          form.mutators.setValue(path.quantidadePeriodoFrequenciaTurno.alias, 1)
          form.mutators.setValue(path.unidadeMedidaTempoFrequenciaTurno.alias, UnidadeMedidaTempoEnum.DIAS)
        }
        if (values.tipoFrequencia === TipoFrequencia.TURNO) {
          form.mutators.setValue(path.turno.alias, TurnoEnum.MANHA)
        }
      }
    },
    [form.mutators, values.tipoFrequencia]
  )

  const handleOnChangeUsoContinuo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        if ((PRESCRICAO_TURNO && values.tipoDose === TipoDoseEnum.UNICA) || values.doseUnica) {
          if (values.tipoFrequencia === TipoFrequencia.TURNO) {
            form.mutators.setValue(path.turno.alias, TurnoEnum.MANHA)
          }
          if (values.tipoFrequencia !== TipoFrequencia.INTERVALO) {
            form.mutators.setValue(path.quantidadePeriodoFrequenciaTurno.alias, 1)
            form.mutators.setValue(path.unidadeMedidaTempoFrequenciaTurno.alias, UnidadeMedidaTempoEnum.DIAS)
          }
          form.mutators.setValue(path.tipoDose.alias, TipoDoseEnum.COMUM)
          form.mutators.setValue(path.tipoFrequencia.alias, TipoFrequencia.INTERVALO)
        }
        if (!PRESCRICAO_TURNO) {
          form.mutators.setValue(path.doseUnica.alias, null)
        }

        form.mutators.setValue(path.duracao.alias, null)
        form.mutators.setValue(path.escalaDuracao.alias, UnidadeMedidaTempoEnum.INDETERMINADO)
      }
    },
    [PRESCRICAO_TURNO, form.mutators, values.doseUnica, values.tipoDose, values.tipoFrequencia]
  )

  const handleOnClickSubmit = useCallback(() => {
    if (onVerifyMedicamentoDuplicated(values)) {
      confirm({
        title: 'Deseja salvar este medicamento?',
        body: 'Este medicamento já foi inserido na prescrição do atendimento.',
        type: 'primary',
        onConfirm: () => {
          setTimeout(submit)
          setShowAlert(true)
        },
        confirmLabel: 'Salvar',
        depthLevel: 2,
        manageOverflow: false,
      })()
    } else {
      submit()
      setShowAlert(true)
    }
  }, [onVerifyMedicamentoDuplicated, submit, values])

  const handleMedicamentoChange = useCallback(() => horusData && setHorusData(null), [horusData])

  return (
    <VFlow
      style={css`
        margin-top: 1rem;
      `}
      vSpacing={2}
    >
      {showAlert && (
        <AlertPrescricaoModal
          tipoAlertaPrescricao={
            submitFailed && errors ? AlertPrescricaoEnum.CAMPO_INCORRETO : AlertPrescricaoEnum.SALVO
          }
          onCloseClick={() => setShowAlert(false)}
        />
      )}
      {showDoseAlert && (
        <Alert type='warning' inline>
          O campo de dose foi atualizado. Adapte a informação abaixo nos novos campos:
          <br />
          <b>{values.dose}</b>
        </Alert>
      )}
      <FormPrompt condition={!isReplicar && isEdit} />
      <Grid gap={1}>
        <Cell size={12}>
          <Tooltip text={!values.registroManual && 'Utilize esta opção para preencher as informações livremente'}>
            <span>
              <CheckboxField
                name={path.registroManual}
                label='Preencher manualmente. Medicamento não encontrado na lista.'
                onChange={handleOnCheckMedicamentoNaoCadastrado}
                data-cy='RegistroManualCheckbox'
              />
            </span>
          </Tooltip>
        </Cell>
        {values.registroManual ? (
          <MedicamentoNaoCadastradoSection path={path} />
        ) : (
          <MedicamentoSection path={path} values={values} onChange={handleMedicamentoChange} />
        )}
        <Cell size={7} data-cy='ViaAdministracao'>
          <ViaAdministracaoSelectField
            name={path.viaAdministracao}
            label='Via de administração'
            onKeyUp={handleKeyEnter}
            required
            data-cy='ViaAdministracaoCombobox'
          />
        </Cell>
        {values.registroManual ? (
          <Cell size={5} data-cy='TipoReceita'>
            <TipoReceitaSelectField
              name={path.tipoReceita}
              label='Tipo de receita'
              onKeyUp={handleKeyEnter}
              required
              data-cy='TipoReceitaCombobox'
            />
          </Cell>
        ) : (
          <Cell size={5}>
            <VFlow vSpacing={0.2}>
              <Text fontWeight='bold'>Tipo de receita</Text>
              <Text data-cy='TipoReceitaLabel'>
                {tipoReceitaRecord[values?.principioAtivoCombo?.principioAtivo?.listaMaterial?.tipoReceita] ?? '-'}
              </Text>
            </VFlow>
          </Cell>
        )}
        {values.isVersaoAnterior && (
          <Cell size={12}>
            <Alert type='info' inline={true}>
              <Text fontWeight='bolder' color='primary'>
                Posologia registrada em versão anterior:
              </Text>
              {` ${medicamentoToEdit?.posologia}`}
            </Alert>
          </Cell>
        )}
        {PRESCRICAO_TURNO && (
          <Cell size={7}>
            <RadioGroupField
              label='Tipo de dose'
              required
              name={path.tipoDose}
              options={[
                { value: TipoDoseEnum.COMUM, label: 'Comum' },
                { value: TipoDoseEnum.UNICA, label: 'Única' },
                { value: TipoDoseEnum.FRACIONADA, label: 'Fracionada' },
              ]}
              onChange={handleOnChangeTipoDose}
            />
          </Cell>
        )}
        {PRESCRICAO_TURNO &&
          (!values.registroManual ? (
            <Cell size={5} data-cy='FormaFarmaceutica'>
              <VFlow vSpacing={0.2}>
                <Text fontWeight='bold'>Forma farmacêutica</Text>
                <Text data-cy='FormaFarmaceuticaLabel'>
                  {values?.principioAtivoCombo?.medicamento?.formaFarmaceutica?.nome ?? '-'}
                </Text>
              </VFlow>
            </Cell>
          ) : (
            <Cell size={5} data-cy='FormaFarmaceutica'>
              <FormaFarmaceuticaSelectField
                name={path.formaFarmaceutica}
                label='Forma farmacêutica'
                onKeyUp={handleKeyEnter}
                required
                data-cy='FormaFarmaceuticaCombobox'
              />
            </Cell>
          ))}

        {values.tipoDose !== TipoDoseEnum.FRACIONADA ? (
          <Cell size={3} data-cy='Dose'>
            <Tooltip placement='bottom-start' text='Utilize vírgula ou barra para doses fracionadas.'>
              <FractionalField
                name={path.qtDose}
                label='Quantidade da dose'
                required
                maxLength={5}
                precisaoDecimal={2}
                precisaoFracionaria={1}
                disabled={!values.principioAtivoCombo && !values.registroManual}
                data-cy='DoseTextField'
              />
            </Tooltip>
          </Cell>
        ) : (
          <Cell size={4} data-cy='Dose'>
            <Tooltip placement='bottom-start' text='Utilize vírgula para doses fracionadas.'>
              <DecimalField
                name={path.qtDose}
                label='Quantidade total da dose'
                required
                precisao={2}
                maxLength={5}
                disabled={!values.principioAtivoCombo && !values.registroManual}
                invalid={hasTotalDoseError}
              />
            </Tooltip>
          </Cell>
        )}
        <Cell size={4}>
          <UnidadeMedidaSelectField
            name={path.unidadeMedidaDose}
            label='Unidade de medida'
            onKeyUp={handleKeyEnter}
            required
            disabled={!values.principioAtivoCombo && !values.registroManual}
          />
        </Cell>

        {!PRESCRICAO_TURNO &&
          (!values.registroManual ? (
            <Cell size={5} data-cy='FormaFarmaceutica'>
              <VFlow vSpacing={0.2}>
                <Text fontWeight='bold'>Forma farmacêutica</Text>
                <Text data-cy='FormaFarmaceuticaLabel'>
                  {values?.principioAtivoCombo?.medicamento?.formaFarmaceutica?.nome ?? '-'}
                </Text>
              </VFlow>
            </Cell>
          ) : (
            <Cell size={5} data-cy='FormaFarmaceutica'>
              <FormaFarmaceuticaSelectField
                name={path.formaFarmaceutica}
                label='Forma farmacêutica'
                onKeyUp={handleKeyEnter}
                required
                data-cy='FormaFarmaceuticaCombobox'
              />
            </Cell>
          ))}

        {!PRESCRICAO_TURNO && (
          <Cell
            size={3}
            style={css`
              padding-left: 0.25rem;
            `}
            alignSelf='center'
          >
            <CheckboxField
              name={path.doseUnica}
              label='Dose única'
              onChange={onChangeDoseUnica}
              data-cy='DoseUnicaCheckbox'
            />
          </Cell>
        )}
        <FrequenciaTabs
          tipoDose={values.tipoDose}
          tipoFrequencia={values.tipoFrequencia}
          handleOnChangeTab={handleOnChangeTab}
          hasFrequenciaDuracaoError={hasFrequenciaDuracaoError}
          hasTotalDoseError={hasTotalDoseError}
          hasTurnosError={hasTurnosError}
          doseUnica={values.doseUnica}
        />
        <Cell size={12}>
          <VFlow vSpacing={0.6}>
            <Heading level={4}>Posologia</Heading>
            <HFlow>
              <Box style={styles.posologiaBox}>
                <Text fontWeight='bold' data-cy='PosologiaBox'>
                  {!!values?.posologia?.trim() ? values.posologia : 'Nenhuma informação adicionada'}
                </Text>
              </Box>
            </HFlow>
          </VFlow>
        </Cell>
        <Cell size={12}>
          <HFlow>
            <div
              data-cy='InicioTratamento'
              css={css`
                max-width: 154px;
              `}
            >
              <DateField
                name={path.dataInicioTratamento}
                label='Início do tratamento'
                minDate={dataAtendimento}
                maxDate={isRegistroTardio && getServerTimeNow()}
                required
                data-cy='InicioTratamentoDateField'
              />
            </div>
            <div
              data-cy='Duracao'
              css={css`
                max-width: 96px;
              `}
            >
              <Tooltip
                text={
                  ((PRESCRICAO_TURNO && values.tipoDose === TipoDoseEnum.UNICA) ||
                    (!PRESCRICAO_TURNO && values.doseUnica)) &&
                  'Medicamento definido como dose única'
                }
              >
                <NumberField
                  name={path.duracao}
                  label='Duração'
                  maxLength={3}
                  disabled={
                    values.escalaDuracao === UnidadeMedidaTempoEnum.INDETERMINADO ||
                    (PRESCRICAO_TURNO && values.tipoDose === TipoDoseEnum.UNICA) ||
                    (!PRESCRICAO_TURNO && values.doseUnica)
                  }
                  required={
                    (PRESCRICAO_TURNO && values.tipoDose !== TipoDoseEnum.UNICA) ||
                    (!PRESCRICAO_TURNO && !values.doseUnica)
                  }
                  data-cy='DuracaoTextField'
                />
              </Tooltip>
            </div>
            <VFlow>
              <Tooltip
                text={
                  ((PRESCRICAO_TURNO && values.tipoDose === TipoDoseEnum.UNICA) ||
                    (!PRESCRICAO_TURNO && values.doseUnica)) &&
                  'Medicamento definido como dose única'
                }
              >
                <HFlow>
                  <VFlow>
                    <RadioField
                      name={path.escalaDuracao}
                      value={UnidadeMedidaTempoEnum.DIAS}
                      label='Dia(s)'
                      disabled={
                        (PRESCRICAO_TURNO && values.tipoDose === TipoDoseEnum.UNICA) ||
                        values.doseUnica ||
                        values.usoContinuo
                      }
                      data-cy='DuracaoDiasRadioButton'
                    />
                    <RadioField
                      name={path.escalaDuracao}
                      value={UnidadeMedidaTempoEnum.MESES}
                      label='Mês(es)'
                      disabled={
                        (PRESCRICAO_TURNO && values.tipoDose === TipoDoseEnum.UNICA) ||
                        values.doseUnica ||
                        values.usoContinuo
                      }
                      data-cy='DuracaoMesesRadioButton'
                    />
                  </VFlow>
                  <VFlow>
                    <RadioField
                      name={path.escalaDuracao}
                      value={UnidadeMedidaTempoEnum.SEMANAS}
                      label='Semana(s)'
                      disabled={
                        (PRESCRICAO_TURNO && values.tipoDose === TipoDoseEnum.UNICA) ||
                        values.doseUnica ||
                        values.usoContinuo
                      }
                      data-cy='DuracaoSemanasRadioButton'
                    />
                    <RadioField
                      name={path.escalaDuracao}
                      value={UnidadeMedidaTempoEnum.INDETERMINADO}
                      label='Indeterminada'
                      disabled={
                        (PRESCRICAO_TURNO && values.tipoDose === TipoDoseEnum.UNICA) ||
                        values.doseUnica ||
                        values.usoContinuo
                      }
                      onChange={handleOnChangeEscalaDuracao}
                      data-cy='DuracaoIndeterminadoRadioButton'
                    />
                  </VFlow>
                </HFlow>
              </Tooltip>
              <ErrorField name={path.escalaDuracao} />
            </VFlow>
            <VFlow vSpacing={0} data-cy='ConclusaoLabel'>
              <Text fontWeight='bold'>Conclusão</Text>
              {values.duracao &&
              values.escalaDuracao !== UnidadeMedidaTempoEnum.INDETERMINADO &&
              values.dataFimTratamento ? (
                <DateTime value={values.dataFimTratamento} format='DD/MM/YYYY' />
              ) : (
                <Text>-</Text>
              )}
            </VFlow>
          </HFlow>
        </Cell>
        <Cell size={12}>
          <CheckboxField
            name={path.usoContinuo}
            label='Uso contínuo'
            data-cy='UsoContinuoCheckbox'
            onChange={handleOnChangeUsoContinuo}
          />
        </Cell>
        <Cell size={12} data-cy='Recomendacoes'>
          <TextAreaField
            style={styles.recomendacoesTextArea}
            name={path.recomendacoes}
            label='Recomendações'
            maxLength={500}
            placeholder='Insira as recomendações para o uso do medicamento, detalhes da dose ou outras informações relevantes.'
            data-cy='RecomendacoesTextArea'
          />
        </Cell>
        {(values.principioAtivoCombo || horusData) && (
          <Cell size={12}>
            <FormLabel label='Integração com Hórus' />
          </Cell>
        )}
        <Cell
          style={css`
            width: 28%;
          `}
          data-cy='Quantidade'
        >
          <NumberField
            name={path.quantidade}
            label='Quantidade solicitada'
            required
            maxLength={3}
            data-cy='QuantidadeTextField'
          />
        </Cell>
        <Cell
          size={6}
          style={css`
            text-align: left;
          `}
          data-cy='UnidadadeFornecimento'
        >
          {!values.registroManual ? (
            <VFlow vSpacing={0}>
              <Text fontWeight='bold'>Unidade de fornecimento</Text>
              <Text data-cy='UnidadadeFornecimentoLabel'>
                {values?.principioAtivoCombo?.unidadeFornecimento?.nome ?? '-'}
              </Text>
            </VFlow>
          ) : (
            <TextField
              name={path.unidadeFornecimento}
              label='Unidade de fornecimento'
              required
              minLength={2}
              maxLength={100}
              data-cy='UnidadadeFornecimentoTextField'
            />
          )}
        </Cell>
        {!values.registroManual &&
          statusConsulta === DisponibilidadeHorusEnum.SUCESSO_CONEXAO &&
          horusData &&
          values.principioAtivoCombo && (
            <Cell
              size={12}
              style={css`
                margin-top: 2rem;
              `}
            >
              <DataTable<ConsultaHorusItemType>
                rows={horusData}
                loading={isHorusLoading}
                columns={[
                  {
                    name: 'estabelecimento',
                    header: 'Estabelecimento',
                    render: (item) => item?.unidadeSaude?.nome,
                    style: css`
                      width: 35%;
                    `,
                  },
                  {
                    name: 'disponibilidade',
                    header: 'Disponibilidade',
                    render: (item) => item?.quantidadeDisponivel,
                  },
                  {
                    name: 'status',
                    header: 'Status',
                    render: (item) => (
                      <DisponibilidadeHorus
                        disponibilidade={calculaDisponibilidadeHorus(item?.quantidadeDisponivel, values.quantidade)}
                        isOnModal
                      />
                    ),
                  },
                ]}
              />
            </Cell>
          )}
        {values.registroManual && values.principioAtivoText && (
          <Cell
            size={12}
            style={css`
              text-align: center;
              margin-top: 2rem;
            `}
          >
            <Text fontWeight='bold'>
              {enumToStringDisponibilidadeHorus[DisponibilidadeHorusEnum.DESABILITADO_MANUAL]}
            </Text>
          </Cell>
        )}
        {!values.registroManual &&
          values.principioAtivoCombo &&
          statusConsulta &&
          statusConsulta !== DisponibilidadeHorusEnum.SUCESSO_CONEXAO && (
            <Cell
              size={12}
              style={css`
                text-align: center;
                margin-top: 2rem;
              `}
            >
              {statusConsulta === DisponibilidadeHorusEnum.DESABILITADO ? (
                <Text fontWeight='bold'>{enumToStringDisponibilidadeHorus[DisponibilidadeHorusEnum.DESABILITADO]}</Text>
              ) : (
                <HFlow alignItems='center'>
                  <Button
                    kind='primary'
                    size='small'
                    loading={loadingConexaoInternet}
                    onClick={() => executeConexaoQuery()}
                  >
                    Tentar novamente
                  </Button>
                  <DisponibilidadeHorus disponibilidade={DisponibilidadeHorusEnum.SEM_CONEXAO} isOnModal />
                </HFlow>
              )}
            </Cell>
          )}
        <Cell
          size={12}
          style={css`
            margin-top: 1.5rem;
          `}
        >
          <HFlow justifyContent='flex-end'>
            <Button onClick={() => handleOnReset(values.registroManual)} size='small' data-cy='LimparCamposButton'>
              Limpar campos
            </Button>
            <Button kind='primary' size='small' onClick={handleOnClickSubmit} data-cy='SalvarButton'>
              Salvar prescrição
            </Button>
          </HFlow>
        </Cell>
      </Grid>
    </VFlow>
  )
}

export default React.memo(AdicionarMedicamentoFormPanel)

const styles = {
  posologiaBox: css`
    min-height: 2.65rem;
    padding: 0.625rem 1rem;
    background: ${theme.pallete.status.info.background};
    border: 1px solid ${theme.pallete.status.info.main};
  `,
  recomendacoesTextArea: css`
    height: 5rem;
    min-height: 3rem;
    max-height: 10rem;
    resize: vertical;
  `,
  doseAlert: css`
    width: 100%;
  `,
}
