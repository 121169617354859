import { AlertFunction } from 'components/alert/AlertStore'
import { MetaResult } from 'components/pivot-table/tree-builder/model-useTreeBuilder'
import { AsyncProcessingMessageTypeEnum } from 'hooks/async/model-asyncProcessing'
import { isNull } from 'lodash'

import {
  convertRelatorioGerencialFormModelToStorageModel,
  convertRelatorioGerencialStorageModelToFormModel,
} from '../components/form/converter-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from '../components/form/model-relatorioGerencialFiltrosForm'
import { RelatorioGerencialFiltrosStorageModel } from './model-relatorioGerencial'
import { UseAsyncRelatorioGerencialProps } from './useAsyncRelatorioGerencial'

export const onProcessingRelatorioGerencialFinished = <T>(
  alertFunction: AlertFunction,
  setIsSearchingMeta: UseAsyncRelatorioGerencialProps<T>['setIsSearchingMeta'],
  onGetMeta: UseAsyncRelatorioGerencialProps<T>['onGetMeta'],
  getMeta: (filtros: RelatorioGerencialFiltrosFormModel) => MetaResult<T>,
  getFiltros: () => RelatorioGerencialFiltrosStorageModel,
  clearFiltros: () => void
) => (status: AsyncProcessingMessageTypeEnum, message: string) => {
  if (status === AsyncProcessingMessageTypeEnum.ERROR) {
    setIsSearchingMeta(false)
    alertFunction('danger', `Não foi possível buscar os dados do relatório porque ocorreu o seguinte erro: ${message}`)
  } else {
    const filtros = convertRelatorioGerencialStorageModelToFormModel(getFiltros())
    if (filtros) {
      onGetMeta(filtros)
      getMeta(filtros)
    }
  }

  clearFiltros()
}

export const handleSubmitAsyncRelatorioGerencial = <T>(
  alertFunction: AlertFunction,
  onGetMeta: UseAsyncRelatorioGerencialProps<T>['onGetMeta'],
  getMeta: (filtros: RelatorioGerencialFiltrosFormModel) => MetaResult<T>,
  setFiltros: (value: RelatorioGerencialFiltrosStorageModel) => void
) => async (filtros: RelatorioGerencialFiltrosFormModel) => {
  onGetMeta(filtros)
  await getMeta(filtros)
    .then((data) => isNull(data) && setFiltros(convertRelatorioGerencialFormModelToStorageModel(filtros)))
    .catch((error) => alertFunction('danger', error || 'Erro inesperado ao buscar os dados do relatório.'))
}
