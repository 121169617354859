import { RadioGroupField, RadioGroupFieldProps } from 'components/form'
import { MotivoAgendamentoReservaEnum } from 'graphql/types.generated'
import React from 'react'

import { motivoReservaRecord } from '../model-agenda'

export function MotivoReservaRadioGroupField(
  props: Omit<RadioGroupFieldProps<MotivoAgendamentoReservaEnum>, 'options'>
) {
  return <RadioGroupField label='Motivo' lines={2} {...props} options={options} />
}

const motivoReservaToRadioOption = (motivo: MotivoAgendamentoReservaEnum) => ({
  value: motivo,
  label: motivoReservaRecord[motivo].nome,
})

const options = [
  motivoReservaToRadioOption(MotivoAgendamentoReservaEnum.REUNIAO),
  motivoReservaToRadioOption(MotivoAgendamentoReservaEnum.ATENDIMENTO_EXTERNO),
  motivoReservaToRadioOption(MotivoAgendamentoReservaEnum.ATIVIDADE_COLETIVA),
  motivoReservaToRadioOption(MotivoAgendamentoReservaEnum.OUTRO),
]
