import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Breadcrumb } from 'components/breadcrumb'
import { parseDateFromLocalStorage } from 'components/form'
import { PageLoading } from 'components/loading'
import { PecSwitch, PrivateRoute } from 'components/route'
import { useEscutaInicialViewQuery, useIdadeGestacionalQuery } from 'graphql/hooks.generated'
import { StatusAtendimento, TipoAtendimentoProfissional } from 'graphql/types.generated'
import { useLocalStorageState } from 'hooks/useStorage'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router'
import { Redirect } from 'react-router-dom'
import { calculateAge } from 'util/date/calculateAge'
import { dateAsYyyyMmDd, toDate } from 'util/date/formatDate'
import { isEmpty } from 'util/validation/Util'

import Permissions from '../../../types/Permissions'
import { AgendamentosCidadaoAtendimentoView } from '../detail/agendamentos/AgendamentosCidadaoAtendimentoView'
import { CidadaoDetailView } from '../detail/cadastro-cidadao/CidadaoDetailView'
import { FolhaRostoView } from '../detail/folha-rosto/FolhaRostoView'
import { AtendimentoHeader, AtendimentoHeaderProps } from '../detail/header/AtendimentoHeader'
import { grupoCboAcompanhamentoPreNatal } from '../detail/soap/pre-natal/acessos'
import { CidadaoAtendimento } from '../types/CidadaoAtendimento'
import { isResponsavelAtendimentoProfissional } from '../util'
import { EscutaInicialMainView } from './EscutaInicialMainView'
import { EscutaInicialState, initialValue } from './model'

const PRE_ATENDIMENTO = 'PRE_ATENDIMENTO'

interface EscutaInicialViewProps {
  atendimentoId: ID
}

export function EscutaInicialView() {
  const { atendimentoId } = useParams<EscutaInicialViewProps>()
  const match = useRouteMatch()
  const { acesso, hasAuthorization, hasCboAuth } = useAcessoLotacaoOrEstagio()
  const acessoId = acesso?.id
  const canCreateNovoAtendimento = hasAuthorization(Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir)

  const {
    data: { atendimento, procedimentosAutomaticos },
    loading,
  } = useEscutaInicialViewQuery({ variables: { atendimentoId } })

  const { isAgendado, atendimentoProfissional, cidadao, tiposServico, prontuario } = atendimento || {}
  const tipoAtendimentoProfissional = isAgendado ? PRE_ATENDIMENTO : TipoAtendimentoProfissional.ESCUTA_INICIAL
  const isGestante = !isEmpty(prontuario?.preNatalAtivo)
  const dataAtendimento = toDate(atendimentoProfissional?.iniciadoEm)
  const { estagio, lotacao, rascunho } = atendimentoProfissional ?? {}
  const prontuarioId = prontuario?.id
  const preNatalAtivo = prontuario?.preNatalAtivo
  const ultimaDumPreNatalAtivo = preNatalAtivo?.ultimaDum

  const { loading: loadingIdadeGestacional, data: dataIdadeGestacional } = useIdadeGestacionalQuery({
    variables: {
      input: {
        prontuarioId,
        dataAtendimento: dateAsYyyyMmDd(dataAtendimento),
        dum: ultimaDumPreNatalAtivo,
      },
    },
    skip: !prontuarioId || !dataAtendimento || !ultimaDumPreNatalAtivo || !hasCboAuth(grupoCboAcompanhamentoPreNatal),
  })
  const idadeGestacional = dataIdadeGestacional?.idadeGestacional

  const [cacheState, setCacheState, deleteCacheState] = useLocalStorageState<EscutaInicialState>(
    `${acesso?.id}/atendimento/escuta-inicial/${atendimentoId}`,
    { modifier: cacheParser, checkVersionCompatibility: true }
  )
  const [initialStateLoaded, setInitialStateLoaded] = useState(!!cacheState)

  const idadeCidadaoEmAnos = useMemo(
    () => cidadao?.dataNascimento && calculateAge(cidadao.dataNascimento, atendimentoProfissional?.iniciadoEm).years,
    [atendimentoProfissional, cidadao]
  )

  const cidadaoAtendimento: CidadaoAtendimento = { ...cidadao, idadeEmAnos: idadeCidadaoEmAnos }

  useEffect(() => {
    if (!initialStateLoaded && !loading) {
      const cacheState = rascunho
        ? cacheParser(JSON.parse(rascunho))
        : initialValue(tiposServico, canCreateNovoAtendimento)
      setCacheState(cacheState)
      setInitialStateLoaded(true)
    }
  }, [canCreateNovoAtendimento, initialStateLoaded, loading, rascunho, setCacheState, tiposServico])

  const statusInvalido =
    atendimento?.statusAtendimento === StatusAtendimento.ATENDIMENTO_REALIZADO ||
    atendimento?.statusAtendimento === StatusAtendimento.NAO_AGUARDOU

  const tipoValido = atendimento?.atendimentoProfissional?.tipo === TipoAtendimentoProfissional.ESCUTA_INICIAL

  if (loading || !initialStateLoaded || loadingIdadeGestacional) {
    return <PageLoading message='Carregando atendimento...' />
  } else if (statusInvalido || !tipoValido) {
    return <Redirect to='/404' />
  } else if (!isResponsavelAtendimentoProfissional(acesso, atendimento?.atendimentoProfissional)) {
    return <Redirect to='/403' />
  }

  const renderEscutaMainView = () => (
    <EscutaInicialMainView
      atendimentoId={atendimentoId}
      cidadao={cidadaoAtendimento}
      dataAtendimento={dataAtendimento}
      isPreAtendimento={tipoAtendimentoProfissional === PRE_ATENDIMENTO}
      cacheState={cacheState}
      updateCache={setCacheState}
      clearCache={deleteCacheState}
      gestacoes={prontuario?.gestacoes || []}
      procedimentosAutomaticos={procedimentosAutomaticos || []}
      isRevisaoAtendimento={estagio?.id != null && acessoId === lotacao.id}
    />
  )

  const renderCidadaoDetail = () => (
    <CidadaoDetailView
      atendimentoId={atendimentoId}
      cidadaoId={cidadao?.id}
      tipoAtendimentoProfissional={tipoAtendimentoProfissional}
      clearCache={deleteCacheState}
      callbackUrl='escuta-inicial'
    />
  )

  const renderFolhaRostoView = () => (
    <FolhaRostoView
      clearCache={deleteCacheState}
      atendimentoId={atendimentoId}
      prontuarioId={prontuarioId}
      cidadao={cidadaoAtendimento}
      lotacao={atendimentoProfissional?.lotacao}
      dataReferencia={atendimento?.atendimentoProfissional?.iniciadoEm}
      isGestante={isGestante}
      gestacoes={prontuario?.gestacoes || []}
      tipoAtendimentoProfissional={tipoAtendimentoProfissional}
      idadeGestacional={idadeGestacional}
    />
  )

  const renderAgendamentos = () => (
    <AgendamentosCidadaoAtendimentoView
      atendimentoId={atendimentoId}
      tipoAtendimentoProfissional={tipoAtendimentoProfissional}
      clearCache={deleteCacheState}
    />
  )

  const headerProps: AtendimentoHeaderProps = {
    cidadaoId: cidadao?.id,
    renderFolhaRosto: true,
    renderSoap: false,
    renderVacinacao: false,
    renderHistorico: false,
    renderCadastroCidadao: true,
    renderEscutaInicial: true,
    cnsCidadao: cidadao?.cns,
    cnsProfissional: atendimentoProfissional.lotacao.profissional.cns,
    cnes: atendimentoProfissional.lotacao.unidadeSaude.cnes,
    municipioId: atendimentoProfissional.lotacao.municipio.id,
    dataAtendimento: atendimentoProfissional.iniciadoEm,
  }

  const labelEscutaPreAtendimento =
    tipoAtendimentoProfissional === PRE_ATENDIMENTO ? 'Pré-atendimento' : 'Escuta inicial'

  return (
    <>
      <Breadcrumb title={labelEscutaPreAtendimento} />

      <AtendimentoHeader labelEscutaPreAtendimento={labelEscutaPreAtendimento} {...headerProps} />

      <PecSwitch>
        <Redirect exact path={match.url} to={`${match.url}/escuta-inicial`} />
        <PrivateRoute path={`${match.url}/escuta-inicial`} render={renderEscutaMainView} />
        <PrivateRoute
          path={`${match.url}/folha-rosto`}
          render={renderFolhaRostoView}
          permission={Permissions.visualizarProntuario}
        />
        <PrivateRoute
          path={`${match.url}/cadastro-cidadao`}
          render={renderCidadaoDetail}
          permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao}
        />
        <PrivateRoute
          path={`${match.path}/agendamentos/:cidadaoId`}
          render={renderAgendamentos}
          permission={Permissions.visualizarAgenda}
        />
      </PecSwitch>
    </>
  )
}

const cacheParser = (cacheState: EscutaInicialState) =>
  cacheState
    ? {
        ...cacheState,
        agendamentoConsulta: cacheState.agendamentoConsulta
          ? {
              ...cacheState.agendamentoConsulta,
              avulsa: cacheState.agendamentoConsulta.avulsa
                ? {
                    ...cacheState.agendamentoConsulta.avulsa,
                    horario: parseDateFromLocalStorage(cacheState.agendamentoConsulta.avulsa.horario),
                  }
                : undefined,
            }
          : undefined,
      }
    : undefined
