/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useTheme } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { ReactComponent as UserSearch } from 'images/agenda/user-search.svg'
import Permissions from 'types/Permissions'
import { grupoCboVisualizarProntuario } from 'view/cidadao/acessos'

import MenuAcoesAgendamentoButton from '../MenuAcoesAgendamentoButton'
import { AcaoAgendamentoButtonProps } from '../model-acoesAgendamento'

export interface VisualizarProntuarioButtonProps extends AcaoAgendamentoButtonProps {
  onClick(): void
}

export function VisualizarProntuarioButton(props: VisualizarProntuarioButtonProps) {
  const { tooltip, disabled, isDropdown, onClick } = props
  const theme = useTheme()

  return (
    <CheckPermission hasAnyCbo={[grupoCboVisualizarProntuario]} hasAnyPermission={[Permissions.visualizarProntuario]}>
      <MenuAcoesAgendamentoButton
        text='Visualizar prontuário'
        tooltip={tooltip}
        disabled={disabled}
        isDropdown={isDropdown}
        onClick={onClick}
        icon={
          <UserSearch
            css={css`
              fill: ${theme.pallete.text.main};
            `}
          />
        }
      />
    </CheckPermission>
  )
}
