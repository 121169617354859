import { catmatTalidomida } from 'api/ImpressaoMedicamentos'
import { Alert, Button, HFlow, Icon, ModalBody, ModalFooter, VFlow } from 'bold-ui'
import { resolveName, usePecField } from 'components/form/final-form/hooks/useField'
import { confirm } from 'components/modals/confirm'
import { ButtonLink } from 'components/route'
import { TitleGroup } from 'components/TitleGroup'
import theme from 'config/theme'
import { css } from 'emotion'
import { usePrincipiosAtivosAlergiasQuery } from 'graphql/hooks.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { isEmpty } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { FieldArrayRenderProps } from 'react-final-form-arrays'
import { useRouteMatch } from 'react-router'
import { TermosOutrosMedicamentosImpressaoPrescricao, TermosTalidomidaImpressaoPrescricao } from 'types/enums'
import { metaPath } from 'util/metaPath'

import MedicamentoCard from '../components/MedicamentoCard'
import { MedicamentoFormModel } from '../model-prescricao'
import { PrescricaoMedicamentoPlanoModel } from '../PrescricaoMedicamentoView'
import { hasIsotretinoinaTretinoinaAcitretina } from '../utils/utils-prescricao'
import PrescricaoMedicamentoForm from './PrescricaoMedicamentoForm'

const path = metaPath<PrescricaoMedicamentoPlanoModel>()

export interface AdicionarPrescricaoFieldArrayProps extends FieldArrayRenderProps<MedicamentoFormModel, any> {
  medicamentoToEdit: MedicamentoFormModel
  medicamentosToReplicate: MedicamentoFormModel[]
  setMedicamentoToEdit(medicamento: MedicamentoFormModel): void
  onClearMedicamentosToReplicate(): void
  onChangeMedicamentosToReplicate(medicamentos: MedicamentoFormModel[]): void
  onCloseModal(): void
  scrollModal(): void
}

export default function AdicionarPrescricaoFieldArray(props: AdicionarPrescricaoFieldArrayProps) {
  const {
    fields: { push, update, remove, value },
    onChangeMedicamentosToReplicate,
    onClearMedicamentosToReplicate,
    medicamentosToReplicate,
    medicamentoToEdit,
    onCloseModal,
    setMedicamentoToEdit,
    scrollModal,
  } = props
  const { analytics } = useFirebase()
  const match = useRouteMatch()
  const [showDoseAlert, setShowDoseAlert] = useState(
    medicamentoToEdit?.dose && !medicamentoToEdit?.qtDose && !medicamentoToEdit?.unidadeMedidaDose
  )

  const {
    input: { value: alreadyPrinted, onChange: onChangeAlreadyPrinted },
  } = usePecField({ name: resolveName(path.alreadyPrinted) })

  const {
    prontuario: { id: prontuarioId },
  } = useAtendimentoContext()

  const urlToEncaminhar = useMemo(() => match.url.replace('/adicionar', '/encaminhar'), [match.url])

  const {
    data: { principiosAtivosAlergias },
  } = usePrincipiosAtivosAlergiasQuery({
    variables: { prontuarioId: prontuarioId },
  })

  const removeAlreadyPrinted = useCallback(
    (medicamento: MedicamentoFormModel) => {
      const { id, principioAtivoCombo } = medicamento

      const toRemove: ID[] = []

      if (!isEmpty(alreadyPrinted) && alreadyPrinted?.find((item: ID) => item === id)) {
        toRemove.push(id)
      }
      if (principioAtivoCombo?.codigoCatMat === catmatTalidomida) {
        toRemove.push(...Object.keys(TermosTalidomidaImpressaoPrescricao))
      }
      if (hasIsotretinoinaTretinoinaAcitretina([medicamento])) {
        toRemove.push(...Object.keys(TermosOutrosMedicamentosImpressaoPrescricao))
      }

      onChangeAlreadyPrinted(alreadyPrinted.filter((item: ID) => !toRemove.includes(item)))
    },
    [alreadyPrinted, onChangeAlreadyPrinted]
  )

  const handleOnChangeEditedMedicamento = useCallback(
    (editedMedicamento: MedicamentoFormModel) => {
      setMedicamentoToEdit(editedMedicamento)
      setShowDoseAlert(editedMedicamento?.dose && !editedMedicamento?.qtDose && !editedMedicamento?.unidadeMedidaDose)
    },
    [setMedicamentoToEdit]
  )

  const handleOnRemoveMedicamento = useCallback(
    (medicamentoToRemove: MedicamentoFormModel) => {
      const index = value.findIndex((item) => item.id === medicamentoToRemove.id)
      remove(index)

      if (!isEmpty(alreadyPrinted) && alreadyPrinted.includes(medicamentoToRemove.id)) {
        removeAlreadyPrinted(medicamentoToRemove)
      }

      if (medicamentoToEdit?.id === medicamentoToRemove.id) {
        setMedicamentoToEdit(null)
        setShowDoseAlert(false)
      }
    },
    [alreadyPrinted, medicamentoToEdit, remove, removeAlreadyPrinted, setMedicamentoToEdit, value]
  )

  const handleOnRemoveMedicamentoToReplicate = useCallback(
    (medicamento: MedicamentoFormModel) => {
      const medicamentos = medicamentosToReplicate.filter((item) => item.id !== medicamento.id)
      if (isEmpty(medicamentos)) {
        onClearMedicamentosToReplicate()
      } else {
        onChangeMedicamentosToReplicate(medicamentos)
      }

      if (!isEmpty(alreadyPrinted) && alreadyPrinted.includes(medicamento.id)) {
        removeAlreadyPrinted(medicamento.id)
      }

      if (medicamentoToEdit?.id === medicamento.id) {
        setMedicamentoToEdit(null)
        setShowDoseAlert(false)
      }
    },
    [
      alreadyPrinted,
      medicamentoToEdit,
      medicamentosToReplicate,
      onChangeMedicamentosToReplicate,
      onClearMedicamentosToReplicate,
      removeAlreadyPrinted,
      setMedicamentoToEdit,
    ]
  )

  const handleOnSubmit = useCallback(
    (medicamento: MedicamentoFormModel) => {
      if (!!medicamentoToEdit) {
        setShowDoseAlert(medicamentoToEdit?.dose && !medicamentoToEdit?.qtDose && !medicamentoToEdit?.unidadeMedidaDose)
        if (medicamentosToReplicate && medicamentosToReplicate.find((item) => item.id === medicamento.id)) {
          push(medicamento)
          handleOnRemoveMedicamentoToReplicate(medicamento)
        } else {
          const index = value?.findIndex((item) => item.id === medicamento.id)

          if (!isEmpty(alreadyPrinted) && alreadyPrinted.includes(medicamento.id)) {
            removeAlreadyPrinted(medicamento.id)
          }

          update(index, medicamento)
        }
      } else {
        push(medicamento)
      }
    },
    [
      alreadyPrinted,
      handleOnRemoveMedicamentoToReplicate,
      medicamentoToEdit,
      medicamentosToReplicate,
      push,
      removeAlreadyPrinted,
      update,
      value,
    ]
  )

  const handleOnMedicamentoDuplicated = useCallback(
    (medicamentoToAdd: MedicamentoFormModel) => {
      return value?.some(
        (receita) =>
          !medicamentoToAdd.registroManual &&
          !receita.registroManual &&
          receita.principioAtivoCombo?.medicamento.id === medicamentoToAdd.principioAtivoCombo?.medicamento.id
      )
    },
    [value]
  )

  const confirmRemoveMedicamento = useCallback(
    (isReplicar: boolean) => (medicamento: MedicamentoFormModel) => {
      confirm({
        title: 'Deseja excluir este medicamento?',
        body: 'Este medicamento será excluído da prescrição do atendimento.',
        type: 'primary',
        onConfirm: () => {
          isReplicar ? handleOnRemoveMedicamentoToReplicate(medicamento) : handleOnRemoveMedicamento(medicamento)
        },
        confirmLabel: 'Excluír',
        depthLevel: 2,
        manageOverflow: false,
      })()
    },
    [handleOnRemoveMedicamento, handleOnRemoveMedicamentoToReplicate]
  )

  return useMemo(
    () => (
      <React.Fragment>
        <HFlow>
          <ModalBody style={styles.formContainer}>
            <TitleGroup
              title='Adicionar prescrição'
              style={css`
                margin-bottom: 1rem;
              `}
            />
            <VFlow vSpacing={2}>
              <PrescricaoMedicamentoForm
                onSubmit={handleOnSubmit}
                isReplicar={!isEmpty(medicamentosToReplicate)}
                medicamentoToEdit={medicamentoToEdit}
                onClearEditedMedicamento={handleOnChangeEditedMedicamento}
                onVerifyMedicamentoDuplicated={handleOnMedicamentoDuplicated}
                scrollModal={scrollModal}
                showDoseAlert={showDoseAlert}
              />
            </VFlow>
          </ModalBody>
          <VFlow vSpacing={0.5} style={styles.listContainer}>
            {medicamentosToReplicate && !isEmpty(medicamentosToReplicate) && (
              <Alert type='warning' inline>
                Revise os dados antes de salvar uma nova prescrição.
              </Alert>
            )}
            {value?.map((item) => (
              <MedicamentoCard
                key={item.id}
                editing={medicamentoToEdit?.id === item.id}
                medicamentoModel={item}
                principiosAtivosAlergias={principiosAtivosAlergias}
                onRemoveMedicamento={confirmRemoveMedicamento(false)}
                onEditMedicamento={setMedicamentoToEdit}
                setShowDoseAlert={setShowDoseAlert}
              />
            ))}
            {medicamentosToReplicate &&
              !isEmpty(medicamentosToReplicate) &&
              medicamentosToReplicate.map((item) => (
                <MedicamentoCard
                  key={item.id}
                  editing={medicamentoToEdit?.id === item.id}
                  replicating
                  medicamentoModel={item}
                  principiosAtivosAlergias={principiosAtivosAlergias}
                  onRemoveMedicamento={confirmRemoveMedicamento(true)}
                  onEditMedicamento={setMedicamentoToEdit}
                  setShowDoseAlert={setShowDoseAlert}
                />
              ))}
          </VFlow>
        </HFlow>
        <ModalFooter style={styles.footer}>
          <HFlow justifyContent='flex-end'>
            <Button onClick={onCloseModal} style={styles.footerButton}>
              Fechar
            </Button>
            <ButtonLink
              kind='primary'
              style={styles.footerButton}
              to={urlToEncaminhar}
              disabled={!value?.length}
              data-cy='EncaminharButton'
              onClick={() => analytics.logEvent('click_button_encaminhar_prescricao')}
            >
              <Icon icon='printerOutline' style={styles.printIcon} />
              Encaminhar
            </ButtonLink>
          </HFlow>
        </ModalFooter>
      </React.Fragment>
    ),
    [
      analytics,
      confirmRemoveMedicamento,
      handleOnChangeEditedMedicamento,
      handleOnMedicamentoDuplicated,
      handleOnSubmit,
      medicamentoToEdit,
      medicamentosToReplicate,
      onCloseModal,
      principiosAtivosAlergias,
      scrollModal,
      setMedicamentoToEdit,
      showDoseAlert,
      urlToEncaminhar,
      value,
    ]
  )
}

const styles = {
  formContainer: css`
    width: 42rem;
    background-color: ${theme.pallete.surface.main};
    border-right: 1px solid + ${theme.pallete.gray.c80};
  `,
  listContainer: css`
    width: 100%;
    max-width: 18rem;
    margin: 4rem 0rem 1rem 0rem;
  `,
  footer: css`
    border-top: 1px solid + ${theme.pallete.gray.c80};
  `,
  printIcon: css`
    margin-right: 0.5rem;
  `,
  footerButton: css`
    width: 9rem;
    height: 3rem;
  `,
}
