import { HFlow, Icon, Text, VFlow } from 'bold-ui'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { SelectField, selectFieldStatus } from 'components/form/final-form'
import { UnidadesSaudeByTipoVinculacaoServicoDocument } from 'graphql/hooks.generated'
import {
  UnidadesSaudeByTipoVinculacaoServicoQuery,
  UnidadesSaudeByTipoVinculacaoServicoQueryVariables,
  VinculacaoServicoTipoEstabelecimentoEnum,
} from 'graphql/types.generated'
import React from 'react'

export type UnidadesSaudeByTipoVinculacaoServicoSelectModel = UnidadesSaudeByTipoVinculacaoServicoQuery['unidadesSaudeByTipoVinculacaoServico'][0]

export interface UnidadeSaudeByTipoVinculacaoServicoSelectFieldProps
  extends AsyncSelectFieldProps<UnidadesSaudeByTipoVinculacaoServicoSelectModel> {
  tipoEstabelecimento: VinculacaoServicoTipoEstabelecimentoEnum
}

export function UnidadeSaudeByTipoVinculacaoServicoSelectField(
  props: UnidadeSaudeByTipoVinculacaoServicoSelectFieldProps
) {
  const { tipoEstabelecimento, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    UnidadesSaudeByTipoVinculacaoServicoSelectModel,
    UnidadesSaudeByTipoVinculacaoServicoQuery,
    UnidadesSaudeByTipoVinculacaoServicoQueryVariables
  >({
    query: UnidadesSaudeByTipoVinculacaoServicoDocument,
    extractItems: (data) => data && data.unidadesSaudeByTipoVinculacaoServico,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        tipoEstabelecimento,
      },
    }),
  })

  return (
    <SelectField<UnidadesSaudeByTipoVinculacaoServicoSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...selectProps}
      {...rest}
    />
  )
}

const itemToString = (option: UnidadesSaudeByTipoVinculacaoServicoSelectModel) => option && option.nome

const renderItem = (item: UnidadesSaudeByTipoVinculacaoServicoSelectModel) => (
  <HFlow alignItems='center' justifyContent='space-between'>
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'>{item.nome}</Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>CNES:</Text>
        {item.cnes}
      </HFlow>
    </VFlow>
    {item.hasVinculacaoServico && (
      <HFlow alignItems='center' hSpacing={0.5} style={{ color: selectFieldStatus.INFO.color }}>
        <Icon icon={selectFieldStatus.INFO.icon} size={1} style={{ display: 'block' }} />
        Possui vínculos.
      </HFlow>
    )}
  </HFlow>
)
