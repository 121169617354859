import { HFlow, Text } from 'bold-ui'
import { CboSelectModel } from 'components/form/field/select/CboSelectField'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import React from 'react'

interface CompartilhamentoCuidadoCboSelectFieldProps extends Omit<SelectFieldProps<CboSelectModel>, 'itemToString'> {
  items: CboSelectModel[]
}

export function CompartilhamentoCuidadoCboSelectField(props: CompartilhamentoCuidadoCboSelectFieldProps) {
  return <SelectField<CboSelectModel> itemToString={itemToString} renderItem={renderItem} {...props} />
}

const itemToString = (option: CboSelectModel) => option?.nome
const renderItem = (item: CboSelectModel) => (
  <>
    <Text fontWeight='bold'>{item.nome}</Text>
    <HFlow hSpacing={0.5}>
      <Text fontWeight='bold'>Código:</Text>
      {item.cbo2002}
    </HFlow>
  </>
)
