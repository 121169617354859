import { Heading } from 'bold-ui'
import {
  AccordionDataTableRowDefault,
  AccordionDataTableRowDefaultProps,
} from 'components/accordion/accordion-data-table/AccordionDataTableRowDefault'
import useSession from 'components/auth/useSession'
import { css } from 'emotion'
import { FormApi } from 'final-form'
import { noop } from 'lodash'
import React from 'react'
import { LembreteModel } from 'view/atendimentos/detail/soap/aside/types/LembreteModel'

import { EditableRow } from '../../EditableList'
import { LembreteForm, LembreteFormModel } from './components/LembreteForm'

interface LembreteTableRowProps extends AccordionDataTableRowDefaultProps<LembreteModel> {
  isEditing?: boolean
  // Atualiza os lembretes jah existentes no soap state
  onRowChanged?(value: LembreteFormModel): void
  // Adiciona uma atualizacao ao soap state caso nao exista uma
  onAddRow?(value: LembreteFormModel, formApi: FormApi)
  removeLembreteEmEdicao?(value: LembreteModel): void
}

export function LembreteTableRowEditable(props: LembreteTableRowProps) {
  const { row, isEditing = false, onRowChanged = noop, onAddRow = noop, removeLembreteEmEdicao = noop } = props

  const handleChange = (lembrete: LembreteFormModel, formApi: FormApi) => {
    if (row.isRegistradoAgora) onRowChanged({ ...lembrete, data: Date.now() })
    else onAddRow({ ...lembrete, data: Date.now() }, formApi)
    removeLembreteEmEdicao(row)
  }

  const { data: sessao, isEstagio } = useSession()
  const canChangeVisibility: boolean = sessao.profissional.id === row.profissionalCriador.id && !isEstagio

  return (
    <>
      <AccordionDataTableRowDefault {...props} />
      {isEditing && (
        <EditableRow editing={isEditing}>
          <Heading
            level={4}
            style={css`
              padding-top: 1rem;
              padding-left: 1rem;
            `}
          >
            Atualizar lembrete
          </Heading>
          <LembreteForm
            isEditing={isEditing}
            hasCancelButton
            canChangeVisibility={canChangeVisibility}
            onSubmit={handleChange}
            onCancel={() => removeLembreteEmEdicao(row)}
            initialValues={{
              _id: row._id ?? `query_lembrete_` + row.id,
              lembreteId: row.id,
              ativo: row.historicoLembrete[0].ativo,
              descricao: row.historicoLembrete[0].descricao,
              visibilidadeLembrete: row.historicoLembrete[0].visibilidadeLembrete,
            }}
          />
        </EditableRow>
      )}
    </>
  )
}
