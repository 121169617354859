import { ExternalStyles, Heading } from 'bold-ui'
import React, { Fragment } from 'react'

export interface DoSearchPanel {
  isCoordenador: boolean
  equipeResponsavelId: ID
}

export function DoSearchPanel(props: DoSearchPanel) {
  const { isCoordenador, equipeResponsavelId } = props
  const isAbleToBuscar = isCoordenador || equipeResponsavelId

  return (
    <div style={styles.div}>
      {isAbleToBuscar ? (
        <Fragment>
          <Heading level={3}>
            Realize uma busca por cidadãos que tenham algum problema ou <br /> condição ativo ou avaliado.
          </Heading>
          <Heading level={5} css={styles.secondHeading}>
            É possível buscar uma lista de cidadãos a partir do preenchimento de qualquer informação, <br /> não esqueça
            de preencher os filtros obrigatórios.
          </Heading>
        </Fragment>
      ) : (
        <Heading level={3}>
          Seu perfil não possui acesso aos resultados. Para buscar os dados é necessário estar vinculado a uma equipe.
        </Heading>
      )}
    </div>
  )
}

const styles = {
  div: {
    textAlign: 'center',
    marginTop: '1rem',
    paddingBottom: '4rem',
  } as React.CSSProperties,
  secondHeading: {
    fontWeight: 'lighter',
    marginTop: '1rem',
  } as ExternalStyles,
}
