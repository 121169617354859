import { InfoLabel, VFlow } from 'bold-ui'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { formatDate } from 'util/date/formatDate'
import { formatNumber } from 'util/number'

import { ResultadoExameQueryModel } from '../../model-resultadosExames'
import {
  getResultadoExameEspecificoProperties,
  getResultadoExameEspecificoPuericulturaLabel,
} from '../../util-resultadosExames'
import { mountIdadeGestacionalValue } from './ResultadoExamePrenatalView'

export const extractResultadoExameMarcadoresBioquimicosValue = (resultadoExame: ResultadoExameQueryModel): string => {
  const {
    exame,
    especifico: { valor },
  } = resultadoExame

  const { unit, precision = 0 } = getResultadoExameEspecificoProperties(exame)

  return `${formatNumber(valor, precision)} ${unit}`
}

export const extractResultadoExamePrenatalValue = (
  resultadoExame: ResultadoExameQueryModel,
  minified?: boolean
): string | JSX.Element => {
  const {
    especifico: { dpp, igSemanas, igDias },
  } = resultadoExame

  const hasIgSemanas = !isUndefinedOrNull(igSemanas)
  const hasIgDias = !isUndefinedOrNull(igDias)

  return minified ? (
    mountIdadeGestacionalValue({ dpp, idadeGestacional: { semanas: igSemanas, dias: igDias } })
  ) : (
    <VFlow vSpacing={0.5}>
      <InfoLabel title='Idade gestacional eco'>
        {hasIgSemanas &&
          `${'semana'.pluralizeAndConcatValue(igSemanas)} ${
            hasIgDias ? `e ${'dia'.pluralizeAndConcatValue(igDias)}` : ''
          }`}
      </InfoLabel>

      <InfoLabel title='DPP eco'>{formatDate(dpp)}</InfoLabel>
    </VFlow>
  )
}

export const extractResultadoExamePuericulturaValue = (resultadoExame: ResultadoExameQueryModel): string => {
  const {
    especifico: { resultadoExamePuericulturaEnum },
  } = resultadoExame

  return getResultadoExameEspecificoPuericulturaLabel(resultadoExamePuericulturaEnum)
}
