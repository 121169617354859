import React from 'react'
import { useRouteMatch } from 'react-router'
import { Route, Switch } from 'react-router-dom'

import {
  MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL,
  ModeloPersonalizadoRelatorioGerencialModal,
} from './ModeloPersonalizadorRelatorioGerencialModal'
import { ModeloPersonalizadoRelatorioGerencialModalProps } from './ModeloPersonalizadorRelatorioGerencialModal'

export function ModeloPersonalizadoRelatorioGerencialRootView(props: ModeloPersonalizadoRelatorioGerencialModalProps) {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${url}${MODELO_PERSONALIZADO_RELATORIO_GERENCIAL_MODAL_URL}/:id?`}>
        <ModeloPersonalizadoRelatorioGerencialModal {...props} />
      </Route>
    </Switch>
  )
}
