/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Heading, Modal, ModalBody, VFlow } from 'bold-ui'
import { Fragment } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { MetaPath } from 'util/metaPath'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { AdicionarLembreteForm } from './AdicionarLembreteForm'
import { LembreteFormModel } from './components/LembreteForm'
import { EditableLembretesTable } from './EditableLembretesTable'
import { LembretesTable } from './LembretesTable'

interface LembretesModalProps {
  prontuarioId: ID
  meta?: MetaPath<LembreteFormModel>
  lotacao: LotacaoAtendimento
  isAtendimentoObservacao: boolean
  lembretesCache?: LembreteFormModel[]
  isEditable?: boolean
}

export function LembretesModal(props: LembretesModalProps) {
  const { meta, prontuarioId, lotacao, isAtendimentoObservacao, lembretesCache, isEditable } = props

  const history = useHistory()
  const classes = styles()
  const match = useRouteMatch()

  const onClose = () => history.push(match.url.replace('/lembretes', ''))

  return (
    <Modal open={true} onClose={onClose}>
      <ModalBody>
        <VFlow style={classes.title}>
          <Heading level={1}>Lembretes</Heading>
          <Alert inline type='info'>
            Os lembretes são informações para os profissionais de saúde.
          </Alert>
        </VFlow>
        {isEditable ? (
          <Fragment>
            <AdicionarLembreteForm meta={meta} />
            <EditableLembretesTable
              prontuarioId={prontuarioId}
              lotacao={lotacao}
              meta={meta}
              lembretesCache={lembretesCache}
              isAtendimentoObservacao={isAtendimentoObservacao}
            />
          </Fragment>
        ) : (
          <LembretesTable lotacao={lotacao} prontuarioId={prontuarioId} />
        )}
      </ModalBody>
    </Modal>
  )
}

const styles = () => ({
  title: css`
    margin-bottom: 1rem;
  `,
})
