/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { isUndefinedOrNull } from 'util/checks'
import { formatDate, formatHoursMinutes } from 'util/date/formatDate'
import { HistoricoAtendimentoModel } from 'view/atendimentos/detail/historico/model/model-historico'
import { isFichaAtendimento } from 'view/atendimentos/detail/historico/utils/utils-historico'

import { OrigemAtendimentoObservacaoIcon } from './OrigemAtendimentoObservacaoIcon'

interface HistoricoAccordionItemObservacaoWrapperProps {
  children: JSX.Element
  historicoAtendimento: HistoricoAtendimentoModel
}

const DATA_HORARIO_NAO_INFORMADOS = 'Data e horário não informados'

export const HistoricoAccordionItemObservacaoWrapper = (props: HistoricoAccordionItemObservacaoWrapperProps) => {
  const { children, historicoAtendimento } = props

  const theme = useTheme()
  const classes = styles(theme)

  if (!historicoAtendimento.hasObservacao) return children

  const isFicha = isFichaAtendimento(historicoAtendimento.origemAtendimento)

  const hasDataInicio = !isUndefinedOrNull(historicoAtendimento.inicioAtendimentoObservacao)
  const hasDataFim = !isUndefinedOrNull(historicoAtendimento.fimAtendimentoObservacao)

  const isDataFimIgualDataInicio =
    hasDataInicio &&
    hasDataFim &&
    historicoAtendimento.inicioAtendimentoObservacao === historicoAtendimento.fimAtendimentoObservacao

  const dataObservacaoInicio =
    hasDataInicio &&
    `${formatDate(historicoAtendimento.inicioAtendimentoObservacao)} às ${formatHoursMinutes(
      historicoAtendimento.inicioAtendimentoObservacao
    )}`

  const dataObservacaoFim =
    hasDataFim &&
    `${formatDate(historicoAtendimento.fimAtendimentoObservacao)} às ${formatHoursMinutes(
      historicoAtendimento.fimAtendimentoObservacao
    )}`

  const nomeFinalizadorObservacao =
    historicoAtendimento.nomeFinalizadorObservacao !== 'Não informado' && historicoAtendimento.nomeFinalizadorObservacao

  return (
    <VFlow vSpacing={0}>
      {(hasDataFim || isFicha) && (
        <div css={css(classes.item, classes.prependItem)}>
          <HFlow hSpacing={0.3} alignItems='center'>
            {nomeFinalizadorObservacao ? (
              <Text fontWeight='bold'>
                Episódios de cuidado de observação finalizada por {nomeFinalizadorObservacao}
              </Text>
            ) : (
              <Text fontWeight='bold'>Fim da observação e desfecho do atendimento</Text>
            )}

            <Text>
              {' | '}
              {isFicha || isDataFimIgualDataInicio ? DATA_HORARIO_NAO_INFORMADOS : dataObservacaoFim}
            </Text>
            {isFicha && <OrigemAtendimentoObservacaoIcon origemAtendimento={historicoAtendimento.origemAtendimento} />}
          </HFlow>
        </div>
      )}

      {children}

      {(hasDataInicio || isFicha) && (
        <div css={css(classes.item, classes.appendItem)}>
          <HFlow hSpacing={0.3} alignItems='center'>
            <Text fontWeight='bold'>Início da observação do cidadão</Text>
            <Text>
              {' | '} {isFicha ? DATA_HORARIO_NAO_INFORMADOS : dataObservacaoInicio}
            </Text>
            {isFicha && <OrigemAtendimentoObservacaoIcon origemAtendimento={historicoAtendimento.origemAtendimento} />}
          </HFlow>
        </div>
      )}
    </VFlow>
  )
}

const styles = (theme: Theme) => ({
  item: css`
    border-radius: 2px;
    border: 1px solid ${theme.pallete.primary.c40};
    background: ${theme.pallete.primary.c90};
    padding: 0.25rem 1rem;

    * {
      color: ${theme.pallete.primary.c40};
    }
  `,
  prependItem: css`
    margin-bottom: -1px;
  `,
  appendItem: css`
    margin-top: -1px;
  `,
})
