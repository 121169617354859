import { Cell, Grid, HFlow, Icon, InfoLabel, Tag, Text, Theme, Tooltip, useStyles, VFlow } from 'bold-ui'
import { green, turquoise } from 'bold-ui/lib/styles/colors'
import { AccordionButton } from 'components/accordion/AccordionButton'
import { DateTime } from 'components/date'
import { AccordionField } from 'components/form/field/AccordionField'
import { css } from 'emotion'
import { OrigemAtendimento } from 'graphql/types.generated'
import { noop } from 'lodash'
import React, { CSSProperties } from 'react'
import { AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'

import { CondicoesFormModel, isBcg, origemDadosVacinacao } from '../model'
import { RegistroDoseWrapperProps } from './RegistroDoseWrapper'
import { VacinacaoVisualizacaoButtons } from './VacinacaoVisualizacaoButtons'

export interface RegistroDoseAccordionItemProps extends RegistroDoseWrapperProps {}

export const RegistroDoseAccordionItem = (props: RegistroDoseAccordionItemProps) => {
  const {
    dataAplicacao,
    origemDados,
    registradoAtendimento,
    idUnicoRegistro,
    handleOnDelete = noop,
    handleOnEdit = noop,
    readOnly = false,
    estrategia,
    grupoAtendimento,
    lote,
    nomeFabricante,
    dataValidadeLote,
    viaDeAdministracao,
    observacoes,
    localDaAplicacao,
    isPuerpera,
    isGestante,
    isViajante,
    isComunicanteHanseniase,
    imunobiologicoId,
    doseNome,
    doseSigla,
    nomeProfissional,
    cbo,
    unidadeSaude,
    idUnicoRegistroAprazamentoProxDose,
    dataRegistro,
    condicoesVacinacao,
    isAplicacaoEquivalente,
    hasOnlyEquivalentesAplicadas,
  } = props

  const { classes } = useStyles(createStyles)

  const renderDoseContent = () => {
    let registroDoseContentProps: RegistroDoseContentProps = {
      cbo: cbo,
      condicoesVacinacao: condicoesVacinacao
        ? condicoesVacinacao
        : {
            comunicanteHanseniase: isComunicanteHanseniase,
            gestante: isGestante,
            puerpera: isPuerpera,
            viajante: isViajante,
          },
      lote: lote,
      nomeFabricante,
      dataRegistro,
      dataValidadeLote,
      estrategia,
      doseNome,
      doseSigla,
      imunobiologicoId,
      isComunicanteHanseniase,
      isGestante,
      isPuerpera,
      isViajante,
      localDaAplicacao,
      nomeProfissional,
      observacoes,
      origemDados,
      registradoAtendimento,
      unidadeSaude,
      viaDeAdministracao,
      grupoAtendimento,
      isAplicacaoEquivalente,
    }

    return <RegistroDoseContent {...registroDoseContentProps} />
  }

  if (readOnly) {
    return renderDoseContent()
  }

  return (
    <VFlow vSpacing={0}>
      <AccordionField name='Registro' allowZeroExpanded>
        <AccordionItem>
          <AccordionButton style={classes.accordionButton}>
            <HFlow
              alignItems='center'
              justifyContent='space-between'
              style={css`
                padding: 0.5rem;
                min-width: 35rem;
                width: 100%;
              `}
            >
              <HFlow>
                <Icon icon='angleDown' size={1.5} />
                <HFlow hSpacing={0.3} alignItems='center'>
                  <Icon color={green.c40} icon='needleFilled' size={1.1} />
                  <Text fontWeight='bold'>Dose aplicada em </Text>
                  <DateTime
                    value={dataAplicacao}
                    style={css`
                      font-weight: bold;
                    `}
                    format='DD/MM/YYYY'
                  />
                </HFlow>
              </HFlow>
              <HFlow hSpacing={0.3} alignItems='center'>
                {hasOnlyEquivalentesAplicadas && isAplicacaoEquivalente && (
                  <Tooltip text='Essa é uma aplicação de uma dose equivalente'>
                    <Tag type='normal'>Equivalente</Tag>
                  </Tooltip>
                )}
                <Tooltip text={origemDadosVacinacao[origemDados]?.hint}>
                  <Tag type='info'>{origemDadosVacinacao[origemDados]?.label}</Tag>
                </Tooltip>
                {registradoAtendimento && (
                  <VacinacaoVisualizacaoButtons
                    idUnicoRegistroAprazamentoProxDose={idUnicoRegistroAprazamentoProxDose}
                    idUnicoRegistro={idUnicoRegistro}
                    tipoModal='APLICACAO'
                    handleOnDelete={handleOnDelete}
                    handleOnEdit={handleOnEdit}
                  />
                )}
              </HFlow>
            </HFlow>
          </AccordionButton>
          <AccordionItemPanel className={classes.panel}>{renderDoseContent()}</AccordionItemPanel>
        </AccordionItem>
      </AccordionField>
    </VFlow>
  )
}

interface RegistroDoseContentProps {
  estrategia: string
  doseSigla?: string
  doseNome?: string
  grupoAtendimento?: string
  lote: string
  nomeFabricante: string
  dataValidadeLote: string
  viaDeAdministracao: string
  localDaAplicacao: string
  observacoes: string
  registradoAtendimento: boolean
  isPuerpera: boolean
  isGestante: boolean
  isViajante: boolean
  isComunicanteHanseniase: boolean
  imunobiologicoId: string
  nomeProfissional: string
  cbo: string
  unidadeSaude: string
  dataRegistro: string
  condicoesVacinacao: CondicoesFormModel
  origemDados: OrigemAtendimento
  isAplicacaoEquivalente: boolean
}

const RegistroDoseContent = (props: RegistroDoseContentProps) => {
  const {
    estrategia,
    grupoAtendimento,
    lote,
    nomeFabricante,
    dataValidadeLote,
    viaDeAdministracao,
    localDaAplicacao,
    observacoes,
    registradoAtendimento,
    isPuerpera,
    isGestante,
    isViajante,
    isComunicanteHanseniase,
    imunobiologicoId,
    doseNome,
    doseSigla,
    nomeProfissional,
    cbo,
    unidadeSaude,
    dataRegistro,
    condicoesVacinacao,
    origemDados,
    isAplicacaoEquivalente,
  } = props

  const { gestante, puerpera, viajante, comunicanteHanseniase } = condicoesVacinacao

  const hasCondicoesVacinacao =
    (registradoAtendimento && (gestante || puerpera || viajante || comunicanteHanseniase)) ||
    isGestante ||
    isPuerpera ||
    isViajante ||
    isComunicanteHanseniase

  const { classes } = useStyles(createStyles)

  const shouldExpandCell = grupoAtendimento && isAplicacaoEquivalente

  return (
    <Grid
      gap={0}
      gapVertical={1}
      style={css`
        padding: 1rem;
        width: 36rem;
        word-wrap: break-word;
      `}
    >
      <Cell size={8}>
        <InfoLabel title='Estratégia'>{estrategia?.capitalize()}</InfoLabel>
      </Cell>
      {isAplicacaoEquivalente && (
        <Cell size={4}>
          <InfoLabel title='Dose aplicada'>{`${doseSigla || ' - '} - ${doseNome?.capitalize() || ' - '}`}</InfoLabel>
        </Cell>
      )}
      {grupoAtendimento && (
        <Cell size={shouldExpandCell ? 8 : 4}>
          <InfoLabel title='Grupo de atendimento'> {grupoAtendimento?.capitalize()} </InfoLabel>
        </Cell>
      )}
      <Cell size={shouldExpandCell ? 4 : 8}>
        <InfoLabel title='Lote/Fabricante'>
          {`${lote?.capitalize() || ' - '}/${nomeFabricante?.capitalize() || ' - '}`}
        </InfoLabel>
      </Cell>
      <Cell size={shouldExpandCell ? 8 : 4}>
        <InfoLabel title='Data de validade'>
          <DateTime value={dataValidadeLote} format='DD/MM/YYYY' />
        </InfoLabel>
      </Cell>
      <Cell size={shouldExpandCell ? 4 : 8}>
        <InfoLabel placeholder='-' title='Via de administração'>
          {viaDeAdministracao?.capitalize()}
        </InfoLabel>
      </Cell>
      <Cell size={4}>
        <InfoLabel placeholder='-' title='Local da aplicação'>
          {localDaAplicacao?.capitalize()}
        </InfoLabel>
      </Cell>
      <Cell size={12}>
        <InfoLabel title='Observações' placeholder='-'>
          {observacoes}
        </InfoLabel>
      </Cell>
      {hasCondicoesVacinacao && (
        <Cell size={12}>
          <InfoLabel title='Condições'>
            <HFlow hSpacing={0.5}>
              {((registradoAtendimento && puerpera) || isPuerpera) && <Tag style={classes.condicaoTag}>Puérpera</Tag>}
              {((registradoAtendimento && gestante) || isGestante) && <Tag style={classes.condicaoTag}>Gestante</Tag>}
              {((registradoAtendimento && viajante) || isViajante) && <Tag style={classes.condicaoTag}>Viajante</Tag>}
              {((registradoAtendimento && comunicanteHanseniase) || isComunicanteHanseniase) &&
                isBcg(imunobiologicoId) && <Tag style={classes.condicaoTag}> Comunicante de hanseníase </Tag>}
            </HFlow>
          </InfoLabel>
        </Cell>
      )}
      <Cell size={12}>
        <InfoLabel title='Aplicado por'>{`${nomeProfissional} (${cbo})`}</InfoLabel>
        {unidadeSaude}
      </Cell>
      <Cell>
        <Text fontWeight='bold'>Aplicação registrada em </Text>
        <DateTime
          value={dataRegistro}
          style={css`
            font-weight: bold;
          `}
          format={origemDados === OrigemAtendimento.PEC ? 'DD/MM/YYYY [às] HH:mm' : 'DD/MM/YYYY'}
        />
      </Cell>
    </Grid>
  )
}

const createStyles = (theme: Theme) => ({
  panel: {
    margin: '0 0.3rem',
    borderWidth: '0 0.063rem 0.063rem',
    borderStyle: 'solid',
    borderColor: theme.pallete.gray.c60,
    position: 'relative',
    top: '-0.6rem',
  } as CSSProperties,
  accordionButton: {
    display: 'flex',
    padding: '0.5rem',
    border: `0.063rem solid ${theme.pallete.gray.c60}`,
    borderBlockEnd: `0.063rem solid ${theme.pallete.gray.c80}`,
  } as CSSProperties,
  condicaoTag: {
    backgroundColor: turquoise.c40,
    color: turquoise.c100,
  } as CSSProperties,
})
