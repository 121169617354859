import { HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import React from 'react'

import { MedicamentoFormModel } from '../../model-prescricao'
import { resolveConcentracao, resolvePrincipioAtivo } from '../../utils/converter-prescricao'
import { MEDICAMENTOS_NAO_PERMITIDOS_TOOLTIP_TEXT } from './model-prescricaoDigital'

interface PrescricaoDigitalMedicamentosNaoPermitidosListProps {
  medicamentosNaoPermitidos: MedicamentoFormModel[]
}

export function PrescricaoDigitalMedicamentosNaoPermitidosList(
  props: PrescricaoDigitalMedicamentosNaoPermitidosListProps
) {
  const { medicamentosNaoPermitidos } = props
  return (
    <VFlow vSpacing={0}>
      <HFlow hSpacing={0.5} alignItems='center'>
        <Text fontWeight='bold'>Medicamentos não permitidos na prescrição digital</Text>
        <Tooltip text={MEDICAMENTOS_NAO_PERMITIDOS_TOOLTIP_TEXT}>
          <Icon icon='infoCircleFilled' size={1} />
        </Tooltip>
      </HFlow>
      <ul>
        {medicamentosNaoPermitidos.map((medicamento) => {
          const principioAtivo = resolvePrincipioAtivo(medicamento)
          const concentracao = resolveConcentracao(medicamento)
          return (
            <li key={medicamento.id}>
              {principioAtivo} {concentracao}
            </li>
          )
        })}
      </ul>
    </VFlow>
  )
}
