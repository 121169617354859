import { AlertType, Text, TextColor } from 'bold-ui'
import { AsyncProcessingNameEnum } from 'graphql/types.generated'
import { asyncProcessingFinishMessageInfoRecord } from 'hooks/async/model-asyncProcessing'
import React from 'react'
import { Link } from 'react-router-dom'

interface AsyncProcessingFinishMessageProps {
  message: string
  type: AlertType & TextColor
  asyncProcessingName: AsyncProcessingNameEnum
  onLinkClick: () => void
}

export function AsyncProcessingFinishMessage({
  message,
  type = 'success',
  asyncProcessingName,
  onLinkClick,
}: AsyncProcessingFinishMessageProps) {
  const { path } = asyncProcessingFinishMessageInfoRecord[asyncProcessingName] ?? {}

  return (
    <Text component='p' color={type}>
      {message}.
      {path && (
        <>
          {' '}
          Clique{' '}
          <Link to={path} onClick={onLinkClick}>
            <Text fontWeight='bold'>aqui</Text>
          </Link>{' '}
          para retornar à tela.
        </>
      )}
    </Text>
  )
}
