import { isCiap } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { AggregatorEnum } from 'components/pivot-table/components/aggregator/model-aggregator'
import { DroppableOrigin } from 'components/pivot-table/components/dragndrop/model-dragndrop'
import { PivotTableInitialValues } from 'components/pivot-table/PivotTable'
import {
  ModeloPersonalizadoRelatorioGerencial,
  ModeloPersonalizadoRelatorioGerencialInput,
  ModeloRelatorioGerencial,
  OpcaoSelecionadaRelatorioGerencialEnum,
  TipoModuloEnum,
  UnidadePeriodoRelatorioGerencialEnum,
} from 'graphql/types.generated'
import { partition } from 'lodash'
import { Permission } from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'

import { KeyMapping } from '../../common/keyMapping-relatorioGerencial'
import { calculatePeriodoRelatorioGerencial } from '../form/calculator-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from '../form/model-relatorioGerencialFiltrosForm'
import { ModeloPersonalizadoRelatorioGerencialFormModel } from './form/model-modeloPersonalizadoRelatorioGerencialForm'
import { VisibilidadeModeloPersonalizadoEnum } from './form/model-modeloPersonalizadoRelatorioGerencialForm'
import { isCampoInconsistente } from './utils-modeloPersonalizado'

export function modeloToPivotTableProps<T>(
  modelo: ModeloRelatorioGerencial,
  keyMapping: Map<string, KeyMapping>
): PivotTableInitialValues<T> {
  return modelo
    ? {
        fields: modelo.campos
          .filter((campo) => !isUndefinedOrNull(keyMapping.get(campo.key)?.visibility))
          .map((campo) => ({
            filters: campo.items,
            key: campo.key as keyof T,
            origin: DroppableOrigin[campo.posicao],
          })),
        aggregatorId: AggregatorEnum[modelo.aggregator],
      }
    : null
}

export function modeloPersonalizadoToFiltrosFormModel(
  modeloPersonalizado: ModeloPersonalizadoRelatorioGerencial,
  keyMapping: Map<string, KeyMapping>,
  modulo: TipoModuloEnum,
  isGestorEstadual: boolean,
  hasAuthorization: (permission: Permission) => boolean
): RelatorioGerencialFiltrosFormModel {
  return {
    ciapsCids: [...(modeloPersonalizado.modelo.ciaps ?? []), ...(modeloPersonalizado.modelo.cids ?? [])],
    gruposCondicoesPrioritarios: modeloPersonalizado.modelo.gruposCondicoes,
    opcaoSelecionada: modeloPersonalizado.modelo.opcaoSelecionada ?? OpcaoSelecionadaRelatorioGerencialEnum.PRIMEIRA,
    unidade: modeloPersonalizado.modelo.unidadePeriodo ?? UnidadePeriodoRelatorioGerencialEnum.MES,
    modeloPersonalizado: {
      id: modeloPersonalizado.id,
      nome: modeloPersonalizado.nome,
      hasCampoInconsistente: modeloPersonalizado.modelo.campos.some(({ key }) =>
        isCampoInconsistente(key, keyMapping, modulo, isGestorEstadual, hasAuthorization)
      ),
      isPublico: modeloPersonalizado.isPublico,
    },
    periodo: calculatePeriodoRelatorioGerencial(
      modeloPersonalizado?.modelo.unidadePeriodo,
      modeloPersonalizado?.modelo.opcaoSelecionada,
      null
    ),
  }
}

export const relatorioGerencialFormModelToJsonModel = (
  values: ModeloPersonalizadoRelatorioGerencialFormModel
): ModeloPersonalizadoRelatorioGerencialInput => {
  const [ciaps, cids] = partition(values.ciapsCids, (ciapCid) => isCiap(ciapCid))
  return {
    aggregator: values.aggregatorModal,
    ciapIds: ciaps?.map((ciapCid) => ciapCid.id),
    cidIds: cids?.map((ciapCid) => ciapCid.id),
    campos: values.campos.map((campo) => ({
      key: campo.key,
      posicao: campo.posicao,
      items: campo.items,
    })),
    gruposCondicoesIds: values.gruposCondicoes?.map((grupoCondicao) => grupoCondicao.id),
    opcaoSelecionada: values.opcaoSelecionadaModal,
    unidadePeriodo: values.unidadePeriodoModal,
  }
}

export function visibilidadeModeloPersonalizadoEnumToIsPublico(visibilidade: VisibilidadeModeloPersonalizadoEnum) {
  return visibilidade === VisibilidadeModeloPersonalizadoEnum.PUBLICO
}

export function isPublicoToVisibilidadeModeloPersonalizadoEnum(
  isPublico: boolean
): VisibilidadeModeloPersonalizadoEnum {
  return isPublico ? VisibilidadeModeloPersonalizadoEnum.PUBLICO : VisibilidadeModeloPersonalizadoEnum.PRIVADO
}
