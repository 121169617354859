import { HFlow, Icon } from 'bold-ui'
import {
  CboSelectModel,
  Form,
  FormDebouncedValueSpy,
  ProfissionalSelectModel,
  UnidadeSaudeSelectModel,
} from 'components/form'
import { CheckboxGroupField } from 'components/form/field/CheckboxGroupField'
import { PopperButton, PopperControls } from 'components/popper'
import { FormState } from 'final-form'
import { useFilterCounter } from 'hooks/useFilterCounter'
import React from 'react'
import { metaPath } from 'util/metaPath'

import { TipoCompartilhamentoCuidadoPlanoEnum } from '../../cuidado-compartilhado/model-cuidadocompartilhado'
import {
  CompartilhamentoCuidadoTableFilterModel,
  CompartilhamentoCuidadoTableFilterPopperModel,
} from '../model-compartilhamentocuidado'
import { CompartilhamentoCuidadoTableFilterPopper } from './CompartilhamentoCuidadoTableFilterPopper'
import { CompartilhamentoCuidadoCboSelectField } from './components/CompartilhamentoCuidadoCboSelectField'

interface CompartilhamentoCuidadoTableFilterProps {
  filter: CompartilhamentoCuidadoTableFilterModel
  onFilterChange: (filter: CompartilhamentoCuidadoTableFilterModel) => void
  profissionaisExecutantes: ProfissionalSelectModel[]
  profissionaisSolicitantes: ProfissionalSelectModel[]
  cbosToFilter: CboSelectModel[]
  unidadeSaudeToFilter: UnidadeSaudeSelectModel[]
}

const path = metaPath<CompartilhamentoCuidadoTableFilterModel>()

export const CompartilhamentoCuidadoTableFilter = (props: CompartilhamentoCuidadoTableFilterProps) => {
  const {
    filter,
    onFilterChange,
    profissionaisExecutantes,
    profissionaisSolicitantes,
    cbosToFilter,
    unidadeSaudeToFilter,
  } = props

  const handlePopperSubmit = (values: CompartilhamentoCuidadoTableFilterPopperModel) => {
    onFilterChange({ ...filter, ...values })
  }
  const renderPopper = (controls: PopperControls) => (
    <CompartilhamentoCuidadoTableFilterPopper
      onSubmit={handlePopperSubmit}
      onClose={controls.close}
      initialValues={filter}
      profissionaisExecutantes={profissionaisExecutantes}
      profissionaisSolicitantes={profissionaisSolicitantes}
      unidadeSaudeToFilter={unidadeSaudeToFilter}
    />
  )

  const { tiposCompartilhamento, ...filtersInPopper } = filter
  const countFiltrosAplicados = useFilterCounter(filtersInPopper)

  return (
    <HFlow justifyContent='space-between'>
      <Form
        initialValues={filter}
        render={() => (
          <HFlow alignItems='center'>
            <FormDebouncedValueSpy
              onChange={(formState: FormState<CompartilhamentoCuidadoTableFilterModel>) =>
                onFilterChange(formState.values)
              }
            />
            <CheckboxGroupField
              name={path.tiposCompartilhamento}
              options={[
                {
                  label: 'Cuidado compartilhado',
                  value: TipoCompartilhamentoCuidadoPlanoEnum.CUIDADO_COMPARTILHADO,
                },
                {
                  label: 'Guia de encaminhamento',
                  value: TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO,
                },
              ]}
            />
            <CompartilhamentoCuidadoCboSelectField name={path.cbo} items={cbosToFilter} placeholder='CBO' />
          </HFlow>
        )}
      />
      <PopperButton kind='primary' size='small' renderPopper={renderPopper} placement='bottom-end'>
        <Icon icon='filterOutline' />
        {'Filtros' + (countFiltrosAplicados > 0 ? ` (${countFiltrosAplicados})` : '')}
      </PopperButton>
    </HFlow>
  )
}
