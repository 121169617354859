import { addDays, addHours, isFriday, isWeekend } from 'date-fns'

import { isBeforeOrEqual } from './compare'

const BUSINESS_HOUR_LIMIT = 18
const SEXTA = 5
const SABADO = 6
const DOMINGO = 0

const isWithinProlongedWeekend = (date: Date): boolean =>
  (isFriday(date) && date.getHours() >= BUSINESS_HOUR_LIMIT) || isWeekend(date)

const getNextValidDateAfterProlongedWeekend = (date: Date): Date => {
  let validDate = date
  switch (date.getDay()) {
    case SEXTA:
      if (date.getHours() >= BUSINESS_HOUR_LIMIT) {
        validDate = addDays(date, 3)
        validDate.setHours(0, 0, 0, 0)
      }
      break
    case SABADO:
      validDate = addDays(date, 2)
      validDate.setHours(0, 0, 0, 0)
      break
    case DOMINGO:
      validDate = addDays(date, 1)
      validDate.setHours(0, 0, 0, 0)
      break
    default:
      break
  }

  return validDate
}

// Função para calcular a diferença em horas úteis, desconsiderando o intervalo prolongado de final de semana
export const differenceInBusinessHoursExcludingProlongedWeekend = (
  start: Date | number,
  end: Date | number
): number => {
  const startDate = typeof start === 'number' ? new Date(start) : start
  const endDate = typeof end === 'number' ? new Date(end) : end
  let currentDate = startDate
  let totalHours = 0
  let spareMinutes = 0

  while (isBeforeOrEqual(currentDate, endDate)) {
    if (!isWithinProlongedWeekend(currentDate)) {
      const nextHour = addHours(currentDate, 1)
      const isWithinProlongedWeekendNextHour = isWithinProlongedWeekend(nextHour)
      if (isBeforeOrEqual(nextHour, endDate)) {
        totalHours++
        if (isWithinProlongedWeekendNextHour) {
          spareMinutes -= nextHour.getMinutes()
        }
      } else if (!isWithinProlongedWeekendNextHour) {
        spareMinutes += currentDate.getMinutes()
      }

      currentDate = nextHour
    } else {
      spareMinutes += endDate.getMinutes()
      currentDate = getNextValidDateAfterProlongedWeekend(currentDate)
    }
  }

  return Math.floor(totalHours + spareMinutes / 60)
}
