import { HFlow, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { UnidadesSaudeSelectFieldQuery } from 'graphql/types.generated'
import React from 'react'

export type UnidadeSaudeSelectModel = UnidadesSaudeSelectFieldQuery['unidadesSaude']['content'][0]

interface CompartilhamentoCuidadoUnidadeSaudeSelectFieldProps
  extends Omit<SelectFieldProps<UnidadeSaudeSelectModel>, 'itemToString'> {
  items: UnidadeSaudeSelectModel[]
}

export function CompartilhamentoCuidadoUnidadeSaudeSelectField(
  props: CompartilhamentoCuidadoUnidadeSaudeSelectFieldProps
) {
  return <SelectField<UnidadeSaudeSelectModel> itemToString={itemToString} renderItem={renderItem} {...props} />
}

const itemToString = (option: UnidadeSaudeSelectModel) => option && option.nome

const renderItem = (item: UnidadeSaudeSelectModel) => (
  <>
    <Text fontWeight='bold'>{item.nome}</Text>
    <HFlow hSpacing={0.5}>
      <Text fontWeight='bold'>CNES:</Text>
      {item.cnes}
    </HFlow>
  </>
)
