import { Icon } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { useErrorHandler } from 'components/error'
import { useRemoverFaltaCidadaoMutationMutation } from 'graphql/hooks.generated'
import React from 'react'
import Permissions from 'types/Permissions'

import MenuAcoesAgendamentoButton from '../MenuAcoesAgendamentoButton'
import { AcaoAgendamentoButtonProps } from '../model-acoesAgendamento'

export function RemoverFaltaCidadaoButton(props: AcaoAgendamentoButtonProps) {
  const { event, tooltip, disabled, isDropdown } = props
  const alert = useAlert()
  const handleException = useErrorHandler()
  const [informarFalta, { loading }] = useRemoverFaltaCidadaoMutationMutation()

  const onClick = () => {
    informarFalta({ variables: { agendamentoId: event.id } })
      .then(() => {
        alert('success', `A falta de ${event.nomeCidadao.titleCase()} foi cancelada.`)
      })
      .catch(handleException)
  }

  return (
    <CheckPermission permission={Permissions.visualizarAgenda.informarFaltaDoCidadao}>
      <MenuAcoesAgendamentoButton
        text='Cancelar falta do cidadão'
        tooltip={tooltip}
        disabled={disabled}
        loading={loading}
        isDropdown={isDropdown}
        onClick={onClick}
        icon={<Icon icon='userReturn' />}
      />
    </CheckPermission>
  )
}
