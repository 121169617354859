import { Calculation } from 'final-form-calculate'
import {
  AtencaoDomiciliarCondutaDesfecho,
  AtencaoDomiciliarModalidade,
  BuscaPeriodoEnum,
} from 'graphql/types.generated'

import { AtencaoDomiciliarFilterPopperModel, meta as pathFilter } from '../filter/AtencaoDomiciliarFilterPopper'
import CidadaoADFormModel, { path as pathForm } from './CidadaoADFormModel'

const modalidadeCalculator = () => [
  {
    field: pathForm.modalidade.absolutePath(),
    updates: (modalidade: CidadaoADFormModel['modalidade'], field, allValues: CidadaoADFormModel) => {
      let data: CidadaoADFormModel = { ...allValues }

      if (modalidade === AtencaoDomiciliarModalidade.POS_OBITO) {
        data = { ...data, ...{ procedencia: null } }
      } else {
        data = { ...data, ...{ cidadaoAdEmObito: null } }
      }

      return data
    },
  },
]

const condutaDesfechoCalculator = () => [
  {
    field: pathForm.condutaDesfecho.absolutePath(),
    updates: (condutaDesfecho: CidadaoADFormModel['condutaDesfecho'], field, allValues: CidadaoADFormModel) => {
      let data: CidadaoADFormModel = { ...allValues }

      if (condutaDesfecho !== AtencaoDomiciliarCondutaDesfecho.OBITO) {
        data = { ...data, ...{ dataObito: null, numeroObito: null } }
      }
      if (condutaDesfecho === AtencaoDomiciliarCondutaDesfecho.PERMANENCIA) {
        data = { ...data, ...{ dataSaida: null } }
      }

      return data
    },
  },
]

const cidadaoSelectFieldCalculator = () => [
  {
    field: pathForm.cidadao.absolutePath(),
    updates: (cidadao: CidadaoADFormModel['cidadao'], field, allValues: CidadaoADFormModel) => {
      let data: CidadaoADFormModel = { ...allValues }

      if (!data.id && cidadao) {
        data = { ...data, ...{ cid10Principal: null, cid10Secundario1: null, cid10Secundario2: null } }
      }

      return data
    },
  },
]

export const createCidadaoAdCalculations = (): Calculation[] => [
  ...condutaDesfechoCalculator(),
  ...modalidadeCalculator(),
  ...cidadaoSelectFieldCalculator(),
]

const tipoBuscaPeriodoCalculator = () => [
  {
    field: pathFilter.tipoBuscaPeriodo?.absolutePath(),
    updates: (
      tipoBuscaPeriodo: AtencaoDomiciliarFilterPopperModel['tipoBuscaPeriodo'],
      field,
      allValues: AtencaoDomiciliarFilterPopperModel
    ) => {
      let data: AtencaoDomiciliarFilterPopperModel = { ...allValues }

      if (tipoBuscaPeriodo === BuscaPeriodoEnum.PERIODO_NENHUM) {
        data = { ...data, ...{ periodoBusca: null } }
      }

      return data
    },
  },
]

export const createFilterListaAdCalculations = (): Calculation[] => [...tipoBuscaPeriodoCalculator()]
