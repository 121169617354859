import axios from 'axios'
import { mapValues } from 'lodash'
import { LocalDateRange } from 'util/date/dateRange'

import { Aggregator } from '../components/aggregator/model-aggregator'
import { PivotTableProps } from '../PivotTable'
import { GroupResult } from './GroupResult'
import { Dictionary, TreeRoot } from './model-treeBuilder'
import { OperationsPaths, TreeMeta } from './model-treeBuilder'
import { IGNORED_TREE_KEYS } from './util-treeBuilder'

interface TreeMetaFromServer<T> {
  isEmpty: boolean
  numberKeys: string[]
  keyValues: { [key: string]: Array<string> }
}

export class TreeBuilder<T extends any> {
  private _maxLeafValue: number = 0
  private data: T[]
  private size: number
  private hasData: boolean
  private operations: OperationsPaths

  set maxLeafValue(value: number) {
    if (value > this._maxLeafValue) {
      this._maxLeafValue = value
    }
  }

  get maxLeafValue() {
    return this._maxLeafValue
  }

  constructor(data?: T[], operations?: OperationsPaths) {
    if (data != null) {
      this.data = data
      this.size = data.length
      this.hasData = true
    } else if (operations != null) {
      this.operations = operations
    } else {
      throw new SyntaxError('Inicie com dados ou query.')
    }
  }

  meta = async (
    keyMapping: PivotTableProps<T>['keyMapping'],
    dateRange: LocalDateRange,
    periodUnit: string,
    filtroCiapCid: string[]
  ): Promise<TreeMeta<T> | null> => {
    if (this.hasData) {
      return {
        isEmpty: this.isEmpty(),
        keyValues: this.mapUniqueValues(keyMapping),
        numberKeys: this.numberKeys(),
      }
    } else {
      const parameters = { dateRange, periodUnit, filtroCiapCid }
      const metaFromServer: TreeMetaFromServer<T> = (await axios.post(this.operations.meta, parameters)).data
      if (metaFromServer) {
        return this.translateKeyValues(keyMapping, metaFromServer)
      }

      return null
    }
  }

  private translateKeyValues = (
    keyMapping: PivotTableProps<T>['keyMapping'],
    metaFromServer: TreeMetaFromServer<T>
  ): TreeMeta<T> => {
    const translatedKeyValues = new Map<keyof T, Array<string>>()

    for (let [key, value] of Object.entries(metaFromServer.keyValues)) {
      translatedKeyValues.set(key as keyof T, Array.from(value).sort(keyMapping.get(key as keyof T)?.ordenator))
    }

    return {
      ...metaFromServer,
      keyValues: translatedKeyValues,
    }
  }

  private isEmpty = () => {
    return this.data.length === 0
  }

  private numberKeys = (): string[] => {
    const sample = this.data[0]
    return Object.keys(sample).filter((k) => typeof sample[k] === 'number')
  }

  private mapUniqueValues = (keyMapping: PivotTableProps<T>['keyMapping']) => {
    const tempKeysValues = new Map<keyof T, Set<string>>()
    this.data.forEach((element: T) => {
      Object.keys(element)
        .filter((k) => !IGNORED_TREE_KEYS.includes(k))
        .forEach((key) => {
          const keyValue = element[key]
          if (keyValue || keyValue === 0) {
            let set = tempKeysValues.get(key as keyof T)
            if (!set) {
              set = new Set<string>()
              tempKeysValues.set(key as keyof T, set)
            }
            set.add(keyValue)
          }
        })
    })

    const result = new Map<keyof T, Array<string>>()

    for (let [key, value] of tempKeysValues.entries()) {
      result.set(key, Array.from(value).sort(keyMapping.get(key)?.ordenator))
    }

    return result
  }

  async build<K extends keyof T>(
    keys: Array<K>,
    dateRange: LocalDateRange,
    periodUnit: string,
    filtroCiapCid: string[],
    aggregator: Aggregator,
    aggregatorKey: keyof T,
    filterKeys?: Map<K, Set<string>>,
    size: number = this.size
  ): Promise<Dictionary<T> & TreeRoot> {
    this._maxLeafValue = 0
    if (this.hasData) {
      let result: Dictionary<T> & TreeRoot
      if (aggregator.chain) {
        for (let agg of aggregator.chain) {
          result = this.buildRecursor(this.data, keys, result?.nodeValue || size, agg, aggregatorKey, filterKeys)
        }
      }
      this._maxLeafValue = 0
      return this.buildRecursor(this.data, keys, result?.nodeValue || size, aggregator, aggregatorKey, filterKeys)
    } else {
      const filter = {
        keys: keys,
        dateRange: dateRange,
        periodUnit: periodUnit,
        filtroCiapCid: filtroCiapCid,
        filterKeys: filterKeys && mapValues(Object.fromEntries(filterKeys), (value) => [...value]),
        aggregatorId: aggregator.id,
        aggregatorKey: aggregatorKey,
      }

      const data = (await axios.post(this.operations.build, filter)).data
      this.maxLeafValue = data.maxLeafValue

      return data
    }
  }

  private buildRecursor<T extends any, K extends keyof T>(
    arr: T[],
    keys: Array<K>,
    size: number,
    aggregator: Aggregator,
    aggregatorKey: keyof T,
    filterKeys?: Map<K, Set<string>>
  ): any {
    let key = keys[0]

    if (!key) {
      let result: GroupResult
      if (aggregator.value && aggregator.keyDependent) {
        result = new GroupResult(aggregator.value(arr.map((v) => v[aggregatorKey]) as any))
      } else if (aggregator.value) {
        result = new GroupResult(aggregator.value([arr.length], size))
      } else {
        result = new GroupResult(arr.length)
      }
      this.maxLeafValue = result.nodeValue
      return result
    }

    const obj: Dictionary<T> & TreeRoot = groupByKey(arr, key, filterKeys)

    obj.nodeKey = key as string

    let count: number[] = []
    Object.keys(obj)
      .filter((k) => !IGNORED_TREE_KEYS.includes(k))
      .forEach((k) => {
        const arr = obj[k]
        const groupResult = this.buildRecursor(
          arr,
          keys.slice(1, keys.length),
          size,
          aggregator,
          aggregatorKey,
          filterKeys
        )
        if (
          !(groupResult instanceof GroupResult) &&
          Object.keys(groupResult).filter((k) => !IGNORED_TREE_KEYS.includes(k)).length === 0
        ) {
          delete obj[k]
        } else {
          obj[k] = groupResult
          count.push(obj[k].nodeValue)
        }
      })

    if (count.length > 0) {
      if (aggregator.value && aggregator.keyDependent) {
        obj.nodeValue = aggregator.value(count)
      } else {
        obj.nodeValue = count.reduce((prev, curr) => prev + curr)
      }
    }
    return obj
  }
}

function groupByKey<T extends any, K extends keyof T>(arr: T[], key: K, filterKeys?: Map<K, Set<string>>) {
  return arr.reduce((result, curr) => {
    const keyValue = curr[key] as any

    for (let [filterKey, filterValues] of filterKeys) {
      const objValue = curr[filterKey]

      if (!filterValues.has(objValue as any)) {
        return result
      }
    }

    if (!result[keyValue]) {
      result[keyValue] = []
    }

    result[keyValue].push(curr)

    return result
  }, {} as Dictionary<T>)
}
