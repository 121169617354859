/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, Icon, Tooltip } from 'bold-ui'
import { useHistory } from 'react-router'

interface VisualizarPaginaButtonProps {
  path: string
  tooltipText: string
}

export const VisualizarPaginaButton = (props: VisualizarPaginaButtonProps) => {
  const { path, tooltipText } = props
  const history = useHistory()

  const handleOnClick = () => {
    history.push(path)
  }

  return (
    <Tooltip text={tooltipText}>
      <Button type='button' skin='ghost' size='small' onClick={handleOnClick}>
        <Icon icon='zoomOutline' />
      </Button>
    </Tooltip>
  )
}
