import { LotacaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { SolicitacaoExameQueryModel } from '../../objetivo/resultados-exames/model-resultadosExames'
import {
  convertSolicitacaoFromAtendToSolicitacaoWithInfo,
  convertSolicitacaoFromQueryToSolicitacaoWithInfo,
} from './converter'
import { ImpressaoSolicitacaoExamesInput } from './DownloadSolicitacaoExames'
import { ItemHistoricoSolicitacaoExameModel, SolicitacaoExameModel } from './model'

export const joinSolicitacoesExame = (
  solicitacoesExameFromQuery: SolicitacaoExameQueryModel[],
  solicitacoesExameFromAtend: SolicitacaoExameModel[],
  props?: { dataReferencia: Instant; atendimentoProfissionalId: ID; lotacao: LotacaoAtendimento | LotacaoFolhaRosto }
) => {
  const { dataReferencia, atendimentoProfissionalId, lotacao } = props ?? {}

  const solicitacoesExameWithMoreInfoFromQuery: ItemHistoricoSolicitacaoExameModel[] = convertSolicitacaoFromQueryToSolicitacaoWithInfo(
    solicitacoesExameFromQuery
  )
  const solicitacoesExameWithMoreInfoFromAtend: ItemHistoricoSolicitacaoExameModel[] = convertSolicitacaoFromAtendToSolicitacaoWithInfo(
    solicitacoesExameFromAtend,
    { dataReferencia, atendimentoProfissionalId, lotacao }
  )

  return [...solicitacoesExameWithMoreInfoFromAtend.reverse(), ...solicitacoesExameWithMoreInfoFromQuery]
}

export const convertHistoricoSolicitacaoExameToImpressao = (
  items: ItemHistoricoSolicitacaoExameModel[],
  prontuarioId: ID,
  atendimentoId: ID,
  cidadaoId: ID
): ImpressaoSolicitacaoExamesInput[] => {
  return items.map((row) => ({
    id: row.adicionadoAgora ? null : row.id,
    prontuarioId: prontuarioId,
    atendimentoId: atendimentoId,
    solicitacao: row.adicionadoAgora
      ? {
          examesRequisitados: row.solicitacao.examesSolicitados.map((item) => ({
            idExame: item.exame.id,
            observacoes: item.observacao,
          })),
          observacoes: row.solicitacao?.observacao,
          tipoExame: row.solicitacao.tipoExame,
          cid10: row.solicitacao.cid10?.id,
          justificativa: row.solicitacao?.justificativa,
        }
      : null,
    idCidadao: cidadaoId,
    idAtendimentoProfissional: row.atendimentoProfissionalId,
  }))
}
