/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Theme, useTheme, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { ErrorField, Form, FormRenderProps } from 'components/form'
import { CiapCidSelectField } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectField } from 'components/form/field/select/GrupoCondicaoSelectField'
import { PivotTableInitialValues } from 'components/pivot-table/PivotTable'
import { FORM_ERROR } from 'final-form'
import createDecorator from 'final-form-calculate'
import { useModeloPersonalizadoRelatorioGerencialLazyQuery } from 'graphql/hooks.generated'
import { TipoModuloEnum } from 'graphql/types.generated'
import { useEffect, useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import { KeyMapping } from '../../common/keyMapping-relatorioGerencial'
import { modeloToPivotTableProps } from '../modelo-personalizado/converter-modeloPersonalizadoRelatorioGerencial'
import { ModeloPersonalizadoSelectField } from '../modelo-personalizado/ModeloPersonalizadoSelectField'
import { createRelatorioGerencialFiltroFormCalculations } from './calculator-relatorioGerencial'
import { RELATORIO_GERENCIAL_FILTROS_FORM_DEFAULT_VALUES } from './model-relatorioGerencialFiltrosForm'
import { RelatorioGerencialFiltrosFormModel } from './model-relatorioGerencialFiltrosForm'
import { RelatorioGerencialPeriodoLabel } from './RelatorioGerencialPeriodoLabel'
import { RelatorioGerencialPeriodoRadioField } from './RelatorioGerencialPeriodoRadioField'
import { RelatorioGerencialUnidadePeriodoSelectField } from './RelatorioGerencialUnidadePeriodoSelectField'
import { relatorioGerencialFiltrosFormValidator } from './validator-relatorioGerencial'

const meta = metaPath<RelatorioGerencialFiltrosFormModel>()

export interface RelatorioGerencialFiltrosFormProps<T> {
  loading: boolean
  hasFiltroCiapCidGrupoCondicoes?: boolean
  modulo: TipoModuloEnum
  keyMapping: Map<string, KeyMapping>
  filtrosLocalStorage?: RelatorioGerencialFiltrosFormModel
  onSubmit: (filtros: RelatorioGerencialFiltrosFormModel) => void
  hideChildren: () => void
  setPivotTableInitialValues: (modelo: PivotTableInitialValues<T>) => void
}

export function RelatorioGerencialFiltrosForm<T>(props: RelatorioGerencialFiltrosFormProps<T>) {
  const {
    loading,
    hasFiltroCiapCidGrupoCondicoes = false,
    modulo,
    filtrosLocalStorage,
    keyMapping,
    onSubmit,
    hideChildren,
    setPivotTableInitialValues,
  } = props

  const { hasAuthorization, isGestorEstadual } = useSession()
  const theme = useTheme()
  const styles = createStyles(theme)

  const initialValues = useMemo<RelatorioGerencialFiltrosFormModel>(
    () => filtrosLocalStorage || RELATORIO_GERENCIAL_FILTROS_FORM_DEFAULT_VALUES,
    [filtrosLocalStorage]
  )

  const [
    executeModeloPersonalizadoQuery,
    { data: dataModeloPersonalizadoQuery, loading: loadingModeloPersonalizadoQuery },
  ] = useModeloPersonalizadoRelatorioGerencialLazyQuery()

  const modeloPersonalizado = dataModeloPersonalizadoQuery?.modeloPersonalizadoRelatorioGerencialById
  const loadingTableOrModelo = loading || loadingModeloPersonalizadoQuery

  const decorators = useMemo(
    () => [
      createDecorator(
        ...createRelatorioGerencialFiltroFormCalculations(
          meta,
          modeloPersonalizado,
          keyMapping,
          modulo,
          isGestorEstadual,
          hasAuthorization
        )
      ),
    ],
    [hasAuthorization, isGestorEstadual, keyMapping, modeloPersonalizado, modulo]
  )
  const validator = useMemo(() => relatorioGerencialFiltrosFormValidator(), [])

  const resetPivotTable = () => {
    hideChildren()
    setPivotTableInitialValues(null)
  }

  const fetchModeloPersonalizadoById = (id: string) => executeModeloPersonalizadoQuery({ variables: { id } })

  useEffect(() => {
    if (modeloPersonalizado) {
      hideChildren()
      setPivotTableInitialValues(modeloToPivotTableProps(modeloPersonalizado.modelo, keyMapping))
    }
  }, [keyMapping, modeloPersonalizado, setPivotTableInitialValues, hideChildren])

  const renderForm = ({ handleSubmit, values }: FormRenderProps<RelatorioGerencialFiltrosFormModel>) => {
    return (
      <VFlow>
        <ModeloPersonalizadoSelectField
          tipoModulo={modulo}
          name={meta.modeloPersonalizado}
          fetchModeloPersonalizadoById={fetchModeloPersonalizadoById}
          resetPivotTable={resetPivotTable}
          disabled={loadingTableOrModelo}
        />
        <RelatorioGerencialPeriodoLabel />
        <ErrorField name={FORM_ERROR} />
        <VFlow css={styles.periodoContainer}>
          <Grid wrap alignItems='center'>
            <Cell size={6}>
              <RelatorioGerencialUnidadePeriodoSelectField
                name={meta.unidade}
                disabled={loadingTableOrModelo}
                style={styles.unidadePeriodoSelectField}
                onChange={hideChildren}
              />
            </Cell>
            <Cell size={12}>
              <RelatorioGerencialPeriodoRadioField
                nameOpcaoSelecionada={meta.opcaoSelecionada}
                namePeriodo={meta.periodo}
                opcaoSelecionadaValue={values?.opcaoSelecionada}
                unidadeTempoValue={values?.unidade}
                disabled={loadingTableOrModelo}
                onChange={hideChildren}
              />
            </Cell>
          </Grid>
        </VFlow>
        {hasFiltroCiapCidGrupoCondicoes && (
          <Grid alignItems='stretch' direction='row'>
            <Cell size={6}>
              <CiapCidSelectField
                label='CIAP2 e CID10'
                placeholder='Selecione outros CIAP2 e CID10'
                name={meta.ciapsCids}
                disabled={loadingTableOrModelo}
                multiple
                onChange={hideChildren}
              />
            </Cell>
            <Cell size={6}>
              <GrupoCondicaoSelectField
                label='Grupos de condições prioritários'
                placeholder='Selecione grupos de condições prioritários'
                name={meta.gruposCondicoesPrioritarios}
                disabled={loadingTableOrModelo}
                multiple
                onChange={hideChildren}
              />
            </Cell>
          </Grid>
        )}
        <Button
          kind='primary'
          size='small'
          type='submit'
          disabled={loadingTableOrModelo}
          loading={loadingTableOrModelo}
          onClick={handleSubmit}
        >
          Consultar
        </Button>
      </VFlow>
    )
  }

  return (
    <Form<RelatorioGerencialFiltrosFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      validate={validator}
      initialValues={initialValues}
      decorators={decorators}
    />
  )
}

const createStyles = (theme: Theme) => ({
  periodoContainer: css`
    position: relative;
    padding: 1rem;
    border: 1px solid ${theme.pallete.gray.c80};
  `,
  unidadePeriodoSelectField: css`
    position: absolute;
    margin-left: -0.5rem;
    top: -0.7rem;
    padding: 0 0.5rem;
    background-color: ${theme.pallete.surface.main};
  `,
})
