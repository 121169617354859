/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { RadioGroupField, RadioOption } from 'components/form'
import { FichaComplementarZikaMicrocefaliaResultadoExameEnum } from 'graphql/types.generated'

import { FactoryResultadoExameEspecificoFieldProps } from '../../model-resultadosExames'
import {
  getResultadoExameEspecificoProperties,
  getResultadoExameEspecificoPuericulturaLabel,
} from '../../util-resultadosExames'

export const ResultadoExamePuericulturaField = (props: FactoryResultadoExameEspecificoFieldProps) => {
  const { name, exame } = props

  const { options } = getResultadoExameEspecificoProperties(exame)

  return (
    <RadioGroupField
      name={name}
      options={convertEnumsToRadioOptions(options)}
      style={{
        hFlow: css`
          padding-top: 0;
        `,
      }}
      clearable
    />
  )
}

const convertEnumsToRadioOptions = (
  values: FichaComplementarZikaMicrocefaliaResultadoExameEnum[]
): RadioOption<FichaComplementarZikaMicrocefaliaResultadoExameEnum>[] =>
  values.map((value) => ({ value, label: getResultadoExameEspecificoPuericulturaLabel(value) }))
