import { HFlow, Tag, Text, VFlow } from 'bold-ui'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { SelectField } from 'components/form/final-form'
import { EstabelecimentosCuidadoCompartilhadoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  EstabelecimentosCuidadoCompartilhadoSelectFieldQuery,
  EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

export type EstabelecimentosCuidadoCompartilhadoSelectModel = EstabelecimentosCuidadoCompartilhadoSelectFieldQuery['estabelecimentosCuidadoCompartilhado']['content'][0]

export interface EstabelecimentosCuidadoCompartilhadoSelectFieldProps
  extends AsyncSelectFieldProps<EstabelecimentosCuidadoCompartilhadoSelectModel> {
  disabled?: boolean
  cboCodigo2002: string
  isVinculado: boolean
  acessoCnes: string
}

export function EstabelecimentosCuidadoCompartilhadoSelectField(
  props: EstabelecimentosCuidadoCompartilhadoSelectFieldProps
) {
  const { cboCodigo2002, isVinculado, acessoCnes, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    EstabelecimentosCuidadoCompartilhadoSelectModel,
    EstabelecimentosCuidadoCompartilhadoSelectFieldQuery,
    EstabelecimentosCuidadoCompartilhadoSelectFieldQueryVariables
  >({
    query: EstabelecimentosCuidadoCompartilhadoSelectFieldDocument,
    extractItems: (data) =>
      data && data.estabelecimentosCuidadoCompartilhado && data.estabelecimentosCuidadoCompartilhado.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        cboCodigo2002,
        isVinculado,
      },
    }),
    skip: () => props.disabled,
    debounceTime: 500,
  })

  return (
    <SelectField<EstabelecimentosCuidadoCompartilhadoSelectModel>
      itemToString={itemToString}
      renderItem={(item) => renderItem(item, acessoCnes)}
      {...selectProps}
      {...rest}
    />
  )
}

const itemToString = (option: EstabelecimentosCuidadoCompartilhadoSelectModel) => option && option.nome

const renderItem = (item: EstabelecimentosCuidadoCompartilhadoSelectModel, cnes: string) => (
  <>
    <HFlow alignItems='center' justifyContent='space-between'>
      <VFlow vSpacing={0}>
        <Text fontWeight='bold'>
          {item.nome}
          {!item.ativo && ' (Inativa)'}
        </Text>
        <HFlow hSpacing={0.5}>
          <Text fontWeight='bold'>CNES:</Text>
          {item.cnes}
        </HFlow>
      </VFlow>
      {item.cnes === cnes && <Tag style={{ alignContent: 'flex-end' }}>eMulti</Tag>}
    </HFlow>
  </>
)
