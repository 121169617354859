/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Text } from 'bold-ui'
import { Procedimento } from 'graphql/types.generated'
import { Fragment } from 'react'
import { showCodigoProcedimentoRef } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util-resultadosExames'

interface ExameSolicitadoTitleFormatterProps {
  exame: Procedimento
}

export function ExameSolicitadoTitleFormatter(props: ExameSolicitadoTitleFormatterProps) {
  const { exame } = props

  const exameSolicitadoName = showCodigoProcedimentoRef(exame)
  const [nome, codigo] = exameSolicitadoName.split(' - ')

  return (
    <Fragment>
      <Text fontWeight='bold'>{nome}</Text>
      {codigo && <span>{` - ${codigo}`}</span>}
    </Fragment>
  )
}
