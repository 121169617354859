/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid, Heading, Modal, ModalBody, VFlow } from 'bold-ui'
import { useHistoricoResultadosExamesQuery } from 'graphql/hooks.generated'
import { TextFilterType } from 'hooks/filter/model'
import { useFilter } from 'hooks/filter/useFilter'
import { groupBy, maxBy, orderBy } from 'lodash'
import { useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { toDate } from 'util/date/formatDate'
import { metaPath } from 'util/metaPath'
import { ResultadoExameQueryModel } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/model-resultadosExames'

import {
  HistoricoResultadosExamesFilter,
  HistoricoResultadosExamesFilterModel,
} from './HistoricoResultadosExamesFilter'
import { HistoricoResultadosExamesItemModel, HistoricoResultadosExamesList } from './HistoricoResultadosExamesList'

interface HistoricoResultadosExamesModalProps {
  prontuarioId: ID
  editAtendProfId: ID
}

export const historicoResultadoExamesUrl = '/historico-de-resultados-de-exames'

export const HistoricoResultadosExamesModal = (props: HistoricoResultadosExamesModalProps) => {
  const { prontuarioId, editAtendProfId } = props

  const history = useHistory()
  const match = useRouteMatch()

  const [filter, setFilter] = useState<HistoricoResultadosExamesFilterModel>()

  const onClose = () => history.push(match.url.replace(historicoResultadoExamesUrl, ''))

  const {
    data: { resultadosExame },
    loading,
  } = useHistoricoResultadosExamesQuery({
    variables: {
      input: {
        prontuarioId,
        editAtendProfId,
        pageParams: { fetchPageInfo: false, size: 2000 },
      },
    },
    fetchPolicy: 'cache-first',
  })

  const items = useMemo(
    () => (resultadosExame?.content as ResultadoExameQueryModel[])?.filter((item) => !!item.conferido) || [],
    [resultadosExame]
  )

  const groupedItems = useMemo(() => groupAndOrderItems(items), [items])

  const filteredItems = useFilter<HistoricoResultadosExamesItemModel, HistoricoResultadosExamesFilterModel>({
    items: groupedItems,
    filter,
    filtersType: [filterTextTypeConfig],
  })

  return (
    <Modal open onClose={onClose}>
      <ModalBody>
        <VFlow vSpacing={2}>
          <Heading level={1}>Histórico de resultados de exames</Heading>

          <Grid gapVertical={0.25}>
            <Cell size={5}>
              <HistoricoResultadosExamesFilter onChangeFilter={setFilter} />
            </Cell>

            <Cell size={12}>
              <HistoricoResultadosExamesList items={filteredItems} loading={loading} searchWords={filter?.texto} />
            </Cell>
          </Grid>
        </VFlow>
      </ModalBody>
    </Modal>
  )
}

const metaRow = metaPath<HistoricoResultadosExamesItemModel>()
const metaFilter = metaPath<HistoricoResultadosExamesFilterModel>()

const filterTextTypeConfig: TextFilterType = {
  filterField: metaFilter.texto,
  searchFields: [metaRow.exame.codigo, metaRow.exame.descricao],
  removeTagsOnFilter: true,
}

const groupAndOrderItems = (initialItems: ResultadoExameQueryModel[]): HistoricoResultadosExamesItemModel[] => {
  if (!initialItems) return []

  const itemsGrouped = groupBy(initialItems, (item) => item.exame.id)

  return orderBy(
    Object.entries(itemsGrouped).map(([id, items]) => ({
      id,
      exame: items[0].exame,
      items: orderBy(
        items,
        [(a) => getValueOfDataRealizacao(a), (b) => b.atendimentoProfissional.iniciadoEm],
        ['desc', 'desc']
      ),
    })),
    [(a) => getValueOfDataRealizacao(a.items[0]), (b) => maxBy(b.items, (c) => c.atendimentoProfissional.iniciadoEm)],
    ['desc', 'desc']
  )
}

export const getValueOfDataRealizacao = ({ dataRealizacao }: ResultadoExameQueryModel) =>
  dataRealizacao ? toDate(dataRealizacao).valueOf() : ''
