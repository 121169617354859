/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, FormControl, Heading, HFlow, isPromise, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import { handleError } from 'components/error/globalErrorHandler'
import { Form, FormValueSpy, SubmitButton } from 'components/form'
import { useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery } from 'graphql/hooks.generated'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { metaPath } from 'util/metaPath'
import {
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoFormModel,
  ResponsavelCuidadoCompartilhado,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CuidadoCompartilhadoEvolucoes } from '../components/cuidado-compartilhado-evolucoes/CuidadoCompartilhadoEvolucoes'
import { ReclassificarPrioridadeField } from '../components/discussao-caso-fields/ReclassificarPrioridadeField'
import { dicussaoCasoCalculator } from './calculator-discussaoCaso'
import { convertDiscussaoCasoBackendErrorsToForm } from './converter-discussaoCaso'
import { DiscussaoCasoExecutanteFields } from './DiscussaoCasoExecutanteFields'
import { DiscussaoCasoSolicitanteFields } from './DiscussaoCasoSolicitanteFields'
import { discussaoCasoFormValidator } from './validator-discussaoCaso'

interface DiscussaoCasoFormProps {
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  initialValues: DiscussaoCasoFormModel
  responsavelCuidado: ResponsavelCuidadoCompartilhado
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
  updateCacheState: (values: DiscussaoCasoFormModel) => void
  handleSubmit: (values: DiscussaoCasoFormModel) => Promise<void>
  handleCancel: () => void
}

const path = metaPath<DiscussaoCasoFormModel>()

const transformResult = async (
  result: Promise<void>,
  suppressNotificationError: boolean,
  suppressValidationError: boolean,
  supressFieldValidationNotificationError: boolean
) => {
  if (!result) {
    return result
  }

  if (isPromise(result)) {
    try {
      await result
      return await Promise.resolve()
    } catch (error) {
      return convertDiscussaoCasoBackendErrorsToForm(
        handleError({
          error,
          suppressNotificationError,
          suppressValidationError,
          supressFieldValidationNotificationError,
        })
      )
    }
  } else {
    return result
  }
}

export const DiscussaoCasoForm = (props: DiscussaoCasoFormProps) => {
  const {
    cuidadoCompartilhado,
    initialValues,
    responsavelCuidado,
    videochamadaUuidState,
    updateCacheState,
    handleCancel,
    handleSubmit,
  } = props

  const isEspecializada =
    cuidadoCompartilhado.unidadeSaudeExecutanteAtual.id !== cuidadoCompartilhado.lotacaoSolicitante.unidadeSaude.id

  const decorator = useMemo(() => dicussaoCasoCalculator(path, responsavelCuidado), [responsavelCuidado])

  const [formInitialized, setFormInitialized] = useState(false)

  const {
    data: { isEstabelecimentoAllowedAsReferenciaVinculacaoServico: isExecutanteFromEstabelecimentoEspecializado },
  } = useIsEstabelecimentoAllowedAsReferenciaVinculacaoServicoQuery({
    variables: {
      tipoCoMs: cuidadoCompartilhado.unidadeSaudeExecutanteAtual.tipo.codigoMs,
      subtipoCoMs: cuidadoCompartilhado.unidadeSaudeExecutanteAtual.subtipo?.codigoMs,
    },
    fetchPolicy: 'cache-first',
  })

  const handleFormChange = useCallback(
    (values: DiscussaoCasoFormModel) => {
      /* Avoids the error of updating a component while rendering a different component. */
      if (formInitialized && !isUndefinedOrNull(values)) {
        values.lastSaved = new Date().getTime()
        updateCacheState(values)
      }
    },
    [formInitialized, updateCacheState]
  )

  useEffect(() => {
    setFormInitialized(true)
  }, [])

  return (
    <Form<DiscussaoCasoFormModel>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={discussaoCasoFormValidator(responsavelCuidado)}
      decorators={[decorator]}
      transformResult={transformResult}
      validateOnBlur
      render={(renderProps) => (
        <form noValidate>
          <FormValueSpy<DiscussaoCasoFormModel> onChange={handleFormChange} />
          <VFlow>
            <VFlow vSpacing={0.5}>
              <Heading level={3}>Discussão</Heading>
              <Box style={styles.box}>
                <CuidadoCompartilhadoEvolucoes cuidadoCompartilhadoId={cuidadoCompartilhado.id} />

                <hr />

                <VFlow>
                  <div css={styles.reclassificarPrioridadeWrapper}>
                    <ReclassificarPrioridadeField
                      name={path.reclassificaoPrioridade}
                      prioridadeAtual={cuidadoCompartilhado.classificacaoPrioridadeAtual}
                    />
                  </div>

                  <FormControl label='Conduta' style={styles.condutas} required>
                    {responsavelCuidado === 'EXECUTANTE' && (
                      <DiscussaoCasoExecutanteFields
                        path={path.executanteFields}
                        cuidadoCompartilhado={cuidadoCompartilhado}
                        videochamadaUuidState={videochamadaUuidState}
                        isEspecializada={isEspecializada}
                        isExecutanteFromEstabelecimentoEspecializado={isExecutanteFromEstabelecimentoEspecializado}
                      />
                    )}
                    {responsavelCuidado === 'SOLICITANTE' && (
                      <DiscussaoCasoSolicitanteFields
                        path={path.solicitanteFields}
                        cuidadoCompartilhado={cuidadoCompartilhado}
                        videochamadaUuidState={videochamadaUuidState}
                        isEspecializada={isEspecializada}
                        isExecutanteFromEstabelecimentoEspecializado={isExecutanteFromEstabelecimentoEspecializado}
                      />
                    )}
                  </FormControl>
                </VFlow>
              </Box>
            </VFlow>
            <HFlow justifyContent='space-between'>
              <Alert inline type='success' styles={{ wrapper: styles.draftAlert }}>
                Rascunho salvo automaticamente às <DateTime format='HH:mm' value={renderProps.values.lastSaved} />
              </Alert>

              <HFlow justifyContent='flex-end'>
                <Button onClick={handleCancel}>Cancelar discussão de caso</Button>
                <SubmitButton handleSubmit={renderProps.handleSubmit}>Registrar discussão de caso</SubmitButton>
              </HFlow>
            </HFlow>
          </VFlow>
        </form>
      )}
    />
  )
}

const styles = {
  draftAlert: css`
    background-color: transparent;
    border-width: 0;
    padding: 0;
  `,
  box: css`
    padding: 1rem 0;
  `,
  condutas: css`
    margin: 0 1rem;
  `,
  reclassificarPrioridadeWrapper: css`
    margin: 0 1rem;
  `,
}
