import { FlagsContextModel } from 'config/useFlagsContext'
import {
  AgendamentoConsultaAtendimentoInput,
  AgendamentoConsultasAtendimentoInput,
  AgendamentoConsultasPlanejadasAtendimentoInput,
  AtendimentoIndividualInput,
  AtestadoPlanoInput,
  AvaliacaoDesenvolvimentoInput,
  AvaliacaoInput,
  CompartilhamentoCuidadoPlanoInput,
  CuidadoCompartilhadoPlanoInput,
  EncerrarGestacaoInput,
  FinalizacaoAtendimentoInput,
  InformacoesObstetricasInput,
  MarcadoresConsumoAlimentarInput,
  MedicoesInput,
  ObjetivoInput,
  PessoalAntecedentesInput,
  PlanoInput,
  PreNatalInput,
  PuericulturaInput,
  SubjetivoInput,
  VigilanciaEmSaudeBucalEnum,
} from 'graphql/types.generated'
import { isObjectDeepNullOrUndefined } from 'util/object'
import { ErrorObject } from 'util/validation'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'
import { FormErrorModel } from 'view/atendimentos/components/validation-error-modal/model'

import { InformacoesObstetricasPanelModel } from '../antecedentes/InformacoesObstetricasPanel'
import { PessoalAntecedentesModel } from '../antecedentes/model'
import { AvaliacaoFormModel } from '../avaliacao/AvaliacaoForm'
import { NecessidadeProteseEnum } from '../avaliacao/components/necessidade-protese/model'
import { FinalizacaoAtendimentoFormModel } from '../finalizacao'
import { AgendarConsultaSectionModel } from '../finalizacao/components/AgendarConsultaSection'
import { AgendarProximasConsultasSectionModel } from '../finalizacao/components/proximas-consultas/AgendarProximasConsultasSection'
import { ObjetivoFormModel } from '../objetivo'
import { MarcadoresConsumoPanelModel } from '../objetivo/marcadores-consumo-alimentar/model'
import { AlteracoesFenotipicasModel } from '../objetivo/puericultura/alteracao-fenotipica/AlteracaoFenotipicaView'
import { FatoresRiscoModel } from '../objetivo/puericultura/fatores-risco/FatoresRiscoView'
import { MarcoFormSectionAccordionModel } from '../objetivo/puericultura/marcos/MarcoFormSectionAccordion'
import { PuericulturaModel } from '../objetivo/puericultura/model'
import AtestadoModel from '../plano/atestados/components/model-atestado'
import { CuidadoCompartilhadoPlanoModel } from '../plano/cuidado-compartilhado/model-cuidadocompartilhado'
import { PlanoFormModel } from '../plano/PlanoForm'
import { PreNatalFormModel } from '../pre-natal/model-preNatal'
import { SubjetivoFormModel } from '../subjetivo'

export const convertSoapErrorsFromBackend = (
  errors: ErrorObject<AtendimentoIndividualInput>,
  flags: FlagsContextModel,
  isAtendimentoOdonto: boolean
): ErrorObject<SoapState> & FormErrorModel => {
  const errorObject = {
    antecedentes: safeString<AtendimentoIndividualInput['antecedentes'], SoapState['antecedentes']>(
      errors?.antecedentes,
      (antecedentes) => ({
        familiar: safeString(antecedentes?.familiar, (familiar) => ({
          ciaps: familiar?.ciaps,
        })),
        pessoal: safeString<PessoalAntecedentesInput, PessoalAntecedentesModel>(antecedentes?.pessoal, (pessoal) => ({
          texto: pessoal?.texto,
          cirurgiasInternacoes: pessoal.cirurgiasInternacoes,
          informacoesObstetricas: safeString<InformacoesObstetricasInput, InformacoesObstetricasPanelModel>(
            pessoal?.informacoesObstetricas,
            (informacoesObstetricas) => ({
              abortos: informacoesObstetricas?.abortos as string,
              filhosVivos: informacoesObstetricas?.filhosVivos as string,
              gestaPrevias: informacoesObstetricas?.gestaPrevias as string,
              nascidosVivos: informacoesObstetricas?.nascidosVivos as string,
              natimortos: informacoesObstetricas?.natimortos as string,
              obitoAntesPrimeiraSemana: informacoesObstetricas?.obitoAntesPrimeiraSemana as string,
              obitoAposPrimeiraSemana: informacoesObstetricas?.obitoAposPrimeiraSemana as string,
              partoMenosDeUmAno: informacoesObstetricas?.partoMenosDeUmAno,
              partos: informacoesObstetricas?.partos as string,
              partosCesareas: informacoesObstetricas?.partosCesareas as string,
              partosDomiciliares: informacoesObstetricas?.partosDomiciliares as string,
              partosVaginais: informacoesObstetricas?.partosVaginais as string,
              recemNascidoAbaixo: informacoesObstetricas?.recemNascidoAbaixo as string,
              recemNascidoAcima: informacoesObstetricas?.recemNascidoAcima as string,
            })
          ),
        })),
      })
    ),
    subjetivo: safeString<SubjetivoInput, SubjetivoFormModel>(errors?.subjetivo, (subjetivo) => ({
      texto: subjetivo?.texto,
      motivos: subjetivo?.motivos,
    })),
    objetivo: safeString<ObjetivoInput, ObjetivoFormModel>(errors?.objetivo, (objetivo) => ({
      texto: objetivo?.texto,
      dum: (objetivo?.medicoes as ErrorObject<MedicoesInput>)?.dum as string,
      medicoes: safeString<MedicoesInput, MedicoesPanelModel>(objetivo?.medicoes, (medicoes) => ({
        altura: medicoes?.altura as string,
        circunferenciaAbdominal: medicoes?.circunferenciaAbdominal as string,
        frequenciaCardiaca: medicoes?.frequenciaCardiaca as string,
        frequenciaRespiratoria: medicoes?.frequenciaRespiratoria as string,
        glicemia: medicoes?.glicemia as string,
        perimetroCefalico: medicoes?.perimetroCefalico as string,
        perimetroPanturrilha: medicoes?.perimetroPanturrilha as string,
        peso: medicoes?.peso as string,
        pressaoArterialDiastolica: medicoes?.pressaoArterialDiastolica as string,
        pressaoArterialSistolica: medicoes?.pressaoArterialSistolica as string,
        saturacaoO2: medicoes?.saturacaoO2 as string,
        temperatura: medicoes?.temperatura as string,
        tipoGlicemia: medicoes?.tipoGlicemia,
      })),
      marcadoresConsumoAlimentar: safeString<MarcadoresConsumoAlimentarInput, MarcadoresConsumoPanelModel>(
        objetivo?.marcadoresConsumoAlimentar,
        (marcadoresConsumoAlimentar) =>
          marcadoresConsumoAlimentar?.respostas as ErrorObject<MarcadoresConsumoPanelModel>
      ),
      necessidadesEspeciais: objetivo?.necessidadesEspeciais,
      atendimentosEspecificos: {
        puericultura: safeString<PuericulturaInput, PuericulturaModel>(objetivo?.puericultura, (puericultura) => ({
          alteracoesFenotipicas: safeString<AvaliacaoDesenvolvimentoInput[], AlteracoesFenotipicasModel>(
            puericultura?.alteracoesFenotipicas,
            (alteracoesFenotipicas) => ({
              alteracoesFenotipicas,
            })
          ),
          marcosDesenvolvimento: safeString<AvaliacaoDesenvolvimentoInput[], MarcoFormSectionAccordionModel>(
            puericultura?.marcosDesenvolvimento,
            (marcosDesenvolvimento) => ({
              marcosRecord: marcosDesenvolvimento as any,
            })
          ),
          tipoAleitamento: puericultura?.tipoAleitamento,
          fatoresRisco: safeString<AvaliacaoDesenvolvimentoInput[], FatoresRiscoModel>(
            puericultura?.fatoresRisco,
            (fatoresRisco) => ({
              fatoresRisco,
            })
          ),
          prenatalPartoNascimento: safeString<
            PuericulturaInput['prenatalPartoNascimento'],
            PuericulturaModel['prenatalPartoNascimento']
          >(puericultura?.prenatalPartoNascimento, (prenatalPartoNascimento) => ({
            antropometriaAoNascer: {
              comprimento: prenatalPartoNascimento?.altura as string,
              perimetroCefalico: prenatalPartoNascimento?.perimetroCefalico as string,
              peso: prenatalPartoNascimento?.peso as string,
            },
            apgar: {
              apgarCinco: prenatalPartoNascimento?.apgarQuintoMinuto,
              apgarDez: prenatalPartoNascimento?.apgarDecimoMinuto,
              apgarUm: prenatalPartoNascimento?.apgarPrimeiroMinuto,
            },
            idadeGestacional: {
              dias: prenatalPartoNascimento?.idadeGestacionalEmDias,
              semanas: prenatalPartoNascimento?.idadeGestacionalEmSemanas,
            },
            tipoGravidez: prenatalPartoNascimento?.tipoGravidez,
            tipoParto: prenatalPartoNascimento?.tipoParto,
          })),
        })),
      },
      resultadosExame: objetivo?.resultadosExame,
    })),
    avaliacao: safeString<AvaliacaoInput, AvaliacaoFormModel>(errors?.avaliacao, (avaliacao) => ({
      texto: avaliacao?.texto,
      alergias: avaliacao?.alergiasAvaliadas,
      encerrarGestacao: safeString<EncerrarGestacaoInput, SoapState['avaliacao']['encerrarGestacao']>(
        avaliacao?.encerrarGestacao,
        (encerrarGestacao) => ({
          dataDesfecho: encerrarGestacao?.dataDesfecho as string,
          tipoGravidez: encerrarGestacao?.tipoGravidez,
        })
      ),
      problemasECondicoes: avaliacao?.problemasCondicoesAvaliadas,
      necessidadeProtese: safeString<AvaliacaoInput['vigilanciaSaudeBucal'], NecessidadeProteseEnum>(
        avaliacao?.vigilanciaSaudeBucal,
        (vigilanciaSaudeBucal) => vigilanciaSaudeBucal?.necessidadeProtese as ErrorObject<NecessidadeProteseEnum>
      ),
      tiposVigilancia: safeString<AvaliacaoInput['vigilanciaSaudeBucal'], VigilanciaEmSaudeBucalEnum[]>(
        avaliacao?.vigilanciaSaudeBucal,
        (vigilanciaSaudeBucal) => vigilanciaSaudeBucal?.tiposVigilancia as ErrorObject<VigilanciaEmSaudeBucalEnum[]>
      ),
    })),
    plano: safeString<PlanoInput, PlanoFormModel>(errors?.plano, (plano) => ({
      texto: plano?.texto,
      startObservacao: plano?.isObservacao,
      atestado: {
        atestadosRecentes: safeString<AtestadoPlanoInput, AtestadoModel[]>(
          plano?.atestado,
          (atestado) => (atestado?.emBranco ?? atestado?.licencaMaternidade ?? atestado?.padrao) as string[]
        ),
      },
      encaminhamentoExterno: {
        encaminhamentosEspecializadosRecentes: plano?.encaminhamentosExternosEspecializados,
        encaminhamentosRecentes: plano?.encaminhamentosExternos,
      },
      intervencoesProcedimentos: {
        ciaps: plano?.ciaps,
        procedimentos: plano?.procedimentos,
      },
      orientacao: plano?.orientacoes,
      prescricaoMedicamento: {
        medicamentos: safeString<
          PlanoInput['prescricaoMedicamento'],
          PlanoFormModel['prescricaoMedicamento']['medicamentos']
        >(plano?.prescricaoMedicamento, (prescricaoMedicamento) => prescricaoMedicamento?.medicamentos as string[]),
      },
      solicitacoesExame: plano?.solicitacoesExame,
      odontologia: safeString<PlanoInput['odontologia'], PlanoFormModel['odontologia']>(
        plano?.odontologia,
        (odontologia) => ({
          odontograma: {
            dentes: safeString(odontologia?.evolucoesDente, () => null),
          },
        })
      ),
      cuidadosCompartilhado:
        !flags.PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED || isAtendimentoOdonto
          ? safeString<PlanoInput['cuidadosCompartilhado'], PlanoFormModel['cuidadosCompartilhado']>(
              plano?.cuidadosCompartilhado,
              convertCuidadoCompartilhadoAtendimentoOdonto
            )
          : safeString<PlanoInput['compartilhamentosCuidado'], PlanoFormModel['cuidadosCompartilhado']>(
              plano?.compartilhamentosCuidado,
              convertCuidadoCompartilhadoDefault
            ),
    })),
    finalizacao: safeString<FinalizacaoAtendimentoInput, FinalizacaoAtendimentoFormModel>(
      errors?.finalizacao,
      (finalizacao) => ({
        agendamentoConsultas: safeString<
          FinalizacaoAtendimentoInput['agendamentoConsultas'],
          FinalizacaoAtendimentoFormModel['agendamentoConsultas']
        >(finalizacao?.agendamentoConsultas, (agendamentoConsultas: AgendamentoConsultasAtendimentoInput) => ({
          avulsa: safeString<AgendamentoConsultaAtendimentoInput, AgendarConsultaSectionModel>(
            agendamentoConsultas.avulsa,
            converterAgendamentoConsulta
          ),
          proximasConsultas: safeString<
            AgendamentoConsultasPlanejadasAtendimentoInput,
            AgendarProximasConsultasSectionModel
          >(agendamentoConsultas.planejadas, (consultasPlanejadas) => ({
            proximaConsultaPreNatal: safeString<AgendamentoConsultaAtendimentoInput, AgendarConsultaSectionModel>(
              consultasPlanejadas.preNatal,
              converterAgendamentoConsulta
            ),
            proximaConsultaOdontologica: safeString<AgendamentoConsultaAtendimentoInput, AgendarConsultaSectionModel>(
              consultasPlanejadas.odontologica,
              converterAgendamentoConsulta
            ),
          })),
        })),
        atendimentoCompartilhadoLotacao: finalizacao?.atendimentoCompartilhadoLotacaoId,
        conduta: finalizacao?.condutas,
        condutasOdonto: finalizacao?.condutasOdonto,
        desfechoAtendimento: safeString<
          FinalizacaoAtendimentoInput['desfechoAtendimento'],
          FinalizacaoAtendimentoFormModel['desfechoAtendimento']
        >(finalizacao?.desfechoAtendimento, (desfechoAtendimento) => ({
          manterCidadaoLista: desfechoAtendimento?.manterCidadaoLista as string,
          atendimento: safeString<
            FinalizacaoAtendimentoInput['desfechoAtendimento']['atendimento'],
            FinalizacaoAtendimentoFormModel['desfechoAtendimento']['atendimento']
          >(desfechoAtendimento?.atendimento, (atendimento) => ({
            agendado: atendimento?.agendado,
            equipe: atendimento?.equipe,
            responsavel: atendimento?.responsavel,
            tiposServico: atendimento?.responsavel,
          })),
        })),
        fichasNotificacaoCasoSuspeito: finalizacao?.fichaNotificacaoCasoSuspeito,
        fornecimento: finalizacao?.fornecimentosOdonto,
        procedimentosAdministrativos: finalizacao?.procedimentosAdministrativos,
        racionalidadeEmSaude: finalizacao?.racionalidadeEmSaude,
        tipoAtendimento: finalizacao?.tipoAtendimento as string,
        tipoConsulta: finalizacao?.tipoConsultaOdonto,
      })
    ),
    lembretes: errors?.lembretes,
    medicamentosInterromper: errors?.medicamentoInterromper,
    medicoesAnteriores: errors?.medicoesAnteriores as string,
    preNatal: safeString<PreNatalInput, PreNatalFormModel>(errors?.preNatal, (preNatal) => ({
      alturaUterina: preNatal?.alturaUterina,
      batimentoCardiacoFetal: preNatal?.batimentoCardiacoFetal,
      edema: preNatal?.edema,
      gravidezPlanejada: preNatal?.gravidezPlanejada as string,
      movimentacaoFetal: preNatal?.movimentacaoFetal as string,
      tipoGravidez: preNatal?.tipoGravidez,
    })),
    problemasECondicoes: errors?.problemasECondicoes,
    vacinacao: errors?.registrosVacinacao as string,
  }
  const formError = errors?.['FINAL_FORM/form-error']
  const errorsSemFormError = Object.fromEntries(
    Object.entries(errors).filter(([key]) => key !== 'FINAL_FORM/form-error')
  )
  if (isObjectDeepNullOrUndefined(errorsSemFormError)) {
    errorObject['formError'] = formError
  }
  return errorObject
}

function safeString<A, B>(
  error: string | ErrorObject<A>,
  safeError: (input: ErrorObject<A>) => ErrorObject<B>
): ErrorObject<B> | string {
  return typeof error === 'object' ? (safeError(error) as ErrorObject<B>) : (error as string)
}

const converterAgendamentoConsulta = (agendamento: ErrorObject<AgendamentoConsultaAtendimentoInput>) => ({
  lotacao: agendamento?.lotacao,
  horario: agendamento?.horario,
  isForaUbs: agendamento?.isForaUbs,
  localAtendimento: agendamento?.localAtendimento,
  observacoes: agendamento?.observacoes,
})

const convertCuidadoCompartilhadoAtendimentoOdonto = (cuidadosCompartilhado) =>
  cuidadosCompartilhado?.map(
    (
      cuidadoCompartilhado: ErrorObject<CuidadoCompartilhadoPlanoInput>
    ): ErrorObject<CuidadoCompartilhadoPlanoModel> => ({
      lotacao: cuidadoCompartilhado?.lotacaoExecutanteId,
      problemasECondicoes: {
        cid10: cuidadoCompartilhado?.cid10,
        ciap: cuidadoCompartilhado?.ciap,
      },
      prioridade: cuidadoCompartilhado?.classificacaoPrioridade,
      discussao: cuidadoCompartilhado?.discussao,
      cidadaoAceitaAtendTic: cuidadoCompartilhado?.cidadaoAceitaAtendTic as string,
    })
  )

const convertCuidadoCompartilhadoDefault = (compartilhamentosCuidado) =>
  compartilhamentosCuidado?.map(
    (
      compartilhamentoCuidado: ErrorObject<CompartilhamentoCuidadoPlanoInput>
    ): ErrorObject<CuidadoCompartilhadoPlanoModel> => ({
      lotacao: compartilhamentoCuidado?.lotacaoExecutanteId,
      problemasECondicoes: {
        cid10: compartilhamentoCuidado?.cid10,
        ciap: compartilhamentoCuidado?.ciap,
      },
      prioridade: compartilhamentoCuidado?.classificacaoPrioridade,
      discussao: compartilhamentoCuidado?.discussao,
      cidadaoAceitaAtendTic: compartilhamentoCuidado?.cidadaoAceitaAtendTic as string,
      cbo: compartilhamentoCuidado.cboId,
      unidadeSaude: compartilhamentoCuidado.unidadeSaudeId,
      tipo: compartilhamentoCuidado.isEncaminhamento as string,
    })
  )
