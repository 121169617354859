import { ExternalStyles, Text, TextColor, Theme, useStyles } from 'bold-ui'
import { FontWeightProperty } from 'csstype'
import React, { CSSProperties } from 'react'

export interface HLabelProps {
  title: React.ReactNode
  children: React.ReactNode
  placeholder?: string
  color?: TextColor
  titleFontWeight?: FontWeightProperty
  style?: ExternalStyles
}

function HLabelComponent(props: HLabelProps) {
  const { title, children, color, style, placeholder } = props

  const { css, classes } = useStyles(createStyles, props)

  return (
    <Text component='p' color={color} className={css(style)} placeholder={placeholder}>
      <Text className={classes.title}>{title}</Text>
      <Text className={classes.content}>{children}</Text>
    </Text>
  )
}

HLabelComponent.defaultProps = {
  placeholder: '—',
  titleFontWeight: 'bold',
}

const createStyles = (theme: Theme, { placeholder, titleFontWeight }: HLabelProps) => ({
  title: {
    fontWeight: titleFontWeight,
    marginRight: '0.375rem',
  } as CSSProperties,
  content: {
    '&:empty::before': {
      content: `"${placeholder}"`,
    },
  } as CSSProperties,
})

export const HLabel = React.memo(HLabelComponent)
