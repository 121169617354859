/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, colors, HFlow, Text, Theme, useTheme } from 'bold-ui'
import cidadaoImage from 'images/soap/ic_cidadao.svg'
import { useMemo } from 'react'
import shadowIcon from 'util/shadowIcon'

interface PessoalButtonProps {
  pessoalAtivo: boolean
  handleClick(): void
}

export function PessoalButton(props: PessoalButtonProps) {
  const { pessoalAtivo, handleClick } = props
  const theme = useTheme()
  const classes = useMemo(() => styles(theme, pessoalAtivo), [pessoalAtivo, theme])

  return (
    <Button size='small' style={classes.pessoalButton} onClick={handleClick}>
      <HFlow alignItems='center' hSpacing={0.3}>
        <img src={cidadaoImage} css={shadowIcon(colors.orange.c70)} alt='Cidadão' />
        <Text fontWeight='bold' style={classes.pessoalButtonText}>
          Cidadão
        </Text>
      </HFlow>
    </Button>
  )
}

const styles = (theme: Theme, pessoalAtivo: boolean) => ({
  pessoalButton: css`
    border: 1px ${pessoalAtivo ? colors.orange.c50 : colors.orange.c60} solid;
    background-color: ${pessoalAtivo ? colors.orange.c60 : theme.pallete.gray.c100};
    margin: 0;
    width: 10rem;
    justify-content: flex-start;
    &:not(:disabled):hover {
      background-color: ${pessoalAtivo ? colors.orange.c50 : colors.orange.c90};
    }
  `,
  pessoalButtonText: css`
    color: ${pessoalAtivo ? theme.pallete.gray.c100 : colors.orange.c40};
  `,
})
