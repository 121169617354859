/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { ResultadoExame, SolicitacaoExame } from 'graphql/types.generated'
import { Fragment } from 'react'
import HistoricoDetailTable from 'view/atendimentos/detail/historico/components/table/HistoricoDetailTable'

import { ExameSolicitadoTitleFormatter } from './ExameSolicitadoTitleFormatter'

interface HistoricoPlanoPecExamesSolicitadosPanelProps {
  examesSolicitados?: SolicitacaoExame[]
}

export default function HistoricoPlanoPecExamesSolicitadosPanel({
  examesSolicitados,
}: HistoricoPlanoPecExamesSolicitadosPanelProps) {
  return (
    <HistoricoDetailTable hasLines title='Exame'>
      <VFlow>
        {examesSolicitados
          .sort((a, b) => b.id.localeCompare(a.id))
          .map((solicitacaoExame: SolicitacaoExame) => (
            <Grid gap={2} key={solicitacaoExame.id}>
              {solicitacaoExame.exames.map(({ id, exame, observacao }: ResultadoExame) => (
                <Cell
                  size={12}
                  style={css`
                    padding-bottom: ${solicitacaoExame.cid10 ? '0.25rem' : '0.5rem'};
                  `}
                >
                  <Fragment key={id}>
                    <ExameSolicitadoTitleFormatter exame={exame} />
                    {observacao && <HLabel title='Observação do exame:'>{observacao}</HLabel>}
                  </Fragment>
                </Cell>
              ))}
              {solicitacaoExame.cid10 && (
                <Cell
                  size={12}
                  style={css`
                    padding-top: 0.25rem;
                  `}
                >
                  <Text>
                    {solicitacaoExame.cid10.nome} - {solicitacaoExame.cid10.codigo} (CID10)
                  </Text>
                </Cell>
              )}
              <Cell size={12}>
                <HLabel title='Justificativa do procedimento:'>{solicitacaoExame.justificativa}</HLabel>
              </Cell>
              {solicitacaoExame.observacoes && (
                <Cell size={12}>
                  <HLabel title='Observações:'>{solicitacaoExame.observacoes}</HLabel>
                </Cell>
              )}
            </Grid>
          ))}
      </VFlow>
    </HistoricoDetailTable>
  )
}
