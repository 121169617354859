import { HFlow, Text } from 'bold-ui'
import { SelectField } from 'components/form'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { CbosCuidadoCompartilhadoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CbosCuidadoCompartilhadoSelectFieldQuery,
  CbosCuidadoCompartilhadoSelectFieldQueryVariables,
  CuidadoCompartilhadoQueryFilterEnum,
} from 'graphql/types.generated'
import React from 'react'
import { CboCuidadoCompartilhadoSelectFieldQueryModel } from 'view/atendimentos/detail/soap/plano/cuidado-compartilhado/model-cuidadocompartilhado'

interface CbosCuidadoCompartilhadoSelectFieldProps
  extends AsyncSelectFieldProps<CboCuidadoCompartilhadoSelectFieldQueryModel> {
  role: CuidadoCompartilhadoQueryFilterEnum
  somenteSemExecutante?: boolean
}

export function CbosCuidadoCompartilhadoSelectField(props: CbosCuidadoCompartilhadoSelectFieldProps) {
  const { role, somenteSemExecutante = false, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    CboCuidadoCompartilhadoSelectFieldQueryModel,
    CbosCuidadoCompartilhadoSelectFieldQuery,
    CbosCuidadoCompartilhadoSelectFieldQueryVariables
  >({
    query: CbosCuidadoCompartilhadoSelectFieldDocument,
    extractItems: (data) => data && data.cbosListaCuidadoCompartilhado,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        role: role,
        somenteSemExecutante,
      },
    }),
  })

  return (
    <SelectField<CboCuidadoCompartilhadoSelectFieldQueryModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...selectProps}
      {...rest}
    />
  )
}

const itemToString = (item: CboCuidadoCompartilhadoSelectFieldQueryModel) => item?.nome

const renderItem = (item: CboCuidadoCompartilhadoSelectFieldQueryModel) => (
  <>
    <Text fontWeight='bold'>{item.nome}</Text>
    <HFlow hSpacing={0.5}>
      <Text fontWeight='bold'>Código:</Text>
      {item.cbo2002}
    </HFlow>
  </>
)
