import { TableFooterProps } from 'bold-ui'
import { PageParams } from 'graphql/types.generated'
import { useMemo, useState } from 'react'

interface UsePaginationProps<T> {
  /**
   * Itens que precisam ser paginados
   */
  items: T[]

  /**
   * Metodo que serah chamado quando a pagina ou tamanho dela mudar
   */
  onChange?(): void

  /**
   * Tamanho inicial da pagina. Por padrao eh 5
   */
  initialPageSize?: number

  /**
   * Lista contendo as opcoes de tamanho de pagina. Por padrao eh [5, 15, 25, 50]
   */
  sizeOptions?: number[]
}

interface PaginationResult<T> {
  /**
   * Itens paginados
   */
  paginatedItems: T[]

  /**
   * Informacoes sobre a paginacao, como pagina atual e quantidade total de paginas (entre outras), que podem ser passadas para os componentes de tabela
   */
  tableProps: Omit<TableFooterProps, 'abbrev' | 'style'>
}

/**
 * Realiza a paginacao dos itens e controla os parametros da paginacao das tabelas (page e size)
 *
 * @returns os itens paginados e informacoes sobre a paginacao que podem ser passadas para os componentes
 */
export function usePagination<T>(props: UsePaginationProps<T>): PaginationResult<T> {
  const { items, onChange, initialPageSize = 5, sizeOptions = [5, 15, 25, 50] } = props

  const [pageParams, setPageParams] = useState<PageParams>({
    page: 0,
    size: initialPageSize,
  })

  return useMemo(() => {
    const offset = pageParams.page * pageParams.size

    const paginaAtualMaiorQueUltima = items.length > 0 && offset >= items.length
    paginaAtualMaiorQueUltima && setPageParams((prevValues) => ({ ...prevValues, page: 0 }))

    const paginatedItems = items.slice(offset, offset + pageParams.size)

    const tableProps = {
      page: pageParams.page,
      totalPages: Math.ceil(items.length / pageParams.size),
      totalElements: items.length,
      pageSize: pageParams.size,
      sizeOptions: sizeOptions,
      onPageChange: (page) => {
        setPageParams((prevValues) => ({ ...prevValues, page }))
        onChange?.()
      },
      onSizeChange: (size) => {
        setPageParams((prevValues) => ({ ...prevValues, size }))
        onChange?.()
      },
    }
    return { paginatedItems, tableProps }
  }, [items, onChange, pageParams.size, pageParams.page, sizeOptions])
}
