import { Alert, DateRange, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PivotTable, PivotTableInitialValues } from 'components/pivot-table/PivotTable'
import { useTreeBuilder } from 'components/pivot-table/tree-builder/useTreeBuilder'
import { TipoModuloEnum } from 'graphql/types.generated'
import { AsyncProcessingNameEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useCallback, useEffect, useState } from 'react'
import { isUndefinedOrNull } from 'util/checks'

import {
  ASYNC_LOADING_MESSAGE,
  RELATORIO_GERENCIAL_VACINACAO_FILTER_STORAGE_KEY,
  RELATORIO_GERENCIAL_VACINACAO_NAME,
  RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO,
} from '../common/model-relatorioGerencial'
import { useAsyncRelatorioGerencial } from '../common/useAsyncRelatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from '../components/form/model-relatorioGerencialFiltrosForm'
import { RelatorioGerencialTableWrapper } from '../components/RelatorioGerencialTableWrapper'
import { vacinacaoKeyMapping } from './keyMapping-relatorioGerencialVacinacao'
import { RelatorioGerencialVacinacaoModel } from './model-relatorioGerencialVacinacao'

const OPERATIONS = {
  build: '/gerenciais/vacinacao/build',
  meta: '/gerenciais/vacinacao/meta',
}

export function RelatorioGerencialVacinacaoView() {
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()
  const [pivotTableInitialValues, setPivotTableInitialValues] = useState<
    PivotTableInitialValues<RelatorioGerencialVacinacaoModel>
  >()

  const { analytics } = useFirebase()

  const {
    meta,
    metaState,
    treeBuilderStatus: { isBuilding, isSearchingMeta },
    isMetaEmpty,
    setDateFilter,
    setIsSearchingMeta,
    ...rest
  } = useTreeBuilder<RelatorioGerencialVacinacaoModel>({
    keyMapping: vacinacaoKeyMapping,
    operations: OPERATIONS,
  })

  const onGetMeta = useCallback(
    ({ periodo, unidade }: RelatorioGerencialFiltrosFormModel) => {
      setDateFilter(periodo, unidade)
      setDateRangeFilter(periodo)
    },
    [setDateFilter]
  )

  const { handleSubmit, filtros, isAsyncProcessingRunning } = useAsyncRelatorioGerencial({
    asyncProcessingName: AsyncProcessingNameEnum.RELATORIO_GERENCIAL_VACINACAO,
    filterStorageKey: RELATORIO_GERENCIAL_VACINACAO_FILTER_STORAGE_KEY,
    isSearchingMeta,
    hasMetaState: !isUndefinedOrNull(metaState),
    meta,
    onGetMeta,
    setIsSearchingMeta,
  })

  useEffect(() => {
    analytics.logEvent(RELATORIOS_GERENCIAIS_ANALYTICS_SELECIONOU_RELATORIO_EVENTO, {
      relatorio: RELATORIO_GERENCIAL_VACINACAO_NAME,
    })
  }, [analytics])

  return (
    <>
      <Breadcrumb title={RELATORIO_GERENCIAL_VACINACAO_NAME} />

      <PageHeaderSection title={RELATORIO_GERENCIAL_VACINACAO_NAME} />

      <PageContent type='filled'>
        <VFlow>
          <Alert inline type='info'>
            Este relatório tem por objetivo apresentar a contagem de doses de vacinas aplicadas de acordo com os filtros
            selecionados. A origem dos atendimentos deste relatório são o CDS e o PEC.
          </Alert>
          <RelatorioGerencialTableWrapper
            isEmpty={isMetaEmpty}
            onSubmit={handleSubmit}
            modulo={TipoModuloEnum.RELATORIO_GERENCIAL_VACINACAO}
            keyMapping={vacinacaoKeyMapping}
            loading={isSearchingMeta || isAsyncProcessingRunning}
            loadingMessage={ASYNC_LOADING_MESSAGE}
            filtrosLocalStorage={filtros}
            setPivotTableInitialValues={setPivotTableInitialValues}
          >
            {metaState && (
              <PivotTable<RelatorioGerencialVacinacaoModel>
                title={RELATORIO_GERENCIAL_VACINACAO_NAME}
                treeMeta={metaState}
                keyMapping={vacinacaoKeyMapping}
                dateRangeFilter={dateRangeFilter}
                isBuilding={isBuilding}
                initialValues={pivotTableInitialValues}
                {...rest}
              />
            )}
          </RelatorioGerencialTableWrapper>
        </VFlow>
      </PageContent>
    </>
  )
}
