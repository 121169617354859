import { HFlow, SelectHelperMenuItem, Text, useLocale, VFlow } from 'bold-ui'
import { SelectField } from 'components/form'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { Cpf } from 'components/label'
import { ProfissionaisCuidadoCompartilhadoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CuidadoCompartilhadoQueryFilterEnum,
  ProfissionaisCuidadoCompartilhadoSelectFieldQuery,
  ProfissionaisCuidadoCompartilhadoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'
import { ProfissionalCuidadoCompartilhadoSelectFieldModel } from 'view/atendimentos/detail/soap/plano/cuidado-compartilhado/model-cuidadocompartilhado'

interface ProfissionalCuidadoCompartilhadoSelectFieldProps
  extends AsyncSelectFieldProps<ProfissionalCuidadoCompartilhadoSelectFieldModel> {
  isProfissionaisSolicitantes: boolean
  somenteSemExecutante?: boolean
  role: CuidadoCompartilhadoQueryFilterEnum
}

export const ProfissionalCuidadoCompartilhadoSelectField = (
  props: ProfissionalCuidadoCompartilhadoSelectFieldProps
) => {
  const { isProfissionaisSolicitantes, role, somenteSemExecutante, ...rest } = props
  const locale = useLocale()

  const { skipping, selectProps } = useAsyncQuerySelect<
    ProfissionalCuidadoCompartilhadoSelectFieldModel,
    ProfissionaisCuidadoCompartilhadoSelectFieldQuery,
    ProfissionaisCuidadoCompartilhadoSelectFieldQueryVariables
  >({
    query: ProfissionaisCuidadoCompartilhadoSelectFieldDocument,
    extractItems: (data) => data?.profissionaisListaCuidadoCompartilhado,
    variables: (query) => ({
      input: {
        isProfissionaisSolicitantes,
        role,
        somenteSemExecutante,
        query,
      },
    }),
    debounceTime: 500,
  })

  return (
    <SelectField<ProfissionalCuidadoCompartilhadoSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      components={{
        EmptyItem: () => (
          <SelectHelperMenuItem>
            {skipping ? 'Digite para buscar um profissional.' : locale.select.emptyItem}
          </SelectHelperMenuItem>
        ),
      }}
      {...selectProps}
      {...rest}
    />
  )
}

const itemToString = (item: ProfissionalCuidadoCompartilhadoSelectFieldModel) => item && item.nome
const renderItem = (item: ProfissionalCuidadoCompartilhadoSelectFieldModel) => (
  <VFlow vSpacing={0}>
    <Text component='p' fontWeight='bold'>
      {item.nome}
    </Text>
    <HFlow hSpacing={0.25}>
      <Text fontWeight='bold'>CPF</Text>
      <Cpf value={item.cpf} />
    </HFlow>
  </VFlow>
)
