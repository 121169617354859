/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, HFlow, Icon, Link, Text, VFlow } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import { useFlags } from 'config/useFlagsContext'
import { ReactComponent as Consulta } from 'images/historico/icone-consulta.svg'
import { ReactComponent as CuidadoCompartilhado } from 'images/historico/icone-cuidado-compartilhado.svg'
import { ReactComponent as PreNatal } from 'images/historico/icone-pre-natal.svg'
import { ReactComponent as AcompanhamentosIcon } from 'images/side-menu/acompanhamentos.svg'
import { ReactComponent as BuscaAtiva } from 'images/side-menu/busca-ativa.svg'
import { ReactComponent as GarantiaAcessoIcon } from 'images/side-menu/garantia-acesso.svg'
import { ReactComponent as UnificacaoDeCadastros } from 'images/side-menu/merge-do-cidadao.svg'
import { ReactComponent as ProfissionaisIcon } from 'images/side-menu/profissionais.svg'
import { ReactComponent as RelatorioGegencialCuidadoCompartilhado } from 'images/side-menu/relatorios.svg'
import { ReactComponent as UnidadeDeSaude } from 'images/side-menu/unidade-de-saude.svg'
import { ReactComponent as VideochamadaIcon } from 'images/side-menu/videochamadas.svg'
import { ReactComponent as AcompanhamentoTerritorioIcon } from 'images/visualizacao-territorial/casa-icone.svg'
import { CSSProperties, Fragment } from 'react'
import { useHistory } from 'react-router'

import { Carousel, CarouselItem } from './carousel/Corousel'
import { NewsGif } from './NewsGif'
import { NewsHeadingSection } from './NewsHeadingSection'
import { NewsImg } from './NewsImg'

export function NewsInternalPanelCincoDois() {
  const {
    VISUALIZACAO_TERRITORIO_ENABLED,
    CUIDADO_COMPARTILHADO_ENABLED,
    HISTORICO_CUIDADO_COMPARTILHADO_ENABLED,
    PILOTO_VIDEOCHAMADA_2_ENABLED,
    PILOTO_VIDEOCHAMADA_3_ENABLED,
    PILOTO_ATESTADO_DIGITAL_ENABLED,
    PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED,
    PRESCRICAO_TURNO,
  } = useFlags()
  const history = useHistory()

  if (!process.env.REACT_APP_VERSION) {
    return (
      <Alert type='danger' inline>
        Nenhuma informação sobre versões anteriores está disponível.
      </Alert>
    )
  }

  return (
    <VFlow vSpacing={1}>
      <Text fontSize={0.875} fontWeight='bold'>
        Novidades:
      </Text>
      <HFlow hSpacing={5}>
        <VFlow vSpacing={0.2}>
          <Text fontSize={0.875} component='li'>
            Atualização da competência do SIGTAP
          </Text>
          {PILOTO_VIDEOCHAMADA_2_ENABLED && PILOTO_VIDEOCHAMADA_3_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Agendamento entre profissionais
            </Text>
          )}
          {VISUALIZACAO_TERRITORIO_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Acompanhamento do Território
            </Text>
          )}
          {PILOTO_ATESTADO_DIGITAL_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Atestado Digital
            </Text>
          )}
          <Text fontSize={0.875} component='li'>
            Busca ativa de vacinação
          </Text>
          <Text fontSize={0.875} component='li'>
            Cuidado Compartilhado
          </Text>
          <Text fontSize={0.875} component='li'>
            e-SUS APS Ampliado
          </Text>
          <Text fontSize={0.875} component='li'>
            Facilidades no agendamento de consultas de pré-natal
          </Text>
          <Text fontSize={0.875} component='li'>
            Gerenciamento de cookies
          </Text>
        </VFlow>
        <VFlow vSpacing={0.2}>
          <Text fontSize={0.875} component='li'>
            Garantia do acesso
          </Text>
          {HISTORICO_CUIDADO_COMPARTILHADO_ENABLED && (
            <Text fontSize={0.875} component='li'>
              Histórico do Cuidado Compartilhado
            </Text>
          )}
          <Text fontSize={0.875} component='li'>
            Modelos personalizados para relatórios gerenciais
          </Text>
          <Text fontSize={0.875} component='li'>
            Prescrição de medicamentos
          </Text>
          <Text fontSize={0.875} component='li'>
            Prescrição digital
          </Text>
          <Text fontSize={0.875} component='li'>
            Relatório gerencial de cuidado compartilhado
          </Text>
          <Text fontSize={0.875} component='li'>
            RNDS - Envio de registros
          </Text>
          <Text fontSize={0.875} component='li'>
            Unificação de cadastros
          </Text>
          <Text fontSize={0.875} component='li'>
            Videochamadas
          </Text>
          <Text fontSize={0.875} component='li'>
            Vínculo entre equipes do PEC
          </Text>
        </VFlow>
      </HFlow>

      <NewsHeadingSection title='Atualização da competência do SIGTAP' id='atualizacao-competencia-sigtap-5-2'>
        <Text fontSize={0.875}>
          A tabela de procedimentos SIGTAP do sistema foi atualizada para a competência de 02/2024. Sendo assim, foi
          realizada a inclusão e atualização de procedimentos.
        </Text>
      </NewsHeadingSection>

      {PILOTO_VIDEOCHAMADA_2_ENABLED && PILOTO_VIDEOCHAMADA_3_ENABLED && (
        <NewsHeadingSection title='Agendamento entre profissionais' id='agendamento-entre-profissionais-5-2'>
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Na Agenda será possível registrar agendamentos com a participação de dois profissionais. Sendo possível
              inserir um cidadão no agendamento e indicar que a consulta será realizada via videochamada. O profissional
              que criar o agendamento será considerado o anfitrião e o outro profissional será o convidado.
            </Text>
            <Carousel>
              <CarouselItem
                image='/static/novidades/5-2_agendamento_entre_profissionais_cadastro.png'
                alt='Imagem do cadastro de um agendamento entre profissionais'
              />
              <CarouselItem
                image='/static/novidades/5-2_agendamento_entre_profissionais_detalhe.png'
                alt='Imagem do detalhe de um agendamento entre profissionais'
              />
            </Carousel>
            <Text fontSize={0.875} component='li'>
              O cidadão que for inserido na lista de atendimento para um agendamento entre profissionais deve exibir o
              nome dos dois profisisonais que irão participar do atendimento:
            </Text>
            <NewsImg
              src='static/novidades/5-2_agendamento_entre_profissionais_la.png'
              alt='Imagem do agendamento entre profissionais inserido na lista de atendimentos'
            />
            <Text fontSize={0.875} component='li'>
              Caso o agendamento entre profissionais seja feito com a presença do cidadão, um novo fluxo no sistema será
              liberado. O cidadão será inserido normalmente na lista de atendimento, porém quando o anfitrião iniciar o
              atendimento a folha de rosto passará a exibir informações sobre a videochamada. Além disso os campos
              Atendimento compartilhado e Forma de participação serão preenchidos automaticamente.
            </Text>
            <Carousel>
              <CarouselItem
                image='/static/novidades/5-2_agendamento_entre_profissionais_folha_rosto.png'
                alt='Imagem do card com detalhes da videochamada na folha de rosto'
              />
              <CarouselItem
                image='/static/novidades/5-2_agendamento_entre_profissionais_finalizacao.png'
                alt='Imagem dos campos de tipo de participação na finalização do atendimento'
              />
            </Carousel>
            <Text fontSize={0.875} component='li'>
              Caso outro profisisonal atenda o cidadão que está aguardando um agendamento entre profissionais com
              videochamada o seguinte modal será exibido. Isso acontece para frisar que as opções de videochamada e o
              preenchimento automático do campo Atendimento compartilhado só serão liberadas quando o anfitrião realizar
              o atendimento.
            </Text>
            <NewsImg
              src='static/novidades/5-2_agendamento_entre_profissionais_modal.png'
              alt='Imagem do modal com informações do agendamento entre profissionais'
            />
            <Text fontSize={0.875} component='li'>
              As informações sobre forma de participação do cidadão e do convidado serão exibidas da seguinte forma no
              histórico:
            </Text>
            <Carousel>
              <CarouselItem
                image='/static/novidades/5-2_agendamento_entre_profissionais_historico_cidadao.png'
                alt='Imagem do histórico indicando a forma de participação do cidadão'
              />
              <CarouselItem
                image='/static/novidades/5-2_agendamento_entre_profissionais_historico_profissional.png'
                alt='Imagem do histórico indicando a forma de participação do profissional do atendimento compartilhado'
              />
            </Carousel>

            <Text fontSize={0.875} component='li'>
              As informações serão enviadas normalmente através da ficha de atendimento individual e da ficha de
              atendimento odontológico.
            </Text>
          </VFlow>
        </NewsHeadingSection>
      )}

      {VISUALIZACAO_TERRITORIO_ENABLED && (
        <NewsHeadingSection
          title='Acompanhamento do Território'
          icon={<AcompanhamentoTerritorioIcon style={{ ...styles.iconSVG, fill: blue.c60 }} />}
          id='acompanhamento-territorio-5-2'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              A versão 5.2 permite visualizar os cadastros sob responsabilidade da sua equipe de forma mais intuitiva,
              facilitando a gestão do seu território adscrito e a formação de núcleos familiares. Basta acessar a aba
              "Território" na seção Acompanhamentos e conferir as novidades.
            </Text>
            <Text fontSize={0.875} component='li'>
              Nessa tela, você verá imóveis e seus moradores organizados por microáreas e logradouros, assim como os
              respectivos índices e filtros de cadastros completos e atualizados. Outras informações de apoio também
              estarão lá, como data da última visita e possíveis inconsistências de cadastro.
            </Text>
            <br></br>
            <NewsGif
              src='/static/novidades/5-2_acompanhamento_territorio.gif'
              pausedSrc='/static/novidades/5-2_acompanhamento_territorio_paused.png'
              alt='Demonstração de funcionalidade do Acompanhamento do Território.'
            />
            <br></br>
            <Text fontSize={0.875} component='li'>
              Além disso, é possível corrigir eventuais divergências de cadastro através da edição de logradouro, que
              cria automaticamente atualizações para os imóveis do mesmo, possibilitando a higienização da sua base de
              cadastros.
            </Text>
          </VFlow>
        </NewsHeadingSection>
      )}

      {PILOTO_ATESTADO_DIGITAL_ENABLED && (
        <NewsHeadingSection
          title='Atestado Digital'
          icon={<Consulta style={styles.iconSVG} />}
          id='atestado-digital-5-2'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Ao gerar um atestado para o cidadão, será possível{' '}
              <Text fontSize={0.875} fontWeight='bold'>
                gerar um atestado digital
              </Text>{' '}
              através da opção “Gerar e enviar atestado digital”, caso a instalação esteja habilitada para assinaturas
              digitais.
            </Text>
            <Text fontSize={0.875} component='li'>
              É possível editar o e-mail onde será enviado o atestado antes de iniciar o processo de assinatura,
              clicando no ícone de edição ao lado do endereço de e-mail.
            </Text>
            <NewsGif
              src='/static/novidades/5-2_atestado_digital_gerar.gif'
              pausedSrc='/static/novidades/5-2_atestado_digital_gerar_paused.webp'
              alt='Demonstração de funcionalidade da geração do atestado digital.'
            />
            <Text fontSize={0.875} component='li'>
              Para gerar um atestado digital, é necessário realizar uma{' '}
              <Text fontSize={0.875} fontWeight='bold'>
                assinatura digital
              </Text>{' '}
              do documento, utilizando um certificado digital ICP-Brasil. O PEC irá abrir uma nova janela para o
              processo de assinatura.
            </Text>
            <Text fontSize={0.875} component='li'>
              Após a assinatura, o documento assinado será enviado ao cidadão no e-mail cadastrado.
            </Text>
            <NewsGif
              src='/static/novidades/5-2_atestado_digital_assinatura.gif'
              pausedSrc='/static/novidades/5-2_atestado_digital_assinatura_paused.webp'
              alt='Demonstração de funcionalidade da assinatura do atestado digital.'
            />
          </VFlow>
        </NewsHeadingSection>
      )}

      <NewsHeadingSection
        title='Busca ativa de vacinação'
        icon={<BuscaAtiva style={styles.iconSVG} />}
        id='busca-ativa-vacinacao-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Inclusão do Grupo-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Gestantes/puérperas
            </Text>{' '}
            para Vacinas da Covid-19;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão do Grupo-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Gestantes
            </Text>{' '}
            para Vacinas do calendário vacinal;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão do Grupo-alvo de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Idosos
            </Text>{' '}
            para Vacinas do calendário vacinal;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão do filtro por{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Faixa etária
            </Text>{' '}
            em cada grupo-alvo disponível para Vacinas do calendário vacinal e Vacinas de Covid-19;
          </Text>
          <Text fontSize={0.875} component='li'>
            Inclusão do filtro por{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Grupo de atendimento
            </Text>{' '}
            para Vacinas da Covid-19;
          </Text>
          <NewsGif
            src='/static/novidades/5-2_busca_ativa_vacinacao_grupo_alvo_filtros.gif'
            pausedSrc='/static/novidades/5-2_busca_ativa_vacinacao_grupo_alvo_filtros.png'
            alt='Demonstração da Busca ativa de vacinação com filtros de grupo-alvo, faixa etária e avançados de imunobiológico e dose.'
          />
          <Text fontSize={0.875} component='li'>
            Exportação do relatório da busca ativa no formato{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              PDF
            </Text>{' '}
            para impressão e download das{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Vacinas do calendário vacinal
            </Text>{' '}
            e{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Vacinas da Covid-19
            </Text>
            .
          </Text>
          <NewsGif
            src='/static/novidades/5-2_busca_ativa_vacinacao_exportacao.gif'
            pausedSrc='/static/novidades/5-2_busca_ativa_vacinacao_exportacao.png'
            alt='Demonstração de exportação de relatório PDF da Busca ativa de vacinação.'
          />
        </VFlow>
      </NewsHeadingSection>

      {CUIDADO_COMPARTILHADO_ENABLED && (
        <NewsHeadingSection
          title='Cuidado Compartilhado'
          icon={<ProfissionaisIcon style={styles.iconSVG} />}
          id='cuidado-compartilhado-5-2'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Todas as Equipe de Saúde da Família (ESF) ou Equipe de Saúde Bucal (ESB) que possuírem vinculo através do
              CNES com equipes multidiscplinares (eMulti) ou equipes de Núcleos de Apoio à Saúde da Família (NASF) terão
              acesso na seção do Plano de atendimentos individuais e de atendimentos odontológicos a nova aba de
              Compartilhamento do Cuidado. O objetivo do compartilhamento de cuidado é possibilitar que o profissional
              da APS discuta o caso clínico do cidadão com um profissional da equipe eMulti ou NASF.
            </Text>
            <NewsImg
              src='/static/novidades/5-2_tela_cuidado_compartilhado.png'
              alt='Imagem do Cuidado Compartilhado no PEC'
            />
            <Text fontSize={0.875} component='li'>
              Contextualização: O profissional solicitante é quem iniciou o cuidado compartilhado e o profissional
              executante é o profissional de referência que foi indicado para discutir o caso.
            </Text>
            <Text fontSize={0.875} component='li'>
              O PEC conta com alguns módulos novos para que o profissional solicitante e o profissional executante
              consigam conversar sobre o caso clínico do cidadão. Através do menu lateral, dentro da opção "Gestão de
              filas" existe a opção "Cuidados Compartilhados". Existem duas listagens no módulo de "Cuidados
              Compartilhados":
            </Text>
            <Carousel>
              <CarouselItem
                image='/static/novidades/5-2_gestao_filas_cuidados.png'
                alt='Imagem do módulo de Gestão de Filas'
              />
              <CarouselItem
                image='/static/novidades/5-2_cuidados_solicitados.png'
                alt='Imagem dos Cuidados Compartilhados - Solicitados por mim'
              />
              <CarouselItem
                image='/static/novidades/5-2_cuidados_compartilhados.png'
                alt='Imagem dos Cuidados Compartilhados - Compartilhados comigo'
              />
            </Carousel>
            <Text fontSize={0.875} component='li'>
              "Solicitados por mim" que exibe todos os cuidados solicitados pelo usuário, além dos cuidados solicitados
              pela equipe do usuário.
            </Text>
            <Text fontSize={0.875} component='li'>
              "Compartilhados comigo" que exibe todos os cuidados compartilhados com o usuário e com a equipe do
              usuário.
            </Text>
            <Text fontSize={0.875} component='li'>
              As colunas das tabelas são iguais em ambas as listagens, sendo que todas as linhas possuem um status
              ("Respondido" e "Aguardando") e duas ações possíveis ("Visualizar discussão" e "Discutir caso"). As
              seguintes regras serão aplicadas:
              <ul>
                <Text fontSize={0.875} component='li'>
                  Na aba "Solicitados por mim" o usuário só poderá clicar em "Discutir caso" caso tenha solicitado
                  aquele compartilhamento e caso o status seja "Respondido";
                </Text>
                <Text fontSize={0.875} component='li'>
                  Na aba "Compartilhados comigo" o usuário só poderá clicar em "Discutir caso" caso aquele
                  compartilhamento tenha sido compartilhado com ele e caso o status seja "Aguardando";
                </Text>
                <Text fontSize={0.875} component='li'>
                  Sempre que o status for "Aguardando" o sistema exibirá um contador de horas que exibirá há quantas
                  horas aquela discussão está aguardando resposta;
                </Text>
                <Text fontSize={0.875} component='li'>
                  O botão "Visualizar discussão" poderá ser acessado por todos os profissionais de nível superior da
                  equipe do profissional solicitante ou do profissional executante.
                </Text>
              </ul>
            </Text>
            <Text fontSize={0.875} component='li'>
              A listagem possui os seguintes filtros para facilitar na organização e busca da lista de Cuidados
              compartilhados:
              <ul>
                <Text fontSize={0.875} component='li'>
                  Filtro para buscar o cidadão pelo nome, CPF ou CNS;
                </Text>
                <Text fontSize={0.875} component='li'>
                  Botão para "Ver somente as minhas solicitações" na aba "Solicitados por mim" e "Ver somente cuidados
                  compartilhados comigo" na aba "Compartilhados comigo";
                </Text>
                <Text fontSize={0.875} component='li'>
                  Combo de filtros que permite indicar qual o status, período e profissionais que deverão ser exibidos
                  na listagem;
                </Text>
                <Text fontSize={0.875} component='li'>
                  Alterar a ordenação da lista por classificação de prioridade ou status.
                </Text>
              </ul>
            </Text>
            <Text fontSize={0.875} component='li'>
              Ao acessar o módulo de "Discutir caso" o sistema exibirá três abas: "Folha de rosto", "Histórico" e
              "Discussão do caso". As duas primeiras abas são idênticas às abas de um atendimento. A aba de "Discussão
              de caso" conta com:
            </Text>
            <Carousel>
              <CarouselItem
                image='/static/novidades/5-2_discussao_solicitante.png'
                alt='Imagem do registro da discussão de caso do Cuidado Compartilhado feita pelo Solicitante'
              />
              <CarouselItem
                image='/static/novidades/5-2_discussao_executante.png'
                alt='Imagem do registro da discussão de caso do Cuidado Compartilhado feita pelo Executante'
              />
            </Carousel>
            <ul>
              <Text fontSize={0.875} component='li'>
                Um resumo com todas as informações inseridas no momento de criação do cuidado compartilhado;
              </Text>
              <Text fontSize={0.875} component='li'>
                Um histórico com todas as respostas para aquela discussão;
              </Text>
              <Text fontSize={0.875} component='li'>
                Uma seção de condutas para serem adotas:
              </Text>
              <ul>
                <Text fontSize={0.875} component='li'>
                  Conduta "Devolutiva de discussão do caso clínico" para o profissional executante devem existir quatro
                  tipos de devolutiva "Pedido de esclarecimento", "Teleconsultoria" e "Recondução de cuidado" sendo
                  obrigatório inserir observações sobre a devolutiva;
                </Text>
                <Text fontSize={0.875} component='li'>
                  Para o profissional solicitante a conduta "Devolutiva de discussão do caso clínico" possui um formato
                  diferente, sendo possível inserir uma nova observação sobre a discussão;
                </Text>
                <Text fontSize={0.875} component='li'>
                  Conduta "Agendar consulta" possibilita o agendamento de consultas com cidadão;
                </Text>
                {PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && (
                  <Text fontSize={0.875} component='li'>
                    Conduta "Enviar para garantia de acesso" permite ao profissional executante registrar e enviar uma
                    demanda para a Garantia de Acesso.
                  </Text>
                )}
                <Text fontSize={0.875} component='li'>
                  Conduta "Trocar profissional de referência" possibilita trocar o profissional executante da discussão.
                  Após a troca somente o novo executante poderá participar da discussão.
                </Text>
                <Text fontSize={0.875} component='li'>
                  A conduta "Sugerir agendamento para grupos" tem o propósito de permitir ao profissional executante de
                  sugerir a participação do cidadão em atividades coletivas.
                </Text>
                <Text fontSize={0.875} component='li'>
                  Conduta "Reclassificação de prioridade", confere ao profissional alterar a classificação de prioridade
                  do cuidado compartilhado.
                </Text>
              </ul>
            </ul>
            <Text fontSize={0.875} component='li'>
              A lógica é sempre de pergunta e resposta, portanto o solicitante sempre envia uma dúvida que será
              respondida pelo executante. Esse ciclo poderá ser repetido até todas as dúvidas serem sanadas. As únicas
              exceções são quando:
            </Text>
            <ul>
              <Text fontSize={0.875} component='li'>
                Profissional solicitante faz um agendamento, pois não é gerada uma nova pergunta para o executante
                responder, por consequência o status da discussão continua "Respondido".
              </Text>
              <Text fontSize={0.875} component='li'>
                Quando o profissional solicitante ou executante optam por reclassificar a prioridade do cuidado
                compartilhado, a conduta "Reclassificação de prioridade" requer sempre uma segunda conduta adicional
                para promover a evolução da discussão e alterar seu status.
              </Text>
            </ul>
            <Text fontSize={0.875} component='li'>
              Acesso ao Cuidado Compartilhado:
            </Text>
            <ul>
              <Text fontSize={0.875} component='li'>
                Para acessar o módulo do Cuidado Compartilhado, é necessário que o perfil do profissional tenha o
                recurso habilitado.
              </Text>
              <Text fontSize={0.875} component='li'>
                O recurso vem ativado por padrão para os seguintes perfis: Coordenador da UBS, Coordenador do CEO,
                Outros profissionais de nível superior NASF, Cirurgião dentista, Cirurgião dentista CEO, Enfermeiro,
                Farmacêutico, Médico e Outro prof. nível superior.
              </Text>
              <Text fontSize={0.875} component='li'>
                Instalações que utilizam perfis customizados, pode ser necessário habilitar manualmente o acesso ao
                Cuidado Compartilhado.
              </Text>
            </ul>
          </VFlow>
        </NewsHeadingSection>
      )}

      <NewsHeadingSection
        title='e-SUS APS Ampliado'
        icon={<UnidadeDeSaude style={styles.iconSVG} />}
        id='esusAmpliado-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            O e-SUS APS Ampliado passará a reconhecer alguns novos estabelecimentos no sistema.
          </Text>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Facilidades no agendamento de consultas de pré-natal'
        icon={<PreNatal style={styles.iconSVG} />}
        id='agendamento-pre-natal-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            Com a nova funcionalidade de agendamentos, durante um atendimento de pré-natal, será possível realizar o{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              agendamento da próxima consulta para acompanhamento de pré-natal e a consulta odontológica de pré-natal
            </Text>
            . O PEC irá{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              sugerir a data e lotação de profissional
            </Text>
            , sendo que a pessoa responsável pelo atendimento tem liberdade para alterar essas informações conforme
            disponibilidade das partes envolvidas.
          </Text>
          <Text fontSize={0.875} component='li'>
            Para o acompanhamento de pré-natal, a data da próxima consulta é sugerida conforme a idade gestacional,
            respeitando as cadências estipuladas na caderneta da gestante, enquanto a lotação de profissional alterna
            entre profissionais com CBO de médico e enfermeiro.
          </Text>
          <Text fontSize={0.875} component='li'>
            Para as consultas odontológicas, é sugerida uma consulta a cada trimestre da gestação, enquanto a lotação de
            profissional sugerida será de profissional com CBO de dentista.
          </Text>
          <NewsGif
            src='/static/novidades/5-2_agendamento_pre_natal.gif'
            pausedSrc='/static/novidades/5-2_agendamento_pre_natal_paused.png'
            alt='Demonstração de funcionalidade de agendamento de acompanhamento de pré-natal.'
          />
          <Text fontSize={0.875} component='li'>
            Ainda durante os atendimentos de pré-natal, serão apresentadas as{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              sugestões de quais serão todas as datas
            </Text>{' '}
            que deverão ocorrer os{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              próximos acompanhamentos de pré-natal
            </Text>
            , bem como um{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              atendimento odontológico
            </Text>{' '}
            a cada trimestre da gestação.
          </Text>
          <NewsImg
            src='/static/novidades/5-2_agendamento_pre_natal_sugestoes.png'
            alt='Imagem exibindo as sugestões de datas para os próximos acompanhamentos de pré-natal e atendimentos odontológicos'
          />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Gerenciamento de cookies' id='gerenciamento-de-cookies-5-2'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            A partir desta atualização, é possível gerenciar os cookies recolhidos pelo PEC. Os cookies são utilizados
            para melhorar a experiência no sistema e permitir serviços personalizados. Agora, além de estar ciente dos
            cookies recolhidos, você pode escolher quais quer autorizar clicando em "Configurar cookies". Para saber
            mais, acesse{' '}
            <Link onClick={() => history.push('/termosUso')}>Termos de uso e Políticas de privacidade</Link>.
          </Text>
          <NewsImg
            src='/static/novidades/5-2_conf_cookies_banner.webp'
            alt='Tela de login do PEC com demosntração de exibição do banner de cookies na parte inferior'
          />
          <NewsImg
            src='/static/novidades/5-2_conf_cookies_modal.webp'
            alt='Modal de configurações avançadas de cookies no PEC'
          />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Garantia do acesso.'
        icon={<GarantiaAcessoIcon style={styles.iconSVG} />}
        id='garantia-acesso-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            O PEC passa a contar com o módulo de Garantia do acesso, cujo objetivo é permitir que os profissionais criem
            uma lista para gerir a demanda reprimida dos cidadãos nos estabelecimentos de saúde. Através do módulo,
            deve-se poder visualizar os contatos e demandas dos cidadãos que buscaram atendimento e não conseguiram
            acesso imediato ao serviço, podendo então direcioná-los para um possível agendamento futuro.
          </Text>
          <Text fontSize={0.875}>
            Dentre as principais funcionalidades e implementações realizadas no módulo, temos:
          </Text>
          <Text fontSize={0.875} component='li'>
            Agendamento de consulta para o cidadão de forma direta através do seu registro na Garantia do acesso;
          </Text>
          <Text fontSize={0.875} component='li'>
            Filtragem da lista através dos campos de Cidadão, Equipe, Tipo de atendimento ou de serviço e Período de
            registro;
          </Text>
          <Text fontSize={0.875} component='li'>
            Coluna de Origem na tabela, indicando o módulo de origem do registro na listagem;
          </Text>
          {PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED && (
            <VFlow vSpacing={0.5}>
              <Text fontSize={0.875}>
                A partir da versão 5.2.28, com o objetivo de facilitar a gestão e visualização da listagem do módulo de
                Garantia do acesso, foram implementadas as seguintes atualizações:
              </Text>
              <Text fontSize={0.875} component='li'>
                Recebimento de registros com origem do módulo de Cuidado compartilhado;
              </Text>
              <Text fontSize={0.875} component='li'>
                Filtragem da lista de registros através dos campos de Origem e Profissional;
              </Text>
              <Text fontSize={0.875} component='li'>
                Ordenação da lista através da Classificação por prioridade.
              </Text>
            </VFlow>
          )}
          <br></br>
          <NewsImg
            src={
              PILOTO_CUIDADO_COMPARTILHADO_1_ENABLED
                ? '/static/novidades/5-2_tela_garantia_acesso_piloto.webp'
                : '/static/novidades/5-2_tela_garantia_acesso.webp'
            }
            alt='Imagem da tela do módulo de garantia do acesso.'
          />
        </VFlow>
      </NewsHeadingSection>

      {HISTORICO_CUIDADO_COMPARTILHADO_ENABLED && (
        <NewsHeadingSection
          title='Histórico do Cuidado Compartilhado'
          icon={<CuidadoCompartilhado style={styles.iconSVG} />}
          id='historico-cuidado-compartilhado-5-2'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875} component='li'>
              Durante um atendimento, independente do tipo (consulta, pré-natal, puericultura, etc.), quando for gerado
              um compartilhamento de cuidado, será apresentado um novo bloco dentro do histórico desse atendimento.
            </Text>
            <NewsGif
              src='static/novidades/5-2_historico_consulta_com_compartilhamento_de_cuidado.gif'
              pausedSrc='static/novidades/5-2_historico_consulta_com_compartilhamento_de_cuidado_paused.png'
              alt='Demonstração de como acessar o histórico do atendimento que gerou compartilhamento de cuidado'
            />
            <Text fontSize={0.875} component='li'>
              Todos os eventos de evolução do compartilhamento de cuidado irão gerar novos itens dentro do histórico do
              cidadão. A cada interação, seja do profissional solicitante ou do executante, um novo registro será
              apresentado no histórico.
            </Text>
            <NewsGif
              src='static/novidades/5-2_historico_cuidado_compartilhado.gif'
              pausedSrc='static/novidades/5-2_historico_cuidado_compartilhado_paused.png'
              alt='Demonstração de como acessar os registros de cuidado compartilhado no histórico do cidadão'
            />
            <Text fontSize={0.875} component='li'>
              Será possível imprimir o registro de cuidado compartilhado.
            </Text>
            <NewsImg
              src='static/novidades/5-2_historico_cuidado_compartilhado_impressao.png'
              alt='Impressão do registro de cuidado compartilhado'
            />
          </VFlow>
        </NewsHeadingSection>
      )}

      <NewsHeadingSection
        title='Modelos personalizados para relatórios gerenciais'
        icon={<Icon icon='filterOutline' style={styles.iconBold} />}
        id='modelos-personalizados-relatorios-gerenciais-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Os modelos personalizados permitem aos profissionais criarem seus próprios modelos definindo filtros,
            colunas e linhas que são utilizadas regularmente na busca de informações dos relatórios gerenciais.
          </Text>
          <Text fontSize={0.875} component='li'>
            Ao cadastrar um novo modelo, o profissional terá pré-definido quais campos serão utilizados nos relatórios.
          </Text>

          <NewsGif
            src='/static/novidades/5-2_modelo_personalizado_relatorio_gerencial_criacao_de_modelo.gif'
            pausedSrc='/static/novidades/5-2_modelo_personalizado_relatorio_gerencial_criacao_de_modelo_paused.png'
            alt='Demonstração de funcionalidade da criação de um Modelo Personalizado para os Relatórios Gerenciais.'
          />

          <Text fontSize={0.875} component='li'>
            Durante a busca, ao selecionar um modelo o sistema automaticamente irá buscar os filtros, colunas e linhas
            previamente definidos no modelo, tornando a busca muito mais eficiente para o profissional.
          </Text>

          <NewsGif
            src='/static/novidades/5-2_modelo_personalizado_relatorio_gerencial_uso_de_modelo.gif'
            pausedSrc='/static/novidades/5-2_modelo_personalizado_relatorio_gerencial_uso_de_modelo_paused.png'
            alt='Demonstração de funcionalidade do uso de um Modelo Personalizado para os Relatórios Gerenciais.'
          />

          <Text fontSize={0.875} component='li'>
            Ainda assim, será possível para o profissional alterar as informações durante a utilização do relatório.
          </Text>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Prescrição de medicamentos' id='prescricao-medicamentos-5-2'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            <b>Atualização do campo "dose" na prescrição de medicamentos</b>
          </Text>
          <Text fontSize={0.875} component='li'>
            Com objetivo de qualificar os dados do PEC, a informação da dose na prescrição de medicamentos foi dividida
            em dois campos:
          </Text>
          <ul>
            <Text fontSize={0.875} component='li'>
              <b>Quantidade da dose:</b> Quantidade da dose prescrita. Para doses fracionadas, utilize vírgula ou barra.
              Exemplos: "0,5", "1/2", etc.
            </Text>
            <Text fontSize={0.875} component='li'>
              <b>Unidade de medida:</b> Unidade de medida para consumo do medicamento prescrito. Exemplos: "comprimido",
              "ml", "sachê", etc.
            </Text>
          </ul>
          <NewsGif
            src='/static/novidades/5-2_campos_dose.gif'
            pausedSrc='/static/novidades/5-2_campos_dose_paused.png'
            alt='Demonstração dos novos campos de dose e unidade de medida no modal da prescrição de medicamentos.'
          />
          <Text fontSize={0.875} component='li'>
            Lembre-se de utilizar o campo <b>"Recomendações"</b> para inserir demais informações relevantes na
            utilização do medicamento.
          </Text>
          <Text fontSize={0.875} component='li'>
            Para <b>replicar prescrição</b> ou <b>replicar medicamento</b>, é necessário atualizar a informação da dose
            nos novos campos. A informação original será exibida no alerta da prescrição.
          </Text>
          <NewsGif
            src='/static/novidades/5-2_replicar_prescricao.gif'
            pausedSrc='/static/novidades/5-2_replicar_prescricao_paused.png'
            alt='Demonstração do comportamento do modal de prescrição de medicamentos ao replicar uma receita solicitando a alteração dos campos de dose'
          />
          {PRESCRICAO_TURNO && (
            <Fragment>
              <Text fontSize={0.875}>
                <b>Inclusão de doses fracionadas por turno</b>
              </Text>
              <Text fontSize={0.875} component='li'>
                Para aproximar o PEC cada vez mais à realidade da rotina do profissional, foi incluído a funcionalidade
                de prescrever medicamentos com doses organizadas por turno.
              </Text>
              <ul>
                <Text fontSize={0.875} component='li'>
                  <b>Nova aba "Fracionado por turno":</b> Para utilizá-la, selecione o tipo da dose "Fracionada". Então,
                  informe a quantidade total da dose e sua unidade de medida. Com isso, insira as quantidades
                  organizadas pelos turnos indicados.
                </Text>
              </ul>
              <Text fontSize={0.875} component='li'>
                É necessário informar a quantidade de pelo menos dois turnos. A soma das doses organizadas por turnos
                deve ser igual à quantidade total da dose, visto que a separação por turno é para tornar mais fácil o
                entendimento do paciente em relação ao uso do medicamento.
              </Text>
              <NewsGif
                src='/static/novidades/5-2_dose_fracionada.gif'
                pausedSrc='/static/novidades/5-2_dose_fracionada_paused.png'
                alt='Demonstração do preenchimento da prescrição de medicamentos com doses fracionadas por turno'
              />
            </Fragment>
          )}
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Prescrição Digital'
        icon={<Consulta style={styles.iconSVG} />}
        id='prescricao-digital-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Ao encaminhar a prescrição de medicamentos para o cidadão, será possível optar por realizar a impressão ou{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              gerar uma prescrição digital
            </Text>{' '}
            através da opção “Prescrição digital”, caso a instalação esteja habilitada para gerar prescrições digitais.
            O documento da prescrição digital será gerado no formato{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              PDF
            </Text>{' '}
            e enviado ao cidadão, que poderá utilizá-lo para retirar medicamentos. Na farmácia, será possível registrar
            que os medicamentos da prescrição foram fornecidos. Para utilizar a funcionalidade é necessário ativar a
            integração com o servidor de Prescrição digital.
          </Text>
          <Text fontSize={0.875} component='li'>
            A prescrição digital será encaminhada ao cidadão via{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              e-mail.
            </Text>{' '}
            É possível editar o endereço de e-mail do cidadão antes de encaminhar clicando no ícone de edição ao lado do
            campo.
          </Text>
          <NewsGif
            src='/static/novidades/5-2_prescricao_digital_gerar.gif'
            pausedSrc='/static/novidades/5-2_prescricao_digital_gerar_paused.png'
            alt='Demonstração de funcionalidade da geração da prescrição digital.'
          />
          <Text fontSize={0.875} component='li'>
            Para gerar a prescrição digital, é necessário realizar uma{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              assinatura eletrônica
            </Text>{' '}
            do documento. O PEC irá abrir uma nova janela para o processo de assinatura.
          </Text>
          <NewsGif
            src='/static/novidades/5-2_prescricao_digital_assinar.gif'
            pausedSrc='/static/novidades/5-2_prescricao_digital_assinar_paused.png'
            alt='Demonstração de funcionalidade de assinatura do documento da prescrição digital.'
          />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Relatório gerencial de cuidado compartilhado'
        icon={<RelatorioGegencialCuidadoCompartilhado style={styles.iconSVG} />}
        id='relatorio-gerencial-cuidado-compartilhado-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            O relatório é baseado nos eventos ocorridos em cada compartilhamento de cuidado, dessa forma é possível
            computar exatamente quantas interações foram realizadas por cada profissional dentro do período. Além disso,
            há a possibilidade de visualizar o volume de eventos que foram respondidos até e acima das 72h.
          </Text>
          <Text fontSize={0.875} component='li'>
            Para profissionais eMulti que apoiam múltiplas unidades de saúde, em uma única consulta será possível
            visualizar toda sua produção em todas as unidades.
          </Text>
          <NewsGif
            src='static/novidades/5-2_relatorio_gerencial_cuidado_compartilhado_producao_em_todas_as_unidades.gif'
            pausedSrc='static/novidades/5-2_relatorio_gerencial_cuidado_compartilhado_producao_em_todas_as_unidades_paused.png'
            alt='Demonstração de uma consulta das produções de todas a unidades de saúde no relatório gerencial de cuidado compartilhado'
          />
          <Text fontSize={0.875} component='li'>
            O relatório permite que seja visualizado o volume de produção da equipe e do próprio profissional.
          </Text>
          <NewsGif
            src='static/novidades/5-2_relatorio_gerencial_cuidado_compartilhado_producao_equipe_e_profissional.gif'
            pausedSrc='static/novidades/5-2_relatorio_gerencial_cuidado_compartilhado_producao_equipe_e_profissional_paused.png'
            alt='Demonstração de uma consulta com as produções da equipe e do próprio profissional no relatório gerencial de cuidado compartilhado'
          />
          <Text fontSize={0.875} component='li'>
            Será possível exportar o relatório gerencial de cuidado compartilhado.
          </Text>
          <NewsImg
            src='static/novidades/5-2_relatorio_gerencial_cuidado-compartilhado_exportacao.png'
            alt='Exportação do relatório gerencial de cuidado compartilhado'
          />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='RNDS - Envio de registros' id='envio-rnds-5-2'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Para as instalações com a RNDS configurada, os seguintes registros serão enviados à Rede Nacional de Dados
            em Saúde (RNDS):
          </Text>
          <ul>
            <Text fontSize={0.875} component='li'>
              <b>Vacinação</b>: Envio ao finalizar o atendimento de vacinação. As transcrições de caderneta também serão
              enviadas, tanto as cadastradas no atendimento de vacinação quanto no atendimento individual.
            </Text>
            <Text fontSize={0.875} component='li'>
              <b>Atendimentos individuais</b>: Envio ao finalizar o atendimento individual. Atendimentos em que o
              cidadão ficou em observação também serão enviados após sua finalização.
            </Text>
            <Text fontSize={0.875} component='li'>
              <b>Prescrição de medicamentos</b>: Envio ao finalizar o atendimento em que houve a prescrição de
              medicamentos.
            </Text>
            <Text fontSize={0.875} component='li'>
              <b>Atestados</b>: Envio ao finalizar o atendimento em que houve a geração de atestado do modelo padrão.
            </Text>
          </ul>
          <Text fontSize={0.875} component='li'>
            Nova página para visualização da quantidade de registros enviados, localizada no módulo de "Gestão
            municipal", aba "RNDS". A página exibe informações de acordo com os tipos de registros enviados.
          </Text>
          <NewsImg src='/static/novidades/5-2_aba_RNDS.png' alt='Imagem da aba RNDS do módulo de Gestão Municipal' />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Unificação de cadastros'
        icon={<UnificacaoDeCadastros style={styles.iconSVG} />}
        id='unificacao-de-cadastros-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Com a nova funcionalidade de{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Exportar cadastros duplicados
            </Text>
            , agora é possível exportar relatórios no formato CSV com os prováveis cadastros de cidadãos duplicados.{' '}
          </Text>
          <NewsImg
            src='/static/novidades/5-2_unificacao_de_cadastros.png'
            alt='Imagem exibindo o novo botão para exportar cadastros duplicados'
          />
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Videochamadas'
        icon={<VideochamadaIcon style={styles.iconSVG} />}
        id='videochamadas-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875}>
            A partir desta atualização, é possível realizar{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              videochamadas
            </Text>{' '}
            diretamente no PEC através do novo módulo{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Videochamadas e-SUS APS
            </Text>{' '}
            que oferece um canal oficial de comunicação aos usuários! A ferramenta conta com a possibilidade de conexão
            entre duas pessoas, compartilhamento de tela, picture-in-picture e chat.
          </Text>
          <br></br>
          <NewsImg
            style={styles.imageVideochamada}
            src='/static/novidades/5-2_tela_videochamada.png'
            alt='Imagem da tela das Videochamadas no PEC'
          />
          <br></br>
          <Text fontSize={0.875}>
            Para utilizar essa nova funcionalidade, é necessário que o acesso à instalação seja feito utilizando o
            protocolo HTTPS e que o{' '}
            <Text fontSize={0.875} fontWeight='bold'>
              Administrador da Instalação
            </Text>{' '}
            habilite as videochamadas nas configurações de internet:
          </Text>
          <br></br>
          <NewsGif
            src='/static/novidades/5-2_videochamadas_habilitar.gif'
            pausedSrc='/static/novidades/5-2_videochamadas_habilitar_paused.png'
            alt='Demonstração de como habilitar videochamadas no sistema.'
          />
          <br></br>
          <Text fontSize={0.875}>
            Depois disso, basta que seja criada uma nova chamada no módulo Videochamadas e que seja enviado o link de
            acesso para o convidado, seja ele usuário do PEC ou não.
          </Text>
          <Carousel>
            <CarouselItem
              image='/static/novidades/5-2_videochamadas_modulo.png'
              alt='Imagem do módulo de Videochamadas'
            />
            <CarouselItem
              image='/static/novidades/5-2_videochamadas_sala_espera.png'
              alt='Sala de espera para usuários convidados'
            />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection
        title='Vínculo entre equipes do PEC'
        icon={<AcompanhamentosIcon style={styles.iconSVG} />}
        id='equipes-vinculadas-5-2'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Visando possibilitar que profissionais que atuam em equipes especialistas (por exemplo a ESB) possam
            visualizar dados de cidadãos vinculados a equipes como ESF, EAP, entre outras. Com a nova versão do XML do
            CNES (3.1), será possível importar o vínculo que existe entre as equipes. Após a importação, é possível
            visualizar os vínculos na aba de equipes dentro do cadastro de unidades de saúde.
          </Text>
          <NewsGif
            src='/static/novidades/5-2_equipes_vinculadas_visualizacao.gif'
            pausedSrc='/static/novidades/5-2_equipes_vinculadas_visualizacao_paused.png'
            alt='Demonstração da visualização de equipes vinculadas'
          />
          <Text fontSize={0.875} component='li'>
            Alguns relatórios do sistema foram adaptados para considerar o vínculo entre as equipes. Dessa forma, quando
            um profissional pertencente a uma equipe vinculada a outra consultar as informações, mesmo que o cidadão não
            seja acompanhado por sua equipe, o relatório será apresentado.
          </Text>
          <NewsGif
            src='/static/novidades/5-2_equipes_vinculadas_relatorio.gif'
            pausedSrc='/static/novidades/5-2_equipes_vinculadas_relatorio_paused.png'
            alt='Demonstração do impacto da vinculação de equipes nos relatórios'
          />
          <ul>
            <Text fontSize={0.875} component='li'>
              Acompanhamento de cidadãos vinculados
            </Text>
            <Text fontSize={0.875} component='li'>
              Acompanhamento de condições de saúde
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório operacional de crianças menores de 5 anos
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório operacional de gestante/puérpera
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório operacional de risco cardiovascular
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório operacional de cadastro territorial
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório consolidado de cadastro domiciliar e territorial
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório consolidado de cadastro individual
            </Text>
            <Text fontSize={0.875} component='li'>
              Relatório consolidado de situação do território
            </Text>
            <Text fontSize={0.875} component='li'>
              Busca ativa de vacinação
            </Text>
          </ul>
          <Text fontSize={0.875} component='li'>
            Os relatórios impactados apresentam a mensagem da imagem abaixo em seu cabeçalho:
          </Text>
          <NewsImg
            src='/static/novidades/5-2_equipes_vinculadas_relatorio_info.png'
            alt='Imagem do aviso de vinculação de equipes nos relatórios'
          />
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}

const styles = {
  imageVideochamada: css`
    max-width: 812px;
    max-height: 100%;
  `,
  iconBold: css`
    margin-right: 0.25rem;
    width: 32px;
    height: 32px;
  `,
  iconSVG: css`
    margin-right: 0.25rem;
    width: 32px;
    height: 32px;
  ` as CSSProperties,
}
