/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HFlow, Icon, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { AccordionCompositeItem } from 'components/accordion/AccordionCompositeItem'
import { ButtonLink, PrivateRoute } from 'components/route'
import theme from 'config/theme'
import { TipoReceitaEnum, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import { Fragment } from 'react'
import { Switch, useRouteMatch } from 'react-router'
import { tipoReceitaRecord } from 'types/enums'
import { formatDate } from 'util/date/formatDate'

import { grupoCboPrescricao } from '../../acessos'
import EncaminharPrescricaoModal from '../modal/EncaminhamentoPrescricaoModal'
import { PrescricaoDigitalFormModel } from '../modal/prescricao-digital/model-prescricaoDigital'
import { MedicamentoFormModel } from '../model-prescricao'
import {
  prescricaoAtendBodyConverter,
  prescricaoAtendHeaderConverter,
  PrescricaoAtendimentoBodyModel,
} from '../utils/converter-prescricao'
import DisponibilidadeHorus from './DisponibilidadeHorus'

interface AccordionPrescricaoProps {
  medicamentos: MedicamentoFormModel[]
  prescricaoDigital: PrescricaoDigitalFormModel
  updatePrescricaoDigital: (prescricao: PrescricaoDigitalFormModel) => void
  atendimentoId: ID
}

export default function AccordionPrescricao({
  medicamentos,
  atendimentoId,
  prescricaoDigital,
  updatePrescricaoDigital,
}: AccordionPrescricaoProps) {
  const match = useRouteMatch()

  const renderHeader = (title: string) => {
    return (
      <HFlow
        style={css`
          width: 100%;
        `}
      >
        <Heading level={5} style={styles.heading}>
          {title}
        </Heading>
        <div css={styles.adicionadoAgora}>
          <Icon
            icon='clockOutline'
            size={1}
            style={css`
              margin-right: 0.5rem;
            `}
          />
          Adicionado agora
        </div>
      </HFlow>
    )
  }

  const renderBody = (prescricaoAtendimento: PrescricaoAtendimentoBodyModel[]) => {
    return prescricaoAtendimento.map((medicamento, index) => {
      return (
        <div
          css={css`
            padding: 0.5rem 1rem;
          `}
        >
          <Grid key={index}>
            <Cell size={8}>
              <HFlow alignItems='center' hSpacing={0.5}>
                <Heading
                  level={5}
                  style={css`
                    word-break: break-word;
                  `}
                >
                  {`${medicamento.principioAtivo} ${medicamento.concentracao ?? ''}`.trim()}
                </Heading>
                {medicamento.usoContinuo && <Tag type='normal'>Uso contínuo</Tag>}
                {medicamento.tipoReceita !== TipoReceitaEnum.COMUM && (
                  <Tag type='info'>{tipoReceitaRecord[medicamento.tipoReceita]}</Tag>
                )}
              </HFlow>
              <Text>{`${medicamento.posologia} | ${medicamento.viaAdministracao.nome} | ${medicamento.formaFarmaceutica}`}</Text>
            </Cell>
            <Cell size={4}>
              <VFlow vSpacing={0}>
                <Heading level={5}>{`Período: ${formatDate(medicamento.dataInicioTratamento)} 
                - ${
                  medicamento.escalaDuracao !== UnidadeMedidaTempoEnum.INDETERMINADO
                    ? formatDate(medicamento.dataFimTratamento)
                    : 'Indeterminado'
                }
              `}</Heading>
                <Text>
                  <DisponibilidadeHorus disponibilidade={medicamento.disponibilidade} />
                </Text>
              </VFlow>
            </Cell>
            <Cell size={12}>
              <Heading level={5}>Recomendações</Heading>
              {medicamento.recomendacoes ? (
                <Text
                  style={css`
                    overflow-wrap: anywhere;
                  `}
                >
                  {medicamento.recomendacoes}
                </Text>
              ) : (
                <Text fontStyle='italic'>Não há recomendações para o medicamento</Text>
              )}
            </Cell>
          </Grid>
        </div>
      )
    })
  }

  const renderHeaderButtons = () => {
    return (
      <HFlow hSpacing={0} alignItems='center'>
        <Tooltip text='Encaminhar'>
          <ButtonLink size='small' skin='ghost' to={`${match.url}/prescricao-medicamentos/encaminhar`}>
            <Icon icon='printerOutline' />
          </ButtonLink>
        </Tooltip>
      </HFlow>
    )
  }

  return (
    <Fragment>
      <AccordionCompositeItem
        header={renderHeader(prescricaoAtendHeaderConverter(medicamentos))}
        headerButtons={renderHeaderButtons()}
        body={renderBody(prescricaoAtendBodyConverter(medicamentos))}
      />
      <Switch>
        <PrivateRoute
          path={`${match.url}/prescricao-medicamentos/encaminhar`}
          permission={grupoCboPrescricao.adicionar}
        >
          <EncaminharPrescricaoModal
            medicamentos={medicamentos}
            prescricaoDigital={prescricaoDigital}
            updatePrescricaoDigital={updatePrescricaoDigital}
            atendimentoId={atendimentoId}
          />
        </PrivateRoute>
      </Switch>
    </Fragment>
  )
}

const styles = {
  heading: css`
    display: flex;
    align-items: center;
  `,
  adicionadoAgora: css`
    color: ${theme.pallete.primary.main};
    display: flex;
    align-items: center;
  `,
}
