import { DateRange } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { MetaResult } from 'components/pivot-table/tree-builder/model-useTreeBuilder'
import { AsyncProcessingNameEnum } from 'graphql/types.generated'
import { useAsyncProcessing } from 'hooks/async/useAsyncProcessing'
import { useLocalStorage } from 'hooks/useStorage'
import { useCallback, useEffect, useState } from 'react'

import {
  convertCiapCidFilter,
  convertRelatorioGerencialStorageModelToFormModel,
} from '../components/form/converter-relatorioGerencial'
import { RelatorioGerencialFiltrosFormModel } from '../components/form/model-relatorioGerencialFiltrosForm'
import { RelatorioGerencialFiltrosStorageModel } from './model-relatorioGerencial'
import {
  handleSubmitAsyncRelatorioGerencial,
  onProcessingRelatorioGerencialFinished,
} from './util-useAsyncRelatorioGerencial'

export interface UseAsyncRelatorioGerencialProps<T> {
  asyncProcessingName: AsyncProcessingNameEnum
  filterStorageKey: string
  isSearchingMeta: boolean
  hasMetaState: boolean
  onGetMeta: (filtros: RelatorioGerencialFiltrosFormModel) => void
  meta: (filterState: DateRange, periodUnit: string, filtroCiapCid?: string[]) => MetaResult<T>
  setIsSearchingMeta: (isSearchingMeta: boolean) => void
}

interface UseAsyncRelatorioGerencialResult {
  filtros: RelatorioGerencialFiltrosFormModel
  isAsyncProcessingRunning: boolean
  handleSubmit: (filtros: RelatorioGerencialFiltrosFormModel) => void
}

export function useAsyncRelatorioGerencial<T>(
  props: UseAsyncRelatorioGerencialProps<T>
): UseAsyncRelatorioGerencialResult {
  const {
    asyncProcessingName,
    filterStorageKey,
    isSearchingMeta,
    hasMetaState,
    onGetMeta,
    meta,
    setIsSearchingMeta,
  } = props

  const { data } = useSession()
  const acessoId = data?.acesso?.id

  const [getFiltrosStorage, setFiltrosStorage, clearFiltrosStorage] = useLocalStorage<
    RelatorioGerencialFiltrosStorageModel
  >(`${filterStorageKey}/${acessoId}`)

  const filtros = convertRelatorioGerencialStorageModelToFormModel(getFiltrosStorage())

  const [filtrosState, setFiltrosState] = useState(filtros)

  const getMeta = useCallback(
    ({ ciapsCids, gruposCondicoesPrioritarios, periodo, unidade }: RelatorioGerencialFiltrosFormModel) => {
      const ciapCidFilterConverted = convertCiapCidFilter(ciapsCids, gruposCondicoesPrioritarios)
      return meta(periodo, unidade, ciapCidFilterConverted)
    },
    [meta]
  )

  const alert = useAlert()

  const onProcessingFinished = useCallback(
    onProcessingRelatorioGerencialFinished(
      alert,
      setIsSearchingMeta,
      onGetMeta,
      getMeta,
      getFiltrosStorage,
      clearFiltrosStorage
    ),
    [setIsSearchingMeta, alert, getFiltrosStorage, getMeta, clearFiltrosStorage]
  )

  const { isStarted, isFinished, hasError, removerProcessoAssincrono } = useAsyncProcessing({
    asyncProcessingName,
    onProcessingFinished,
  })

  // Buscando os dados quando o usuario voltar para a pagina do modulo soh depois de terminar o processamento
  useEffect(() => {
    if (isStarted && isFinished && filtrosState && !isSearchingMeta && !hasMetaState) {
      removerProcessoAssincrono()
      clearFiltrosStorage()

      if (!hasError) {
        getMeta(filtrosState)
        onGetMeta(filtrosState)
      } else {
        setFiltrosState(null)
      }
    }
  }, [
    filtros,
    getMeta,
    getFiltrosStorage,
    hasError,
    hasMetaState,
    isFinished,
    isSearchingMeta,
    isStarted,
    onGetMeta,
    clearFiltrosStorage,
    removerProcessoAssincrono,
    filtrosState,
  ])

  const handleSubmit = useCallback(handleSubmitAsyncRelatorioGerencial(alert, onGetMeta, getMeta, setFiltrosStorage), [
    alert,
    getMeta,
    onGetMeta,
    setFiltrosStorage,
  ])

  return {
    filtros: filtrosState,
    isAsyncProcessingRunning: isStarted && !isFinished,
    handleSubmit,
  }
}
