/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useTheme } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { useErrorHandler } from 'components/error'
import { useRemoverListaAtendimentoMutation } from 'graphql/hooks.generated'
import { ReactComponent as DataTimes } from 'images/agenda/data-times-outlined.svg'
import Permissions from 'types/Permissions'

import MenuAcoesAgendamentoButton from '../MenuAcoesAgendamentoButton'
import { AcaoAgendamentoButtonProps } from '../model-acoesAgendamento'

export function RemoverListaAtendimentoButton(props: AcaoAgendamentoButtonProps) {
  const { event, tooltip, disabled, isDropdown } = props
  const theme = useTheme()
  const alert = useAlert()
  const handleException = useErrorHandler()
  const [removerListaAtendimento, { loading }] = useRemoverListaAtendimentoMutation()

  const onClick = () => {
    removerListaAtendimento({
      variables: {
        atendimentoId: event.atendimento.id,
      },
    })
      .then(() => {
        alert('success', `O atendimento de ${event.nomeCidadao.titleCase()} foi removido da lista.`)
      })
      .catch(handleException)
  }

  return (
    <CheckPermission permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}>
      <MenuAcoesAgendamentoButton
        text='Remover da lista de atendimento'
        tooltip={tooltip}
        disabled={disabled}
        loading={loading}
        isDropdown={isDropdown}
        onClick={onClick}
        icon={
          <DataTimes
            css={css`
              fill: ${theme.pallete.text.main};
            `}
          />
        }
      />
    </CheckPermission>
  )
}
