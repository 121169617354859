import { useAlertStore } from 'components/alert'
import useSession from 'components/auth/useSession'
import { useCallback } from 'react'
import { useLocation } from 'react-router'

import useAtmosphere from '../useAtmosphere'
import { AsyncProcessingFinishMessage } from './AsyncProcessingFinishMessage'
import {
  AsyncProcessingAtmosphereResponse,
  asyncProcessingConfigurationRecord,
  asyncProcessingFinishMessageInfoRecord,
  AsyncProcessingMessageTypeEnum,
} from './model-asyncProcessing'

const ALERT_TIMEOUT_MILLIS = 10000

export function AsyncProcessingTopicListener() {
  const { data } = useSession()
  const acessoId = data?.acesso?.id

  const { pathname } = useLocation()
  const { alert, remove } = useAlertStore()

  const showAlert = useCallback(
    ({ asyncProcessingName, message, type }: AsyncProcessingAtmosphereResponse) => {
      const alertType = type === AsyncProcessingMessageTypeEnum.ERROR ? 'danger' : 'success'
      const { id } = alert(
        alertType,
        AsyncProcessingFinishMessage({
          message,
          type: alertType,
          asyncProcessingName,
          onLinkClick: () => {
            remove(id)
          },
        }),
        ALERT_TIMEOUT_MILLIS
      )
    },
    [alert, remove]
  )

  const handleMessage = useCallback(
    (response: AsyncProcessingAtmosphereResponse) => {
      const { asyncProcessingName, isFinished } = response
      if (isFinished) {
        const asyncProcessingConfiguration = asyncProcessingConfigurationRecord[asyncProcessingName]
        const isAtModuloPage = pathname === asyncProcessingFinishMessageInfoRecord[asyncProcessingName]?.path

        if (isAtModuloPage && asyncProcessingConfiguration) {
          document.dispatchEvent(
            new CustomEvent<AsyncProcessingAtmosphereResponse>(asyncProcessingConfiguration.finishProcessingEventName, {
              detail: response,
            })
          )
        } else {
          showAlert(response)
        }
      }
    },
    [pathname, showAlert]
  )

  useAtmosphere<AsyncProcessingAtmosphereResponse>({
    topic: `async/status/${acessoId}`,
    onMessage: handleMessage,
  })

  return null
}
