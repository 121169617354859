import { CampoSalvoRelatorioGerencial, TipoModuloEnum } from 'graphql/types.generated'
import { partition } from 'lodash'
import Permissions, { Permission } from 'types/Permissions'
import { emptyArray } from 'util/array'

import { KeyMapping } from '../../common/keyMapping-relatorioGerencial'
import {
  ModeloPersonalizadoRelatorioGerencialCampoModel,
  VisibilidadeModeloPersonalizadoEnum,
} from './form/model-modeloPersonalizadoRelatorioGerencialForm'

type PermissionsRelatorioGerencialType =
  | typeof Permissions.relatorios.gerenciais.atendimentos
  | typeof Permissions.relatorios.gerenciais.absenteismo
  | typeof Permissions.relatorios.gerenciais.cuidadoCompartilhado
  | typeof Permissions.relatorios.gerenciais.vacinacao

export const permissionsByModulo: { [modulo in TipoModuloEnum]: PermissionsRelatorioGerencialType } = {
  RELATORIO_GERENCIAL_ATENDIMENTO: Permissions.relatorios.gerenciais.atendimentos,
  RELATORIO_GERENCIAL_ABSENTEISMO: Permissions.relatorios.gerenciais.absenteismo,
  RELATORIO_GERENCIAL_CUIDADO_COMPARTILHADO: Permissions.relatorios.gerenciais.cuidadoCompartilhado,
  RELATORIO_GERENCIAL_VACINACAO: Permissions.relatorios.gerenciais.vacinacao,
}

export const checkAccessCampoByKey = (
  key: string,
  modulo: TipoModuloEnum,
  isGestorEstadual: boolean,
  hasAuthorization: (permission: Permission) => boolean
): boolean | null => {
  const permission = permissionsByModulo[modulo]
  switch (key) {
    case 'cbo':
      return hasAuthorization(permission.cbo)
    case 'equipe':
      return hasAuthorization(permission.todasAsEquipes)
    case 'unidadeSaude':
      return hasAuthorization(permission.unidadeDeSaude)
    case 'municipio':
      return hasAuthorization(permission.municipio)
    case 'categoriaProfissional':
      return hasAuthorization(permission.todosOsProfissionais.categoriaProfissional)
    case 'nomeProfissional':
      return hasAuthorization(permission.todosOsProfissionais)
    case 'producoes':
      return !isGestorEstadual && !hasAuthorization(permission.todasAsEquipes)
    default:
      return null
  }
}

export const isCampoNaoSelecionado = (key: string, campos: ModeloPersonalizadoRelatorioGerencialCampoModel[]) =>
  !campos?.some((campo) => campo.key === key)

export const isCampoPublico = (value: KeyMapping) => value?.visibility === VisibilidadeModeloPersonalizadoEnum.PUBLICO
export const isCampoPrivado = (value: KeyMapping) => value?.visibility === VisibilidadeModeloPersonalizadoEnum.PRIVADO

export const isCampoPrivadoComAcesso = (
  key: string,
  value: KeyMapping,
  modulo: TipoModuloEnum,
  isGestorEstadual: boolean,
  hasAuthorization: (permission: Permission) => boolean
) => isCampoPrivado(value) && checkAccessCampoByKey(key, modulo, isGestorEstadual, hasAuthorization) === true

export const isCampoPrivadoSemAcesso = (
  key: string,
  value: KeyMapping,
  modulo: TipoModuloEnum,
  isGestorEstadual: boolean,
  hasAuthorization: (permission: Permission) => boolean
) => isCampoPrivado(value) && checkAccessCampoByKey(key, modulo, isGestorEstadual, hasAuthorization) === false

export const getCamposDisponiveis = (
  keyMapping: Map<string, KeyMapping>,
  modulo: TipoModuloEnum,
  campos: ModeloPersonalizadoRelatorioGerencialCampoModel[],
  isModeloPublico: boolean,
  isGestorEstadual: boolean,
  hasAuthorization: (permission: Permission) => boolean
) => {
  if (!keyMapping) return emptyArray as string[]

  const keyMappingCamposDisponiveis = [...keyMapping].filter(([key, _]) => isCampoNaoSelecionado(key, campos))

  const [camposPublicos, camposPrivados] = partition(keyMappingCamposDisponiveis, ([_, value]) => isCampoPublico(value))

  const camposPrivadosComAcesso = camposPrivados.filter(
    ([key, value]) =>
      !isModeloPublico && isCampoPrivadoComAcesso(key, value, modulo, isGestorEstadual, hasAuthorization)
  )
  return [...camposPublicos, ...camposPrivadosComAcesso].sort(sortCampos).map(([key, _]) => key)
}

export const isCampoInconsistente = (
  key: CampoSalvoRelatorioGerencial['key'],
  keyMapping: Map<string, KeyMapping>,
  modulo: TipoModuloEnum,
  isGestorEstadual: boolean,
  hasAuthorization: (permission: Permission) => boolean
) =>
  !keyMapping.has(key) || isCampoPrivadoSemAcesso(key, keyMapping.get(key), modulo, isGestorEstadual, hasAuthorization)

const sortCampos = (a: [string, KeyMapping], b: [string, KeyMapping]) => (a[1].keyName >= b[1].keyName ? 1 : -1)
