import { VFlow } from 'bold-ui'
import { ErrorField } from 'components/form/final-form/ErrorField/ErrorField'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import {
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoExecutanteFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { AgendarConsultaCuidadoCompartilhadoField } from '../components/discussao-caso-fields/AgendarConsultaCuidadoCompartilhadoField'
import { DevolutivaCuidadoCompartilhadoField } from '../components/discussao-caso-fields/DevolutivaCuidadoCompartilhadoField'
import { SugestaoAgendamentoGrupoCuidadoCompartilhadoField } from '../components/discussao-caso-fields/SugestaoAgendamentoGrupoCuidadoCompartilhadoField'
import { TrocaExecutanteCuidadoCompartilhadoField } from '../components/discussao-caso-fields/TrocaExecutanteCuidadoCompartilhadoField'

interface DiscussaoCasoExecutanteFieldsProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel>
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
  isEspecializada: boolean
  isExecutanteFromEstabelecimentoEspecializado: boolean
}

export const DiscussaoCasoExecutanteFields = (props: DiscussaoCasoExecutanteFieldsProps) => {
  const {
    path,
    cuidadoCompartilhado,
    videochamadaUuidState,
    isEspecializada,
    isExecutanteFromEstabelecimentoEspecializado,
  } = props

  return (
    <VFlow vSpacing={0.5}>
      <DevolutivaCuidadoCompartilhadoField path={path} />

      <TrocaExecutanteCuidadoCompartilhadoField
        path={path}
        lotacaoSolicitante={cuidadoCompartilhado.lotacaoSolicitante}
        profissionalExecutanteId={cuidadoCompartilhado.lotacaoExecutanteAtual?.profissional?.id}
        unidadeSaudeExecutanteId={cuidadoCompartilhado.unidadeSaudeExecutanteAtual.id}
        isEspecializada={isEspecializada}
      />

      {!isExecutanteFromEstabelecimentoEspecializado && (
        <AgendarConsultaCuidadoCompartilhadoField
          path={path}
          cuidadoCompartilhado={cuidadoCompartilhado}
          videochamadaUuidState={videochamadaUuidState}
        />
      )}

      <SugestaoAgendamentoGrupoCuidadoCompartilhadoField path={path} />

      <ErrorField name={path} ignoreObjectError />
    </VFlow>
  )
}
