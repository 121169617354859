import { css } from '@emotion/core'
import { Button, HFlow, Icon, Text, Tooltip, VFlow } from 'bold-ui'
import { orange } from 'bold-ui/lib/styles/colors'
import theme from 'config/theme'
import React, { useMemo } from 'react'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'

import { MedicamentoFormModel, PrincipiosAtivosAlergiasQueryPayload } from '../model-prescricao'

interface MedicamentoCardProps {
  medicamentoModel: MedicamentoFormModel
  editing: boolean
  replicating?: boolean
  onRemoveMedicamento(medicamento: MedicamentoFormModel): void
  onEditMedicamento(medicamento: MedicamentoFormModel): void
  setShowDoseAlert(show: boolean): void
  principiosAtivosAlergias: PrincipiosAtivosAlergiasQueryPayload
}

export default function MedicamentoCard(props: MedicamentoCardProps) {
  const {
    medicamentoModel,
    editing,
    onRemoveMedicamento,
    onEditMedicamento,
    setShowDoseAlert,
    replicating,
    principiosAtivosAlergias,
  } = props
  const styles = createStyles(editing, replicating)

  const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(medicamentoModel)

  const buildItemTitle = (): string => {
    return !!medicamentoModel.registroManual
      ? `${medicamentoModel.principioAtivoText} ${medicamentoModel.concentracaoText ?? ''}`
      : `${medicamentoModel.principioAtivoCombo.medicamento.principioAtivo} ${
          medicamentoModel.principioAtivoCombo.medicamento.concentracao ?? ''
        }`
  }

  const buildUnidadeFornecimento = (): string =>
    !!medicamentoModel.registroManual
      ? medicamentoModel.unidadeFornecimento
      : medicamentoModel.principioAtivoCombo.medicamento.unidadeFornecimento

  const isAlergico = useMemo(
    () =>
      principiosAtivosAlergias?.some(
        (principio) => principio.id === medicamentoModel.principioAtivoCombo?.principioAtivo?.id
      ),
    [medicamentoModel.principioAtivoCombo, principiosAtivosAlergias]
  )

  return (
    <>
      <VFlow vSpacing={0} style={styles.container}>
        <HFlow justifyContent='space-between'>
          <VFlow vSpacing={0} style={styles.textCard}>
            <Text fontWeight='bold'>{buildItemTitle()}</Text>
            <Text>{buildUnidadeFornecimento()}</Text>
          </VFlow>
          <HFlow hSpacing={0} alignItems='center'>
            <Tooltip text='Editar'>
              <Button
                size='small'
                skin='ghost'
                kind='normal'
                onClick={() => {
                  medicamentoModel.isEditFirstLoad = true
                  onEditMedicamento(medicamentoModel)
                  setShowDoseAlert(
                    medicamentoModel.dose && !medicamentoModel.qtDose && !medicamentoModel.unidadeMedidaDose
                  )
                }}
                data-cy='EditarMedicamentoButton'
              >
                <Icon icon='penOutline' />
              </Button>
            </Tooltip>
            <Tooltip text={tooltipText}>
              <Button
                size='small'
                skin='ghost'
                onClick={() => onRemoveMedicamento(medicamentoModel)}
                disabled={isEditable}
                data-cy='ExcluirMedicamentoButton'
              >
                <Icon icon='trashOutline' />
              </Button>
            </Tooltip>
          </HFlow>
        </HFlow>
        {isAlergico && (
          <HFlow hSpacing={0.25} alignItems='center' style={styles.alergicoAlertSection}>
            <Icon color={orange.c40} icon='exclamationTriangleOutline' size={1} />
            <Text color='alert'>Alergia ao princípio ativo</Text>
          </HFlow>
        )}
      </VFlow>
    </>
  )
}

const createStyles = (editing: boolean, replicating: boolean) => {
  return {
    container: css`
      width: 100%;
      max-width: 18rem;
      min-height: 4.5rem;
      border-radius: 2;
      box-sizing: 'border-box';
      padding-right: 0.25rem;
      transition: background-color 0.1s ease, border 0.1s linear;
      -webkit-transition: background-color 0.1s, border 0.1s linear;
      background-color: ${editing
        ? theme.pallete.status.info.background
        : replicating
        ? theme.pallete.status.alert.background
        : theme.pallete.surface.main};
      border: ${editing
        ? '2px solid' + theme.pallete.status.info.main
        : replicating
        ? '1px solid' + theme.pallete.status.alert.main
        : '1px solid' + theme.pallete.gray.c80};
    `,
    textCard: css`
      margin: 1rem 0 1rem 1rem;
      font-size: 13px;
      word-break: break-word;
    `,
    alergicoAlertSection: css`
      margin: 0 0 1rem 1rem;
    `,
  }
}
