import { msg } from '../messages'
import { nonWhitespaces } from './nonWhitespaces'

const REGEX = '^[a-zA-Z0-9áéíóúÁÉÍÓÚçÇâêôÂÊÔõãÕÃäöüÄÖÜàÀ. /&,-]+$'

export function nomeLote(value: string) {
  return nonWhitespaces(value) || validaNome(value)
}

export const nomeFabricante = (value: string) => validaNome(value)

function validaNome(value: string) {
  if (!new RegExp(REGEX).test(value)) {
    return msg('match', value)
  }
  return null
}
