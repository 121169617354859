import { VFlow } from 'bold-ui'
import React from 'react'

import { IdadeOrigemAtendimento, InformacoesAdministrativas } from '../../../model/model-historico'
import { HistoricoAtendimentoObservacaoAlert } from './atendimento-observacao/HistoricoAtendimentoObservacaoAlert'
import HistoricoIdadeOrigemAtendimento from './HistoricoIdadeOrigemAtendimento'
import HistoricoInfoAdminPanel from './HistoricoInfoAdminPanel'

interface HistoricoGapPanelProps {
  idadeOrigemAtendimento?: IdadeOrigemAtendimento
  informacoesAdministrativas: InformacoesAdministrativas
  children: React.ReactNode
  isAtendObservacao?: boolean
  dataInicioCompartilhamento?: LocalDate
  tipoParticipacaoCidadao?: string
}

export default function HistoricoGapPanel({
  idadeOrigemAtendimento,
  children,
  informacoesAdministrativas,
  isAtendObservacao = false,
  dataInicioCompartilhamento,
  tipoParticipacaoCidadao,
}: HistoricoGapPanelProps) {
  return (
    <VFlow>
      <VFlow vSpacing={0.5}>
        {isAtendObservacao && <HistoricoAtendimentoObservacaoAlert />}
        {idadeOrigemAtendimento && (
          <HistoricoIdadeOrigemAtendimento
            idadeOrigemAtendimento={idadeOrigemAtendimento}
            isCompartilhamentoCuidado={informacoesAdministrativas.stCuidadoCompartilhado}
            dataInicioCompartilhamento={dataInicioCompartilhamento}
            tipoParticipacaoCidadao={tipoParticipacaoCidadao}
          />
        )}
      </VFlow>
      <VFlow vSpacing={0}>
        <VFlow>{children}</VFlow>
        <HistoricoInfoAdminPanel content={informacoesAdministrativas} />
      </VFlow>
    </VFlow>
  )
}
