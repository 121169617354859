/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { isBefore } from 'date-fns'
import { SituacaoAgendadoEnum } from 'graphql/types.generated'
import { Fragment, useState } from 'react'
import { CancelarAgendamentoButton } from 'view/agenda/components/acoes-agendamento/buttons/CancelarAgendamentoButton'
import { ImprimirAgendamentoButton } from 'view/agenda/components/acoes-agendamento/buttons/ImprimirAgendamentoButton'
import { getStatusAcoesConsulta } from 'view/agenda/components/acoes-agendamento/getStatusAcoesConsulta'
import { getStatusAcoesConsultaAD } from 'view/agenda/components/acoes-agendamento/getStatusAcoesConsultaAD'
import JustificativaCancelarAgendamentoModal from 'view/agenda/components/acoes-agendamento/JustificativaCancelarAgendamentoModal'
import MenuAcoesAgendamento from 'view/agenda/components/acoes-agendamento/MenuAcoesAgendamento'
import { AcaoAgendamentoEnum } from 'view/agenda/components/acoes-agendamento/model-acoesAgendamento'
import { excluirAgendamentoTooltip } from 'view/agenda/components/acoes-agendamento/utils'
import { AgendamentoFooter, AgendamentoFooterProps } from 'view/agenda/components/AgendamentoFooter'
import { SituacaoAgendamentoBar } from 'view/agenda/components/SituacaoAgendamentoBar'

import { AgendamentoCidadaoModel, AgendamentoCidadaoPermissions, LotacaoAgendadaModel } from '../model'
import { AgendamentoCidadaoHeader } from './components/AgendamentoCidadaoHeader'
import { AgendamentoCidadaoLotacaoAgendada } from './components/AgendamentoCidadaoLotacaoAgendada'
import { AgendamentoCompartilhadoCidadaoProfissionaisAgendados } from './components/AgendamentoCompartilhadoCidadaoProfissionaisAgendados'
import { convertAgendamentoCidadao } from './converter-agendamentoCidadao'
import { AgendamentoCidadaoStatus } from './model'

interface AgendamentosCidadaoListingItemProps extends Pick<AgendamentoFooterProps, 'isAgendaOnlineAtiva'> {
  agendamento: AgendamentoCidadaoModel
  permissions: AgendamentoCidadaoPermissions
  profissionalId: ID
  equipeId: ID
  ubsId: ID
  serverTime: Date
  onCancelar?: () => void
}

export function AgendamentosCidadaoListingItem(props: AgendamentosCidadaoListingItemProps) {
  const {
    agendamento,
    permissions: { podeCancelarTodasEquipes, podeCancelarAgendamento },
    profissionalId,
    equipeId,
    ubsId,
    serverTime,
    isAgendaOnlineAtiva,
    onCancelar,
  } = props
  const theme = useTheme()

  const [isModalJustificativaOpen, setModalJustificativaOpen] = useState(false)
  const styles = createStyles(theme)

  const isPassado = isBefore(agendamento.horarioInicial, serverTime)
  const { isMesmaUbs, isOutroProfissionalDeOutraEquipe } = getStatusAgendamentoCidadao(
    agendamento.lotacaoAgendada,
    profissionalId,
    equipeId,
    ubsId
  )
  const isAtencaoDomiciliar = agendamento.isAtencaoDomiciliar
  const showCancelar = agendamento.situacao === SituacaoAgendadoEnum.AGENDADO && podeCancelarAgendamento && isMesmaUbs
  const isCancelarDisabled =
    isPassado || isAtencaoDomiciliar || (isOutroProfissionalDeOutraEquipe && !podeCancelarTodasEquipes)

  const agendaEvent = convertAgendamentoCidadao(agendamento)

  const statusAcoes = isAtencaoDomiciliar
    ? getStatusAcoesConsultaAD({ dataConsulta: agendaEvent.start, dataAtual: serverTime })
    : getStatusAcoesConsulta({
        situacao: agendaEvent.situacao,
        situacaoAtendimento: agendaEvent.atendimento?.situacao,
        possuiAtendimentoPrevio: agendaEvent.atendimento?.possuiAtendimentosProfissionais,
        isForaUbs: agendaEvent.isForaUbs,
        dataAtual: serverTime,
        dataConsulta: new Date(agendaEvent.start),
      })

  const statusImprimirAgendamento = statusAcoes[AcaoAgendamentoEnum.IMPRIMIR_AGENDAMENTO]

  return (
    <Fragment>
      <HFlow hSpacing={0} style={styles.wrapper} justifyContent='space-between'>
        <HFlow hSpacing={0}>
          <SituacaoAgendamentoBar situacao={agendamento.situacao} />
          <VFlow vSpacing={0.5} style={styles.item}>
            <AgendamentoCidadaoHeader agendamento={agendamento} />
            {!agendamento.profissionalAnfitriao ? (
              <AgendamentoCidadaoLotacaoAgendada lotacaoAgendada={agendamento.lotacaoAgendada} />
            ) : (
              <AgendamentoCompartilhadoCidadaoProfissionaisAgendados
                profissionalAnfitriao={agendamento.profissionalAnfitriao}
                profissionalParticipante={agendamento.profissionalParticipante}
                nomeUnidadeSaude={agendamento.lotacaoAgendada?.unidadeSaude?.nome}
              />
            )}

            {agendamento.observacao && (
              <HLabel title='Observações:'>
                <Text fontStyle='italic'>{agendamento.observacao}</Text>
              </HLabel>
            )}
            <AgendamentoFooter
              color='secondary'
              responsavelCriador={agendamento.responsavelCriador}
              dataCriacao={agendamento.dataCriacao}
              origem={agendamento.origem}
              situacaoSincronizacao={agendamento.situacaoSincronizacao}
              isAgendaOnlineAtiva={isAgendaOnlineAtiva}
              justificativaCancelamento={agendamento.justificativaCancelamento}
            />
          </VFlow>
        </HFlow>
        <MenuAcoesAgendamento>
          <ImprimirAgendamentoButton
            event={agendaEvent}
            disabled={statusImprimirAgendamento.disabled}
            tooltip={statusImprimirAgendamento.tooltip}
            isDropdown
          />
          {showCancelar && (
            <CancelarAgendamentoButton
              onAction={onCancelar}
              event={agendaEvent}
              openModalCancelarAgendamento={() => setModalJustificativaOpen(true)}
              disabled={isCancelarDisabled}
              tooltip={excluirAgendamentoTooltip(isPassado, isOutroProfissionalDeOutraEquipe, isAtencaoDomiciliar)}
              isDropdown
            />
          )}
        </MenuAcoesAgendamento>
      </HFlow>
      <JustificativaCancelarAgendamentoModal
        onAction={onCancelar}
        event={agendaEvent}
        open={isModalJustificativaOpen}
        setOpen={setModalJustificativaOpen}
      />
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  wrapper: css`
    background-color: ${theme.pallete.surface.main};
    border: 1px ${theme.pallete.divider} solid;
    border-radius: 2px;
  `,
  item: css`
    padding: 0.5rem 1rem;
  `,
})

function getStatusAgendamentoCidadao(
  lotacaoAgendada: LotacaoAgendadaModel,
  profissionalId: ID,
  equipeId: ID,
  ubsId: ID
): AgendamentoCidadaoStatus {
  const isMesmaUbs = lotacaoAgendada.unidadeSaude.id === ubsId
  const isOutroProfissionalDeOutraEquipe =
    lotacaoAgendada.profissional.id !== profissionalId &&
    lotacaoAgendada.equipe !== null &&
    lotacaoAgendada.equipe.id !== equipeId
  return { isMesmaUbs, isOutroProfissionalDeOutraEquipe }
}
