import { Theme, useStyles } from 'bold-ui'
import { useClickOutside } from 'bold-ui/lib/hooks'
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import { PopperProps, usePopper } from 'react-popper'

export interface UsePopperButtonOptions<Modifiers = any> extends Omit<PopperProps<Modifiers>, 'children'> {
  zIndex?: number
  onClose?(): void
  onOpen?(): void
  closeOnBackdropClick?: boolean
  focusOnOpen?: boolean
}

export function usePopperButton(props: UsePopperButtonOptions) {
  const {
    strategy,
    zIndex,
    onClose,
    onOpen,
    referenceElement,
    placement = 'bottom',
    closeOnBackdropClick = true,
    focusOnOpen = true,
  } = props

  const rootRef = useRef<HTMLDivElement>()
  const [buttonRef, setButtonRef] = useState<HTMLElement>()
  const [popperRef, setPopperRef] = useState<HTMLDivElement>()

  const [isOpen, setOpen] = useState(false)

  const open = useCallback(() => setOpen(true), [])

  const close = useCallback(() => {
    onClose?.()
    setOpen(false)
  }, [onClose])

  const toggle = useCallback(() => {
    if (!isOpen) {
      onOpen?.()
    }

    setOpen((state) => !state)
  }, [isOpen, onOpen])

  const { css, classes } = useStyles(createStyles, { isOpen, zIndex })

  useClickOutside(rootRef, () => {
    isOpen && closeOnBackdropClick && close()
  })

  useEffect(() => {
    // Attach "Escape" to close popper
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close()
        buttonRef?.focus()
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown)
    } else {
      document.removeEventListener('keydown', handleKeyDown)
    }
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [isOpen, close, buttonRef])

  useEffect(() => {
    if (isOpen && popperRef && focusOnOpen) {
      setTimeout(() => {
        const e: HTMLElement = popperRef.querySelector(
          'a:not([disabled]), button:not([disabled]), input:not([disabled]), textarea:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])'
        )
        e && e.focus()
      })
    }
  }, [focusOnOpen, isOpen, popperRef])

  const {
    styles: { popper: popperStyle },
  } = usePopper(referenceElement ?? buttonRef, popperRef, { placement, strategy })

  return {
    rootRef,
    popperContainerRef: setPopperRef,
    buttonRef: setButtonRef,
    open,
    close,
    toggle,
    isOpen,
    popperContainerStyle: css(classes.popper, popperStyle as any),
  }
}

const createStyles = (theme: Theme, { isOpen, zIndex }) => ({
  popper: {
    zIndex: zIndex ?? theme.zIndex.popper,
    visibility: isOpen ? 'visible' : 'hidden',
    transition: 'opacity .2s ease',
    opacity: isOpen ? 1 : 0,
  } as CSSProperties,
})
