import { PosicaoCampoRelatorioGerencialEnum } from 'graphql/types.generated'
import { isUndefinedOrNull } from 'util/checks'
import { createValidator, empty, maxLength, required } from 'util/validation'

import { visibilidadeModeloPersonalizadoEnumToIsPublico } from '../converter-modeloPersonalizadoRelatorioGerencial'
import { ModeloPersonalizadoRelatorioGerencialFormModel } from './model-modeloPersonalizadoRelatorioGerencialForm'

export const modeloPersonalizadoRelatorioGerencialFormValidator = (
  hasFiltroCiapCid: boolean,
  isModeloSalvoPublico?: boolean
) =>
  createValidator<ModeloPersonalizadoRelatorioGerencialFormModel>(
    {
      nome: [required, maxLength(255)],
      aggregatorModal: [required],
      opcaoSelecionadaModal: [required],
      unidadePeriodoModal: [required],
      modulo: [required],
    },
    (value, errors) => {
      const hasNoColunaOrLinha =
        value.campos?.isEmpty() ||
        !value.campos?.some(
          ({ posicao }) =>
            posicao === PosicaoCampoRelatorioGerencialEnum.COLUNA ||
            posicao === PosicaoCampoRelatorioGerencialEnum.LINHA
        )

      const hasChangedVisibilidade =
        !isUndefinedOrNull(isModeloSalvoPublico) &&
        isModeloSalvoPublico !== visibilidadeModeloPersonalizadoEnumToIsPublico(value.visibilidade)

      if (!hasFiltroCiapCid) {
        errors.ciapsCids = empty(value.ciapsCids)
        errors.gruposCondicoes = empty(value.gruposCondicoes)
      }

      if (hasNoColunaOrLinha) {
        errors.campos = 'Obrigatório adicionar pelo menos uma coluna/linha.'
      }

      if (hasChangedVisibilidade) {
        errors.visibilidade = 'Não é possível editar a visibilidade.'
      }

      return errors
    }
  )
