import { CboSelectModel, ProfissionalSelectModel, UnidadeSaudeSelectModel } from 'components/form'
import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import { toDate } from 'date-fns'
import { startOfDay } from 'date-fns'
import { ClassificacaoPrioridadeCuidadoEnum, CompartilhamentoCuidadoPlanoDto, DateRange } from 'graphql/types.generated'
import { isDateInDateRange } from 'hooks/filter/useFilter'
import { compact } from 'lodash'
import { emptyArray } from 'util/array'

import {
  CuidadoCompartilhadoPlanoModel,
  TipoCompartilhamentoCuidadoPlanoEnum,
} from '../cuidado-compartilhado/model-cuidadocompartilhado'
import { CompartilhamentoCuidadoPlanoModel } from './model-compartilhamentocuidado'

export const joinCompartilhamentosCuidado = (
  compartilhamentosAtendimentoAtual: CuidadoCompartilhadoPlanoModel[],
  compartilhamentosHistorico: CompartilhamentoCuidadoPlanoDto[],
  atendIniciadoEm: Date,
  atendimentoProfissionalId: ID
): CompartilhamentoCuidadoPlanoModel[] => {
  return [
    ...(compartilhamentosAtendimentoAtual || emptyArray)
      .map((valorAtual) => convertValorAtualToJoined(valorAtual, atendIniciadoEm, atendimentoProfissionalId))
      .sort((a, b) => b.iniciadoEm.valueOf() - a.iniciadoEm.valueOf()),
    ...(compartilhamentosHistorico?.map(convertValorHistoricoToJoined) ?? emptyArray),
  ]
}

const convertValorAtualToJoined = (
  valorAtual: CuidadoCompartilhadoPlanoModel,
  iniciadoEm: Date,
  atendimentoProfissionalId: ID
): CompartilhamentoCuidadoPlanoModel => ({
  cacheId: valorAtual._id,
  cbo: valorAtual.cbo.cbo,
  unidadeSaude: valorAtual.unidadeSaude,
  lotacaoSolicitante: valorAtual.lotacaoSolicitante,
  lotacaoExecutante: valorAtual.lotacao,
  prioridade: valorAtual.prioridade,
  iniciadoEm: toDate(valorAtual.lastUpdate) ?? iniciadoEm,
  discussao: valorAtual.discussao,
  problemasECondicoes: valorAtual.problemasECondicoes,
  cidadaoAceitaAtendTic: valorAtual.cidadaoAceitaAtendTic,
  isRegistradoAgora: true,
  tipo: valorAtual.tipo,
  atendimentoProfissional: valorAtual.atendimentoProfissional,
})

const convertValorHistoricoToJoined = (
  valorHistorico: CompartilhamentoCuidadoPlanoDto
): CompartilhamentoCuidadoPlanoModel => ({
  dbId: valorHistorico.id,
  cbo: valorHistorico.cbo,
  unidadeSaude: valorHistorico.unidadeSaude,
  lotacaoSolicitante: valorHistorico.lotacaoSolicitante,
  lotacaoExecutante: valorHistorico.lotacaoExecutanteAtual,
  prioridade: valorHistorico.classificacaoPrioridadeAtual,
  iniciadoEm: valorHistorico.dataInicio,
  discussao: valorHistorico.discussao,
  problemasECondicoes: { ciap: valorHistorico.ciap, cid10: valorHistorico.cid10 },
  cidadaoAceitaAtendTic: valorHistorico.cidadaoAceitaAtendTic ? SimNaoEnum.SIM : SimNaoEnum.NAO,
  isRegistradoAgora: false,
  tipo: valorHistorico.isEncaminhamento
    ? TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO
    : TipoCompartilhamentoCuidadoPlanoEnum.CUIDADO_COMPARTILHADO,
  atendimentoProfissional: valorHistorico.atendimentoProfissional,
})

export const getProfissionaisCompartilhamentoCuidado = (
  compartilhamentosAtuais: CuidadoCompartilhadoPlanoModel[],
  compartilhamentosHistorico: CompartilhamentoCuidadoPlanoDto[]
) => {
  const profissionaisExecutantes = compact(
    (Array.isArray(compartilhamentosAtuais)
      ? compartilhamentosAtuais.map((item) => item.lotacao?.profissional)
      : []
    ).concat(compartilhamentosHistorico?.map((item) => item.lotacaoExecutanteAtual?.profissional))
  )
    .removeDuplicate((item) => item.id)
    .sort((a, b) => a.nome.localeCompare(b.nome))

  const profissionaisSolicitantes = compact(
    (Array.isArray(compartilhamentosAtuais)
      ? compartilhamentosAtuais.map((item) => item.lotacaoSolicitante?.profissional)
      : []
    ).concat(compartilhamentosHistorico?.map((item) => item.lotacaoSolicitante?.profissional))
  )
    .removeDuplicate((item) => item.id)
    .sort((a, b) => a.nome.localeCompare(b.nome))
  return {
    profissionaisSolicitantes,
    profissionaisExecutantes,
  }
}

export const compartilhamentoCuidadoFilterByPrioridade = (
  prioridades: readonly ClassificacaoPrioridadeCuidadoEnum[]
) => (row: CompartilhamentoCuidadoPlanoModel) => {
  return prioridades?.length > 0 ? prioridades.includes(row.prioridade) : true
}

export const compartilhamentoCuidadoFilterBySolicitante = (solicitante: ProfissionalSelectModel) => (
  row: CompartilhamentoCuidadoPlanoModel
) => {
  return solicitante ? solicitante.id === row.lotacaoSolicitante.profissional.id : true
}

export const compartilhamentoCuidadoFilterByExecutante = (executante: ProfissionalSelectModel) => (
  row: CompartilhamentoCuidadoPlanoModel
) => {
  return executante ? executante.id === row.lotacaoExecutante?.profissional?.id : true
}

export const compartilhamentoCuidadoFilterByUnidadeSaude = (unidadeSaude: UnidadeSaudeSelectModel) => (
  row: CompartilhamentoCuidadoPlanoModel
) => {
  return unidadeSaude ? unidadeSaude.id === row.unidadeSaude?.id : true
}

export const compartilhamentoCuidadoFilterByPeriodo = (periodo: DateRange, iniciadoEm: Date) => () => {
  return isDateInDateRange(startOfDay(iniciadoEm), periodo)
}

export const compartilhamentoCuidadoFilterByCbo = (cbo: CboSelectModel) => (row: CompartilhamentoCuidadoPlanoModel) => {
  return cbo ? cbo.id === row.cbo.id : true
}

export const compartilhamentoCuidadoFilterByTiposCompartilhamento = (
  tipoCompartilhamento: TipoCompartilhamentoCuidadoPlanoEnum[]
) => (row: CompartilhamentoCuidadoPlanoModel) => {
  return tipoCompartilhamento?.isNotEmpty() ? tipoCompartilhamento.includes(row.tipo) : true
}
