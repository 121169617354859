import { Cell, Grid } from 'bold-ui'
import { Form, FormDebouncedValueSpy, SwitchField, TextField } from 'components/form'
import { FormState } from 'final-form'
import React from 'react'
import { metaPath } from 'util/metaPath'

import { EstabelecimentosReceptoresTableFieldFilterModel } from './model-vincularEstabelecimentoReferencia'

interface EstabelecimentosReceptoresTableFieldFilterProps {
  initialValues?: EstabelecimentosReceptoresTableFieldFilterModel
  onChange(values: EstabelecimentosReceptoresTableFieldFilterModel): void
}

const meta = metaPath<EstabelecimentosReceptoresTableFieldFilterModel>()

export function EstabelecimentosReceptoresTableFieldFilter(props: EstabelecimentosReceptoresTableFieldFilterProps) {
  const { initialValues, onChange } = props

  const handleChange = (formState: FormState<EstabelecimentosReceptoresTableFieldFilterModel>) => {
    onChange(formState.values)
  }

  const renderForm = () => (
    <>
      <FormDebouncedValueSpy onChange={handleChange} />

      <Grid alignItems='center' gap={1}>
        <Cell size={4}>
          <TextField name={meta.query} icon='zoomOutline' placeholder='Pesquise pelo nome ou CNES' />
        </Cell>
        <Cell>
          <SwitchField
            name={meta.exibirPrimeiroSemVinculo.alias}
            label='Exibir primeiro as unidades não selecionadas'
          />
        </Cell>
      </Grid>
    </>
  )

  return (
    <Form<EstabelecimentosReceptoresTableFieldFilterModel>
      onSubmit={onChange}
      render={renderForm}
      initialValues={initialValues}
    />
  )
}
