import { useAlert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { useErrorHandler } from 'components/error'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { confirm } from 'components/modals/confirm'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { FormApi, FormState } from 'final-form'
import {
  useExcluirVinculacaoServicoMutation,
  useSalvarVinculacaoServicoMutation,
  useVinculacaoServicoQuery,
} from 'graphql/hooks.generated'
import { UnidadeSaude } from 'graphql/types.generated'
import React from 'react'
import { Redirect, useHistory, useParams, useRouteMatch } from 'react-router'
import { isUndefinedOrNull } from 'util/checks'

import { SERVICOS_REFERENCIA_PATH } from '../../ServicosReferenciaView'
import { VincularEstabelecimentoReferenciaFormModel } from './model-vincularEstabelecimentoReferencia'
import { VincularEstabelecimentoReferenciaForm } from './VincularEstabelecimentoReferenciaForm'

interface UrlParams {
  estabelecimentoReferenciaId: string
}

export function VincularEstabelecimentoReferenciaView() {
  const match = useRouteMatch()
  const history = useHistory()
  const params = useParams<UrlParams>()
  const alert = useAlert()
  const handleRejection = useErrorHandler()
  const [salvarVinculacaoServicoMutation] = useSalvarVinculacaoServicoMutation()
  const isEdit = !isUndefinedOrNull(params.estabelecimentoReferenciaId)

  const [excluirVinculacaoServico] = useExcluirVinculacaoServicoMutation({
    onCompleted: () => {
      alert('success', 'Vínculo excluído com sucesso.')
      history.push(servicoReferenciaTabPath)
    },
  })

  const onSubmit = (values: VincularEstabelecimentoReferenciaFormModel, formApi: FormApi) => {
    const vinculacoesId = values.vinculacoes.map((receptor) => receptor.id)
    const referenciaId = values.referencia?.id
    if (vinculacoesId.isEmpty() && (isEdit || values.referencia?.hasVinculacaoServico)) {
      confirm({
        title: 'Deseja excluir o vínculo?',
        body: `Serão excluídos todos os vínculos entre o estabelecimento ${values.referencia.nome} e as unidades básicas de saúde do município`,
        type: 'danger',
        confirmLabel: 'Excluir',
        onConfirm: () =>
          excluirVinculacaoServico({
            variables: { referenciaId },
          })
            .then((ret) => {
              resetFields(formApi)
              return ret
            })
            .catch(handleRejection),
      })()
    } else {
      return salvarVinculacaoServicoMutation({
        variables: {
          input: {
            referenciaId,
            vinculacoesId,
          },
        },
      })
        .then((ret) => {
          resetFields(formApi)
          return ret
        })
        .catch(handleRejection)
    }
  }

  const servicoReferenciaTabPath = getServicoReferenciaTabPath(match.url)

  const onSubmitSucceeded = (form: FormState<VincularEstabelecimentoReferenciaFormModel>) => {
    const values = form.values
    const vinculacoesId = values.vinculacoes.map((receptor) => receptor.id)
    if (!(vinculacoesId.isEmpty() && (isEdit || values.referencia?.hasVinculacaoServico))) {
      history.push(servicoReferenciaTabPath)
      alert('success', 'Vínculo realizado com sucesso')
    }
  }

  const onCancel = () => {
    history.push(servicoReferenciaTabPath)
  }

  const {
    data: { vinculacaoServico },
    loading,
  } = useVinculacaoServicoQuery({
    variables: { referenciaId: params.estabelecimentoReferenciaId },
    skip: !isEdit,
  })

  const initialValues: VincularEstabelecimentoReferenciaFormModel = isEdit
    ? {
        referencia: vinculacaoServico?.referencia as UnidadeSaude,
        vinculacoes: vinculacaoServico?.vinculacoes as UnidadeSaude[],
      }
    : {
        referencia: null,
        vinculacoes: [],
      }

  return isEdit && !loading && isUndefinedOrNull(vinculacaoServico) ? (
    <Redirect to='/404' />
  ) : (
    <>
      <Breadcrumb title='Serviços de referência' to={servicoReferenciaTabPath} />
      <Breadcrumb title='Vinculação de referência' />
      <PageHeaderSection title='Vinculação de referência' />
      <PageContent type='filled'>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <VincularEstabelecimentoReferenciaForm
            initialValues={initialValues}
            isEdit={isEdit}
            onSubmit={onSubmit}
            onSubmitSucceeded={onSubmitSucceeded}
            onCancel={onCancel}
          />
        )}
      </PageContent>
    </>
  )
}

const getServicoReferenciaTabPath = (url: string) =>
  `${url.split(SERVICOS_REFERENCIA_PATH)[0]}${SERVICOS_REFERENCIA_PATH}`

const resetFields = (formApi: FormApi) => {
  formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
  setTimeout(formApi.reset)
}
