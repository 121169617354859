import { FormControl } from 'bold-ui'
import { SwitchField, SwitchFieldProps } from 'components/form'
import { resolveName } from 'components/form/final-form/hooks/useField'
import React, { useEffect } from 'react'
import { useField } from 'react-final-form'
import { isUndefinedOrNull } from 'util/checks'

export interface NecessidadesEspeciaisFieldProps extends Omit<SwitchFieldProps, 'label'> {
  label: string
}

export function NecessidadesEspeciaisField(props: NecessidadesEspeciaisFieldProps) {
  const { label, name, initialValue, ...rest } = props

  const {
    input: { value: necessidadesEspeciais, onChange },
  } = useField(resolveName(name), { subscription: { value: true } })

  useEffect(() => {
    if (isUndefinedOrNull(necessidadesEspeciais) || necessidadesEspeciais === '') {
      onChange(false)
    }
  }, [necessidadesEspeciais, onChange])

  return (
    <FormControl label={label}>
      <SwitchField name={name} label={necessidadesEspeciais ? 'Sim' : 'Não'} initialValue={false} {...rest} />
    </FormControl>
  )
}
