import { Text } from 'bold-ui'
import React from 'react'

import { LotacaoAgendaModel } from '../model-agenda'

interface LotacaoCadastroAgendamentoTextProps {
  lotacao: Pick<LotacaoAgendaModel, 'profissional' | 'cbo' | 'equipe'>
}

export function LotacaoCadastroAgendamentoText({ lotacao }: LotacaoCadastroAgendamentoTextProps) {
  return (
    <Text component='p' variant='h3'>
      {lotacao.profissional.nome.titleCase()}{' '}
      <Text variant='h3' fontWeight='normal'>
        | {lotacao.cbo?.nome.capitalize()}
        {lotacao.equipe ? ` | ${lotacao.equipe.nome}` : ''}
      </Text>
    </Text>
  )
}
