import {
  HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery,
  HistoricoCuidadoCompartilhadoEvolucaoHeaderFragment,
  HistoricoCuidadoCompartilhadoHeaderFragment,
} from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { HistoricoDetalhesModel, HistoricoPanelProps } from 'view/atendimentos/detail/historico/model/model-historico'

import { HistoricoCuidadoCompartilhadoDwPanel } from './HistoricoCuidadoCompartilhadoDwPanel'
import { HistoricoCuidadoCompartilhadoPecPanel } from './HistoricoCuidadoCompartilhadoPecPanel'

interface HistoricoCuidadoCompartilhadoPanelProps
  extends Pick<HistoricoPanelProps, 'uuidRegistro' | 'cpfOuCnsCidadao' | 'idadeOrigemAtendimento'> {
  historicoDetalhes: HistoricoDetalhesModel
  cuidadoCompartilhado: HistoricoCuidadoCompartilhadoHeaderFragment
  cuidadoCompartilhadoEvolucao: HistoricoCuidadoCompartilhadoEvolucaoHeaderFragment
}

export type CuidadoCompartilhadoEvolucaoDetalhes = HistoricoCuidadoCompartilhadoEvolucaoDetalhesQuery['cuidadoCompartilhadoEvolucao']

export const HistoricoCuidadoCompartilhadoPanel = (props: HistoricoCuidadoCompartilhadoPanelProps) => {
  const {
    cuidadoCompartilhado,
    cuidadoCompartilhadoEvolucao,
    historicoDetalhes: {
      classificacaoPrioridade,
      nomeExecutante,
      cboExecutante,
      nomeUbsExecutante,
      cnsExecutante,
      nomeSolicitante,
      cboSolicitante,
      nomeUbsSolicitante,
      cnsSolicitante,
    },
    idadeOrigemAtendimento,
    uuidRegistro,
    cpfOuCnsCidadao,
  } = props

  const showClassificacaoPrioridade = isUndefinedOrNull(classificacaoPrioridade)
  const showSolicitante =
    isUndefinedOrNull(nomeSolicitante) &&
    isUndefinedOrNull(cboSolicitante) &&
    isUndefinedOrNull(nomeUbsSolicitante) &&
    isUndefinedOrNull(cnsSolicitante)
  const showExecutante =
    isUndefinedOrNull(nomeExecutante) &&
    isUndefinedOrNull(cboExecutante) &&
    isUndefinedOrNull(nomeUbsExecutante) &&
    isUndefinedOrNull(cnsExecutante)

  const selectContent = useMemo(() => {
    return isUndefinedOrNull(uuidRegistro) ? (
      <HistoricoCuidadoCompartilhadoPecPanel
        cuidadoCompartilhadoHeader={cuidadoCompartilhado}
        cuidadoCompartilhadoEvolucaoHeader={cuidadoCompartilhadoEvolucao}
        idadeOrigemAtendimento={idadeOrigemAtendimento}
      />
    ) : (
      <HistoricoCuidadoCompartilhadoDwPanel
        uuidRegistro={uuidRegistro}
        cpfOuCnsCidadao={cpfOuCnsCidadao}
        idadeOrigemAtendimento={idadeOrigemAtendimento}
        showClassificacaoPrioridade={showClassificacaoPrioridade}
        showSolicitante={showSolicitante}
        showExecutante={showExecutante}
      />
    )
  }, [
    cpfOuCnsCidadao,
    cuidadoCompartilhado,
    cuidadoCompartilhadoEvolucao,
    idadeOrigemAtendimento,
    showClassificacaoPrioridade,
    showExecutante,
    showSolicitante,
    uuidRegistro,
  ])

  return selectContent
}
