import { Icon } from 'bold-ui'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { useCallback } from 'react'
import { useAgendaAuthorization } from 'view/agenda/authorization/useAgendaAuthorization'
import { EventoAgendaLotacao, TipoAgendamento } from 'view/agenda/model-agenda'

import MenuAcoesAgendamentoButton from '../MenuAcoesAgendamentoButton'
import { AcaoAgendamentoButtonProps } from '../model-acoesAgendamento'

interface EditarAgendamentoButtonProps extends Omit<AcaoAgendamentoButtonProps, 'event'> {
  event: Pick<EventoAgendaLotacao, 'id' | 'tipo' | 'isAtencaoDomiciliar' | 'lotacao' | 'nomeProfissionalParticipante'>
  eventoElement?: Element
  buttonRef?: React.Ref<HTMLButtonElement>
  onClick?: () => void
}

export function EditarAgendamentoButton(props: EditarAgendamentoButtonProps) {
  const { event, disabled, tooltip, isDropdown = false, buttonRef, onClick } = props

  const { hasAuthorizationAgendar } = useAgendaAuthorization(event.isAtencaoDomiciliar)

  const { analytics } = useFirebase()

  const tipo = !!event.nomeProfissionalParticipante ? TipoAgendamento.CONSULTA_COMPARTILHADA : event.tipo

  const handleClickEditarAgendamento = useCallback(() => {
    onClick?.()
    switch (tipo) {
      case TipoAgendamento.CONSULTA:
        analytics.logEvent('edicao_agendamento_consulta')
        break
      case TipoAgendamento.RESERVA:
        analytics.logEvent('edicao_agendamento_reserva')
        break
      case TipoAgendamento.CONSULTA_COMPARTILHADA:
        analytics.logEvent('edicao_agendamento_entre_prof')
        break
    }
  }, [analytics, tipo, onClick])

  return (
    hasAuthorizationAgendar(event.lotacao) && (
      <MenuAcoesAgendamentoButton
        text='Editar'
        tooltip={tooltip}
        disabled={disabled}
        isDropdown={isDropdown}
        onClick={handleClickEditarAgendamento}
        icon={<Icon icon='penOutline' />}
        buttonRef={buttonRef}
      />
    )
  )
}
