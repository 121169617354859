import { Heading, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useEditarAgendadoQuery } from 'graphql/hooks.generated'
import React, { useMemo } from 'react'
import { convertToAgendamentoEdicaoModel } from 'view/agenda/converter-agenda'
import { EventoAgendaLotacao, TipoAgendamento } from 'view/agenda/model-agenda'

import { AgendamentoBox } from '../components/AgendamentoBox'
import { LotacaoCadastroAgendamentoText } from '../components/LotacaoCadastroAgendamentoText'
import { AgendamentoConsultaCompartilhadaForm } from './AgendamentoConsultaCompartilhadaForm'
import { AgendamentoConsultaForm } from './AgendamentoConsultaForm'
import { AgendamentoReservaForm } from './AgendamentoReservaForm'

export interface AgendamentoEdicaoViewProps {
  event: Pick<EventoAgendaLotacao, 'id' | 'tipo' | 'isAtencaoDomiciliar' | 'lotacao' | 'nomeProfissionalParticipante'>
  isAtencaoDomiciliar?: boolean
  onClose?(): void
}

export const AgendamentoEdicaoView = (props: AgendamentoEdicaoViewProps) => {
  const { event, onClose, isAtencaoDomiciliar = false } = props

  const {
    data: { agendado },
    loading,
  } = useEditarAgendadoQuery({
    variables: { id: event.id },
  })

  const tipo = !!event.nomeProfissionalParticipante ? TipoAgendamento.CONSULTA_COMPARTILHADA : event.tipo
  const agendamento = useMemo(() => convertToAgendamentoEdicaoModel(agendado, tipo), [agendado, tipo])

  const agendamentoDetails = useMemo(() => {
    if (!agendamento) return {}

    const lotacao = agendamento.lotacao
    const isAgendamentoConsultaCompartilhada = agendamento.tipo === TipoAgendamento.CONSULTA_COMPARTILHADA
    const isAgendamentoConsulta = agendamento.tipo === TipoAgendamento.CONSULTA
    const isAgendamentoReserva = agendamento.tipo === TipoAgendamento.RESERVA
    const titulo = getTituloEdicaoAgendamento(agendamento.tipo)

    return {
      lotacao,
      isAgendamentoConsultaCompartilhada,
      isAgendamentoConsulta,
      isAgendamentoReserva,
      titulo,
    }
  }, [agendamento])

  return (
    <AgendamentoBox>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <VFlow>
          <Heading level={1}>{agendamentoDetails.titulo}</Heading>
          <LotacaoCadastroAgendamentoText lotacao={agendamentoDetails.lotacao} />
          {agendamentoDetails.isAgendamentoReserva && (
            <AgendamentoReservaForm
              agendamento={agendamento}
              isAtencaoDomiciliar={isAtencaoDomiciliar}
              onClose={onClose}
            />
          )}
          {agendamentoDetails.isAgendamentoConsulta && (
            <AgendamentoConsultaForm
              agendamento={agendamento}
              isAtencaoDomiciliar={isAtencaoDomiciliar}
              onClose={onClose}
            />
          )}
          {agendamentoDetails.isAgendamentoConsultaCompartilhada && (
            <AgendamentoConsultaCompartilhadaForm agendamento={agendamento} onClose={onClose} />
          )}
        </VFlow>
      )}
    </AgendamentoBox>
  )
}
function getTituloEdicaoAgendamento(tipo: TipoAgendamento) {
  switch (tipo) {
    case TipoAgendamento.RESERVA:
      return 'Editar reserva'
    case TipoAgendamento.CONSULTA:
      return 'Editar consulta'
    case TipoAgendamento.CONSULTA_COMPARTILHADA:
      return 'Editar agendamento entre profissionais'

    default:
      throw new Error('Tipo de agendamento não previsto')
  }
}
