import { RadioGroupField, RadioGroupFieldProps, RadioOption } from 'components/form'
import { OpcaoTipoPerguntaEnum } from 'graphql/types.generated'
import React from 'react'

import { VisibilidadeModeloPersonalizadoEnum } from './model-modeloPersonalizadoRelatorioGerencialForm'

interface VisibilidadeModeloPersonalizadoRadioGroupFieldProps
  extends Omit<RadioGroupFieldProps<OpcaoTipoPerguntaEnum>, 'options'> {}

export function VisibilidadeModeloPersonalizadoRadioGroupField(
  props: VisibilidadeModeloPersonalizadoRadioGroupFieldProps
) {
  return <RadioGroupField options={options} {...props} />
}

const options: RadioOption<VisibilidadeModeloPersonalizadoEnum>[] = [
  { value: VisibilidadeModeloPersonalizadoEnum.PRIVADO, label: 'Privado' },
  { value: VisibilidadeModeloPersonalizadoEnum.PUBLICO, label: 'Público' },
]
