import { VFlow } from 'bold-ui'
import { green } from 'bold-ui/lib/styles/colors'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import CheckPermission from 'components/auth/CheckPermission'
import { useFlags } from 'config/useFlagsContext'
import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import React from 'react'
import { Accordion, AccordionItem } from 'react-accessible-accordion'
import { TipoAtendimentoFooter } from 'view/atendimentos/model'

import AtestadosTable from '../../soap/plano/atestados/list/AtestadosTable'
import { CompartilhamentoCuidadoTable } from '../../soap/plano/compartilhamento-cuidado/compartilhamento-cuidado-table/CompartilhamentoCuidadoTable'
import { PlanoTabs, PlanoTabsEnum } from '../../soap/plano/components/PlanoTabs'
import { CuidadoCompartilhadoTable } from '../../soap/plano/cuidado-compartilhado/cuidado-compartilhado-table/CuidadoCompartilhadoTable'
import EncaminhamentoExternoTable from '../../soap/plano/encaminhamento-externo/list/EncaminhamentoExternoTable'
import { OrientacoesTable } from '../../soap/plano/orientacoes/list/OrientacoesTable'
import HistoricoPrescricaoTable from '../../soap/plano/prescricao-medicamento/historico-prescricao/HistoricoPrescricaoTable'
import { SoapSection } from '../../soap/SoapSection'
import { grupoCboFolhaRostoOdontologico } from '../acessos'
import { CidadaoFolhaRosto, LotacaoFolhaRosto } from '../model'
import { OdontologicoFolhaRostoView } from './odontologico/OdontologicoFolhaRostoView'
import { SolicitacoesExamesFolhaRosto } from './solicitacoes-exames/SolicitacoesExamesFolhaRosto'

interface PlanoFolhaRostoViewProps {
  prontuarioId: ID
  atendimentoId: ID
  cidadao: CidadaoFolhaRosto
  lotacao: LotacaoFolhaRosto
  dataReferencia: Instant
  tipoAtendimentoProfissional: TipoAtendimentoFooter
}

export const PlanoFolhaRostoView = (props: PlanoFolhaRostoViewProps) => {
  const { prontuarioId, atendimentoId, cidadao, dataReferencia, lotacao, tipoAtendimentoProfissional } = props

  const accordionProps = useAccordionControl({})

  const { PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED } = useFlags()

  return (
    <Accordion allowZeroExpanded preExpanded={['P']}>
      <AccordionItem uuid='P'>
        <SoapSection initial='P' title='Plano' color={green}>
          <VFlow vSpacing={2.5}>
            {tipoAtendimentoProfissional !== TipoAtendimentoProfissional.VACINACAO && (
              <CheckPermission permission={grupoCboFolhaRostoOdontologico}>
                <OdontologicoFolhaRostoView
                  prontuarioId={prontuarioId}
                  cidadaoDataNascimento={cidadao.dataNascimento}
                  dataReferencia={dataReferencia}
                />
              </CheckPermission>
            )}

            <VFlow vSpacing={0.5}>
              <PlanoTabs isFolhaRosto isAtendimentoProcedimentos={false}>
                {(currentTab) => (
                  <>
                    {currentTab === PlanoTabsEnum.PRESCRICAO_MEDICAMENTOS && (
                      <HistoricoPrescricaoTable
                        isAtendimentoProcedimento={false}
                        prontuarioId={prontuarioId}
                        dataReferencia={dataReferencia}
                        readOnly
                      />
                    )}

                    {currentTab === PlanoTabsEnum.ATESTADO && (
                      <AtestadosTable
                        prontuarioId={prontuarioId}
                        atendimentoId={atendimentoId}
                        cidadao={cidadao}
                        lotacao={lotacao}
                        dataReferencia={dataReferencia}
                        readOnly
                      />
                    )}

                    {currentTab === PlanoTabsEnum.EXAMES && (
                      <SolicitacoesExamesFolhaRosto
                        prontuarioId={prontuarioId}
                        atendimentoId={atendimentoId}
                        lotacao={lotacao}
                        cidadaoId={cidadao.id}
                        dataReferencia={dataReferencia}
                      />
                    )}

                    {currentTab === PlanoTabsEnum.ORIENTACOES && (
                      <OrientacoesTable
                        prontuarioId={prontuarioId}
                        atendimentoId={atendimentoId}
                        cidadao={cidadao}
                        lotacao={lotacao}
                        dataReferencia={dataReferencia}
                        readOnly
                        {...accordionProps}
                      />
                    )}

                    {currentTab === PlanoTabsEnum.ENCAMINHAMENTO_EXTERNO && (
                      <EncaminhamentoExternoTable
                        atendimentoId={atendimentoId}
                        prontuarioId={prontuarioId}
                        dataReferencia={dataReferencia}
                        lotacao={lotacao}
                        readOnly
                      />
                    )}

                    {currentTab === PlanoTabsEnum.CUIDADO_COMPARTILHADO &&
                      (PILOTO_CUIDADO_COMPARTILHADO_2_ENABLED ? (
                        <CompartilhamentoCuidadoTable
                          prontuarioId={prontuarioId}
                          atendimentoId={atendimentoId}
                          viaFolhaRosto
                          {...accordionProps}
                        />
                      ) : (
                        <CuidadoCompartilhadoTable prontuarioId={prontuarioId} viaFolhaRosto {...accordionProps} />
                      ))}
                  </>
                )}
              </PlanoTabs>
            </VFlow>
          </VFlow>
        </SoapSection>
      </AccordionItem>
    </Accordion>
  )
}
