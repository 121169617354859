import { CidadaoAtendimentoPorCpfSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CidadaoAtendimentoPorCpfSelectFieldQuery,
  CidadaoAtendimentoPorCpfSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'
import { CPF_REGEX, CPF_REGEX_MASK } from 'util/validation'

import { CidadaoAtendimentoSelectFieldModel } from '../CidadaoAtendimentoSelectField'
import { CidadaoAtendimentoSelectFieldCommon } from '../CidadaoAtendimentoSelectField/CidadaoAtendimentoSelectFieldCommon'
import { useAsyncQuerySelect } from '../useAsyncQuerySelect'
import { CidadaoAtendimentoPorPropriedadeEmptyItem } from './CidadaoAtendimentoPorPropriedadeEmptyItem'
import { CidadaoAtendimentoPorPropriedadeLoadingItem } from './CidadaoAtendimentoPorPropriedadeLoadingItem'
import { CidadaoAtendimentoPorPropriedadeSelectFieldProps } from './CidadaoAtendimentoPorPropriedadeSelectField'
import { commonCidadaoAtendimentoPorPropriedadeSelectFieldInput } from './util-cidadaoAtendimentoPorPropriedadeSelectField'

interface CidadaoAtendimentoPorCpfSelectFieldProps extends CidadaoAtendimentoPorPropriedadeSelectFieldProps {
  addCidadaoCallbackUrl: string
}

export function CidadaoAtendimentoPorCpfSelectField(props: CidadaoAtendimentoPorCpfSelectFieldProps) {
  const { name, addCidadaoCallbackUrl, onChange } = props

  const { skipping, selectProps } = useAsyncQuerySelect<
    CidadaoAtendimentoSelectFieldModel,
    CidadaoAtendimentoPorCpfSelectFieldQuery,
    CidadaoAtendimentoPorCpfSelectFieldQueryVariables
  >({
    query: CidadaoAtendimentoPorCpfSelectFieldDocument,
    extractItems: (data) => data && data.cidadaosComboByCpf,
    variables: (inputString) => ({
      input: {
        ...commonCidadaoAtendimentoPorPropriedadeSelectFieldInput,
        query: inputString,
      },
    }),
    skip: (inputString) => skipBuscaCidadaoAtendimentoPorCpf(inputString),
    debounceTime: 500,
  })

  return (
    <CidadaoAtendimentoSelectFieldCommon
      name={name}
      addCidadaoCallbackUrl={addCidadaoCallbackUrl}
      placeholder='Digite o CPF completo do cidadão'
      components={{
        EmptyItem: () => (
          <CidadaoAtendimentoPorPropriedadeEmptyItem
            skipping={skipping}
            skippingText='Informe um CPF válido para realizar a busca'
          />
        ),
        LoadingItem: () => <CidadaoAtendimentoPorPropriedadeLoadingItem />,
      }}
      selectProps={selectProps}
      onChange={onChange}
    />
  )
}

function skipBuscaCidadaoAtendimentoPorCpf(input: string): boolean {
  if (!input) return true

  const trimmedInput = input.trim()
  return !CPF_REGEX.test(trimmedInput) && !CPF_REGEX_MASK.test(trimmedInput)
}
