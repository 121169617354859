/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { Box } from 'components/Box'
import { ReactNode } from 'react'

interface AgendamentoBoxProps {
  children: ReactNode
}

export function AgendamentoBox({ children }: AgendamentoBoxProps) {
  const theme = useTheme()
  const styles = createStyles(theme)

  return <Box style={styles.box}>{children}</Box>
}

const createStyles = (theme: Theme) => ({
  box: css`
    border: 1px solid ${theme.pallete.gray.c80};
    border-radius: ${theme.radius.popper}px;
    box-sizing: border-box;
    box-shadow: ${theme.shadows.outer[40]};
    width: 47.75rem;
  `,
})
