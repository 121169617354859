import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { useAgendaAuthorizationQuery } from 'graphql/hooks.generated'
import { useCallback, useMemo } from 'react'
import Permissions from 'types/Permissions'

import { LotacaoAgendaModel } from '../model-agenda'

export function useAgendaAuthorization(isAd: boolean = false) {
  const {
    data: {
      profissional: { id: profissionalAcessoId },
    },
  } = useSession({ fetchPolicy: 'cache-first' })

  const { hasAuthorization, acesso } = useAcessoLotacaoOrEstagio()

  const equipeAcessoId = acesso?.equipe?.id

  const {
    data: { equipe: { emapsApoio: emapsQueApoiamEquipeAcesso, equipesVinculadas } = {} },
  } = useAgendaAuthorizationQuery({
    variables: { equipeId: equipeAcessoId, isAd },
    skip: !equipeAcessoId,
  })

  const idsEquipesPodeAlterar = useMemo(
    () =>
      new Set<ID>([
        equipeAcessoId,
        ...(emapsQueApoiamEquipeAcesso?.map((e) => e.id) ?? []),
        ...(equipesVinculadas?.map((e) => e.id) ?? []),
      ]),
    [equipeAcessoId, emapsQueApoiamEquipeAcesso, equipesVinculadas]
  )

  const hasAuthorizationAgendar = useCallback(
    ({ equipe, profissional }: LotacaoAgendaModel) =>
      hasAuthorization(Permissions.visualizarAgenda.agendarEEditar) &&
      (profissional.id === profissionalAcessoId ||
        !equipe ||
        idsEquipesPodeAlterar.has(equipe.id) ||
        hasAuthorization(Permissions.visualizarAgenda.agendarEEditar.todasAsEquipes)),
    [hasAuthorization, idsEquipesPodeAlterar, profissionalAcessoId]
  )

  const hasAuthorizationAgendarAd = useCallback(
    ({ equipe }: LotacaoAgendaModel) =>
      hasAuthorization(
        Permissions.atencaoDomiciliar.visualizarAgendaDeAdDaEquipe.visualizarAgendaDeAdDoMunicipio.agendarEEditar
      ) ||
      (idsEquipesPodeAlterar.has(equipe?.id) &&
        hasAuthorization(Permissions.atencaoDomiciliar.visualizarAgendaDeAdDaEquipe.agendarEEditar)),
    [hasAuthorization, idsEquipesPodeAlterar]
  )

  const hasAuthorizationCancelar = useCallback(
    ({ equipe, profissional }: LotacaoAgendaModel) =>
      hasAuthorization(Permissions.visualizarAgenda.cancelar) &&
      (profissional.id === profissionalAcessoId ||
        !equipe ||
        equipeAcessoId === equipe.id ||
        hasAuthorization(Permissions.visualizarAgenda.cancelar.todasAsEquipes)),
    [equipeAcessoId, hasAuthorization, profissionalAcessoId]
  )

  const hasAuthorizationCancelarAd = useCallback(
    ({ equipe }: LotacaoAgendaModel) =>
      hasAuthorization(
        Permissions.atencaoDomiciliar.visualizarAgendaDeAdDaEquipe.visualizarAgendaDeAdDoMunicipio.cancelar
      ) ||
      (idsEquipesPodeAlterar.has(equipe?.id) &&
        hasAuthorization(Permissions.atencaoDomiciliar.visualizarAgendaDeAdDaEquipe.cancelar)),
    [hasAuthorization, idsEquipesPodeAlterar]
  )

  return useMemo(
    () => ({
      hasAuthorizationAgendar: isAd ? hasAuthorizationAgendarAd : hasAuthorizationAgendar,
      hasAuthorizationCancelar: isAd ? hasAuthorizationCancelarAd : hasAuthorizationCancelar,
    }),
    [hasAuthorizationAgendar, hasAuthorizationAgendarAd, hasAuthorizationCancelar, hasAuthorizationCancelarAd, isAd]
  )
}
