import { Cell, Grid, HFlow, Text, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useErrorHandler } from 'components/error'
import { Form, NumberField, ProfissionalSelectField, RadioField, SubmitButton, TextField } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { TipoEventoSelectField } from 'components/form/field/select/TipoEventoSelectField/TipoEventoSelectField'
import { TipoRegistroAfetadoSelectField } from 'components/form/field/select/TipoRegistroAfetadoSelectField/TipoRegistroAfetadoSelectField'
import { useProcessarAuditoriaMutation } from 'graphql/hooks.generated'
import { TipoArquivoEnum } from 'graphql/types.generated'
import moment from 'moment'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { createValidator, periodBeforeEqualToday, required, requiredPeriod } from 'util/validation'
import convertModelToInput from 'view/auditoria/convertModelToInput'

import { metaPath } from '../../util/metaPath'
import AuditoriaFormModel from './AuditoriaFormModel'

export const validate = createValidator<AuditoriaFormModel>(
  {
    periodo: [requiredPeriod, periodBeforeEqualToday],
    tipoArquivo: [required],
  },
  (values, errors) => {
    if (!errors.periodo) {
      if (
        values.periodo?.startDate &&
        values.periodo?.endDate &&
        moment(values.periodo.startDate).isAfter(moment(values.periodo.endDate))
      ) {
        errors.periodo = 'Data inicio deve ser anterior ou igual à Data fim.'
      }
      return errors
    }
  }
)

const path = metaPath<AuditoriaFormModel>()

export function AuditoriaForm() {
  const handleRejection = useErrorHandler()
  const [processarAuditoria] = useProcessarAuditoriaMutation()
  const alert = useAlert()

  const onSubmit = (values: AuditoriaFormModel) => {
    return processarAuditoria({ variables: { input: convertModelToInput(values) } })
      .then(() => {
        alert(
          'success',
          `Gerando ${
            values.tipoArquivo === TipoArquivoEnum.CSV ? 'CSV' : 'PDF'
          } de trilha de auditoria ${moment().format('LT')}`
        )
      })
      .catch(handleRejection)
  }

  const renderForm = (formProps: FormRenderProps) => {
    return (
      <VFlow>
        <Grid wrap>
          <Cell xs={12} md={4}>
            <DateRangeField name={path.periodo} label='Período' maxDate={new Date()} required />
          </Cell>
          <Cell xs={12} md={4}>
            <TipoEventoSelectField name={path.tiposEvento} label='Tipo de evento' multiple />
          </Cell>
          <Cell xs={12} md={4}>
            <TextField name={path.detalheEvento} label='Detalhes do evento' placeholder='Ex.: Hórus' maxLength={200} />
          </Cell>
          <Cell xs={12} md={4}>
            <ProfissionalSelectField name={path.usuariosGerador} label='Usuário gerador do evento' multiple />
          </Cell>
          <Cell xs={12} md={4}>
            <TipoRegistroAfetadoSelectField
              name={path.tiposRegistroAfetado}
              label='Tipo de registro afetado'
              multiple
            />
          </Cell>
          <Cell xs={12} md={4}>
            <NumberField name={path.idRegistroAfetado} label='Identificador do registro afetado' maxLength={10} />
          </Cell>
          <Cell xs={12} md={4}>
            <TextField
              name={path.componenteGerador}
              label='Componente gerador'
              placeholder='Ex.: 200.148.12.188'
              maxLength={150}
            />
          </Cell>
          <Cell xs={12} md={2}>
            <VFlow vSpacing={0.8}>
              <Text>
                <b>Tipo de arquivo gerado</b>
              </Text>
              <HFlow>
                <RadioField name='tipoArquivo' label='PDF' value={TipoArquivoEnum.PDF} required />
                <RadioField name='tipoArquivo' label='CSV' value={TipoArquivoEnum.CSV} required />
              </HFlow>
            </VFlow>
          </Cell>
        </Grid>

        <HFlow justifyContent='flex-end'>
          <SubmitButton
            kind='primary'
            size='medium'
            handleSubmit={formProps.handleSubmit}
            data-testid='gerarTrilhaAuditoria'
          >
            Gerar trilha de auditoria
          </SubmitButton>
        </HFlow>
      </VFlow>
    )
  }

  return (
    <Form
      render={renderForm}
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{ tipoArquivo: TipoArquivoEnum.PDF }}
    />
  )
}
