import { Button, ButtonGroup, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { useState } from 'react'
import React from 'react'

import { AgendamentoBox } from '../components/AgendamentoBox'
import { LotacaoCadastroAgendamentoText } from '../components/LotacaoCadastroAgendamentoText'
import { LotacaoAgendaModel, TipoAgendamento } from '../model-agenda'
import { AgendamentoConsultaCompartilhadaForm } from './AgendamentoConsultaCompartilhadaForm'
import { AgendamentoConsultaForm } from './AgendamentoConsultaForm'
import { AgendamentoReservaForm } from './AgendamentoReservaForm'

export interface AgendamentoCadastroViewProps {
  lotacao: LotacaoAgendaModel
  isAtencaoDomiciliar?: boolean
  onSubmit?(): void
  onClose?(): void
  start: number
  end: number
  defaultTab?: TipoAgendamento.CONSULTA | TipoAgendamento.RESERVA | TipoAgendamento.CONSULTA_COMPARTILHADA
}

export const AgendamentoCadastroView = (props: AgendamentoCadastroViewProps) => {
  const {
    lotacao,
    onSubmit,
    onClose,
    start,
    end,
    isAtencaoDomiciliar = false,
    defaultTab = TipoAgendamento.CONSULTA,
  } = props

  const { PILOTO_VIDEOCHAMADA_2_ENABLED } = useFlags()

  const [activeTab, setActiveTab] = useState(defaultTab)
  const isActiveTabAsConsulta = activeTab === TipoAgendamento.CONSULTA
  const isActiveTabAsReserva = activeTab === TipoAgendamento.RESERVA
  const isActiveTabAsConsultaCompartilhada = activeTab === TipoAgendamento.CONSULTA_COMPARTILHADA

  return (
    <AgendamentoBox>
      <VFlow>
        <LotacaoCadastroAgendamentoText lotacao={lotacao} />
        <ButtonGroup>
          <Button
            kind={isActiveTabAsConsulta ? 'primary' : 'normal'}
            size='small'
            onClick={() => setActiveTab(TipoAgendamento.CONSULTA)}
          >
            Consulta
          </Button>
          <Button
            kind={isActiveTabAsReserva ? 'primary' : 'normal'}
            size='small'
            onClick={() => setActiveTab(TipoAgendamento.RESERVA)}
          >
            Reserva
          </Button>
          {PILOTO_VIDEOCHAMADA_2_ENABLED && !isAtencaoDomiciliar && (
            <Button
              kind={isActiveTabAsConsultaCompartilhada ? 'primary' : 'normal'}
              size='small'
              onClick={() => setActiveTab(TipoAgendamento.CONSULTA_COMPARTILHADA)}
            >
              Entre profissionais
            </Button>
          )}
        </ButtonGroup>
        {isActiveTabAsReserva && (
          <AgendamentoReservaForm
            start={start}
            end={end}
            isAtencaoDomiciliar={isAtencaoDomiciliar}
            onClose={onClose}
            lotacaoId={lotacao.id}
            onSubmit={onSubmit}
          />
        )}
        {isActiveTabAsConsulta && (
          <AgendamentoConsultaForm
            start={start}
            isAtencaoDomiciliar={isAtencaoDomiciliar}
            onClose={onClose}
            lotacao={lotacao}
            onSubmit={onSubmit}
          />
        )}
        {PILOTO_VIDEOCHAMADA_2_ENABLED && !isAtencaoDomiciliar && isActiveTabAsConsultaCompartilhada && (
          <AgendamentoConsultaCompartilhadaForm start={start} onClose={onClose} lotacao={lotacao} onSubmit={onSubmit} />
        )}
      </VFlow>
    </AgendamentoBox>
  )
}
