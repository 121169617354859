import { CiapCidSelectModel } from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import { GrupoCondicaoSelectModel } from 'components/form/field/select/GrupoCondicaoSelectField'
import {
  AggregatorRelatorioGerencialEnum,
  CampoSalvoRelatorioGerencialInput,
  OpcaoSelecionadaRelatorioGerencialEnum,
  TipoModuloEnum,
  UnidadePeriodoRelatorioGerencialEnum,
} from 'graphql/types.generated'

export interface ModeloPersonalizadoRelatorioGerencialFormModel {
  id?: string
  nome: string
  modulo: TipoModuloEnum
  unidadePeriodoModal: UnidadePeriodoRelatorioGerencialEnum
  opcaoSelecionadaModal: OpcaoSelecionadaRelatorioGerencialEnum
  ciapsCids: CiapCidSelectModel[]
  gruposCondicoes: GrupoCondicaoSelectModel[]
  campos: ModeloPersonalizadoRelatorioGerencialCampoModel[]
  aggregatorModal: AggregatorRelatorioGerencialEnum
  visibilidade: VisibilidadeModeloPersonalizadoEnum
}

export interface ModeloPersonalizadoRelatorioGerencialCampoModel extends CampoSalvoRelatorioGerencialInput {
  _id: ID
  isInconsistente?: boolean
  isAdicionadoAgora?: boolean
  isInvalido?: boolean
}

export enum VisibilidadeModeloPersonalizadoEnum {
  PUBLICO = 'PUBLICO',
  PRIVADO = 'PRIVADO',
}
