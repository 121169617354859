/** @jsx jsx */

import { QueryLazyOptions } from '@apollo/react-hooks'
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Tag, Text, useTheme, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { isBefore, parseISO } from 'date-fns/esm'
import { RegistrosDoseVacinacaoQueryVariables } from 'graphql/types.generated'
import React from 'react'
import { DoseItemModel, statusDoseRecord } from 'view/atendimentos/atendimento-vacinacao/model'

import { InfoCardModalVacinacao } from '../../vacinacao/VacinacaoCalendarioView'

export interface DoseItemProps {
  model: DoseItemModel
  handleOnDoseClicked: (info: InfoCardModalVacinacao) => void
  registrosDoseLazyQuery: (options?: QueryLazyOptions<RegistrosDoseVacinacaoQueryVariables>) => void
  readOnly?: boolean
  isAcompanhamentoVacinal?: boolean
}

export function DoseItem(props: DoseItemProps) {
  const {
    model: {
      nome,
      status,
      idadeRecomendada,
      dataAprazamento,
      registradoAtendimento,
      doseId,
      imunobiologicoId,
      prontuarioId,
      imunobiologicoNomeCompleto,
      imunobiologicoSigla,
      cidadaoCpf,
      cidadaoId,
      dataAplicacao,
      quantidadeAplicacoes,
      dataRegistroAprazamento,
      hasDoseEquivalenteOnly,
    },
    readOnly = false,
  } = props
  const theme = useTheme()
  const record = statusDoseRecord[status]
  const aplicacoesPlural = quantidadeAplicacoes > 1 ? ' aplicações' : ' aplicação'
  const apresentaDataAprazamento =
    dataAprazamento &&
    ((dataAplicacao && dataRegistroAprazamento && !isBefore(dataRegistroAprazamento, parseISO(dataAplicacao))) ||
      !dataAplicacao)

  const onDoseClicked = () => {
    props.registrosDoseLazyQuery({
      variables: {
        input: {
          doseId: doseId,
          imunobiologicoId: imunobiologicoId,
          prontuarioId: prontuarioId,
          cidadaoId: cidadaoId,
          cpf: cidadaoCpf,
          hasDoseEquivalenteOnly: hasDoseEquivalenteOnly,
        },
      },
    })

    props.handleOnDoseClicked({
      imunobiologico: {
        id: imunobiologicoId,
        nome: imunobiologicoNomeCompleto,
        sigla: imunobiologicoSigla,
      },
      doseId: doseId,
      doseNome: nome,
      idadeRecomendada: idadeRecomendada,
      registradoAtendimento: registradoAtendimento,
      status: status,
      hasRegistro: !!dataAplicacao || !!dataAprazamento,
    })
  }

  const isAplicada = !!dataAplicacao || !!dataAprazamento

  const style = css`
    border: 1px solid ${record.borderColor};
    background: ${record.backgroundColor};
    box-sizing: border-box;
    box-shadow: ${theme.shadows.outer[10]};
    &:hover {
      box-shadow: ${theme.shadows.outer[!readOnly || isAplicada ? 40 : 10]};
      cursor: ${!readOnly || isAplicada ? 'pointer' : 'auto'};
    }
    border-radius: 2px;
    min-height: 4.25rem;
    width: 100%;
    padding: 0.375rem;
  `

  return (
    <React.Fragment>
      <div css={style} onClick={!readOnly || isAplicada ? onDoseClicked : undefined}>
        <VFlow vSpacing={0}>
          <div style={{ padding: '0.125rem' }}>
            <HFlow
              hSpacing={0.5}
              alignItems='center'
              justifyContent='space-between'
              style={{ marginBottom: '0.625rem' }}
            >
              <Text fontWeight='bold' style={{ color: record.fontColor }}>
                {nome}
              </Text>
              {registradoAtendimento && <Icon icon='clockOutline' color={theme.pallete.primary.main} />}
            </HFlow>
            <HFlow hSpacing={0.25} alignItems='baseline' style={{ paddingLeft: '0.125rem' }}>
              <Text>
                {idadeRecomendada && !dataAplicacao
                  ? idadeRecomendada.capitalize()
                  : !idadeRecomendada && !dataAplicacao
                  ? ''
                  : quantidadeAplicacoes + aplicacoesPlural}
              </Text>
            </HFlow>
          </div>
          <VFlow vSpacing={dataAplicacao && dataAprazamento ? 0.3 : 0}>
            {dataAplicacao && (
              <Tag type='success'>
                <HFlow hSpacing={0.3}>
                  <Icon icon='needleFilled' size={1} />
                  <DateTime
                    value={dataAplicacao}
                    style={css`
                      font-weight: bold;
                    `}
                    format='DD/MM/YYYY'
                  />
                </HFlow>
              </Tag>
            )}
            {apresentaDataAprazamento && (
              <Tag
                style={css`
                  background-color: ${record.tagColor};
                  color: ${record.tagTextColor};
                `}
              >
                <HFlow hSpacing={0.3}>
                  <Icon icon='calendarFilled' size={1} />
                  <DateTime
                    value={dataAprazamento}
                    style={css`
                      font-weight: bold;
                    `}
                    format='DD/MM/YYYY'
                  />
                </HFlow>
              </Tag>
            )}
          </VFlow>
        </VFlow>
      </div>
    </React.Fragment>
  )
}
