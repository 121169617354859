import { createValidator, ErrorObject, required } from 'util/validation'

import { VincularEstabelecimentoReferenciaFormModel } from './model-vincularEstabelecimentoReferencia'

export const vincularEstabelecimentoValidator = (isEdit: boolean) =>
  createValidator<VincularEstabelecimentoReferenciaFormModel>(
    {
      referencia: required,
    },
    (
      values: VincularEstabelecimentoReferenciaFormModel,
      errors: ErrorObject<VincularEstabelecimentoReferenciaFormModel>
    ) => {
      if (values.vinculacoes.length === 0 && !(values.referencia?.hasVinculacaoServico || isEdit)) {
        errors.vinculacoes = required(values.vinculacoes)
      }
      return errors
    }
  )
