import { Text, useStyles, VFlow } from 'bold-ui'
import { CheckboxField } from 'components/form'
import { useTipoServicoCheckQuery } from 'graphql/hooks.generated'
import React, { CSSProperties } from 'react'
import { MetaPath } from 'util/metaPath'

export interface TipoServicoCheckFieldProps {
  name: MetaPath<string>
  disabled?: boolean
}

export function TipoServicoCheckField(props: TipoServicoCheckFieldProps) {
  const { data } = useTipoServicoCheckQuery({
    fetchPolicy: 'cache-first',
    variables: { input: { pageParams: { sort: ['nome'], size: 100 }, unidadeSaudeSessao: true } },
  })
  const { classes } = useStyles(createStyles)

  if (!data.tiposServico) {
    return null
  }

  return (
    <VFlow vSpacing={0.5}>
      <Text fontWeight='bold'>Tipo de serviço</Text>
      {data.tiposServico.content.length === 0 ? (
        <Text>Todos os tipos de serviço estão inativos e não podem ser selecionados</Text>
      ) : (
        <div className={classes.container}>
          {data.tiposServico.content.map((tipoServico) => (
            <div key={tipoServico.id}>
              <CheckboxField
                name={props.name}
                value={tipoServico.id}
                label={<span style={{ wordBreak: 'break-word' }}>{tipoServico.nome.toUpperCase()}</span>}
                style={classes.checkBox}
                disabled={props.disabled}
              />
            </div>
          ))}
        </div>
      )}
    </VFlow>
  )
}

const createStyles = () => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '33% 33% 33%',
    gridAutoFlow: 'row',
    gridGap: '0 0.5rem',
    alignItems: 'center',
  } as CSSProperties,

  checkBox: {
    padding: '0.25rem 0',
  },
})
