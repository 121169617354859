import { HFlow, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { SelectField } from 'components/form'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_PAGE_PARAM,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { CidadaoAdSelectFieldDocument } from 'graphql/hooks.generated'
import {
  AtencaoDomiciliarCondutaDesfecho,
  CidadaoAdSelectFieldQuery,
  CidadaoAdSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

export interface CidadaoADSelectFieldProps extends AsyncSelectFieldProps<CidadaoAdSelectFieldModel> {
  condutaDesfecho: AtencaoDomiciliarCondutaDesfecho
}

export type CidadaoAdSelectFieldModel = CidadaoAdSelectFieldQuery['cidadaosAD']['content'][0]

export function CidadaoADSelectField(props: CidadaoADSelectFieldProps) {
  const { condutaDesfecho, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    CidadaoAdSelectFieldModel,
    CidadaoAdSelectFieldQuery,
    CidadaoAdSelectFieldQueryVariables
  >({
    query: CidadaoAdSelectFieldDocument,
    extractItems: (data) => data?.cidadaosAD?.content,
    variables: (inputString) => ({
      input: {
        query: inputString,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
        },
        condutaDesfecho: condutaDesfecho,
      },
    }),
  })

  const itemToString = (item: CidadaoAdSelectFieldModel) => item?.cidadao?.nomeSocial || item?.cidadao?.nome

  const renderItem = (item: CidadaoAdSelectFieldModel) => {
    return (
      <VFlow vSpacing={0}>
        <b>
          {item?.cidadao?.nomeSocial ? `${item.cidadao?.nomeSocial} (${item?.cidadao?.nome})` : item?.cidadao?.nome}
        </b>
        <HFlow>
          <HFlow hSpacing={0.25}>
            <Text fontWeight='bold'>CPF</Text>
            <Text>{item?.cidadao?.cpf ? <Cpf value={item?.cidadao?.cpf} /> : '  —'}</Text>
          </HFlow>
          <HFlow hSpacing={0.25}>
            <Text fontWeight='bold'>CNS</Text>
            <Text>{item?.cidadao?.cns || '  —'}</Text>
          </HFlow>
        </HFlow>
        <HLabel title='Nascimento'>
          <DateTime value={item.cidadao.dataNascimento} format='DD/MM/YYYY' />
        </HLabel>
        <HLabel title='Nome da mãe'>{item.cidadao.nomeMae || 'Desconhece a informação'}</HLabel>
      </VFlow>
    )
  }

  return (
    <SelectField<CidadaoAdSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...selectProps}
      {...rest}
    />
  )
}
