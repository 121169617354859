/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tabs } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { TabLink } from 'components/route'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import { grupoCboVisualizarProntuario } from 'view/cidadao/acessos'

import { grupoCboHistorico } from '../acessos'

export interface AtendimentoHeaderTabsProps {
  cidadaoId: ID
  labelEscutaPreAtendimento?: string
  renderFolhaRosto?: boolean
  renderSoap?: boolean
  renderVacinacao?: boolean
  renderHistorico?: boolean
  renderCadastroCidadao?: boolean
  renderEscutaInicial?: boolean
  renderAgendamentos?: boolean
  renderDiscussaoCaso?: boolean
}

export function AtendimentoHeaderTabs(props: AtendimentoHeaderTabsProps) {
  const {
    cidadaoId,
    labelEscutaPreAtendimento,
    renderFolhaRosto = true,
    renderSoap = true,
    renderVacinacao = true,
    renderHistorico = true,
    renderCadastroCidadao = true,
    renderAgendamentos = true,
    renderEscutaInicial = false,
    renderDiscussaoCaso,
  } = props

  const { analytics } = useFirebase()
  const match = useRouteMatch()

  return (
    <Tabs
      style={css`
        border-bottom-width: 0;
      `}
    >
      {renderFolhaRosto && (
        <CheckPermission
          hasAnyCbo={[grupoCboVisualizarProntuario]}
          hasAnyPermission={[Permissions.visualizarProntuario]}
        >
          <TabLink to={`${match.url}/folha-rosto`}>Folha de rosto</TabLink>
        </CheckPermission>
      )}
      {renderSoap && <TabLink to={`${match.url}/soap`}>SOAP</TabLink>}
      {renderEscutaInicial && <TabLink to={`${match.url}/escuta-inicial`}> {labelEscutaPreAtendimento}</TabLink>}
      {renderHistorico && (
        <CheckPermission permission={grupoCboHistorico.visualizar}>
          <TabLink to={`${match.url}/historico`} onClick={() => analytics.logEvent('click_tab_atendimento_historico')}>
            Histórico
          </TabLink>
        </CheckPermission>
      )}
      {renderVacinacao && <TabLink to={`${match.url}/vacinacao`}>Vacinação</TabLink>}

      {renderCadastroCidadao && (
        <CheckPermission permission={Permissions.gestaoDeCadastrosDeCidadao}>
          <TabLink to={`${match.url}/cadastro-cidadao`}>Cadastro do cidadão</TabLink>
        </CheckPermission>
      )}
      {renderAgendamentos && (
        <CheckPermission permission={Permissions.visualizarAgenda}>
          <TabLink to={`${match.url}/agendamentos/${cidadaoId}`}>Agendamentos</TabLink>
        </CheckPermission>
      )}

      {renderDiscussaoCaso && <TabLink to={`${match.url}/discussao-caso`}>Discussão de caso</TabLink>}
    </Tabs>
  )
}
