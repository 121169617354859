/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, InfoLabel } from 'bold-ui'
import { formatDate } from 'util/date/formatDate'

import { ResultadoExameEspecificoModel, ResultadoExameSemSolicitacaoModel } from '../model-resultadosExames'
import { getResultadoExameEspecificoView, isExameEspecifico, showCodigoProcedimentoRef } from '../util-resultadosExames'
import { ResultadoExameListItemContainer } from './ResultadoExameListItemContainer'

interface ResultadoExameSemSolicitacaoListItemProps {
  item: ResultadoExameSemSolicitacaoModel & ResultadoExameEspecificoModel
}

export const ResultadoExameSemSolicitacaoListItem = ({ item }: ResultadoExameSemSolicitacaoListItemProps) => {
  const { exame, resultado, dataRealizado, dataResultado, dataSolicitado } = item

  const isEspecifico = isExameEspecifico(exame)
  const ResultadoExameEspecificoView = getResultadoExameEspecificoView(exame)

  return (
    <ResultadoExameListItemContainer title={showCodigoProcedimentoRef(exame, true)}>
      <HFlow hSpacing={2}>
        <InfoLabel title='Data de realização'>{formatDate(dataRealizado)}</InfoLabel>

        <InfoLabel title='Data de resultado'>{formatDate(dataResultado)}</InfoLabel>

        <InfoLabel title='Data de solicitação'>{formatDate(dataSolicitado)}</InfoLabel>

        {isEspecifico && <ResultadoExameEspecificoView value={resultado} exame={exame} />}
      </HFlow>

      <InfoLabel
        title={isEspecifico ? 'Descrição' : 'Resultado'}
        childStyles={css`
          word-break: break-word;
          white-space: pre-line;
        `}
      >
        {isEspecifico ? item.descricao : resultado}
      </InfoLabel>
    </ResultadoExameListItemContainer>
  )
}
